import React, { ReactElement, ReactNode, SyntheticEvent, useCallback } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { isString } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgButton } from '../arg-button/arg-button';

import './arg-breadcrumb-item.less';

const renderSeparator = (separatorColor: string, separator: ReactNode, index: number) => {
    if (index === 0) {
        return;
    }

    if (isString(separator)) {
        return (
            <ArgIcon
                name={separator}
                color={separatorColor}
                data-testid={`arg-${separator}-breadcrumb-separator`}
            />
        );
    } else {
        return separator;
    }
};

export interface ArgBreadcrumbItemProps {
    label: MessageDescriptor | ReactNode,
    icon?: ReactNode,
    onClick?: (evt: SyntheticEvent) => void,
    className?: ClassValue,
    name: string,
    path?: string,
    index: number,
    separator: string | ReactElement,
    separatorColor: string,
}

export function ArgBreadcrumbItem (props: ArgBreadcrumbItemProps) {
    const {
        label,
        icon,
        onClick,
        className,
        name,
        path,
        separator,
        separatorColor,
        index,
    } = props;

    const classNames = useClassNames('arg-breadcrumb-item');
    const history = useHistory();
    const handleClick = useCallback((evt: SyntheticEvent) => {
        onClick?.(evt);

        if ((onClick && evt.isDefaultPrevented()) || !path) {
            return;
        }

        history.push(path);
    }, [onClick, path, history]);

    const separatorComponent = renderSeparator(separatorColor, separator, index);

    return (
        <div key={name} className={classNames('&', className)}>
            {separatorComponent}
            <ArgButton
                type='ghost'
                className={classNames('&-label')}
                onClick={handleClick}
                icon={typeof icon === 'string' ? <ArgIcon name={icon} data-testid={`arg-${icon}-breadcrumb`} /> : icon}
                label={label}
            />
        </div>
    );
}
