import Debug from 'debug';

import {
    DataFilter,
    DataProvider,
    DataSorter,
    OnCleared,
    OnDestroy,
    OnLoaded,
    OnLoading,
    OnRowCountChanged,
} from './data-provider';
import { ArgTable3RowState } from '../arg-table3/arg-table3';
import { ProgressMonitorsFactoryType } from '../progress-monitors/use-progress-monitors';
import { ProgressMonitor, ProgressMonitorOptions } from '../progress-monitors/progress-monitor';

const debug = Debug('basic:components:basic:PagedDataProvider');

const DEFAULT_LOAD_PAGE_TIMEOUT = 200;
const DEFAULT_REFRESH_PAGE_TIMEOUT = 1;

interface Page<T> {
    error?: Error;
    progressMonitor: ProgressMonitor;
    index: number;
    rows: Map<number, T>;
}

export class PagedDataProvider<T, F extends DataFilter = any> extends DataProvider<T, F> {
    #progressMonitorsFactory?: ProgressMonitorsFactoryType;
    #progressMonitorOptions?: ProgressMonitorOptions;
    #pageRowCount: number;
    #pages: Map<number, Page<T>>;
    #filter?: F;
    #sorter?: DataSorter;

    #totalRowCount?: number;

    #viewportFirst: number | undefined;
    #viewportLast: number | undefined;

    searchTerm: string | undefined = undefined;

    readonly #loadPageTimeout: number;
    readonly #refreshPageTimeout: number;

    constructor(
        pageRowCount: number,
        progressMonitorsFactory?: ProgressMonitorsFactoryType,
        progressMonitorOptions?: ProgressMonitorOptions,
        loadPageTimeout?: number) {
        super();

        debug('constructor()');
        this.#progressMonitorsFactory = progressMonitorsFactory;
        this.#progressMonitorOptions = progressMonitorOptions;
        this.#pages = new Map();
        this.#pageRowCount = pageRowCount;
        this.#loadPageTimeout = loadPageTimeout || DEFAULT_LOAD_PAGE_TIMEOUT;
        this.#refreshPageTimeout = DEFAULT_REFRESH_PAGE_TIMEOUT;
    }

    protected get totalRowCountMayVary(): boolean {
        return false;
    }

    get pageRowCount(): number {
        return this.#pageRowCount;
    }

    setSearchTerm(searchTerm: string | undefined) {
        this.searchTerm = searchTerm;
    }

    setFilter(filter?: F, sorter?: DataSorter) {
        this.#filter = filter;
        this.#sorter = sorter;
        this.clear();
    }

    setViewPort(first: number, last: number): void {
        if (this.#viewportFirst === first && this.#viewportLast === last) {
            return;
        }

        this._setViewPort(first, last, false);
    }

    _setViewPort(first: number, last: number, refreshMode: boolean): void {
        let index = first;

        this.#viewportFirst = first;
        this.#viewportLast = last;

        const oldPages = this.#pages;
        this.#pages = new Map();

        for (; index <= last;) {
            const pageIndex = Math.floor(index / this.#pageRowCount);
            index = (pageIndex + 1) * this.#pageRowCount;

            const page = oldPages.get(pageIndex);
            if (page) {
                oldPages.delete(pageIndex);
                // TODO: if refreshMode, we should flag page as outdated, so that we can properly delete the page when this.loadPageContent() fails during loadPage.
                this.#pages.set(pageIndex, page);
                if (!refreshMode) {
                    this.updateStateId();
                    continue;
                }
            }

            this.loadPage(pageIndex, refreshMode);
        }

        oldPages.forEach((page, index) => {
            if (page.progressMonitor?.isRunning) {
                page.progressMonitor.cancel();
            }

            this.emit(OnDestroy, index * this.#pageRowCount, (index + 1) * this.#pageRowCount - 1);
        });
    }

    /**
     * Don't use loadPage directly, you must use  setViewPort and getRow() to get datas
     *
     * @TODO  loadPage will be set to protected
     */
    loadPage(pageIndex: number, refreshMode?: boolean) {
        let progressMonitor: ProgressMonitor | undefined;

        if (this.#progressMonitorsFactory) {
            progressMonitor = this.#progressMonitorsFactory(`page:${pageIndex}`, `Loading page #${pageIndex}`, 1, this.#progressMonitorOptions);
        }

        if (!progressMonitor) {
            progressMonitor = new ProgressMonitor(`Loading page #${pageIndex}`, 1);
        }

        debug('loadPage', 'pageIndex=', pageIndex, 'refresh=', refreshMode);

        const page: Page<T> = {
            progressMonitor,
            index: pageIndex,
            rows: new Map(),
        };
        if (!refreshMode) {
            this.#pages.set(pageIndex, page);
        }

        this.emit(OnLoading, pageIndex * this.#pageRowCount, (pageIndex + 1) * this.#pageRowCount - 1);

        let $rows: T[] = [];
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (progressMonitor!.isCancelled) {
                return;
            }

            this.loadPageContent(pageIndex, this.#pageRowCount, this.#filter, this.#sorter, progressMonitor!)
                .then(
                    (rows) => {
                        $rows = rows;

                        let index = 0; // pageIndex * this.#pageRowCount;
                        const map: Map<number, T> = new Map();
                        for (let i = 0; i < rows.length; i++) {
                            map.set(index++, rows[i]);
                        }

                        page.rows = map;
                    },
                    (error) => {
                        page.error = error;
                        console.error(error);
                    }
                )
                .finally(() => {
                    if (refreshMode) {
                        this.#pages.set(pageIndex, page);
                    }

                    if (progressMonitor?.isCancelled) {
                        return;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    progressMonitor!.done();
                    this.updateStateId();
                    this.emit(OnLoaded, pageIndex * this.#pageRowCount, (pageIndex + 1) * this.#pageRowCount - 1, $rows);
                });
        }, (refreshMode ? this.#refreshPageTimeout : this.#loadPageTimeout));
    }

    getRow(rowIndex: number): T | ArgTable3RowState {
        const pageIndex = Math.floor(rowIndex / this.#pageRowCount);
        const rowInPage = rowIndex % this.#pageRowCount;

        const page = this.#pages.get(pageIndex);

        debug('getRow', 'rowIndex=', rowIndex, 'rowInPage=', rowInPage, '=> page=', page);

        if (page) {
            if (page.progressMonitor?.isRunning) {
                return ArgTable3RowState.Loading;
            }
            if (page.error) {
                return ArgTable3RowState.Error;
            }

            const row = page.rows.get(rowInPage);
            if (!row) {
                return ArgTable3RowState.Error;
            }

            return row;
        }
        this.loadPage(pageIndex);

        return ArgTable3RowState.Loading;
    }

    clear() {
        debug('clear');

        const oldPages = this.#pages;
        this.#pages = new Map();
        this.#viewportFirst = undefined;
        this.#viewportLast = undefined;
        this.#totalRowCount = undefined;
        this.updateStateId();

        oldPages.forEach((page, index) => {
            if (page.progressMonitor?.isRunning) {
                page.progressMonitor.cancel();
            }

            this.emit(OnDestroy, index * this.#pageRowCount, (index + 1) * this.#pageRowCount - 1);
        });

        this.emit(OnCleared);
    }

    /**
     * You must implement this method
     */
    protected async loadPageContent(
        pageIndex: number,
        pageSize: number,
        filter: F | undefined,
        sorter: DataSorter | undefined,
        progressMonitor: ProgressMonitor
    ): Promise<T[]> {
        return [];
    }

    get totalRowCount(): number | undefined {
        return this.#totalRowCount;
    }

    set totalRowCount(rowCount: number | undefined) {
        if ((rowCount !== undefined && this.#totalRowCount !== undefined) || (this.#totalRowCount === undefined && rowCount === undefined)) {
            if (!this.totalRowCountMayVary) {
                console.error(new Error('Already setted'));
            }
        }

        this.#totalRowCount = rowCount;
        this.emit(OnRowCountChanged);
    }

    listContent(): Map<number, T> {
        const ret = this.listContentByKey(((t, rowIndex) => rowIndex));

        return ret;
    }

    listContentByKey<K = number>(computeKey: (t: T, rowIndex: number) => K): Map<K, T> {
        const pageRowCount = this.#pageRowCount;

        const ret = new Map<K, T>();
        this.#pages.forEach((page: Page<T>, pageIndex: number): void => {
            page.rows.forEach((row: T, rowIndex: number): void => {
                const index = pageRowCount * pageIndex + rowIndex;

                const k = computeKey(row, index);

                ret.set(k, row);
            });
        });

        return ret;
    }

    get rowCount(): number | undefined {
        return this.totalRowCount;
    }

    refreshPages() {
        if (this.#viewportFirst === undefined || this.#viewportLast === undefined) {
            return;
        }

        this._setViewPort(this.#viewportFirst, this.#viewportLast, true);
    }
}
