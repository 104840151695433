// @ts-ignore
import _immutableSet from 'immutable-set';
import { get, isEqual, omit } from 'lodash';

type Options = Partial<{
    withArrays: boolean;
    equality: (prev: any, next: any) => any;
    safe: boolean;
    sameValue: boolean;
}>

export function immutableSet<T>(value: T, path: string | (string | number)[], nodeValue: any, options?: Options): T {
    const newValue = _immutableSet(value, path, nodeValue, options);

    return newValue;
}

export function immutableUpdate<T>(value: T, path: string | (string | number)[], update: (prev: any) => any, options?: Options): T {
    const nodeValue = get(value, path);
    const newNodeValue = update(nodeValue);

    if (isEqual(newNodeValue, nodeValue)) {
        return value;
    }

    const newValue = immutableSet(value, path, newNodeValue, options);

    return newValue;
}

export function immutableOmit<T>(value: T, path: string | (string | number)[], omitPropertiesName: string | (string | number)[], options?: Options): T {
    const nodeValue = get(value, path);
    const newNodeValue = omit(nodeValue, omitPropertiesName);

    if (isEqual(newNodeValue, nodeValue)) {
        return value;
    }

    const newValue = immutableSet(value, path, newNodeValue, options);

    return newValue;
}
