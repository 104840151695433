import React, { useCallback, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import { Resizable } from 're-resizable';
import { isEmpty, isNil, omit, values } from 'lodash';

// Hooks and components
import { ArgChangeReason } from '../../../types';
import { ArgMap } from '../map/arg-map';
import { parseRadius } from '../../helpers/parse-radius';
import { formatRadius } from '../../helpers/format-radius';
import { getDataTestIdFromProps } from '../../../utils';
import { ArgCombo } from '../../../arg-combo/arg-combo';
import { DEFAULT_RADIUS_UNIT, GeolocationValue, MapMode, RadiusUnit } from '../../model/geolocation-value';
import { ArgInputNumber } from '../../../arg-input/arg-input-number';
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { ProgressMonitor } from '../../../progress-monitors/progress-monitor';
import { LatitudeAndLongitudeResponse } from '../../model/geolocation-json';
import { ArgButton } from '../../../arg-button/arg-button';
import { ArgInputText } from '../../../arg-input/arg-input-text';
import { KeyBindingDescriptor } from '../../../keybindings/keybinding';
import { useProgressMonitor } from '../../../progress-monitors/use-progress-monitor';
import { useCallbackAsync } from 'src/components/basic/arg-hooks/use-callback-async';
import { useNotifications } from '../../../arg-notifications/arg-notifications';

// Styles
import './arg-geolocation-form.less';

export const POPOVER_MIN_WIDTH = '420px';
export const POPOVER_MAX_WIDTH = '630px';
export const POPOVER_DEFAULT_WIDTH = '416px';

export const POPOVER_MIN_HEIGHT = '277px';
export const POPOVER_MAX_HEIGHT = '416px';
export const POPOVER_DEFAULT_HEIGHT = '630px';

export const argGeolocationFormMessages = defineMessages({
    [RadiusUnit.Kilometer]: {
        id: 'basic.arg-geolocation-picker.form.radius.unit.values.kilometer',
        defaultMessage: 'km',
    },
    [RadiusUnit.Meter]: {
        id: 'basic.arg-geolocation-picker.form.radius.unit.values.meter',
        defaultMessage: 'm',
    },
    getCurrentLocation: {
        id: 'basic.arg-geolocation-picker.form.address.getCurrentLocation',
        defaultMessage: 'Get current location',
    },
    addressPlaceholder: {
        id: 'basic.arg-geolocation-picker.form.address.placeholder',
        defaultMessage: 'Define a place or area',
    },
    radiusPlaceholder: {
        id: 'basic.arg-geolocation-picker.form.radius.placeholder',
        defaultMessage: 'Radius',
    },
    radiusUnitPlaceholder: {
        id: 'basic.arg-geolocation-picker.form.radius.unit.placeholder',
        defaultMessage: 'Unit',
    },
    loadAddressError: {
        id: 'basic.arg-geolocation-picker.LoadAddressError',
        defaultMessage: 'Failed to retrieve the corresponding address',
    },
});

export interface ArgGeolocationFormProps {
    value?: GeolocationValue;
    className?: ClassValue;
    onChange: (value: GeolocationValue | null | ((prev: GeolocationValue | null) => GeolocationValue | null), reason?: ArgChangeReason) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
    zoomInKeyBinding?: KeyBindingDescriptor;
    zoomOutKeyBinding?: KeyBindingDescriptor;
}

export function ArgGeolocationForm(props: ArgGeolocationFormProps) {
    const {
        value,
        className,
        onChange,
        getAddressCoordinates,
        zoomInKeyBinding,
        zoomOutKeyBinding,
    } = props;

    const classNames = useClassNames('arg-geolocation-form');
    const notifications = useNotifications();

    const map = useRef(undefined as L.Map | undefined);

    const [initialAddress] = useState<string | undefined>(value?.address);

    const [getAddressProgressMonitor, createAddressProgressMonitor] = useProgressMonitor();

    const dataTestId = getDataTestIdFromProps(props);

    const cls = {};


    const handleInputAddressChange = useCallback((address: string) => {
        onChange((prev) => ({
            ...omit(prev, ['address', 'polygon', 'center']),
            address,
            mapMode: MapMode.CenterArea,
        }), 'keypress');
    }, [onChange]);

    const [handleAddressChange] = useCallbackAsync(async (progressMonitor: ProgressMonitor, address: string | null, reason?: ArgChangeReason) => {
        if (reason === 'clear' || !address) {
            // Cancel a previous the request
            progressMonitor.done();

            onChange(null, reason);

            return;
        }

        // Ignore the blur if the value doesn't change
        if (reason === 'blur' && value?.address === address) {
            return;
        }

        // Get lat/lng from searching API
        getAddressCoordinates(address, progressMonitor).then((center: LatitudeAndLongitudeResponse | undefined) => {
            onChange((prev) => ({
                ...omit(prev, ['area', 'polygon']),
                radius: prev?.radius || 0,
                mapMode: MapMode.CenterArea,
                center: center ? {
                    lat: center.latitude,
                    lng: center.longitude,
                } : undefined,
            }), 'debounce');
        }, (error) => {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.error({ message: argGeolocationFormMessages.loadAddressError }, error as Error);
        }).finally(() => {
            progressMonitor.done();
        });
    }, [value?.address, getAddressCoordinates, onChange]);

    const handleResize = useCallback(() => {
        map.current?.invalidateSize();
    }, [map]);

    const handleRadiusChange = useCallback((radius: number | null, _: ArgChangeReason) => {
        onChange((prev) => ({
            ...prev,
            radius: parseRadius(radius, prev?.radiusUnit),
        })); // Remove clear
    }, [onChange]);

    const handleRadiusUnitChange = useCallback((radiusUnit: RadiusUnit | undefined, reason: ArgChangeReason) => {
        onChange((prev) => ({
            ...prev,
            radiusUnit,
        }), reason);
    }, [onChange]);

    return (
        <Resizable
            data-testid={dataTestId}
            lockAspectRatio={true}
            onResize={handleResize}
            onResizeStop={handleResize}
            onResizeStart={handleResize}
            minWidth={POPOVER_MIN_WIDTH}
            maxWidth={POPOVER_MAX_WIDTH}
            minHeight={POPOVER_MIN_HEIGHT}
            maxHeight={POPOVER_MAX_HEIGHT}
            defaultSize={{
                height: POPOVER_DEFAULT_WIDTH,
                width: POPOVER_DEFAULT_HEIGHT,
            }}
            className={classNames('&', className, cls)}
            enable={{
                bottom: true,
                left: true,
                bottomLeft: true,
            }}
        >
            <div className={classNames('&-header')}>
                {/* Address input */}
                <ArgInputText
                    autoFocus={true}
                    value={getInputValue(value)}
                    initialValue={initialAddress}
                    placeholder={argGeolocationFormMessages.addressPlaceholder}
                    right={
                        <ArgButton
                            type='ghost'
                            icon='icon-map-marker'
                            loading={getAddressProgressMonitor?.isRunning}
                            className={classNames('&-header-input-right')}
                        />
                    }
                    onChange={handleAddressChange}
                    onInputChange={handleInputAddressChange}
                    data-testid='arg-geolocation-form-address-input'
                    className={classNames('&-header-input', '&-header-input-address')}
                />

                {/* Radius input */}
                <ArgInputNumber
                    min={0}
                    step={1}
                    placeholder={argGeolocationFormMessages.radiusPlaceholder}
                    onChange={handleRadiusChange}
                    value={formatRadius(value?.radius, value?.radiusUnit)}
                    className={classNames('&-header-input', '&-header-input-radius')}
                    left={
                        <span className={classNames('arg-input-left', '&-header-input-left')}>
                            ±
                        </span>
                    }
                    data-testid='arg-geolocation-form-radius-input'
                    disabled={isRadiusAndUnitInputsDisabled(value)}
                />

                {/* Radius unit input */}
                <ArgCombo<RadiusUnit>
                    cardinality='one'
                    items={values(RadiusUnit)}
                    onChange={handleRadiusUnitChange}
                    initialValue={DEFAULT_RADIUS_UNIT}
                    getItemLabel={(item) => argGeolocationFormMessages[item]}
                    value={value?.radiusUnit || DEFAULT_RADIUS_UNIT}
                    placeholder={argGeolocationFormMessages.radiusUnitPlaceholder}
                    data-testid='arg-geolocation-form-radius-unit-input'
                    className={classNames('&-header-input', '&-header-input-unit')}
                    disabled={isRadiusAndUnitInputsDisabled(value)}
                />
            </div>

            <div className={classNames('&-body')} data-testid='arg-geolocation-form-map-container'>
                <ArgMap
                    value={value}
                    initialMapMode={MapMode.CenterArea}
                    forwardRef={map}
                    onChange={onChange}
                    className={classNames('&-body-map')}
                    zoomInKeyBinding={zoomInKeyBinding}
                    zoomOutKeyBinding={zoomOutKeyBinding}
                />
            </div>
        </Resizable>
    );
}

function getInputValue(value: GeolocationValue | undefined): string | undefined {
    return value?.address || (value?.center ? `${value?.center?.lat?.toString()} ${value?.center?.lat?.toString()}` : undefined);
}

function isRadiusAndUnitInputsDisabled(value: GeolocationValue | undefined): boolean {
    return (isNil(value?.address) || isEmpty(value?.address)) && (isNil(value?.center) || isEmpty(value?.center));
}
