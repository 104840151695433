import { filter, isEmpty, isFunction, isNil, isString, map } from 'lodash';
import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToolNodes } from './use-tool-nodes';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ToolContext, ToolTreeNode } from './tool-context';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { isToolDisabled, isToolVisible } from './tool';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgToolItemRenderContext } from './arg-toolbar-item';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { ArgMenuItemDivider } from '../arg-menu/arg-menu-item-divider';
import { ArgSubMenu } from '../arg-menu/arg-sub-menu';
import { renderText } from '../utils/message-descriptor-formatters';
import { preventContextMenu } from '../../../utils/prevent-context-menu';

import './arg-tool-menu.less';

export interface OverlayProps {
    x?: number;
    y?: number;
}

export interface ArgToolMenuProps {
    overlay?: OverlayProps;
    visible?: boolean;
    className?: ClassValue;
    menuContext: ToolContext;
    onCloseMenu: () => void;
    getPopupContainer?: (node: HTMLElement) => HTMLElement;
}

export function ArgToolMenu(props: ArgToolMenuProps) {
    const {
        overlay,
        visible,
        getPopupContainer,
        className,
        menuContext,
        onCloseMenu,
    } = props;

    const classNames = useClassNames('arg-tool-menu');

    const [activeKey, setActiveKey] = useState<string>();

    useEffect(() => {
        //        console.log('**** ON SHOW');
        menuContext.emit('OnShow');

        return () => {
            //            console.log('**** ON HIDE');
            menuContext.emit('OnHide');
        };
    }, []);

    const style = useMemo<CSSProperties | undefined>(() => {
        if (!isNil(overlay) && !isEmpty(overlay)) {
            const x: string | number = overlay?.x ? `${overlay?.x}px` : 0;
            const y: string | number = overlay?.y ? `${overlay?.y}px` : 0;

            return {
                top: y,
                left: x,
            };
        }
    }, [overlay]);

    const renderedNodes = useRenderToolMenu(menuContext, onCloseMenu, setActiveKey, getPopupContainer);

    // Manage the overlay menu to be hidden
    if (visible === false) {
        return null;
    }

    const cls = {
        'overlaying': !isNil(overlay) && !isEmpty(overlay),
    };

    return <ArgMenu
        style={style}
        openKeys={activeKey ? [activeKey] : undefined}
        onContextMenu={preventContextMenu}
        getPopupContainer={getPopupContainer}
        className={classNames('&', className, cls)}
    >
        {renderedNodes}
    </ArgMenu>;
}

export function useRenderToolMenu(
    menuContext: ToolContext,
    onCloseMenu: () => void,
    setActiveKey?: (key: string | undefined) => void,
    getPopupContainer?: (node: HTMLElement) => HTMLElement
): ReactNode[] {
    const classNames = useClassNames('arg-tool-menu');
    const [toolNodes, toolTreeContext] = useToolNodes(menuContext);
    const hasAnyPreventCloseClickChildren = useMemo(() => {
        return toolNodes.filter(node => !!node.children)
            .flatMap(node => node.children)
            .findIndex(childNode => !!childNode?.preventCloseMenuOnClick) >= 0;
    }, [toolNodes]);

    const handleNodeClick = useCallback((node: ToolTreeNode, event: ButtonClickEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (!node.preventCloseMenuOnClick) {
            onCloseMenu();
        }

        node.onClick?.(node, event);
    }, [onCloseMenu]);

    const intl = useIntl();

    const renderNodes = useCallback((nodes: ToolTreeNode[]): ReactNode[] => {
        return map(nodes, (node: ToolTreeNode) => {
            if (!isToolVisible(node)) {
                return;
            }

            const context: ArgToolItemRenderContext = {
                onCloseMenu,
                menuItemClassName: classNames('&-item', 'arg-menu-item', node.className),
            };

            // Get the icon
            let _icon = node.icon;
            if (isString(_icon)) {
                _icon = <ArgIcon name={_icon} className={classNames('&-icon')}/>;
            } else {
                _icon = renderText(_icon);
            }

            // Create the icon component
            const icon = _icon && (
                <span className={classNames('&-icon-container')}>
                    {_icon}
                </span>
            );

            // Get the label
            let label = node.label;
            if (label && !isString(label)) {
                label = renderText(label);
            }
            if (!label && node.keyBinding) {
                label = node.keyBinding.name;
            }

            if (isMessageDescriptor(label)) {
                label = (
                    <FormattedMessage {...label} />
                );
            }

            const isLoading = node.loading || toolTreeContext.fetchedTools[node.path]?.progressMonitor?.isRunning;

            // Don't render the group if there is no visible children
            const isValidGroup = node.type === 'group' && !isEmpty(filter(node.children, isToolVisible));

            // Render the group when ready
            if (isValidGroup && !isLoading && !isToolDisabled(node)) {
                return (
                    <ArgSubMenu
                        icon={icon}
                        label={label}
                        key={node.path}
                        popupOffset={[10, 0]}
                        className={classNames('&-submenu')}
                        popupClassName={classNames('&-submenu-popup', node.menuClassName)}
                        onTitleMouseEnter={(event, menuKey) => {
                            if (hasAnyPreventCloseClickChildren) {
                                setActiveKey?.(menuKey);
                            }
                            node.onMouseOver?.(event);
                        }}
                    >
                        {node.children && renderNodes(node.children)}
                    </ArgSubMenu>
                );
            }

            // Render separator
            if (node.type === 'separator') {
                return (
                    <ArgMenuItemDivider
                        key={node.path}
                        className={classNames('&-item-separator')}
                    />
                );
            }
            const customRenderResult = node.customRender?.(node, context);
            if (node.menuItemCustomRender) {
                return customRenderResult;
            }

            let _tooltip: ReactNode;
            let tooltip = node.tooltip;
            if (tooltip) {
                if (isFunction(tooltip)) {
                    tooltip = tooltip(node);
                }

                if (isMessageDescriptor(tooltip)) {
                    _tooltip = intl.formatMessage(tooltip);
                } else {
                    _tooltip = String(tooltip);
                }
            }

            // Render menu item or loading group
            return (
                <ArgMenuItem
                    key={node.path}
                    icon={icon}
                    disabled={isToolDisabled(node) || isLoading || (node.type === 'group' && !isValidGroup)}
                    className={classNames(context.menuItemClassName, {
                        'custom': !!customRenderResult,
                        'selected': node.selected,
                    })}
                    tooltip={_tooltip}
                    getPopupContainer={getPopupContainer}
                    onClick={(event) => handleNodeClick(node, event)}
                    onMouseEnter={() => {
                        if (hasAnyPreventCloseClickChildren
                            && node.path.split('/').length === 1 /* root node case => close opened nodes */) {
                            setActiveKey?.(undefined);
                        }
                    }}
                >
                    {(label || isLoading) &&
                        <span className={classNames('&-item-label-container')}>
                            <span className={classNames('&-item-label')}>{label}</span>
                            {isLoading && (
                                <ThreeDotsLoading className={classNames('&-item-label-loading')}/>
                            )}
                        </span>
                    }
                    {customRenderResult}
                </ArgMenuItem>
            );
        });
    }, [classNames, getPopupContainer, handleNodeClick, hasAnyPreventCloseClickChildren, intl, onCloseMenu, setActiveKey, toolTreeContext.fetchedTools]);

    const renderedNodes = useMemo<ReactNode[]>(() => {
        const ret = renderNodes(toolNodes);

        return ret;
    }, [renderNodes, toolNodes]);

    return renderedNodes;
}
