import { useCallback, useContext, useEffect, useState } from 'react';
import _, { forEach } from 'lodash';
import Debug from 'debug';
import { MessageDescriptor } from 'react-intl';

import { ProgressMonitor, ProgressMonitorOptions } from './progress-monitor';
import { GlobalProgressContext } from './global-progress-context';
import { $yield } from '../utils/yield';

const debug = Debug('argonode:hooks:use-progress-monitors');

export type ProgressMonitorKey = string;

export type ProgressMonitorsFactoryType = (key: ProgressMonitorKey, name?: string | MessageDescriptor, taskCount?: number, options?: ProgressMonitorOptions) => ProgressMonitor;

/**
 *
 */
export function useProgressMonitors(): [Readonly<Record<ProgressMonitorKey, ProgressMonitor>>, ProgressMonitorsFactoryType] {
    const [progressMonitors, setProgressMonitors] = useState<Record<ProgressMonitorKey, ProgressMonitor>>({});
    const globalProgressContext = useContext(GlobalProgressContext);

    useEffect(() => {
        // The component is unmounted, release all progressMonitors !
        return () => {
            forEach(progressMonitors, (progressMonitor) => {
                progressMonitor.cancel();
            });
        };
    }, []); // Do not put  progressMonitors  in the array

    const createProgressMonitor = useCallback<ProgressMonitorsFactoryType>((key, name, taskCount = 1, options) => {
        const prevProgressMonitor = progressMonitors[key];
        if (prevProgressMonitor) {
            prevProgressMonitor.cancel();
        }

        const progressMonitor = new ProgressMonitor(name, taskCount, options);
        if (options?.global) {
            globalProgressContext.register(progressMonitor);
        }

        const removeProgressMonitor = () => {
            $yield(() => {
                setProgressMonitors((prev) => {
                    let newProgressMonitors = prev;
                    if (prev[key] === progressMonitor) {
                        newProgressMonitors = _.omit(prev, key);
                    }

                    debug('useProgressMonitors', 'receive a Done or Cancel, remove from the list =>', newProgressMonitors);

                    return newProgressMonitors;
                });
            });
        };

        const updateProgressMonitor = () => {
            $yield(() => {
                setProgressMonitors((prev) => {
                    return { ...prev };
                });
            });
        };

        progressMonitor.on('Cancel', removeProgressMonitor);
        progressMonitor.on('Done', removeProgressMonitor);
        progressMonitor.on('NameChanged', updateProgressMonitor);

        $yield(() => {
            setProgressMonitors((prev) => {
                return {
                    ...prev,
                    [key]: progressMonitor,
                };
            });
        });

        return progressMonitor;
    }, [globalProgressContext, progressMonitors]);

    return [progressMonitors, createProgressMonitor];
}
