import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Debug from 'debug';

import { PublicUser, User } from '../../model/user';
import userConnector from 'src/utils/connectors/users-connector';
import { ArgUserId, ArgUserInfo, DataCacheRepository, DataCacheRepositoryResponse, ProgressMonitor } from '../basic';
import { StateId } from '../../utils/states/basic-state';
import { useUserState } from '../../exploration/features/exploration/states/use-user-state';
import { isObject } from 'lodash';
import { isResponse404 } from '../basic/utils/response-error';

const debug = Debug('common:caches:UsersCache');

const NO_DATA: DataCacheRepositoryResponse<any> = [undefined, undefined, undefined];

const DEFAULT_TTL_MS = 0;

export class UsersCache {
    #dataCache: DataCacheRepository<PublicUser>;

    constructor(cacheName: string, ttlMs: number = DEFAULT_TTL_MS) {
        this.#dataCache = new DataCacheRepository<PublicUser>(`users-cache:${cacheName}`, this.#dataLoader, ttlMs);
    }

    get dataCache(): DataCacheRepository<PublicUser> {
        return this.#dataCache;
    }

    getUser(userId: ArgUserId, stateId: StateId, progressMonitor?: ProgressMonitor): Promise<User | null> {
        const keys = { userId };
        const ret = this.#dataCache.loadPromise(undefined, keys, stateId, progressMonitor);

        return ret as Promise<User | null>;
    }

    #dataLoader = async (key: string, infos: {
        userId: ArgUserId
    }, previousValue: PublicUser | undefined, progressMonitor: ProgressMonitor): Promise<PublicUser | null> => {
        try {
            if (infos.userId) {
                const ret = await userConnector.getPublicUser(infos.userId, progressMonitor);

                return ret;
            }

            throw new Error('Unsupported key');
        } catch (error) {
            console.error('DataLoader error=', error);
            if (isResponse404(error)) {
                return null;
            }

            throw error;
        }
    };

    dispose() {
        this.#dataCache.dispose();
    }
}


export const UsersCacheContext = createContext<UsersCache | undefined>(undefined);

interface UserIdsCacheScopeProps {
    name: string;
    children: ReactNode;
    ttlMs?: number;
}

export function UsersCacheScope(props: UserIdsCacheScopeProps) {
    const { children, ttlMs, name } = props;

    const explorationObjectCacheRef = useRef<UsersCache>();
    if (!explorationObjectCacheRef.current) {
        explorationObjectCacheRef.current = new UsersCache(name, ttlMs);
    }

    useEffect(() => {
        return () => {
            const explorationObjectCache = explorationObjectCacheRef.current;
            if (!explorationObjectCache) {
                return;
            }
            explorationObjectCacheRef.current = undefined;
            explorationObjectCache.dispose();
        };
    }, []);

    return <UsersCacheContext.Provider value={explorationObjectCacheRef.current}>
        {children}
    </UsersCacheContext.Provider>;
}

export function useUsersCache(userIdsCacheByParam?: UsersCache, ttlMs?: number): UsersCache {
    const userIdsCacheByContext = useContext(UsersCacheContext);

    const userIdsCacheByRef = useRef<UsersCache>();

    useEffect(() => {
        return () => {
            if (userIdsCacheByRef.current) {
                userIdsCacheByRef.current.dispose();
            }
        };
    }, []);

    if (userIdsCacheByParam) {
        return userIdsCacheByParam;
    }
    if (userIdsCacheByContext) {
        return userIdsCacheByContext;
    }

    if (userIdsCacheByRef.current) {
        return userIdsCacheByRef.current;
    }

    const userIdsCache = new UsersCache('useUsersCache', ttlMs);

    userIdsCacheByRef.current = userIdsCache;

    return userIdsCache;
}


function useUsersCacheRepository<K = string>(
    infos: K | string | undefined,
    stateId: StateId | undefined,
    userIdsCacheByParam?: UsersCache
): DataCacheRepositoryResponse<PublicUser> | undefined {
    const [dataOrError, setDataOrError] = useState<DataCacheRepositoryResponse<PublicUser>>();

    const cacheContext = useUsersCache(userIdsCacheByParam);

    const dataRepository = cacheContext?.dataCache;

    const key: string | undefined = useMemo(() => {
        if (!infos) {
            return undefined;
        }
        if (typeof (infos) === 'string') {
            return infos;
        }

        return JSON.stringify(infos);
    }, [infos]);

    useEffect(() => {
        debug('useDataCacheRepository', 'dataRepository=', dataRepository, 'key=', key);
        if (!dataRepository || !key) {
            // Loading manually
            return;
        }

        let loaded = false;

        function handleDataLoaded(data?: PublicUser, error?: Error) {
            debug('useDataCacheRepository', 'dataLoaded key=', key, 'data=', data, 'error=', error);

            if (error) {
                setDataOrError([null, error, undefined]);

                return;
            }

            loaded = true;
            dataRepository!.link(key!);
            setDataOrError([data, null, undefined]);
        }

        dataRepository.addListener(`loaded:${key}`, handleDataLoaded);

        const dataOrError = dataRepository.load(key, infos, stateId);
        debug('useDataCacheRepository', 'load() key=', key, 'ret=', dataOrError);
        if (dataOrError) {
            loaded = true;
            setDataOrError(dataOrError);
            dataRepository.link(key);
        }

        return () => {
            debug('useDataCacheRepository', 'release key=', key, 'loaded=', loaded);
            setDataOrError(undefined);

            dataRepository.removeListener(`loaded:${key}`, handleDataLoaded);
            if (loaded) {
                dataRepository.unlink(key);
            }
        };
    }, [key, dataRepository, stateId]);

    if (!dataRepository) {
        return undefined;
    }

    return dataOrError || NO_DATA;
}


export function useUserById(userIdOrInfo: ArgUserId | ArgUserInfo | undefined, userIdsCacheByParam?: UsersCache): DataCacheRepositoryResponse<PublicUser | null> {
    const userId = userIdOrInfo ? (isObject(userIdOrInfo) ? userIdOrInfo.id : userIdOrInfo) : undefined;
    const key = userId ? { userId } : undefined;

    const userState = useUserState(userId);

    const ret = useUsersCacheRepository(key, userState?.stateId, userIdsCacheByParam);

    if (userIdOrInfo === undefined) {
        return NO_DATA;
    }

    return ret as DataCacheRepositoryResponse<PublicUser>;
}
