import React, { MouseEvent, ReactNode, RefObject, useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Tooltip, { TooltipPlacement } from 'antd/lib/tooltip';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgChangeReason, ArgMessageValues, ArgRenderedText, ArgSize, ArgSwitchType } from '../types';
import { DEFAULT_TOOLTIP_BUTTON_DELAY, DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { findOrCreatePopupArea, getDataTestIdFromProps } from '../utils';
import { renderText } from '../utils/message-descriptor-formatters';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { useKeyBinding } from '../keybindings/use-keybinding';
import { KeyBindingKeys } from '../keybindings/keybinding-keys';

import './arg-switch.less';


export const messages = defineMessages({
    active: {
        id: 'basic.arg-switch.state.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'basic.arg-switch.state.inactive',
        defaultMessage: 'Inactive',
    },
});

interface ArgSwitchProps {
    className?: ClassValue;
    type?: ArgSwitchType;
    label?: ArgRenderedText;
    labelPosition?: 'left' | 'right';
    otherLabel?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    children?: ReactNode;
    checked?: boolean;
    value?: boolean;
    disabled?: boolean;
    onClick?: (event: MouseEvent) => void;
    onChange?: (value: boolean, reason?: ArgChangeReason) => void;
    size?: ArgSize;
    readOnly?: boolean;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    keyBinding?: KeyBindingDescriptor;

    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
}

export const ArgSwitch = (props: ArgSwitchProps) => {
    const {
        className,
        label,
        labelPosition = 'right',
        otherLabel,
        messageValues,
        disabled,
        onClick,
        onChange,
        children,
        size = 'small',
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        type = 'switch',
        readOnly,
        keyBinding,
        getPopoverContainer,
    } = props;


    const classNames = useClassNames('arg-switch');
    const dataTestId = getDataTestIdFromProps(props);

    let value = props.value;
    if ('checked' in props) {
        value = props.checked;
    }

    const labelNode = renderText(label || children, messageValues);
    const otherNode = renderText(otherLabel, messageValues);

    const handleClick = useCallback((event: MouseEvent) => {
        onClick?.(event);

        if (event.defaultPrevented) {
            return;
        }

        onChange && onChange(!value, 'selection');
    }, [onClick, onChange, value]);

    const buttonRef = useRef<HTMLButtonElement>(null);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (typeof (getPopoverContainer) === 'function') {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (buttonRef.current) {
            return findOrCreatePopupArea(buttonRef.current) || buttonRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);

    const handleKeyBinding = useCallback(() => {
        if (buttonRef.current) {
            buttonRef.current.click();
        }

        onChange?.(!value, 'selection');
    }, [onChange, value]);

    useKeyBinding(keyBinding, handleKeyBinding, !disabled);

    const cls = {
        [`type-${type}`]: true,
        [`size-${size}`]: true,
        checked: value,
        disabled,
        readOnly,
    };

    let switchButton =
        <button
            className={classNames('&', cls, className)}
            onClick={handleClick}
            disabled={disabled || readOnly}
            type='button'
            data-testid={dataTestId}
            ref={buttonRef}
        >
            {labelNode && labelPosition === 'left' && (
                <span className={classNames('&-label', labelPosition)}>
                    {renderText(labelNode, messageValues)}
                </span>
            )}
            {otherNode && labelPosition === 'left' && (
                <span className={classNames('&-label', labelPosition)}>
                    {renderText(otherNode, messageValues)}
                </span>
            )}
            <div className={classNames('&-control')}>
                <span className={classNames('&-slider', 'round')}/>
            </div>

            {labelNode && labelPosition === 'right' && (
                <span className={classNames('&-label', labelPosition)}>
                    {renderText(labelNode, messageValues)}
                </span>
            )}
            {otherNode && labelPosition === 'right' && (
                <span className={classNames('&-label', labelPosition)}>
                    {renderText(otherNode, messageValues)}
                </span>
            )}
        </button>;

    if (tooltip || keyBinding) {
        let _tooltip = renderText(tooltip, messageValues);

        if (keyBinding) {
            _tooltip = (
                <div className={classNames('&-tooltip-keys')}>
                    <div className={classNames('&-tooltip-keys-message')}>
                        <FormattedMessage {...keyBinding.name} values={messageValues}/>
                    </div>
                    <KeyBindingKeys
                        keyBindingDescriptor={keyBinding}
                        className={classNames('&-tooltip-keybinding')}
                    />
                </div>
            );
        }

        switchButton =
            <Tooltip
                key='tooltip'
                title={_tooltip}
                data-testid='tooltip'
                className={classNames('&-tooltip', 'arg-tooltip')}
                placement={tooltipPlacement || DEFAULT_TOOLTIP_PLACEMENT}
                mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
                overlayClassName={classNames('&-tooltip-overlay', tooltipClassName)}
                getPopupContainer={computePopoverContainer}
            >
                {switchButton}
            </Tooltip>;
    }

    return switchButton;
};
