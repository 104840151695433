import Debug from 'debug';
import { isEmpty, isEqual, isNil, isNumber } from 'lodash';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import computeArea from '@turf/area';
import L from 'leaflet';

// Components and hooks
import { ArgChangeReason, ArgInputType, ArgPlaceholderText, ArgRenderedText } from '../types';
import { getDataTestIdFromProps } from '../utils';
import { ArgInput } from '../arg-input/arg-input';
import { ArgButton } from '../arg-button/arg-button';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgGeolocationForm } from './components/form/arg-geolocation-form';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { GeolocationValue, RadiusUnit } from './model/geolocation-value';
import { LatitudeAndLongitudeResponse } from './model/geolocation-json';
import { formatRadius } from './helpers/format-radius';

// Styles
import './arg-geolocation-picker.less';

export interface ArgGeolocationPickerProps {
    className?: ClassValue;
    readOnly?: boolean;
    tooltip?: ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    disabled?: boolean;
    type?: ArgInputType;
    value?: GeolocationValue | null;
    initialValue?: GeolocationValue;
    autoFocus?: boolean;
    onChange?: (value: GeolocationValue | null | ((prev: GeolocationValue | null) => GeolocationValue | null), reason?: ArgChangeReason) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
    placeholder?: ArgPlaceholderText | null;
    clearable?: boolean;
}

const debug = Debug('argonode:components:Geolocation-picker');

export const argGeolocationPickerMessages = defineMessages({
    placeholder: {
        id: 'basic.arg-geolocation-picker.popover.address.input',
        defaultMessage: 'Define a place or area',
    },
    customArea: {
        id: 'basic.arg-geolocation-picker.custom.area',
        defaultMessage: 'Custom area: {value}{unit}',
    },
    address: {
        id: 'basic.arg-geolocation-picker.address',
        defaultMessage: '{address} (±{radius})',
    },
});

export const ArgGeolocationPicker: React.FunctionComponent<ArgGeolocationPickerProps> = (props) => {
    const {
        onChange,
        className,
        value: externalValue,
        initialValue,
        readOnly,
        disabled,
        type,
        autoFocus,
        getAddressCoordinates,
        placeholder,
        tooltip,
        tooltipPlacement,
        clearable,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('arg-geolocation-picker');

    const useInternalValue = !('value' in props);

    const [internalValue, setInternalValue] = useState<GeolocationValue | null>(null);

    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const dataTestId = getDataTestIdFromProps(props);

    const value = useInternalValue ? internalValue : externalValue;

    useEffect(() => {
        setInternalValue(initialValue || null);
    }, [initialValue]);

    const handleClear = useCallback(() => {
        // Clear the internal value
        setInternalValue(null);

        // Close the popover
        setPopoverVisible(false);

        // Clear the form values
        onChange?.(null, 'clear');
    }, [onChange]);

    // Input with formatted changed
    const handleChange = useCallback((value: GeolocationValue | null | ((value: GeolocationValue | null) => GeolocationValue | null), reason?: ArgChangeReason) => {
        if (reason === 'blur') {
            return;
        }

        const setAction = (prevValue: GeolocationValue | null) => {
            let newValue;
            if (typeof (value) === 'function') {
                newValue = value(prevValue || {});
            } else {
                newValue = value;
            }

            if (isEqual(prevValue, newValue)) {
                debug('handleChange', 'Ignore prevValue=', prevValue);

                return prevValue;
            }

            debug('handleChange', 'CHANGE prevValue=', prevValue, 'newValue=', newValue);

            return newValue;
        };

        setInternalValue(setAction);
        onChange?.(setAction, reason);
    }, [onChange]);

    const formatValue = useCallback((value: GeolocationValue | null) => {
        // Generate custom area with the proper unit
        if (!isNil(value?.address) && !isEmpty(value?.address)) {
            if (value && !isNil(value?.address) && !isEmpty(value?.address) && value.radius) {
                const radiusUnit = (value?.radiusUnit === RadiusUnit.Meter) ? RadiusUnit.Meter : RadiusUnit.Kilometer;

                return intl.formatMessage(argGeolocationPickerMessages.address, {
                    address: value?.address,
                    radius: formatRadiusUnit(intl, formatRadius(value?.radius, value?.radiusUnit), radiusUnit),
                });
            }

            return value?.address!;
        }

        if (value?.area) {
            return formatArea(intl, value?.area);
        }

        if (value?.polygon && value?.polygon.length > 0) {
            const GeoJSON = L.polygon(value?.polygon).toGeoJSON();
            const area = computeArea(GeoJSON);

            if (area > 0) {
                return formatArea(intl, area);
            }
        }

        if (value?.center && value.center.lat) {
            const label = `${value.center.lat} ${value.center.lng}`;
            const radius = value?.radius;
            if (isNumber(radius) && radius > 0) {
                const radiusUnit = (value?.radiusUnit === RadiusUnit.Meter) ? RadiusUnit.Meter : RadiusUnit.Kilometer;

                return intl.formatMessage(argGeolocationPickerMessages.address, {
                    address: label,
                    radius: formatRadiusUnit(intl, formatRadius(value?.radius, value?.radiusUnit), radiusUnit),
                });
            }
        }

        return '';
    }, [intl]);

    const cls = {
        editable: !readOnly && !disabled,
        disabled: readOnly || disabled,
    };

    const onPopoverVisibleChange = useCallback((value: React.SetStateAction<boolean | undefined>) => {
        if (disabled || readOnly) {
            return;
        }

        setPopoverVisible(value);
    }, [disabled, readOnly]);

    return (
        <ArgInput<GeolocationValue>
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            value={value || undefined}
            readOnly={true}
            disabled={disabled}
            type={type}
            clearable={!readOnly && clearable}
            popoverTrigger='click'
            popoverFitWidth={false}
            onClear={handleClear}
            data-testid={dataTestId}
            parseValue={() => (value || null)}
            formatValue={formatValue}
            popoverVisible={popoverVisible}
            onPopoverVisibleChange={onPopoverVisibleChange}
            className={classNames('&', className, cls)}
            popoverOverlayClassName={classNames('&-popover')}
            popover={(!readOnly && !disabled) && (() => (
                <ArgGeolocationForm
                    onChange={handleChange}
                    value={value || undefined}
                    getAddressCoordinates={getAddressCoordinates}
                />
            ))}
            placeholder={placeholder === null ? undefined : argGeolocationPickerMessages.placeholder}
            right={
                !readOnly && <ArgButton
                    key='marker'
                    type='ghost'
                    icon='icon-map-marker'
                    className={classNames('&-right')}
                    autoFocus={autoFocus}
                    disabled={disabled}
                />
            }
        />
    );
};
const formatArea = (intl: IntlShape, area: number) => {
    let unit = 'm²';

    if (area > 1000000 || area === 1000000) {
        area /= 1000000;
        unit = 'km²';
    }

    const formattedNumber = intl.formatNumber(area, {
        maximumFractionDigits: area < 1 ? 2 : 0,
        notation: 'standard',
    });

    return intl.formatMessage(argGeolocationPickerMessages.customArea, {
        value: formattedNumber,
        unit: unit,
    });
};

// Format radius value with the proper unit
const formatRadiusUnit = (intl: IntlShape, number: number | undefined, unit: RadiusUnit) => {
    return intl.formatNumber(number || 0, {
        unit,
        style: 'unit',
        notation: 'standard',
        unitDisplay: 'narrow',
    });
};
