import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { last, pull } from 'lodash';
import Debug from 'debug';
import { MessageDescriptor } from 'react-intl';

import { GlobalProgressContext } from './global-progress-context';
import { $yield } from '../utils/yield';
import { ProgressMonitor, ProgressMonitorOptions } from './progress-monitor';

const debug = Debug('argonode:hooks:use-progress-monitor');

export interface ProgressMonitorFactoryType {
    (name: string | MessageDescriptor | undefined, taskCount: number, options?: ProgressMonitorOptions): ProgressMonitor
}

/**
 *
 * @param {boolean} uniq  Cancel the previous progressMonitor if exists
 */
export function useProgressMonitor(uniq = true): [ProgressMonitor | undefined, ProgressMonitorFactoryType] {
    const [progressMonitors, setProgressMonitors] = useState<ProgressMonitor[]>([]);
    const destroyed = useRef<boolean>(false);
    const globalProgressContext = useContext(GlobalProgressContext);

    useEffect(() => {
        // The component is unmounted, release all progressMonitors !
        return () => {
            destroyed.current = true;
            progressMonitors.forEach((progressMonitor) => {
                progressMonitor.cancel();
            });
        };
    }, []); // Do not put  progressMonitors  in the array

    const createProgressMonitor: ProgressMonitorFactoryType = useCallback(
        (name, taskCount, options) => {
            const progressMonitor = new ProgressMonitor(name, taskCount, options);
            if (options?.global) {
                globalProgressContext.register(progressMonitor);
            }

            const removeProgressMonitor = () => {
                progressMonitor.off('Cancel', removeProgressMonitor);
                progressMonitor.off('Done', removeProgressMonitor);
                progressMonitor.off('NameChanged', updateProgressMonitor);
                progressMonitor.off('Worked', updateProgressMonitor);

                if (destroyed.current) {
                    return;
                }

                $yield(() => {
                    if (destroyed.current) {
                        return;
                    }

                    setProgressMonitors((prev) => {
                        if (prev.indexOf(progressMonitor) < 0) {
                            return prev;
                        }

                        const newProgressMonitors = pull([...prev], progressMonitor);

                        debug('useProgressMonitor', 'receive a Done or Cancel, remove from the list =>', newProgressMonitors);

                        return newProgressMonitors;
                    });
                });
            };

            const updateProgressMonitor = () => {
                $yield(() => {
                    if (destroyed.current) {
                        return;
                    }
                    setProgressMonitors((prev) => {
                        return [...prev];
                    });
                });
            };

            progressMonitor.on('Cancel', removeProgressMonitor);
            progressMonitor.on('Done', removeProgressMonitor);
            progressMonitor.on('NameChanged', updateProgressMonitor);
            progressMonitor.on('Worked', updateProgressMonitor);

            $yield(() => {
                if (destroyed.current) {
                    return;
                }

                setProgressMonitors((prev) => {
                    if (uniq) {
                        if (prev?.length) {
                            debug('useProgressMonitor', 'the list is not empty=', prev);
                            prev.forEach((progressMonitor) => {
                                progressMonitor.cancel();
                            });
                        }
                        prev = [];
                    }

                    return [...prev, progressMonitor];
                });
            });

            return progressMonitor;
        }, [globalProgressContext, uniq]);

    return [last(progressMonitors), createProgressMonitor];
}
