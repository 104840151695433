import { useEffect, useState } from 'react';
import { DataProvider } from './data-provider';

export function useDataProvider<T>(dataProvider?: DataProvider<T>): number {
    const [stateId, setStateId] = useState<number>(0);

    useEffect(() => {
        if (!dataProvider) {
            setStateId(0);

            return;
        }

        const fct = () => {
            setStateId(dataProvider.stateId);
        };

        dataProvider.on('Loaded', fct);
        dataProvider.on('Cleared', fct);

        return () => {
            dataProvider.off('Loaded', fct);
            dataProvider.off('Cleared', fct);
        };
    }, [dataProvider]);

    return stateId;
}
