import { useBasicState } from '../../../../utils/states/use-basic-state';
import { UserState } from '../../../../utils/states/user-state';
import { ArgUserId } from '../../../../components/basic';
import { getDataExplorationApi } from '../../../../utils/connectors/api-url';

export function userStateFactory(userId: ArgUserId) {
    const api = getDataExplorationApi();
    if (!api) {
        throw new Error('Exploration API is not defined');
    }

    return new UserState(api, userId);
}

export function computeUserStateURL(userId: ArgUserId) {
    return `/users/${userId}`;
}

export function useUserState(userId: ArgUserId): UserState;
export function useUserState(userId: ArgUserId | undefined): UserState | undefined;

export function useUserState(userId: ArgUserId | undefined): UserState | undefined {
    const state = useBasicState<UserState>(() => userStateFactory(userId!), (userId) ? computeUserStateURL(userId) : undefined);

    return state;
}
