import React, { MouseEvent, ReactNode, useCallback, useState } from 'react';


import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgSize } from '../types';
import { DEFAULT_SIZE } from '../defaults';

import './arg-pager.less';

export interface ArgPagerProps {
    size?: ArgSize;
    className?: ClassValue;
    initialValue?: number;
    value?: number;
    onChange: (pageNumber: number) => void;
    pageCount?: number;
    minPageCount?: number;
}

export function ArgPager(props: ArgPagerProps) {
    const { className, value: externalValue = 1, onChange, pageCount, minPageCount, size = DEFAULT_SIZE } = props;

    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<number>(1);

    const value: number = useInternalValue ? internalValue : externalValue;

    const classNames = useClassNames('arg-pager');

    const handleButtonClick = useCallback((event: MouseEvent) => {
        let element: HTMLElement | null = event.target as HTMLElement;
        let indexValue;
        for (; element && !indexValue; element = element.parentElement) {
            indexValue = element.getAttribute('data-index');
        }

        if (!indexValue) {
            return;
        }
        const index = parseInt(indexValue);

        setInternalValue(index);
        onChange(index);
    }, [onChange]);

    const buttons: ReactNode[] = [];

    let start = Math.max(1, value - 2);
    let end = start + 5;

    if (pageCount === undefined) {
        if (typeof (minPageCount) === 'number' && end > minPageCount) {
            end = minPageCount + 1;
            start = Math.max(end - 5, 1);
        } else if (end > value) {
            end = value + 1;
            start = Math.max(end - 5, 1);
        }
    } else if (end > pageCount) {
        end = pageCount;
        start = Math.max(end - 5, 1);
    }

    for (let index = start; index <= end; index++) {
        if (index > start) {
            buttons.push(<div key={`divider-${index}`} className={classNames('&-divider')}/>);
        }
        if (index === value) {
            buttons.push(<div key='current' className={classNames('&-current', '&-number')}>{index}</div>);
            continue;
        }
        buttons.push(<button
            className={classNames('&-page', '&-number')}
            key={`button-${value}`}
            type='button'
            disabled={index === value}
            onClick={handleButtonClick}
            data-index={index}
        >
            {index}
        </button>);
    }

    const cls = {
        [`size-${size}`]: true,
    };

    return <div className={classNames('&', className, cls)}>
        <button
            className={classNames('&-page', '&-first')}
            key='first'
            type='button'
            disabled={value === 1}
            onClick={handleButtonClick}
            data-index={1}
        >
            <ArgIcon className={classNames('&-page-icon')} name='icon-first'/>
        </button>
        <button className={classNames('&-page', '&-prev')}
                key='prev'
                type='button'
                disabled={value === 1}
                onClick={handleButtonClick}
                data-index={value - 1}>
            <ArgIcon className={classNames('&-page-icon')} name='icon-previous'/>
        </button>

        <div key='numbers' className={classNames('&-numbers')}>
            {buttons}
        </div>

        <button
            className={classNames('&-page', '&-next')}
            key='next'
            onClick={handleButtonClick}
            type='button'
            disabled={pageCount !== undefined && value >= pageCount}
            data-index={value + 1}
        >
            <ArgIcon className={classNames('&-page-icon')} name='icon-next'/>
        </button>
        {pageCount !== undefined && <button
            className={classNames('&-page', '&-last')}
            key='last'
            onClick={handleButtonClick}
            type='button'
            disabled={pageCount === undefined || value >= pageCount}
            data-index={pageCount}
        >
            <ArgIcon className={classNames('&-page-icon')} name='icon-last'/>
        </button>
        }

    </div>;
}
