import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

// Components and hooks
import { forCount } from '../utils';
import { ArgChangeReason } from '../types';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton } from '../arg-button/arg-button';
import { CalendarMode } from './arg-calendar';
import { dayjs } from '../utils/dayjs';

import './arg-calendar-decade.less';

const MIN_YEAR_DEFAULT_DIFF = 100;
const MAX_YEAR_DEFAULT_DIFF = 100;


export interface ArgCalendarDecadeProps {
    value?: dayjs.Dayjs;
    minYear?: number;
    maxYear?: number;
    mode: CalendarMode;
    onChange: (date: dayjs.Dayjs, reason: ArgChangeReason) => void;
}

export function ArgCalendarDecade(props: ArgCalendarDecadeProps) {
    const {
        value,
        mode,
        onChange,
        minYear,
        maxYear,
    } = props;

    const classNames = useClassNames('arg-calendar-decade');

    const yearValue = (dayjs(value).isValid() ? dayjs(value) : dayjs()).year();
    const minYearValue = minYear ? minYear : (yearValue - MIN_YEAR_DEFAULT_DIFF);
    const maxYearValue = (maxYear && maxYear > minYearValue) ? maxYear : (yearValue + MAX_YEAR_DEFAULT_DIFF);
    const yearsRange = maxYearValue - minYearValue + 1;

    const [, setPrevMode] = useState<CalendarMode>();
    const activeYearItem = useRef<HTMLElement>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);

    const setYear = useCallback((year: number) => {
        onChange((dayjs(value).isValid() ? dayjs(value) : dayjs()).set('year', year), 'keypress');
    }, [onChange, value]);

    useEffect(() => {
        setPrevMode(mode);
    }, [mode]);

    // Auto focus on selected year !
    useLayoutEffect(() => {
        if (mode !== 'decade') {
            return;
        }

        const timerId = setTimeout(() => {
            activeYearItem.current?.focus();
        }, 200);

        return () => {
            clearTimeout(timerId);
        };
    }, [mode]);

    const yearsNode = forCount(yearsRange, (i) => {
        const year = i + minYearValue;
        const isActive = year === yearValue;

        return (
            <ArgButton
                className={classNames('&-item')}
                key={year}
                onClick={isActive ? undefined : () => {
                    setYear(year);
                }}
                buttonRef={isActive ? activeYearItem : undefined}
                size='small'
                type={isActive ? 'primary' : 'ghost'}
                label={year}
            />
        );
    });

    return (
        <div ref={scrollContainer} className={classNames('&')}>
            {/* Go to previous month / year */}
            <div className={classNames('&-content')}>
                {yearsNode}
            </div>
        </div>
    );
}
