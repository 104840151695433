import { Menu } from 'antd';
import React from 'react';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

//import './arg-menu-divider.less';

interface ArgMenuDividerProps {
    className?: ClassValue;
}

export function ArgMenuItemDivider(props: ArgMenuDividerProps) {
    const { className, ...otherProps } = props;

    const classNames = useClassNames('arg-menu-item-divider');

    return (
        <Menu.Divider {...otherProps} className={classNames('&', className)}/>
    );
}
