import { createContext } from 'react';
import { MessageDescriptor } from 'react-intl';

export type ApplicationType =
    | 'Application'
    | 'DataExploration'
    | 'DataPreparation'
    | 'DataCollect'
    | 'Administration'
    | 'Settings'

export interface ApplicationDescriptor {
    type: ApplicationType;
    applicationName: string | MessageDescriptor;
    logoURL?: string;
    iconURL?: string;
    color?: string;
//    documentTitle?: string;
}

export const DEFAULT_APPLICATION_DESCRIPTOR: ApplicationDescriptor = {
    type: 'Administration',
    applicationName: 'Argonos',
};

export const ApplicationDescriptorContext = createContext<ApplicationDescriptor>(DEFAULT_APPLICATION_DESCRIPTOR);
