import React, { KeyboardEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { useIntl } from 'react-intl';

// Components and hooks
import { ArgChangeReason } from '../types';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgInput, ArgInputKeyPressEvent, ArgInputProps } from './arg-input';
import { StringUtils } from '../../../utils/string-utils';
import { ArgButton } from '../arg-button/arg-button';

// Styles
import './arg-input-number.less';

type ArgInputNumberType = 'number' | 'integer';

export const DEFAULT_STEP = 1;
export const DEFAULT_SHIFT_STEP = 10;
const NUMBER_FILTERED_KEY = /[0-9.,\-]/;

export interface ArgInputNumberProps<U = any> extends Omit<ArgInputProps<number, U>, 'formatValue' | 'parseValue' | 'onKeyPress'> {
    min?: number;
    max?: number;
    step?: number;
    shiftStep?: number;
    numberType?: ArgInputNumberType;
    suffix?: string;
    minimumFractionDigits?: number,
    maximumFractionDigits?: number,
    minimumSignificantDigits?: number,
    maximumSignificantDigits?: number,
    displayRightControl?: boolean;
}

export function ArgInputNumber(props: ArgInputNumberProps) {
    const {
        value: externalValue,
        initialValue,
        min,
        max,
        suffix,
        inputType = 'text',
        numberType = 'number',
        disabled,
        className,
        readOnly,
        onChange,
        onClear,
        onInputChange,
        step = DEFAULT_STEP,
        shiftStep = DEFAULT_SHIFT_STEP,
        minimumFractionDigits,
        maximumFractionDigits,
        minimumSignificantDigits,
        maximumSignificantDigits,
        displayRightControl,
        right,
        ...restProps
    } = props;

    const intl = useIntl();

    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<number | undefined>();

    const value = useInternalValue ? internalValue : externalValue;

    const classNames = useClassNames('arg-input-number');

    const thousandSeparator = useMemo<string>(() => {
        const num = intl.formatNumber(1234, {
            maximumFractionDigits: 0,
            minimumSignificantDigits: 4,
        });
        const reg = /([^\d])/.exec(num);

        return reg?.[1] || '';
    }, [intl]);

    useEffect(() => {
        if (!useInternalValue) {
            return;
        }

        setInternalValue(initialValue);
    }, [initialValue]);

    const defaultValue = useCallback(() => {
        if (!isNil(min) && !isNil(max) && max < min) {
            throw new Error('Invalid min / max values');
        }

        if (!isNil(step) && step <= 0) {
            throw new Error('Invalid step, step should be higher than 0');
        }

        if (!isNil(min) && (min > 0)) {
            return min;
        }

        if (!isNil(max) && (max < 0)) {
            return max;
        }

        return 0;
    }, [max, min, step]);

    const numberFormatValue = useCallback(
        (value: number | null): string => {
            if (value === null) {
                return '';
            }

            let numberFormat = intl.formatNumber(value, {
                minimumFractionDigits,
                maximumFractionDigits,
                minimumSignificantDigits,
                maximumSignificantDigits,
            });

            numberFormat = StringUtils.replaceAll(numberFormat, thousandSeparator, '');

            return `${numberFormat}${suffix ? suffix : ''}`;
        },
        [
            suffix,
            minimumFractionDigits,
            maximumFractionDigits,
            minimumSignificantDigits,
            maximumSignificantDigits,
            thousandSeparator,
            intl,
        ]
    );

    const numberParseValue = useCallback((value: string): number | null => {
        value = value.trim();

        if (value === '') {
            return null;
        }

        let v = numberType === 'integer' ? parseInt(value, 10) : parseFloat(value.replace(',', '.'));
        if (isNaN(v)) {
            return null;
        }
        if (max !== undefined && v > max) {
            v = max;
        } else if (min !== undefined && v < min) {
            v = min;
        }

        return v;
    }, [numberType, min, max]);

    const handleButton = useCallback((step: number, reason: ArgChangeReason) => {
        let newValue = (value ?? defaultValue()) + step;

        if (!isNil(min)) {
            newValue = Math.max(newValue, min);
        }

        if (!isNil(max)) {
            newValue = Math.min(newValue, max);
        }

        if (useInternalValue) {
            setInternalValue(newValue);
        }

        onChange && onChange(newValue, reason);
    }, [onChange, value, useInternalValue, setInternalValue, defaultValue, min, max]);

    // Handle keydown for arrow keys
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            handleButton(event.shiftKey ? shiftStep : step, 'keypress');

            return;
        }

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            handleButton(-(event.shiftKey ? shiftStep : step), 'keypress');

            return;
        }
    };

    const numberKeypress = useCallback((event: ArgInputKeyPressEvent): void => {
        const keyboardEvent = event.keyboardEvent;
        const key = keyboardEvent.key;

        if (key.length === 1
            && !keyboardEvent.ctrlKey
            && !keyboardEvent.altKey
            && !keyboardEvent.metaKey
            && !NUMBER_FILTERED_KEY.test(key)) {
            keyboardEvent.preventDefault();

            console.log('BLOCKED KEY=', key, NUMBER_FILTERED_KEY);

            return;
        }
    }, []);

    const rightButtons = useMemo(() => {
        const plusDisabled = !isNil(max) && ((value ?? defaultValue()) >= max);
        const minusDisabled = !isNil(min) && ((value ?? defaultValue()) <= min);

        return (
            <div className={classNames('&-right')}>
                <ArgButton
                    type='ghost'
                    onClick={(event) => handleButton(((event as MouseEvent).shiftKey ? shiftStep : step), 'selection')}
                    icon='icon-triangle-up'
                    tabIndex={-1}
                    className={classNames('&-right-item')}
                    disabled={disabled || readOnly || plusDisabled}
                />
                <ArgButton
                    type='ghost'
                    onClick={(event) => handleButton(-((event as MouseEvent).shiftKey ? shiftStep : step), 'selection')}
                    tabIndex={-1}
                    icon='icon-triangle-down'
                    className={classNames('&-right-item')}
                    disabled={disabled || readOnly || minusDisabled}
                />
            </div>
        );
    }, [max, value, defaultValue, min, classNames, disabled, readOnly, handleButton, shiftStep, step]);

    const handleNumberChange = useCallback((value: number | null, reason: ArgChangeReason) => {
        if (useInternalValue) {
            setInternalValue(value ?? undefined);
        }
        onChange && onChange(value, reason);
    }, [onChange, useInternalValue, setInternalValue]);

    const handleClear = useCallback(() => {
        if (useInternalValue) {
            setInternalValue(undefined);
        }

        if (!onClear) {
            onChange?.(null, 'clear');

            return;
        }

        onClear();
    }, [useInternalValue, onChange, onClear]);

    if (!disabled) {
        restProps.onKeyDown = handleKeyDown;
    }

    return (
        <ArgInput<number>
            inputType={inputType}
            right={right || (!readOnly && displayRightControl && rightButtons)}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            parseValue={numberParseValue}
            formatValue={numberFormatValue}
            className={classNames('&', className)}
            {...restProps}
            onKeyPress={numberKeypress}
            onInputChange={onInputChange}
            onChange={handleNumberChange}
            onClear={handleClear}
        />
    );
}
