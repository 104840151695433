import React, { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import { ArgIcon, ClassValue, useClassNames } from '../basic';

import './check-version.less';

const MIN_VERSION_CHECK_MS = 1000 * 30;

const messages = defineMessages({
    newVersion: {
        id: 'common.check-version.NewVersion',
        defaultMessage: 'Click here to upgrade to the new version ({version})',
    },
    closeTooltip: {
        id: 'common.check-version.CloseTooltip',
        defaultMessage: 'Close this alert',
    },
});

interface CheckVersionProps {
    className?: ClassValue;

    intervalMs?: number;
}

export function CheckVersion(props: CheckVersionProps) {
    const { className, intervalMs } = props;

    const classNames = useClassNames('common-check-version');

    const [newVersion, setNewVersion] = useState<string>();
    const intervalRef = useRef<any>();

    const intervalVersion = useCallback(() => {
        console.log('Checking version ...');
        getVersion().then((version) => {
            console.log('Retrieved version=', version);

            if (version === undefined) {
                return;
            }

            if (version === window?.argonodeConfig?.version?.build) {
                return;
            }

            setNewVersion(version);
        });
    }, []);

    useEffect(() => {
        const ms = Math.max(intervalMs ?? 0, MIN_VERSION_CHECK_MS);

        console.log('Check version each', (ms / 1000), 'seconds');
        const intervalId = setInterval(intervalVersion, ms);

        intervalRef.current = intervalId;

        return () => {
            clearInterval(intervalId);
            intervalRef.current = undefined;
        };
    }, [intervalVersion, intervalMs]);

    const handleUpdateVersion = useCallback(() => {
        document.location.reload();
    }, []);

    const handleHideUpdate = useCallback(() => {
        setNewVersion(undefined);
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
    }, []);

    if (!newVersion) {
        return null;
    }

    return <div className={classNames('&', className)}>
        <button className={classNames('&-button')} onClick={handleUpdateVersion} type='button'>
            <FormattedMessage {...messages.newVersion} values={{ version: newVersion }}/>
        </button>

        <Tooltip title={<FormattedMessage {...messages.closeTooltip}/>}>
            <button type='button' className={classNames('&-close')} onClick={handleHideUpdate}>
                <ArgIcon name='icon-cross' size='large'/>
            </button>
        </Tooltip>
    </div>;
}


async function getVersion(): Promise<string | undefined> {
    try {
        const response = await fetch('/config.js', { cache: 'no-store' });

        const text = await response.text();

        const reg = /build:\W+'(.*)'/.exec(text);

        const buildId = reg?.[1];

        return buildId;
    } catch (x) {
        console.error('Can not get config.js', x);
    }

    return undefined;
}
