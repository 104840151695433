import L, { LatLngExpression } from 'leaflet';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { Marker, MarkerProps, useMap } from 'react-leaflet';

import { LatLngExpressionToLatLng } from '../../../../helpers/LatLngExpressionToLatLng';

export interface ArgMapMarkerProps extends MarkerProps {
    onChange?: (center: LatLngExpression) => void;
}

export function ArgMapMarker(props: ArgMapMarkerProps) {
    const { position, icon, onChange } = props;

    const map = useMap(); // React leaflet Hook providing the Leaflet Map instance
    const markerRef = useRef(null as L.Marker | null);

    useEffect(() => {
        const center = LatLngExpressionToLatLng(position);

        if (!map || !center) {
            return;
        }

        try {
            map.flyTo(
                center,
                map.getZoom() < 10 ? 10 : map.getZoom(), //Zoom
                {
                    animate: true,
                    duration: 0.5,
                }
            );
        } catch (error) {
            console.error('leaflet error :-(', error);
        }
    }, [position, map]);

    const eventHandlers = useMemo(() => ({
        dragend() {
            const marker = markerRef.current;

            if (!isNil(marker) && !isNil(onChange)) {
                onChange(marker.getLatLng());
            }
        },
    }), [onChange, markerRef]);

    return (
        <Marker
            icon={icon}
            autoPan={true}
            ref={markerRef}
            // TODO : Enable draggable marker then compute the new address name
            // draggable={true}
            position={position}
            eventHandlers={eventHandlers}
        />
    );
}
