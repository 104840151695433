import { isObject, isString, omit, union, uniqBy, without } from 'lodash';

import { ConnectorRequestInit } from '../../utils/connector';
import {
    AddGroupDTO,
    AddGroupMembersDTO,
    AddPolicyDTO,
    AddUserDTO,
    AddRoleDTO,
    DetailedWebHookDTO,
    EditGroupDTO,
    EditUserDTO,
    GetPolicyResponse,
    Policy,
    PolicyId,
    Role,
    RoleId,
    RolePermission,
    RolesScope,
} from '../models/dtoApi';
import { Group, GroupId, User, Users } from '../../model/user';
import { ArgUserId, immutableSet, ProgressMonitor } from 'src/components/basic';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { UniverseId } from 'src/exploration/model/universe';
import { PropertyObject } from '../models/policy';
import { mapUser } from 'src/utils/connectors/users-connector';
import { BaseConnector } from '../../utils/connectors/base-connector';
import { DetailedWebHook, DetailedWebHookId, DetailedWebHookRequest, WebhookEvent } from '../models/detailed-webhooks';
import {
    getAdministrationApi,
    getDataExplorationApi,
    getDataPreparationApi,
    getSettingsApi,
} from 'src/utils/connectors/api-url';
import { Component } from 'src/preparation/model/component';
import { ValuationPolicy, ValuationPolicyId, ValuationPolicyPostRequest } from '../models/valuation-policy';
import { FormPolicy, FormPolicyId, FormPolicyPostRequest } from '../models/form-policy';
import { AppSettingsKey } from 'src/model/application-settings';
import {
    mapDetailedWebhook,
    mapExternalComponent,
    mapFormPolicy,
    mapGroup,
    mapPolicy,
    mapRole,
    mapUserProfileField,
    mapValuationPolicy,
} from './mappers';
import { ExternalComponent, ExternalComponentDto, ExternalComponentKey } from '../models/external-component';
import { UserProfileField } from 'src/model/user-metadata';
import { ImportAction, ImportExportManifest, ImportExportOptions } from '../models/configuration';

const SUPPORT_LEGACY = false;
const REMOVE_ID = true;

const isDataExplorationSupport = getDataExplorationApi() && process.env.REACT_APP_EXPLORATION_SUPPORT !== 'false';
const isDataPreparationSupport = getDataPreparationApi() && process.env.REACT_APP_PREPARATION_SUPPORT !== 'false';

class SettingsConnector extends BaseConnector {
    private static instance: SettingsConnector;

    static getInstance(): SettingsConnector {
        if (!SettingsConnector.instance) {
            SettingsConnector.instance = new SettingsConnector('settings', getSettingsApi());
        }

        return SettingsConnector.instance;
    }

    static getRoleScopeApi(roleScope: RolesScope | undefined): string {
        let api: string | undefined = undefined;

        if (roleScope === 'admin') {
            api = getAdministrationApi();
        } else if (roleScope === 'data_exploration') {
            api = getDataExplorationApi();
        } else if (roleScope === 'data_preparation') {
            api = getDataPreparationApi();
        } else {
            api = getSettingsApi();
        }

        if (api) {
            return api;
        }

        throw new Error(`API URL for role scope for ${roleScope} is undefined`);
    }

    async getUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<User> {
        const url = `/users/${encodeURIComponent(userId)}`;

        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const userResult: any = await this.request(url, options, progressMonitor);

        const ret = mapUser(userResult);

        return ret;
    }

    async getUsers(
        deleted: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<User[]> {
        const url = '/users';
        const options = {
            method: 'GET',
            params: {
                deleted,
            },
            verifyJSONResponse: true,
        };
        const response: Users = await this.request(url, options, progressMonitor);

        const users = response.users?.map(mapUser) || [];

        return users;
    }

    async getPolicies(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Policy[]> {
        const url = '/data-access-policies';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response: GetPolicyResponse = await this.request(
            url,
            options,
            progressMonitor
        );

        const policies = response[universeId]?.vectorPolicies || [];

        const ret = policies.map(mapPolicy);

        return ret;
    }

    async getPolicy(
        policyId: PolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Policy> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const policy = mapPolicy(response);

        let patchedPolicy = policy;
        if (SUPPORT_LEGACY) {
            /*           policy.statement.Actions[0] = {
                InferenceDetection: false,
                Targets: [{ object: { 'Mission': 'profile:<Missions', '_kind': 'Vertex' } }],
                Effects: [],
            };
*/
            policy.statement.Actions.forEach((action, actionIndex) => {
                action.Targets.forEach((target, targetIndex) => {
                    if (!target?.object?._kind && target?.and && target?.or) {
                        return;
                    }

                    const keys = without(Object.keys(target.object || {}), '_kind', '_type');
                    if (keys.length !== 1) {
                        return;
                    }

                    patchedPolicy = immutableSet(patchedPolicy, `statement.Actions[${actionIndex}].Targets[${targetIndex}]`, {
                        and: [
                            {
                                object: omit(target.object!, keys[0]),
                            },
                            {
                                object: {
                                    [keys[0]]: (target.object as PropertyObject)[keys[0]],
                                },
                            },
                        ],
                    });
                });
            });
        }

        return patchedPolicy;
    }


    async editPolicy(
        policyId: string,
        newPolicy: Policy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        let patchedPolicy = newPolicy;
        if (SUPPORT_LEGACY) {
            patchedPolicy.statement.Actions.forEach((action, actionIndex) => {
                action.Targets.forEach((target, targetIndex) => {
                    if (!target?.and && target?.or && target.and?.length !== 2) {
                        return;
                    }
                    const keys = Object.keys(target?.and?.[1]?.object || {});
                    if (keys.length !== 1 || keys[0] === '_kind' || keys[0] === '_type') {
                        return;
                    }

                    patchedPolicy = immutableSet(patchedPolicy, `statement.Actions[${actionIndex}].Targets[${targetIndex}]`, {
                        object: {
                            ...target.and![0].object,
                            [keys[0]]: (target.and![1].object as PropertyObject)[keys[0]],
                        },
                    });
                });
            });
        }
        if (REMOVE_ID) {
            patchedPolicy.scopes.forEach((scope, scopeIndex) => {
                if (!(scope as any).id) {
                    return;
                }
                patchedPolicy = immutableSet(patchedPolicy, ['scopes', scopeIndex], omit(scope, 'id'));
            });
        }
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PUT',
            json: patchedPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async addUser(
        addUserPayload: AddUserDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ id: ArgUserId }> {
        const url = '/users';
        const options = {
            method: 'POST',
            verifyJSONResponse: true,
            json: {
                ...addUserPayload,
                fullName: addUserPayload.displayName,
            },
        };
        const newUserId = await this.request(url, options, progressMonitor);

        return newUserId;
    }

    async deleteUser(
        userId: ArgUserId,
        deletePermanently: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}`;
        const options = {
            method: 'DELETE',
            params: {
                deletePermanently,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async editUser(
        editUserPayload: EditUserDTO,
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}`;
        const options = {
            method: 'PUT',
            json: {
                ...editUserPayload,
                fullName: editUserPayload.displayName,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async editUserRoles(
        userId: ArgUserId,
        roleIds: RoleId[],
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/roles`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api,
            json: {
                userId,
                roleIds,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getUserRoles(
        userId: ArgUserId,
        scope?: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const url = `/users/${encodeURIComponent(userId)}/roles`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'GET',
            api,
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const ret: Role[] = response?.roles.map(mapRole);

        return ret;
    }

    async enableUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/enable`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }

    async disableUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/disable`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }

    async resetPassword(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ password: string }> {
        const url = `/users/${encodeURIComponent(userId)}/password`;
        const options = {
            method: 'POST',
        };
        const newPassword = await this.request(url, options, progressMonitor);

        return newPassword;
    }

    async editGroup(
        editGroupPayload: EditGroupDTO,
        groupId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}`;
        const options = {
            method: 'PUT',
            json: editGroupPayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async addMembersToGroup(
        addMembersToGroupPayload: AddGroupMembersDTO,
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members`;
        const options = {
            method: 'POST',
            json: addMembersToGroupPayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteUserFromGroup(
        groupId: GroupId,
        userIdToRemove: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ) {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members/users/${encodeURIComponent(userIdToRemove)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteGroupFromGroup(
        groupId: GroupId,
        groupIdToRemove: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ) {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members/groups/${encodeURIComponent(groupIdToRemove)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async editGroupRoles(
        groupId: GroupId,
        roleIds: RoleId[],
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/roles`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api,
            json: {
                groupId,
                roleIds,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getGroupRoles(
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/roles`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        const ret: Role[] = response.roles.map(mapRole);

        return ret;
    }

    async restoreUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/users/${encodeURIComponent(userId)}/restore`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }

    async addRole(
        role: AddRoleDTO,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RoleId> {
        const url = '/user-roles';
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'POST',
            api: api,
            json: {
                role: {
                    name: role.name,
                    description: role.description,
                    scope: role.scope,
                },
            },
        };
        const res = await this.request(url, options, progressMonitor);

        return res?.role?.id;
    }

    async editRole(
        role: Role,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RoleId> {
        const url = `/user-roles/${encodeURIComponent(role.id)}`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api,
            json: {
                role: role,
            },
        };

        const res = await this.request(url, options, progressMonitor);

        return res?.role?.id;
    }

    async deleteRole(
        roleId: RoleId,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(scope)}/${encodeURIComponent(roleId)}`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'DELETE',
            api: api,
        };
        await this.request(url, options, progressMonitor);
    }

    async getAllRoles(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const apiCalls = [this.getRoles(false, 'admin', progressMonitor)];
        if (isDataExplorationSupport) {
            apiCalls.push(this.getRoles(false, 'data_exploration', progressMonitor));
        }
        if (isDataPreparationSupport) {
            apiCalls.push(this.getRoles(false, 'data_preparation', progressMonitor));
        }

        const response = await Promise.all(apiCalls);

        const ret = uniqBy(union(...response), (role) => role.id);

        return ret;
    }

    async getAllUserRoles(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const apiCalls = [this.getUserRoles(userId, 'admin', progressMonitor)];
        if (isDataExplorationSupport) {
            apiCalls.push(this.getUserRoles(userId, 'data_exploration', progressMonitor));
        }
        if (isDataPreparationSupport) {
            apiCalls.push(this.getUserRoles(userId, 'data_preparation', progressMonitor));
        }

        const response = await Promise.all(apiCalls);

        const ret = uniqBy(union(...response), (role) => role.id);

        return ret;
    }

    async getRoles(
        includeDraft: boolean,
        scope?: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Role[]> {
        const url = '/user-roles';
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'GET',
            api,
            params: {
                includeDraft,
            },
            verifyJSONResponse: true,
        };
        const response = await this.request(url, options, progressMonitor);

        const ret: Role[] = response?.roles.map(mapRole);

        return ret;
    }

    async getRolePermissions(
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RolePermission[]> {
        const url = '/user-roles/permissions';
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'GET',
            api: api,
            verifyJSONResponse: true,
        };
        const response = await this.request(url, options, progressMonitor);

        return response;
    }

    async editRolePermission(
        permission: RolePermission,
        scope: RolesScope,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(permission.roleId)}/permission/${encodeURIComponent(permission.key)}`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'PUT',
            api: api,
            json: {
                permission: permission,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async getComponentsPermissions(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<RolePermission[]> {
        const api = getDataPreparationApi();
        const options = {
            method: 'GET',
            api: api,
            verifyJSONResponse: true,
        };
        const result = await this.request('/components', options, progressMonitor);

        const permissions = result.components
            .sort((component1: Component, component2: Component) => component1.category.localeCompare(component2.category))
            .map((component: Component) => {
                const permission: RolePermission = {
                    id: '',
                    roleId: '',
                    key: component.key,
                    name: component.category ? `${component.category} - ${component.name}` : component.name,
                    isAccess: false,
                    scope: 'data_preparation',
                    section: 'components',
                    type: component.type,
                };

                return permission;
            });

        return permissions;
    }

    async publishUserRoles(
        scope: RolesScope,
        progressMonitor: ProgressMonitor
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(scope)}/publish`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'POST',
            api: api,
        };
        await this.request(url, options, progressMonitor);
    }

    async resetUserRoles(
        scope: RolesScope,
        progressMonitor: ProgressMonitor
    ): Promise<void> {
        const url = `/user-roles/${encodeURIComponent(scope)}/clean`;
        const api = SettingsConnector.getRoleScopeApi(scope);
        const options = {
            method: 'POST',
            api: api,
        };
        await this.request(url, options, progressMonitor);
    }

    async getUserGroups(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group[]> {
        const url = `/users/${encodeURIComponent(userId)}/memberships`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        // TODO OO Map date
        const response: { groups: Group[] } = await this.request(
            url,
            options,
            progressMonitor
        );

        return response.groups;
    }

    async getGroups(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Group[]> {
        const url = '/user-groups';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };
        const response: { groups: any } = await this.request(
            url,
            options,
            progressMonitor
        );

        const ret = response.groups.map(mapGroup);

        return ret;
    }

    async getGroup(
        groupId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group> {
        const url = `/user-groups/${encodeURIComponent(groupId)}`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const groupResult = await this.request(url, options, progressMonitor);
        const ret = mapGroup(groupResult);

        return ret;
    }

    async addPolicy(
        addPolicyPayload: AddPolicyDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/data-access-policies';
        const options = {
            method: 'POST',
            json: addPolicyPayload,
        };

        await this.request(url, options, progressMonitor);
    }

    async addGroup(
        addGroupPayload: AddGroupDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Group> {
        const url = '/user-groups';
        const options = {
            method: 'POST',
            json: addGroupPayload,
        };
        const newUserGroup = await this.request(url, options, progressMonitor);

        return newUserGroup;
    }

    async addGroupMembers(
        groupId: GroupId,
        addGroupMembersPayload: AddGroupMembersDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members`;
        const options = {
            method: 'POST',
            json: addGroupMembersPayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async getGroupMembers(
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<{ users: User[]; groups: Group[] }> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/members`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };
        const response = await this.request(url, options, progressMonitor);

        return response;
    }

    async getGroupMemberships(
        groupId: GroupId,
        inherited: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<string[]> {
        const url = `/user-groups/${encodeURIComponent(groupId)}/memberships`;
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
            params: {
                inherited,
            },
        };
        const response = await this.request(url, options, progressMonitor);

        const ret = response.groups.map((group: Group) => group.id);

        return ret;
    }

    async deletePolicy(
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteGroup(
        groupId: GroupId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/user-groups/${encodeURIComponent(groupId)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async changePolicyActivationStatus(
        policyEnabled: boolean,
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Policy> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'enable or disable the policy',
                changes: [
                    {
                        op: 'replace',
                        path: '/enabled',
                        value: !policyEnabled,
                    },
                ],
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };

        const editedPolicy = await this.request(url, options, progressMonitor);

        // TODO OO Map Date
        return editedPolicy;
    }

    async patchPolicyNameAndDescription(
        policyId: string,
        name: string,
        description: string | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Policy> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const jsonPayload = {
            comment: 'Edit the policy\'s name and description',
            changes: [
                {
                    op: 'replace',
                    path: '/Name',
                    value: name,
                },
                {
                    op: 'replace',
                    path: '/Description',
                    value: description || null,
                },
            ],
        };
        const options = {
            method: 'PATCH',
            json: jsonPayload,
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };
        const newPolicy = await this.request(url, options, progressMonitor);

        // TODO OO Map Date
        return newPolicy;
    }

    async publishPolicy(
        policyId: string,
        operation: 'Publish' | 'CancelChanges',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-access-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async reorderPolicies(
        orderedPolicyIds: string[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/data-access-policies/order';
        const options = {
            method: 'POST',
            json: {
                policyIds: orderedPolicyIds,
                selectedPolicyId: null,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async getContextualVariables(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ContextualVariable[]> {
        const url = '/contextual-variables';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return result.variables || [];
    }

    async getUserProfileFields(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<UserProfileField[]> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };


        const result = await this.request(url, options, progressMonitor);

        const ret = (result.fields as (any[] | undefined) || []).map((raw) => {
            const ret = mapUserProfileField(raw);

            return ret;
        });

        return ret;
    }

    async getWebhooks(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<DetailedWebHook[]> {
        const url = '/webhooks';

        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response = await this.request(url, options, progressMonitor);

        return response.webHooks.map((rawWebhook: DetailedWebHookDTO) => mapDetailedWebhook(rawWebhook));
    }

    async putWebhook(
        id: DetailedWebHookId,
        webhook: DetailedWebHookRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/webhooks/${encodeURIComponent(id)}`;

        const options = {
            method: 'PUT',
            json: webhook,
        };

        await this.request(url, options, progressMonitor);
    }

    async createWebhook(
        webhook: DetailedWebHookRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/webhooks';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: webhook,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async getWebhookEvents(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<WebhookEvent[]> {
        const url = '/webhook-events';

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret = result.webHookEvents.map((webhookEvent: WebhookEvent) => {
            return {
                id: webhookEvent,
                isEnabled: webhookEvent.isEnabled,
            };
        });

        return ret;
    }

    async deleteWebhook(
        webhookId: DetailedWebHookId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/webhooks/${encodeURIComponent(webhookId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteWebhooks(
        webhookIds: DetailedWebHookId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/webhooks';

        const options: ConnectorRequestInit = {
            method: 'DELETE',
            json: {
                webHookIds: webhookIds,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async toggleWebhooks(
        webhookIds: DetailedWebHookId[],
        isEnabled: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/webhooks/toggle';

        const options: ConnectorRequestInit = {
            method: 'PATCH',
            json: {
                webHookIds: webhookIds,
                state: isEnabled,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async exportWebhook(
        webhookId: DetailedWebHookId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<DetailedWebHookDTO> {
        const url = `/webhooks/${encodeURIComponent(webhookId)}/export`;

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async importWebhook(
        webhook: DetailedWebHookDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<DetailedWebHookDTO> {
        const url = '/webhooks/import';

        const options: ConnectorRequestInit = {
            method: 'POST',
            verifyJSONResponse: true,
            json: webhook,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async putValuationPolicy(
        id: ValuationPolicyId,
        valuationPolicy: ValuationPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(id)}`;

        const options = {
            method: 'PUT',
            json: valuationPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async createValuationPolicy(
        valuationPolicyPostRequest: ValuationPolicyPostRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/data-valuation-policies';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: valuationPolicyPostRequest,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async getValuationPolicies(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ValuationPolicy[]> {
        const url = '/data-valuation-policies';

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const rawValuationPolicies = result[universeId]?.vectorPolicies || [];

        const ret: ValuationPolicy[] = rawValuationPolicies.map(mapValuationPolicy);

        return ret;
    }

    async getValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ValuationPolicy> {
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;

        const rawValuationPolicy = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: ValuationPolicy = mapValuationPolicy(rawValuationPolicy);

        return ret;
    }

    async deleteValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async editValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        newPolicy: ValuationPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        let patchedPolicy = newPolicy;
        if (REMOVE_ID) {
            patchedPolicy.scopes?.forEach((scope, scopeIndex) => {
                if (!(scope as any).id) {
                    return;
                }
                patchedPolicy = immutableSet(patchedPolicy, ['scopes', scopeIndex], omit(scope, 'id'));
            });
        }
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'PUT',
            json: patchedPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async publishValuationPolicy(
        valuationPolicyId: ValuationPolicyId,
        operation: 'Publish' | 'CancelChanges',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/data-valuation-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async changeValuationPolicyActivationStatus(
        policyEnabled: boolean,
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ValuationPolicy> {
        const url = `/data-valuation-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'enable or disable the valuation policy',
                changes: [
                    {
                        op: 'replace',
                        path: '/enabled',
                        value: !policyEnabled,
                    },
                ],
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };

        const rawValuationPolicy = await this.request(url, options, progressMonitor);

        const ret: ValuationPolicy = mapValuationPolicy(rawValuationPolicy);

        return ret;
    }

    async putFormPolicy(
        id: FormPolicyId,
        valuationPolicy: FormPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(id)}`;

        const options = {
            method: 'PUT',
            json: valuationPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async createFormPolicy(
        valuationPolicyPostRequest: FormPolicyPostRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/display-template-policies';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: valuationPolicyPostRequest,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async getFormPolicies(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FormPolicy[]> {
        const url = '/display-template-policies';

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const rawFormPolicies = result[universeId]?.vectorPolicies || [];

        const ret: FormPolicy[] = rawFormPolicies.map(mapFormPolicy);

        return ret;
    }

    async getFormPolicy(
        valuationPolicyId: FormPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FormPolicy> {
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;

        const rawFormPolicy = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: FormPolicy = mapFormPolicy(rawFormPolicy);

        return ret;
    }

    async deleteFormPolicy(
        valuationPolicyId: FormPolicyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;

        const options: ConnectorRequestInit = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async editFormPolicy(
        valuationPolicyId: FormPolicyId,
        newPolicy: FormPolicy,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        let patchedPolicy = newPolicy;
        if (REMOVE_ID) {
            patchedPolicy.scopes?.forEach((scope, scopeIndex) => {
                if (!(scope as any).id) {
                    return;
                }
                patchedPolicy = immutableSet(patchedPolicy, ['scopes', scopeIndex], omit(scope, 'id'));
            });
        }
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'PUT',
            json: patchedPolicy,
        };

        await this.request(url, options, progressMonitor);
    }

    async publishFormPolicy(
        valuationPolicyId: FormPolicyId,
        operation: 'Publish' | 'CancelChanges',
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/display-template-policies/${encodeURIComponent(valuationPolicyId)}`;
        const options = {
            method: 'POST',
            params: {
                operation,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async changeFormPolicyActivationStatus(
        policyEnabled: boolean,
        policyId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<FormPolicy> {
        const url = `/display-template-policies/${encodeURIComponent(policyId)}`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'enable or disable the display template policy',
                changes: [
                    {
                        op: 'replace',
                        path: '/enabled',
                        value: !policyEnabled,
                    },
                ],
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
            verifyJSONResponse: true,
        };

        const rawFormPolicy = await this.request(url, options, progressMonitor);

        const ret: FormPolicy = mapFormPolicy(rawFormPolicy);

        return ret;
    }

    async getExternalComponents(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ExternalComponent[]> {
        const url = '/remote-components';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
            api: api,
        };

        const response = await this.request(url, options, progressMonitor);

        return response.remoteComponents.map((rawExternalComponent: ExternalComponentDto) => mapExternalComponent(rawExternalComponent));
    }

    async createExternalComponent(
        externalComponent: ExternalComponent,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/remote-components';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                ...externalComponent,
            },
            verifyJSONResponse: true,
            api: api,
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteExternalComponent(
        externalComponentKey: ExternalComponentKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/remote-components/${encodeURIComponent(externalComponentKey)}`;
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'DELETE',
            api: api,
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteExternalComponents(
        externalComponentKeys: ExternalComponentKey[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/remote-components';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'DELETE',
            api: api,
            json: {
                remoteComponentsIds: externalComponentKeys,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async exportExternalComponent(
        externalComponentKeys: ExternalComponentKey[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Blob> {
        const url = '/remote-components/export';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options: ConnectorRequestInit = {
            method: 'POST',
            api: api,
            json: {
                remoteComponentsIds: externalComponentKeys,
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async importExternalComponent(
        file: Blob,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ExternalComponent> {
        const url = '/remote-components/import';
        const api = SettingsConnector.getRoleScopeApi('data_preparation');

        const options: ConnectorRequestInit = {
            method: 'POST',
            body: file,
            api: api,
            verifyJSONResponse: true,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async putExternalComponent(
        externalComponentKey: ExternalComponentKey,
        externalComponent: ExternalComponent,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = `/remote-components/${encodeURIComponent(externalComponentKey)}`;
        const api = SettingsConnector.getRoleScopeApi('data_preparation');
        const options = {
            method: 'PUT',
            json: externalComponent,
            api: api,
        };

        await this.request(url, options, progressMonitor);
    }

    computeAppSettingsURL(key: AppSettingsKey): string {
        const url = `${this.api}/application/settings/${encodeURIComponent(key)}`;

        return url;
    }

    async getAppSettingsBlob(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Blob> {
        const url = this.computeAppSettingsURL(key);

        const options: ConnectorRequestInit = {
            method: 'GET',
            noCache: false,
        };

        const result = await this.request(url, options, progressMonitor);

        if (!(result instanceof Blob)) {
            throw new Error('Invalid response type (not a BLOB)');
        }

        return result;
    }

    async getAppSettingsJSON(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<any> {
        const url = this.computeAppSettingsURL(key);

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
            noCache: false,
        };

        const json = await this.request(url, options, progressMonitor);

        return json;
    }

    async setAppSettings(
        key: AppSettingsKey,
        settingsValue: Blob | string | any,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        if (settingsValue === undefined) {
            return this.deleteAppSettings(key, progressMonitor);
        }

        const url = this.computeAppSettingsURL(key);

        const data = new FormData();
        if (settingsValue instanceof Blob) {
            data.append('file', settingsValue);
        } else if (isObject(settingsValue)) {
            const jsonBLOB = new Blob([JSON.stringify(settingsValue)], {
                type: 'application/json',
            });
            data.append('file', jsonBLOB);
        } else if (isString(settingsValue)) {
            data.append('text', String(settingsValue));
        }

        const options = {
            method: 'PUT',
            body: data,
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteAppSettings(
        key: AppSettingsKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = this.computeAppSettingsURL(key);

        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async importManifest(
        file: Blob,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ImportExportManifest> {
        const url = '/configurations/import/manifest';
        const options: ConnectorRequestInit = {
            method: 'POST',
            body: file,
            verifyJSONResponse: true,
            headers: {
                'Content-Type': 'application/zip',
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async importConfigurations(
        file: Blob,
        configOptions: ImportExportOptions,
        importAction: ImportAction,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const url = '/configurations/import';

        const data = new FormData();
        data.append('action', importAction);
        data.append('formFile', new Blob([file], { type: 'application/zip' }));
        data.append('options', JSON.stringify({ requestBody: configOptions.options }));

        const options: ConnectorRequestInit = {
            method: 'POST',
            body: data,
            verifyJSONResponse: true,
        };

        await this.request(url, options, progressMonitor);
    }

    async exportConfigurations(
        configOptions: ImportExportOptions,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Blob> {
        const url = '/configurations/export';

        const options: ConnectorRequestInit = {
            method: 'POST',
            json: {
                requestBody: configOptions.options,
            },
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async getConfigurationsExportManifest(
        applicationId: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<ImportExportManifest> {
        const url = '/configurations/export/manifest';

        const ret = await this.request(url, {
            verifyJSONResponse: true,
            params: {
                applicationId,
            },
        }, progressMonitor);

        return ret;
    }
}

export default SettingsConnector.getInstance();
