import { ToolContext } from './tool-context';
import { useEffect, useState } from 'react';


export function useContextUpdate(toolContext: ToolContext): number {
    const [stateId, setStateId] = useState(toolContext.stateId);

    useEffect(() => {
        const updateToolbar = () => {
            setStateId(toolContext.stateId);
        };

        toolContext.on('*', updateToolbar);

        return () => {
            setStateId(-1);
            toolContext.removeListener('*', updateToolbar);
        };
    }, [toolContext]);

    return stateId;
}
