import { defineMessages, useIntl } from 'react-intl';
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import Debug from 'debug';

const debug = Debug('common:hooks:JobQuiControl');

export interface JobQuitControl {
    add: <T> (promise: Promise<T>) => Promise<T>;
}

export const JobQuitControlContext = createContext<JobQuitControl>({
    add: (promise) => {
        return promise;
    },
});

const messages = defineMessages({
    quitConfirm: {
        id: 'common.use-job-quit-control.quitConfirm',
        defaultMessage: 'Do you want to leave this page?',
    },
});

export interface JobQuitControlContextProviderProps {
    children?: ReactNode;
}

export function JobQuitControlContextProvider(props: JobQuitControlContextProviderProps) {
    const {
        children,
    } = props;

    const pendingJobsRef = useRef<Set<Promise<unknown>>>();
    const intl = useIntl();

    useEffect(() => {
        // Before unload handling
        function quitConfirm(event: Event) {
            debug('quitConfirm', 'count=', pendingJobsRef.current?.size);

            // If any job is pending
            if (!pendingJobsRef.current?.size) {
                return;
            }

            const message = intl.formatMessage(messages.quitConfirm);

            (event as any).returnValue = message;

            return message;
        }

        window.addEventListener('beforeunload', quitConfirm);

        return () => {
            window.removeEventListener('beforeunload', quitConfirm);
        };
    }, [intl]);

    const value = useMemo<JobQuitControl>(() => {
        function add(promise: Promise<any>): Promise<any> {
            if (!pendingJobsRef.current) {
                pendingJobsRef.current = new Set();
            }

            // Add the job to pending jobs queue
            pendingJobsRef.current.add(promise);
            debug('AddPromise', 'count=', pendingJobsRef.current.size);

            // Run the promise and clear the pending job when done
            const newPromise = promise.finally(() => {
                pendingJobsRef.current!.delete(promise);
                debug('ReleasePromise', 'new count=', pendingJobsRef.current!.size);
            });

            return newPromise;
        }

        return {
            add,
        };
    }, []);

    return (
        <JobQuitControlContext.Provider value={value}>
            {children}
        </JobQuitControlContext.Provider>
    );
}

export function useJobQuiteControl() {
    const jobQuitControl = useContext(JobQuitControlContext);

    if (jobQuitControl === undefined) {
        throw new Error('useJobQuiteControl must be used within a JobQuitControlContextProvider');
    }

    return jobQuitControl;
}
