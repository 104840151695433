import { isNumber, isObject } from 'lodash';
import { CSSProperties, ReactNode } from 'react';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import { ArgSize } from '../types';

import './arg-badge.less';

const DEFAULT_SIZE = 'small';

const DEFAULT_COLOR = 'red';

export type ArgBadges = ReactNode | ReactNode[];

export type ArgBadgeLiteralPosition = 'topLeft' | 'top' | 'topRight' | 'right' | 'bottomRight' | 'bottom' | 'bottomLeft' | 'left' | 'center';

export type ArgBadgePixelPosition = { left?: number, right?: number, top?: number, bottom?: number };

export type ArgBadgeDeltaPosition = { literalPosition: ArgBadgeLiteralPosition, deltaX?: number, deltaY?: number };

export type ArgBadgePosition = ArgBadgeLiteralPosition | ArgBadgePixelPosition | ArgBadgeDeltaPosition;

interface ArgBadgeProps {
    className?: ClassValue;
    icon?: string | 'dot' | 'important';
    count?: number;
    style?: CSSProperties;
    position: ArgBadgePosition;
    size?: ArgSize;
    color?: 'red' | 'green' | 'dark' | 'blue' | 'yellow' | 'grey' | 'primary';
}

export function ArgBadge(props: ArgBadgeProps) {
    const {
        className,
        icon,
        style,
        position,
        size = DEFAULT_SIZE,
        color = DEFAULT_COLOR,
        count,
    } = props;

    const classNames = useClassNames('arg-badge');

    let iconName: string | undefined;
    let label: ReactNode;
    let type = '';
    if (isNumber(count)) {
        label = (count > 9) ? '9+' : count;
        type = 'dot';
    } else if (icon === 'important') {
        iconName = 'icon-exclamation-point';
        type = 'important';
    } else if (icon === 'dot') {
        type = 'dot';
    } else if (typeof (icon) === 'string') {
        iconName = icon;
        type = 'icon';
    }

    const cls = {
        [`size-${size}`]: true,
        [`color-${color}`]: true,
        [`type-${type}`]: true,
        'is-count': isNumber(count),
    };

    let positionStyle: any = style;
    if (typeof (position) === 'string') {
        cls[`position-${position}`] = true;
    } else if (isObject(position)) {
        positionStyle = { ...style || {} };

        const manualPosition = position as ArgBadgePixelPosition;
        if (isNumber(manualPosition.left)) {
            positionStyle.left = `${manualPosition.left}px`;
        }
        if (isNumber(manualPosition.right)) {
            positionStyle.right = `${manualPosition.right}px`;
        }
        if (isNumber(manualPosition.top)) {
            positionStyle.top = `${manualPosition.top}px`;
        }
        if (isNumber(manualPosition.bottom)) {
            positionStyle.bottom = `${manualPosition.bottom}px`;
        }

        const deltaPosition = position as ArgBadgeDeltaPosition;
        if (isNumber(deltaPosition.deltaX)) {
            positionStyle['--arg-badge-delta-left'] = `${-deltaPosition.deltaX}px`;
            positionStyle['--arg-badge-delta-right'] = `${-deltaPosition.deltaX}px`;
        }
        if (isNumber(deltaPosition.deltaY)) {
            positionStyle['--arg-badge-delta-top'] = `${-deltaPosition.deltaY}px`;
            positionStyle['--arg-badge-delta-bottom'] = `${-deltaPosition.deltaY}px`;
        }
        if (deltaPosition.literalPosition) {
            cls[`position-${deltaPosition.literalPosition}`] = true;
        }
    }

    return <div className={classNames('&', cls, className)} style={positionStyle}>
        {iconName != undefined && <i className={classNames(iconName, '&-icon')} />}
        {label !== undefined && <span className={classNames('&-count')}>
            {label}
        </span>}
    </div>;
}
