import { CasePermissions, CaseUserPermissions, FolderCasePiece } from '../../model/folder-case-piece';
import { Collaborator, CollaboratorRole, CollaboratorType } from '../../model/collaborator';
import { mapUser } from './users-connector';
import { CasePieceType } from '../../model/case-piece-type';
import { CasePiece, FilterCasePieceCondition } from '../../exploration/model/case-piece';
import { BasicCasePiece } from '../../model/basic-case-piece';
import { Template } from '../../model/template';
import { RawTemplate } from '../../model/raws';
import { Folder } from '../../model/folder';
import { compact } from 'lodash';

export type CasePieceMapper<T extends BasicCasePiece = BasicCasePiece> = (
    target: T,
    properties: Record<string, any> | undefined,
    settings: Record<string, any>,
    userSettings: Record<string, any>,
) => void;

const casePieceTypeMapper: Map<string, CasePieceType> = new Map();
const casePieceCustomMapper: Map<CasePieceType, CasePieceMapper> = new Map();

function formatCasePath(path: string): string {
    let startIndex = 0;
    let endIndex = path.length;

    if (path[startIndex] === '/') {
        startIndex++;
    }

    if (path[endIndex] === '/') {
        endIndex--;
    }

    const formattedPath = path
        .slice(startIndex, endIndex)
        .trim()
        .replace('/', ' / ')
        .replace('  ', ' ')
        ;

    return formattedPath;
}

export function getRoleFromPermissions(permissions: CasePermissions): CollaboratorRole {
    const { hasOwnership, allowWrite, allowRead, allowDelete } = permissions;

    if (allowWrite && allowRead && !hasOwnership && !allowDelete) {
        return CollaboratorRole.Writer;
    }

    if (!allowWrite && allowRead && !hasOwnership && !allowDelete) {
        return CollaboratorRole.Reader;
    }

    return CollaboratorRole.Owner;
    // return CollaboratorRole.Custom;
}


export function mapCollaborators(userPermissions?: CaseUserPermissions[]) {
    const ret = userPermissions?.map((userPermission) => {
        const collaborator: Collaborator = {
            identityId: userPermission.user.id,
            name: userPermission.user.displayName || '',
            type: CollaboratorType.User,
            user: mapUser(userPermission.user),
            role: getRoleFromPermissions(userPermission.permissions),
            permission: userPermission.permissions,
        };

        return collaborator;
    }) || [];

    return ret;
}

export function mapFolder(raw: any, filterCasePieceCondition?: FilterCasePieceCondition): Folder {
    const folderCasePiece = mapFolderCasePiece(raw);

    let argonosPieces: CasePiece[] =
        raw.argonosPieces?.map((argonosPieceRaw: any) => {
            const ret = mapArgonosPiece(argonosPieceRaw);

            return ret;
        }) ?? [];

    if (filterCasePieceCondition) {
        argonosPieces = compact(argonosPieces).filter(filterCasePieceCondition);
    }

    const folder: Folder = {
        ...folderCasePiece,
        pieces: compact(argonosPieces),
    };

    return folder;
}

export function mapFolderCasePiece(result: any): FolderCasePiece {
    const folderCasePiece: FolderCasePiece = {
        id: result.id,
        displayName: result.name,
        description: result.description,
        parentFolderId: result.parentFolderId,
        isParentFolderReadable: result.isParentFolderReadable,
        path: formatCasePath(result.path),
        lastVisitedDate: mapDate(result.lastVisitedDate),
        createdDate: mapDate(result.createdDate),
        createdBy: result.createdBy,
        lastUpdatedBy: result.lastUpdatedBy,
        lastUpdatedDate: mapDate(result.lastUpdatedDate),
        permissions: result.permissions,
        scope: result.scope,
        isPrivate: !!result.isSandbox,
        userPermissions: result.allPermissions?.userPermissions,

        type: CasePieceType.Folder,
    };

    return folderCasePiece;
}

export function mapArgonosPiece(piece: any): BasicCasePiece | undefined {
    const type = convertCasePieceTypeToFront(piece);
    if (type === undefined) {
        console.warn('Unknown type of casePiece=', piece);

        return undefined;
    }

    const ret: BasicCasePiece = {
        id: piece.entityId,

        displayName: piece.name,
        description: piece.description,
        type,

        createdBy: piece.createdBy,
        createdDate: mapDate(piece.createdDate),

        lastUpdatedBy: piece.lastUpdatedBy,
        lastUpdatedDate: mapDate(piece.lastUpdatedDate),

        lastVisitedDate: mapDate(piece.lastVisitedDate),
    };

    const { entityProperties, settings, userSettings } = piece;
    const mapper = casePieceCustomMapper.get(type);

    if (mapper) {
        mapper(ret, entityProperties, settings, userSettings);
    }

    return ret;
}

export function convertCasePieceTypeToFront(piece: any): CasePieceType | undefined {
    const type = piece.entityType;
    const entityProperties = piece.entityProperties;
    switch (type) {
        case 'bulksearch': {
            return CasePieceType.MassiveSearch;
        }
        case 'exploration': {
            const origin = entityProperties?.origin;
            if (origin === 'FastSearch') {
                return CasePieceType.FastSearch;
            }
            if (origin === 'AdvancedSearch') {
                return CasePieceType.AdvancedSearch;
            }

            return CasePieceType.Exploration;
        }
    }

    const ret = casePieceTypeMapper.get(type);

    return ret;
}

export function convertCasePieceTypeToBack(type: CasePieceType): string | undefined {
    switch (type) {
        case CasePieceType.Exploration:
        case CasePieceType.FastSearch:
        case CasePieceType.AdvancedSearch:
            return 'exploration';
        case CasePieceType.MassiveSearch:
            return 'bulksearch';
    }

    for (const [casePieceTypeName, casePieceType] of casePieceTypeMapper.entries()) {
        if (casePieceType === type) {
            return casePieceTypeName;
        }
    }

    return undefined;
}


export function mapDate(date: undefined): undefined;
export function mapDate(date: string): Date;
export function mapDate(date: string | undefined): Date | undefined;

export function mapDate(date: string | undefined): Date | undefined {
    if (!date) {
        return undefined;
    }

    return new Date(date);
}

export function registerCasePieceType<T extends BasicCasePiece>(casePieceTypeName: string, casePieceType: CasePieceType, mapper?: CasePieceMapper<T>) {
    casePieceTypeMapper.set(casePieceTypeName, casePieceType);
    if (mapper) {
        casePieceCustomMapper.set(casePieceType, mapper as CasePieceMapper);
    }
}

export function mapTemplate<TContent>(result: RawTemplate<TContent>): Template<TContent> {
    const template: Template<TContent> = {
        id: result.id,
        name: result.name,
        description: result.description,
        type: result.type,
        parentId: result.parentId,
        content: result.content,
        createdDate: mapDate(result.createdDate)!,
        createdBy: result.createdBy,
        lastUpdatedDate: mapDate(result.lastUpdatedDate),
        lastUpdatedBy: result.lastUpdatedBy,
        isDefault: result.isDefault,
    };

    return template;
}

