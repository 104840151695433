import React, { ReactNode, SyntheticEvent } from 'react';
import { IntlShape } from 'react-intl/src/types';
import classNames from 'classnames';
import { forEach, get, isArray, isObject, isPlainObject, isString, replace, toNumber, toString, values } from 'lodash';

import { ClassValue } from './arg-hooks/use-classNames';
import { isMessageDescriptor } from './utils/is-message-descriptor';
import { ArgTable2Column } from './arg-table/arg-table2';
import { ArgMessageValues, ArgRenderedText } from './types';
import { ArgIconCheckboxStates } from './arg-checkbox/arg-icon-checkbox';

export interface ArgExpandState {
    hasAnyChild: boolean,
    isExpanded: boolean,
    level: number,
}

export type ArgGetItemKey<T> = ((item: T) => (string | null)) | string;
export type ArgGetItemLabel<T> = ((item: T) => ArgRenderedText) | string;
export type ArgGetItemDescription<T> = ((item: T) => ArgRenderedText) | string;
export type ArgGetItemTooltip<T> = ((item: T) => ArgRenderedText) | string | false;
export type ArgGetItemCount<T> = ((item: T) => ReactNode) | string;
export type ArgGetItemIcon<T> = ((item: T) => ReactNode) | string;
export type ArgGetItemClassName<T> = ((item: T) => ClassValue) | string;
export type ArgGetItemDisabled<T> = ((item: T) => boolean) | string;
export type ArgGetItemCheckedState<T> = ((item: T) => ArgIconCheckboxStates) | string;
export type ArgGetItemExpandState<T> = ((item: T) => ArgExpandState);
export type ArgGetItemBackgroundColor<T> = ((item: T) => (string | undefined)) | string;

type PrimitiveType = string | number | boolean | null | undefined | Date;

export const NULL_MAGIC_KEY = '##MAGIC-NULL##OO';

const FORCE_BODY = true;

const IGNORE_SCROLLABLE_ELEMENT = true;

export function computeItemKey<T>(item: T, getItemKey?: ArgGetItemKey<T>, defaultItemKey?: string): string {
    if (getItemKey === undefined) {
        if (defaultItemKey) {
            return defaultItemKey;
        }

        return String(item);
    }
    if (isString(getItemKey)) {
        const key = get(item as any, getItemKey);
        if (key === undefined) {
            throw new Error('Invalid key for item');
        }

        if (key === null) {
            return NULL_MAGIC_KEY;
        }

        return key;
    }

    const key = getItemKey(item);
    if (key === undefined) {
        throw new Error('Invalid key for item');
    }
    if (key === null) {
        return NULL_MAGIC_KEY;
    }

    return key;
}

export function computeItemLabel<T>(item: T | null, getItemLabel?: ArgGetItemLabel<T>): ArgRenderedText {
    if (getItemLabel === undefined) {
        if (!item) {
            return null;
        }

        if (isMessageDescriptor(item)) {
            return item;
        }

        return String(item);
    }

    if (isString(getItemLabel)) {
        if (!item) {
            return null;
        }

        const ret = get(item as any, getItemLabel);

        return ret;
    }

    if (item === null) {
        return '';
    }

    const ret = getItemLabel(item);

    return ret;
}

export function computeItemDescription<T>(item: T | null, getItemDescription?: ArgGetItemDescription<T>): ArgRenderedText {
    if (getItemDescription === undefined) {
        if (!item) {
            return null;
        }

        return String(item);
    }

    if (isString(getItemDescription)) {
        if (!item) {
            return null;
        }

        const ret = get(item as any, getItemDescription);

        return ret;
    }

    if (item === null) {
        return '';
    }

    const ret = getItemDescription(item);

    return ret;
}

export function computeItemTooltip<T>(item: T | null, getItemTooltip?: ArgGetItemTooltip<T> | false): ArgRenderedText | false {
    if (getItemTooltip === false) {
        return false;
    }
    if (getItemTooltip === undefined) {
        if (!item) {
            return null;
        }

        return String(item);
    }

    if (isString(getItemTooltip)) {
        if (!item) {
            return null;
        }

        const ret = get(item as any, getItemTooltip);

        return ret;
    }

    if (item === null) {
        return '';
    }

    const ret = getItemTooltip(item);

    return ret;
}


export function computeItemIcon<T>(item: T, getItemIcon?: ArgGetItemIcon<T>): ReactNode {
    if (getItemIcon === undefined) {
        return null;
    }

    if (isString(getItemIcon)) {
        if (!item) {
            return null;
        }

        return get(item as any, getItemIcon);
    }

    return getItemIcon(item);
}

export function computeItemClassName<T>(item: T, getItemClassName?: ArgGetItemClassName<T>): ClassValue {
    if (getItemClassName === undefined) {
        return null;
    }

    if (isString(getItemClassName)) {
        if (!item) {
            return null;
        }

        return get(item as any, getItemClassName);
    }

    return getItemClassName(item);
}

export function computeItemCount<T>(item: T, getItemCount?: ArgGetItemCount<T>): ArgRenderedText {
    if (!getItemCount) {
        return undefined;
    }

    if (isString(getItemCount)) {
        return get(item as any, getItemCount);
    }

    return getItemCount(item);
}

export function computeItemDisabled<T>(item: T, getItemDisabled?: ArgGetItemDisabled<T>): boolean {
    if (getItemDisabled === undefined) {
        return false;
    }

    if (isString(getItemDisabled)) {
        if (!item) {
            return false;
        }

        return !!get(item as any, getItemDisabled);
    }

    return getItemDisabled(item);
}

export function computeItemExpandState<T>(item: T, getItemExpandState?: ArgGetItemExpandState<T>): ArgExpandState | undefined {
    if (getItemExpandState === undefined) {
        return undefined;
    }

    return getItemExpandState(item);
}

export function computeItemBackgroundColor<T>(item: T, getItemBackgroundColor?: ArgGetItemBackgroundColor<T>): string | undefined {
    if (!getItemBackgroundColor) {
        return undefined;
    }

    if (isString(getItemBackgroundColor)) {
        return get(item as any, getItemBackgroundColor);
    }

    return getItemBackgroundColor(item);
}

export function escapeColumnKey(columnKey: string): string {
    return CSS.escape(columnKey);
}

export function isNullItemKey(key: string) {
    return key === NULL_MAGIC_KEY;
}

export function normalizeText(text: undefined): undefined;
export function normalizeText(text: string): string;
export function normalizeText(text: string | undefined): string | undefined;
export function normalizeText(text: string | undefined): string | undefined {
    if (text === undefined) {
        return undefined;
    }

    return normalizeText0(text); // BAD IDEA .trim().replace(/\s+/g, ' ');
}

function normalizeText0(text: string): string {
    return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export interface RefCount {
    current: number;
}

export function getRecursiveChildText(reactNode: JSX.Element): string | undefined {
    const children = reactNode.props?.children || undefined;

    if (Array.isArray(reactNode)) {
        const joinedNodes: ReactNode[] = reactNode.map((node) => {
            if (isObject(node)) {
                return getRecursiveChildText(node as JSX.Element);
            }
            if (isString(node)) {
                return node;
            }

            return '';
        });

        return joinedNodes.join(' ');
    }

    if (children === undefined) {
        return '';
    }
    if (typeof children === 'object') {
        return getRecursiveChildText(children);
    }
    if (isString(children)) {
        return children;
    }

    return undefined;
}

export function highlightSplit(text: string, token?: string | null, className?: ClassValue, countRef?: RefCount): ReactNode {
    if (countRef) {
        countRef.current = 0;
    }
    if (!token) {
        return <span
            key='no-highlight'
            className={classNames('no-highlight', className)}
        >
            {text}
        </span>;
    }
    const normalizedToken = normalizeText0(token);
    const ret: ReactNode[] = [];
    const normalizedCaseText = normalizeText0(text);

    let keyIdx = 0;
    let idx = 0;
    for (; ;) {
        const i = normalizedCaseText.indexOf(normalizedToken, idx);
        if (i < 0) {
            break;
        }

        if (i > idx) {
            ret.push(<span key={keyIdx++} className='no-highlight'>
                {text.substring(idx, i)}
            </span>);
        }
        ret.push(<span key={keyIdx++} className='search-highlight-other-result'>
            {text.substring(i, i + token.length)}
        </span>);
        idx = i + token.length;

        if (countRef) {
            countRef.current++;
        }
    }
    if (idx < normalizedCaseText.length) {
        if (!ret.length) {
            return text;
        }
        ret.push(
            <span key={keyIdx++} className='no-highlight'>
                {text.substring(idx)}
            </span>);
    }

    if (ret.length === 1) {
        return ret[0];
    }

    return ret;
}


export function highlightSplitDOM(element: Element, text: string, token?: string): Element[] | undefined {
    if (!token) {
        return;
    }

    const fragment = element.ownerDocument.createDocumentFragment();

    const normalizedToken = normalizeText0(token);
    const normalizedCaseText = normalizeText0(text);

    const ret: Element[] = [];

    let idx = 0;
    for (; ;) {
        const i = normalizedCaseText.indexOf(normalizedToken, idx);
        if (i < 0) {
            break;
        }

        if (i > idx) {
            addElement(fragment, 'no-highlight', text.substring(idx, i));
        }
        ret.push(addElement(fragment, 'search-highlight-other-result', text.substring(i, i + token.length)));

        idx = i + token.length;
    }
    if (idx < normalizedCaseText.length) {
        if (!ret.length) {
            return ret;
        }
        addElement(fragment, 'no-highlight', text.substring(idx));
    }

    for (; element.lastChild;) {
        element.removeChild(element.lastChild);
    }

    element.append(fragment);

    return ret;
}

function addElement(fragment: DocumentFragment, className: string, text: string): Element {
    const span = fragment.ownerDocument.createElement('span');
    span.setAttribute('class', className);
    span.textContent = text;

    fragment.append(span);

    return span;
}

export function getModulusAndRemainder(value: number, divisor: number): [number, number] {
    // value = modulus * divisor + remainder
    const remainder = value % divisor;
    const modulus = (value - remainder) / divisor;

    return [modulus, remainder];
}

export interface HighlightedElement {
    key: string;
    className: 'search-highlight-current-result' | 'search-highlight-other-result' | 'no-highlight';
    content: string;
}

export function forCount<T>(count: number, callback: (index: number) => T): T[] {
    const items: T[] = [];

    for (let i = 0; i < count; i++) {
        items.push(callback(i));
    }

    return items;
}

export const highlightSplitWithDelimiters = (
    text: string,
    openingDelimiter: string,
    closingDelimiter: string,
    current: boolean,
    className?: ClassValue
): HighlightedElement[] => {
    /*
        1. get indices of opening and closing delimiters in one array:
            openingDelimiter: {OD}
            closingDelimiter: {CD}
            text = 'abc{OD}def{CD}gh{OD}ijk{CD}lmn'
            [3, 10, 16, 23]
        ! indices are impacted by delimiters length +> TAKE THIS INTO CONSIDERATION
    */
    const list: HighlightedElement[] = [];
    const openingDelimiterLength = openingDelimiter.length;
    const closingDelimiterLength = closingDelimiter.length;
    const matchers: RegExpMatchArray[] = [
        ...text.matchAll(new RegExp(`${openingDelimiter}|${closingDelimiter}`, 'gi')),
    ];
    if (matchers.length === 0) {
        return list;
    }

    const indices = matchers.map((value) => {
        return value.index as number;
    });

    /*
        2. Remove delimiters from text
            cleanedUpText = 'abcdefghijklmn'
    */
    // const cleanedUpText = text
    //     .replaceAll(new RegExp(`${openingDelimiter}`, 'gi'), '')
    //     .replaceAll(new RegExp(`${closingDelimiter}`, 'gi'), '');

    const cleanedUpText = text
        .replace(new RegExp(`${openingDelimiter}`, 'gi'), '')
        .replace(new RegExp(`${closingDelimiter}`, 'gi'), '');


    /*   3. Removes delimiters length impact from indices computeExploration
        [3, 10, 16, 23] => [3, 5, 8, 11] */
    // let inc
    const cleanedUpIndices = indices.map((value, index) => {
        const [modulus, remainder] = getModulusAndRemainder(index, 2);

        return value - remainder * openingDelimiterLength - modulus * (openingDelimiterLength + closingDelimiterLength);
    });

    /*
        4. Generate an array of ReactNodes:
        [
            <span className='no-highlight'>abc</span>,
            <span className='highlight'>def</span>,
            <span className='no-highlight'>gh</span>,
            <span className='highlight'>ijk</span>,
            <span className='no-highlight'>lmn</span>,
        ]
    */

    if (cleanedUpIndices[0] !== 0) {
        const content = cleanedUpText.substring(0, cleanedUpIndices[0]);
        list.push({
            key: '_$$_first-no-highlight-element_$$_',
            className: 'no-highlight',
            content,
        });
    }

    cleanedUpIndices.forEach((value, index) => {
        const chunck = cleanedUpText.substring(value, cleanedUpIndices[index + 1]);
        if (index % 2 === 0) {
            list.push({
                key: `_$$_${index}-highlight_$$_`,
                className: current ? 'search-highlight-current-result' : 'search-highlight-other-result',
                content: chunck,
            });
        } else {
            chunck.length > 0 && list.push({
                key: `_$$_${index}-no-highlight_$$_`,
                className: 'no-highlight',
                content: chunck,
            });
        }
    });

    return list;
};

export function filterItems<T>(
    items: T[],
    searchedToken: string,
    getItemLabel: ArgGetItemLabel<T> | undefined,
    intl: IntlShape,
    messageValues?: ArgMessageValues
) {
    const token = normalizeText(searchedToken.trim());
    const filteredItems = items.filter((item) => {
        let label = computeItemLabel(item, getItemLabel);
        if (isMessageDescriptor(label)) {
            if (!intl) {
                throw new Error('Intl is not defined');
            }
            label = intl.formatMessage(label, messageValues);
        }

        if (isString(label)) {
            return normalizeText(label).indexOf(token) >= 0;
        }

        return false;
    });

    return filteredItems;
}

export function getDataTestIdFromProps(props: any): string {
    return props['data-testid'];
}

export const valuesDeep = (object: any, allValues: any[] = []) => {
    const nestedValues = values(object);

    forEach(nestedValues, (nestedValue) => (isArray(nestedValue) || isPlainObject(nestedValue)
        ? valuesDeep(nestedValue, allValues)
        : allValues.push(nestedValue))
    );

    return allValues;
};

export function getTableCellValue<T>(column: ArgTable2Column<T>, row: T, rowIndex: number, intl: IntlShape, search?: string) {
    const cellData = get(row, column.dataIndex);

    let cellValue;
    if (column.render) {
        cellValue = column.render(cellData, row, rowIndex, search);
    } else if (isMessageDescriptor(cellData)) {
        cellValue = intl.formatMessage(cellData, { row: row as unknown as PrimitiveType });
    } else if (cellData) {
        cellValue = String(cellData);
    }

    return cellValue;
}

/**
 * Returns the closest scrollable ancestor of a DOM element.
 */
export function findClosestScrollableAncestor(domElement: HTMLElement) {
    let domIter = domElement.parentElement;
    while (domIter && domIter.tagName !== 'BODY') {
        if (!IGNORE_SCROLLABLE_ELEMENT) {
            const overflow = window.getComputedStyle(domIter).overflowY;
            if (overflow === 'auto' || overflow === 'scroll') {
                return domIter;
            }
        }

        if (domIter.getAttribute('role') === 'document') {
            return domIter;
        }

        domIter = domIter.parentElement;
    }

    return domIter;
}

/**
 * Find or create a div in closest scroll area.
 */
export function findOrCreatePopupArea(domElement: HTMLElement) {
    if (FORCE_BODY) {
        return domElement.ownerDocument.body;
    }
    const found = findClosestScrollableAncestor(domElement);
    if (found === null || domElement.tagName === 'BODY') {
        return found;
    }
    // search dedicated popuparea
    for (let i = 0; i !== found.childNodes.length; i++) {
        const item = found.childNodes[i] as HTMLElement;
        if (item.className === 'arg-popup-area') {
            return item;
        }
    }
    // create relative
    const popuparea = window.document.createElement('div');
    popuparea.className = 'arg-popup-area';
    found.appendChild(popuparea);

    return popuparea;
}

export function highlightHtml(html: string, htmlToken: string): string {
    let tokenIndexes: number[] | undefined = undefined;
    const allReplacements: number[] = [];

    const normalizedHtmlToken = normalizeText(htmlToken);

    let index = 0;
    for (; index < html.length; index++) {
        const c = normalizeText(html.charAt(index));
        if (c === '<') {
            // Skip balise
            for (; index < html.length;) {
                const c = html.charAt(index);
                if (c === '>') {
                    break;
                }
                index++;
            }
            continue;
        }
        if (c !== normalizedHtmlToken[tokenIndexes?.length ?? 0]) {
            tokenIndexes = undefined;
            continue;
        }

        if (!tokenIndexes) {
            tokenIndexes = [];
        }
        tokenIndexes.push(index);

        if (tokenIndexes.length < htmlToken.length) {
            continue;
        }

        allReplacements.push(...tokenIndexes);
        tokenIndexes = undefined;
    }

    if (!allReplacements.length) {
        return html;
    }

    let ret = html;
    for (let i = allReplacements.length - 1; i >= 0;) {
        let index = allReplacements[i];
        let prevIt = i;
        for (; prevIt > 0; prevIt--) {
            if (allReplacements[prevIt - 1] === index - 1) {
                index--;
                continue;
            }
            break;
        }

        const before = ret.substring(0, allReplacements[prevIt]);
        const token = ret.substring(allReplacements[prevIt], allReplacements[i] + 1);
        const after = ret.substring(allReplacements[i] + 1);
        ret = `${before}<span class='search-highlight-other-result'>${token}</span>${after}`;

        i = prevIt - 1;
    }

    return ret;
}

export function toPx(px: number | string): number {
    return toNumber(replace(toString(px), 'px', ''));
}

export function preventDefault(event: SyntheticEvent) {
    event.preventDefault();
}

export function computeItemCheckedState<T>(item: T, getItemCheckedState?: ArgGetItemCheckedState<T>): ArgIconCheckboxStates {
    if (getItemCheckedState === undefined) {
        return false;
    }

    if (isString(getItemCheckedState)) {
        const state = get(item as any, getItemCheckedState);

        return state;
    }

    const state = getItemCheckedState(item);

    return state;
}
