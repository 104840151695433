import React from 'react';

import { ArgUploaderEngine } from './arg-uploader-context-provider';

export const ArgUploaderContext = React.createContext<ArgUploaderEngine>({
    add: () => {
    },
    get: () => [],
    clear: () => {
    },
    clearUploaded: () => {
    },
    queue: [],
    uploaded: [],
    current: undefined,
});
