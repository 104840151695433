import React from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgSize } from '../types';
import { DEFAULT_SIZE } from '../defaults';
import { ArgIcon } from '../arg-icon/arg-icon';

import './arg-icon-radio.less';

interface ArgIconRadioProps {
    className?: ClassValue;
    state?: boolean;
    size?: ArgSize | 'node';
}

export function ArgIconRadio(props: ArgIconRadioProps) {
    const {
        state,
        className,
        size = DEFAULT_SIZE,
    } = props;

    const classNames = useClassNames('arg-icon-radio');

    const cls = {
        'state-true': state === true,
        'state-false': state === false,
        [`size-${size}`]: true,
    };

    return (
        <div
            className={classNames('&', className, cls)}
            data-testid={`radio-${state === true ? 'checked' : 'unchecked'}`}
        >
            {state === true && <ArgIcon data-testid='icon-filled-circle' name='icon-filled-circle'/>}
            {state === false && <ArgIcon data-testid='icon-empty-circle' name='icon-empty-circle'/>}
        </div>
    );
}
