import React, { ReactNode, useRef } from 'react';

import { DataCacheRepository } from './data-cache-repository';
import connector from '../../../utils/connector';

export const ImageCacheRepositoryContext = React.createContext<DataCacheRepository<Blob>>(new DataCacheRepository<Blob>('InitialImageCacheRepository', async (): Promise<Blob | null> => {
    return null;
}));

interface ImagesRepositoryProps {
    children: ReactNode;
    serverApi: string;
    ttlMs?: number;
}

export function ImagesRepository(props: ImagesRepositoryProps) {
    const {
        children,
        ttlMs,
        serverApi,
    } = props;

    const imageRepositoryRef = useRef<DataCacheRepository<Blob>>();
    if (!imageRepositoryRef.current) {
        imageRepositoryRef.current = new DataCacheRepository<Blob>('ImageCacheRepository', (key, progressMonitor) => {
            const p = connector.request(key, { api: serverApi }, progressMonitor);

            return p;
        }, ttlMs);
    }

    return <ImageCacheRepositoryContext.Provider value={imageRepositoryRef.current}>
        {children}
    </ImageCacheRepositoryContext.Provider>;
}
