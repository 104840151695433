import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Hooks and utils
import connector from './utils/connector';

// Components
import { Main } from './components/containers/main';
import { ArgRoute } from './components/basic';
import { Logout } from './components/features/logout/logout';
import { Login } from './components/features/login/login';

const renderLogin = () => <Login/>;
const renderLogout = () => <Logout/>;
const ROOT_PATH = '/';
const LOGIN_PATH = '/login';
const LOGOUT_PATH = '/logout';
const OTHER_PATHS_REGEX = /^\/(exploration|preparation|admin|collect|settings|me)/;

export function Routes() {
    // Retrieve the PublicClientApplication object
    useLocation(); // Needed to refresh component on URL change
    const { push } = useHistory();

    const onLogout = useCallback(() => {
        push(LOGOUT_PATH);
    }, [push]);


    const renderMain = useCallback(() => {
        return <Main
            onLogout={onLogout}
            onChangePassword={connector.openChangePasswordWindow}
        />;
    }, [onLogout]);

    return (
        <>
            <ArgRoute exactPath={ROOT_PATH} render={renderLogin}/>
            <ArgRoute exactPath={LOGIN_PATH} replace={ROOT_PATH}/>
            <ArgRoute exactPath={LOGOUT_PATH} render={renderLogout}/>
            <ArgRoute path={OTHER_PATHS_REGEX} render={renderMain}/>
        </>
    );
}
