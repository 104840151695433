import { ArgUserInfo } from '../../components/basic';

export interface ExternalComponent {
    name?: string;
    key: string;
    getDefinitionUrl: string;
    category?: string;
    type?: string;
    description?: string;
    isEnabled?: boolean;
    lastUpdatedBy?: ArgUserInfo;
    lastUpdatedDate?: Date;
}

export interface ExternalComponentDto {
    name?: string;
    key: string;
    remoteComponentURL: string;
    category?: string;
    remoteComponentType: number;
    description?: string;
    isEnabled?: boolean;
    lastUpdatedBy?: ArgUserInfo;
    lastUpdatedDate?: string;
}

export enum ExternalComponentType {
    Connector,
    Module,
    Injector,
    Invalid
}

export type ExternalComponentKey = string;
