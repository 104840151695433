import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgSize } from '../types';
import { ArgBreadcrumbItem } from './arg-breadcrumb-item';

import './arg-breadcrumb.less';

export interface ArgBreadcrumbItem {
    label: MessageDescriptor | ReactNode,
    icon?: ReactNode,
    onClick?: (evt: SyntheticEvent) => void,
    className?: ClassValue,
    name: string,
    path?: string,
}

export interface ArgBreadcrumbProps {
    items: ArgBreadcrumbItem[],
    className?: ClassValue,
    separator?: string | ReactElement,
    separatorColor?: string,
    size?: ArgSize
}

export function ArgBreadcrumb (props: ArgBreadcrumbProps) {
    const {
        items,
        className,
        separator = 'icon-cheveron-right',
        separatorColor = '#39939E',
        size = 'medium',
    } = props;

    const classNames = useClassNames('arg-breadcrumb');

    const cls = {
        [`size-${size}`]: true,
    };

    return (
        <div className={classNames('&', cls, className)}>
            {
                items.map((item: ArgBreadcrumbItem, index: number) => {
                    return (
                        <ArgBreadcrumbItem
                            key={item.name}
                            index={index}
                            separator={separator}
                            separatorColor={separatorColor}
                            className={classNames('&-item')}
                            {...item}
                        />
                    );
                })
            }
        </div>
    );
}
