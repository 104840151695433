import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import { IAceEditorProps } from 'react-ace';
import { Ace } from 'ace-builds';
import { IAceEditor } from 'react-ace/lib/types';
import useResizeObserver from '@react-hook/resize-observer';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgAceEditorInput, ArgAceEditorInputError, ArgAceLanguage } from './arg-input-expression-editor';
import { ArgChangeReason } from '../types';
import { ArgModal } from '../arg-modal/arg-modal';
import { ArgUploaderButton } from '../arg-uploader/arg-uploader-button';
import { ArgButton } from '../arg-button/arg-button';
import { ArgDragAndDropUploader } from '../arg-uploader/arg-drag-and-drop-uploader';
import { ArgToolbarLayout } from '../arg-toolbar/arg-toolbar-layout';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgInputExpressionSnippetsPanel } from './arg-input-expression-snippets-panel';
import { useCallbackAsync } from 'src/components/basic/arg-hooks/use-callback-async';
import { useToolItem } from '../arg-toolbar/use-tool-item';
import { ArgInputExpressionInformationPanel, InformationText } from './arg-input-expression-information-panel';
import { ArgInputExpressionCompleter } from './arg-input-expression-completer';
import { useToolContext } from '../arg-toolbar/use-tool-context';
import { Snippet, SnippetsRepository } from './types';
import { $yield } from '../utils/yield';
import { useNotifications } from '../arg-notifications/arg-notifications';
import { ListPanel } from '../../common/list-panel/list-panel';

import './arg-input-expression-modal.less';

const messages = defineMessages({
    expressionPopupTitle: {
        id: 'basic.arg-input-expression-modal.Title',
        defaultMessage: 'Edit Expression',
    },
    expressionBtnSave: {
        id: 'basic.arg-input-expression-modal.Save',
        defaultMessage: 'Apply',
    },
    expressionBtnCancel: {
        id: 'basic.arg-input-expression-modal.Cancel',
        defaultMessage: 'Cancel',
    },
    expressionBtnTest: {
        id: 'basic.arg-input-expression-modal.Test',
        defaultMessage: 'Test',
    },
    import: {
        id: 'basic.arg-input-expression-modal.Import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'basic.arg-input-expression-modal.Export',
        defaultMessage: 'Export',
    },
    importError: {
        id: 'basic.arg-input-expression-modal.importError',
        defaultMessage: 'The imported file isn\'t compatible',
    },
    codeSnippetsTooltip: {
        id: 'basic.arg-input-expression-modal.CodeSnippetsTooltip',
        defaultMessage: 'Show/hide code snippets',
    },
    expressionShowErrors: {
        id: 'basic.arg-input-expression-modal.showErrors',
        defaultMessage: 'Show syntax errors',
    },
    expressionHideErrors: {
        id: 'basic.arg-input-expression-modal.hideErrors',
        defaultMessage: 'Hide syntax errors',
    },
    errorTitle: {
        id: 'basic.arg-input-expression-modal.errorTitle',
        defaultMessage: 'Syntax errors ({count})',
    },
    errorLine: {
        id: 'basic.arg-input-expression-modal.errorLine',
        defaultMessage: 'Error line {line} :',
    },
    applyQueryApiError: {
        id: 'basic.arg-input-expression-modal.applyQueryApiError',
        defaultMessage: 'An error occurred while applying the expression',
    },
    testQueryApiError: {
        id: 'basic.arg-input-expression-modal.testQueryApiError',
        defaultMessage: 'An error occurred while testing the expression',
    },
    testQuerySuccessTitle: {
        id: 'basic.arg-input-expression-modal.testQuerySuccessTitle',
        defaultMessage: 'Expression tested',
    },
    testQuerySuccessMessage: {
        id: 'basic.arg-input-expression-modal.testQuerySuccessMessage',
        defaultMessage: 'No Errors found',
    },
    saveQuerySuccessTitle: {
        id: 'basic.arg-input-expression-modal.saveQuerySuccessTitle',
        defaultMessage: 'Expression published',
    },
    saveQuerySuccessMessage: {
        id: 'basic.arg-input-expression-modal.saveQuerySuccessMessage',
        defaultMessage: 'The update of expression has successfully been applied',
    },
    dropSnippetMessage: {
        id: 'basic.arg-input-expression-modal.DropSnippetMessage',
        defaultMessage: 'Add code snippet to the editor by dropping it here',
    },
    informationTooltip: {
        id: 'basic.arg-input-expression-modal.InformationTooltip',
        defaultMessage: 'Show/hide information panel',
    },
});

interface ArgExpressionModalProps {
    className?: ClassValue;
    valueEditor: string;
    visible: boolean;
    onSaveExpression: (value: string) => void;
    onCancel?: () => void;
    aceProps?: IAceEditorProps;
    language: string | ArgAceLanguage;
    cursorStart?: Ace.Point;
    snippetsRepository?: SnippetsRepository;
    snippetsLoading?: ProgressMonitor;
    snippetsError?: Error;
    information?: InformationText;
    onImport?: (blob: Blob) => Promise<string>;
    onExport?: (value: string) => void;
    onValidate?: (value: string, progressMonitor: ProgressMonitor) => Promise<ArgAceEditorInputError[] | null | undefined>;
    completers?: ArgInputExpressionCompleter | ArgInputExpressionCompleter[];
}

export function ArgInputExpressionModal(props: ArgExpressionModalProps) {
    const {
        valueEditor,
        visible,
        onSaveExpression,
        onCancel,
        aceProps,
        language,
        cursorStart,
        snippetsRepository,
        snippetsLoading,
        snippetsError,
        information,
        onExport,
        onImport,
        onValidate,
        completers,
    } = props;

    const classNames = useClassNames('arg-input-expression-modal');
    const notifications = useNotifications();

    const [editorValue, setEditorValue] = useState<string>(valueEditor || '');
    const toolbarContext = useToolContext();
    const [rightPanelId, setRightPanelId] = useState<string | undefined>(() => (snippetsRepository ? 'code-snippets' : undefined));
    const [errors, setErrors] = useState<ArgAceEditorInputError[]>([]);
    const [isErrorPanelVisible, setIsErrorPanelVisible] = useState<boolean>(false);
    const [editorHeight, setEditorHeight] = useState<number>();

    const aceEditorRef = useRef<IAceEditor>(null);
    const aceEditorContainerRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        setEditorValue(valueEditor || '');
    }, [valueEditor]);

    const insertSnippetHandler = useCallback((snippet: Snippet) => {
        const editor = aceEditorRef.current!;

        editor.session.replace(editor.getSelection().getRange(), snippet.code);

        $yield(() => {
            editor.focus();
        });
    }, []);

    const codeSnippetsRender = useCallback(() => {
        return <ArgInputExpressionSnippetsPanel
            snippetsRepository={snippetsRepository!}
            progressMonitor={snippetsLoading}
            error={snippetsError}
            language={language}
            onInsertSnippet={insertSnippetHandler}
        />;
    }, [snippetsRepository, snippetsLoading, snippetsError, language, insertSnippetHandler]);

    const informationRender = useCallback(() => (
        <ArgInputExpressionInformationPanel
            information={information!}
        />
    ), [information]);

    const handleSetRightPanelId = useCallback((panelId: string) => {
        setRightPanelId((prev) => {
            if (prev === panelId) {
                return undefined;
            }

            return panelId;
        });
    }, []);

    const codeSnippetsClick = useCallback(() => {
        handleSetRightPanelId('code-snippets');
    }, [handleSetRightPanelId]);

    useResizeObserver(aceEditorContainerRef.current, (entry: ResizeObserverEntry) => {
        setEditorHeight(entry.target.clientHeight);
    });

    const informationClick = useCallback(() => {
        handleSetRightPanelId('information');
    }, [handleSetRightPanelId]);

    // Code Snippets Panel
    useToolItem(
        toolbarContext,
        {
            path: 'right/snippets',
            type: 'panel',
            icon: 'icon-code-braces',
            tooltip: messages.codeSnippetsTooltip,
        },
        {
            onClick: codeSnippetsClick,
            panelRender: codeSnippetsRender,
            visible: !!snippetsRepository || snippetsLoading?.isRunning,
            selected: rightPanelId === 'code-snippets',
        }
    );

    // Information Panel
    useToolItem(
        toolbarContext,
        {
            path: 'right/information',
            type: 'panel',
            icon: 'icon-information',
            tooltip: messages.informationTooltip,
            visible: !!information,
        },
        {
            onClick: informationClick,
            panelRender: informationRender,
            selected: rightPanelId === 'information',
        }
    );

    const handleEditorChange = useCallback((value: string | null, _: ArgChangeReason) => {
        setEditorValue(value || '');
        setErrors([]);
    }, [setEditorValue]);

    const handleReset = useCallback(() => {
        setRightPanelId(snippetsRepository ? 'code-snippets' : undefined);
        setErrors([]);
        setIsErrorPanelVisible(false);
    }, [snippetsRepository]);

    const onTestQuery = useCallback(async (editorValue: string, progressMonitor: ProgressMonitor) => {
        if (!onValidate) {
            return;
        }

        const validationErrors = await onValidate(editorValue, progressMonitor);

        if (validationErrors) {
            setErrors(validationErrors);
            setIsErrorPanelVisible(true);
        } else {
            setErrors([]);
            setIsErrorPanelVisible(false);
        }

        return validationErrors;
    }, [onValidate]);

    const [handleTestQuery, testQueryPM] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const validationErrors = await onTestQuery(editorValue, progressMonitor);
            if (validationErrors) {
                return;
            }

            notifications.success({
                message: messages.testQuerySuccessTitle,
                description: messages.testQuerySuccessMessage,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.error({ message: messages.testQueryApiError }, error as Error);
        }
    }, [onTestQuery, editorValue, notifications]);

    const [handleSaveExpression, saveExpressionProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const validationErrors = await onTestQuery(editorValue, progressMonitor);
            if (validationErrors) {
                return;
            }

            notifications.success({
                message: messages.saveQuerySuccessTitle,
                description: messages.saveQuerySuccessMessage,
            });
            onSaveExpression(editorValue);
            handleReset();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.error({ message: messages.applyQueryApiError }, error as Error);
        }
    }, [onTestQuery, editorValue, notifications, onSaveExpression, handleReset]);

    const handleCancel = useCallback(() => {
        setEditorValue(valueEditor);
        handleReset();
        onCancel && onCancel();
    }, [onCancel, valueEditor, handleReset]);

    const handleImportExpression = useCallback(async (blob: Blob, progressMonitor: ProgressMonitor) => {
        if (!onImport) {
            return;
        }
        try {
            const expression = await onImport(blob);

            progressMonitor.verifyCancelled();

            setEditorValue(expression);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.error({ message: messages.importError }, error as Error);
        }
    }, [notifications, onImport]);

    const handleExportExpression = useCallback(() => {
        onExport?.(editorValue);
    }, [editorValue, onExport]);

    const handlePanelVisibility = useCallback(() => {
        setIsErrorPanelVisible((visible) => !visible);
    }, []);

    const renderImportButton = useCallback(() => {
        return (
            <ArgUploaderButton
                type='ghost'
                size='medium'
                icon='icon-download'
                className={classNames('&-header-import-button')}
                label={messages.import}
                acceptedFiles='.json'
                method={handleImportExpression}
            />
        );
    }, [handleImportExpression, classNames]);

    const renderExportButton = useCallback(() => {
        return (
            <ArgButton
                icon='icon-upload'
                className={classNames('&-header-export-button')}
                label={messages.export}
                type='ghost'
                onClick={handleExportExpression}
                disabled={!editorValue}
            />
        );
    }, [handleExportExpression, editorValue, classNames]);

    useToolItem(toolbarContext, {
        path: 'left/import',
        type: 'custom',
    }, {
        customRender: onImport && renderImportButton,
        visible: !!onImport,
    });

    useToolItem(toolbarContext, {
        path: 'left/export',
        type: 'custom',
    }, {
        customRender: onExport && renderExportButton,
        visible: !!onExport,
    });

    const handleFocusLine = useCallback((lineNumber: number, columnNumber?: number) => {
        const editor = aceEditorRef.current;
        const selection = editor?.session.selection;

        selection?.clearSelection();
        selection?.moveCursorTo(lineNumber, columnNumber || 0);

        editor?.focus();
    }, []);

    const isOkButtonDisabled = !!errors.length || editorValue.trim().length === 0;

    //TODO: all props should be accessible outside and can be <> than the input
    return (
        <ArgModal
            size='xlarge'
            visible={visible}
            maskClosable={false}
            centered={true}
            onClose={handleCancel}
            className={classNames('&')}
            title={messages.expressionPopupTitle}
            footer={
                <div className={classNames('&-footer')}>
                    <ArgButton
                        className={classNames('&-footer-btn')}
                        type='secondary'
                        onClick={handleCancel}
                        label={messages.expressionBtnCancel}
                    />
                    {onValidate && <ArgButton
                        className={classNames('&-footer-btn')}
                        type='secondary'
                        onClick={handleTestQuery}
                        loading={testQueryPM?.isRunning}
                        label={messages.expressionBtnTest}
                        disabled={isOkButtonDisabled}
                    />}
                    <ArgButton
                        className={classNames('&-footer-btn')}
                        type='primary'
                        htmlType='submit'
                        onClick={handleSaveExpression}
                        label={messages.expressionBtnSave}
                        loading={saveExpressionProgressMonitor?.isRunning}
                        disabled={isOkButtonDisabled}
                    />
                </div>
            }
        >
            <ArgDragAndDropUploader
                method={handleImportExpression}
                disabled={!onImport}
                className={classNames('&-dnd-uploader')}
            >
                <ArgToolbarLayout
                    className={classNames('&-body')}
                    toolbarContext={toolbarContext}
                >
                    <ArgAceEditorInput
                        cursorStart={cursorStart}
                        className={classNames('&-editor', 'fullsize')}
                        focus={true}
                        value={editorValue}
                        onChange={handleEditorChange}
                        language={language}
                        aceProps={aceProps}
                        inputRef={aceEditorRef}
                        errors={errors}
                        height={editorHeight}
                        completers={completers}
                    />
                    {isErrorPanelVisible && errors.length > 0 && (
                        <ListPanel
                            type='error'
                            title={messages.errorTitle}
                            items={errors}
                            messageValues={{ count: errors.length }}
                            onClose={handlePanelVisibility}
                            getItemLineNumber={(error) => {
                                return error.line || 0;
                            }}
                            getItemColumnNumber={(error) => {
                                return error.column || 0;
                            }}
                            getItemMessage={(error) => {
                                return error.message;
                            }}
                            className={classNames('&-errors-container')}
                            onFocusLine={handleFocusLine}
                        />
                    )}

                    {errors?.length > 0 && !isErrorPanelVisible && <ArgButton
                        icon='icon-embed2'
                        className={classNames('&-editor-error-button')}
                        label={isErrorPanelVisible ? messages.expressionHideErrors : messages.expressionShowErrors}
                        type='ghost'
                        onClick={handlePanelVisibility}
                    />}
                </ArgToolbarLayout>
            </ArgDragAndDropUploader>
        </ArgModal>
    );
}
