import Debug from 'debug';
import { union } from 'lodash';

import type { PublicUser, User, UserPermissionId, UserPermissions } from '../../model/user';
import type { Configuration } from '../../model/configurations';
import { Collaborator, CollaboratorRole, CollaboratorType } from 'src/model/collaborator';
import { Environment } from '../environment';
import { ArgUserId, ProgressMonitor } from '../../components/basic';
import { ConnectorRequestInit } from '../connector';
import { BaseConnector } from './base-connector';
import { mapDate } from './mappers';
import { mapUserProfileField } from 'src/settings/connectors/mappers';
import { UserProfileField } from '../../model/user-metadata';
import { getAdministrationApi, getDataExplorationApi, getDataPreparationApi } from './api-url';

const debug = Debug('common:utils:Connector');

const isDataExplorationSupport = getDataExplorationApi() && process.env.REACT_APP_EXPLORATION_SUPPORT !== 'false';
const isDataPreparationSupport = getDataPreparationApi() && process.env.REACT_APP_PREPARATION_SUPPORT !== 'false';

export type PermissionsScope = 'data_exploration' | 'data_preparation';

export class UsersConnector extends BaseConnector {
    private static instance: UsersConnector;

    static getInstance(): UsersConnector {
        if (!UsersConnector.instance) {
            UsersConnector.instance = new UsersConnector('administration.users', getAdministrationApi());
        }

        return UsersConnector.instance;
    }

    static getPermissionScopeApi(permissionScope: PermissionsScope | undefined): string {
        let api: string | undefined = undefined;

        if (permissionScope === 'data_exploration') {
            api = getDataExplorationApi();
        } else if (permissionScope === 'data_preparation') {
            api = getDataPreparationApi();
        }

        if (api) {
            return api;
        }

        throw new Error(`API URL for permission scope for ${permissionScope} is undefined`);
    }

    async myUserDetails(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<User> {
        const result: User = await this.request('/users/me', {
            verifyJSONResponse: true,
        }, progressMonitor);

        debug('myUserDetails', 'result=', result);

        const ret = mapMeToUser(result);

        return ret;
    }

    async myUserPermissions(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UserPermissions> {
        const result = await this.request('/users/me/permissions', {
            verifyJSONResponse: true,
        }, progressMonitor);

        debug('myUserPermissions', 'result=', result);

        const ret = mapUserPermission(result);

        return ret;
    }

    async getMyUserPermissions(progressMonitor: ProgressMonitor = ProgressMonitor.empty(), scope?: PermissionsScope): Promise<string[]> {
        const api = UsersConnector.getPermissionScopeApi(scope);
        const result = await this.request('/users/me/permissions', {
            verifyJSONResponse: true,
            api,
        }, progressMonitor);

        debug('myUserPermissions', 'result=', result);

        return result;
    }

    async getAllMyUserPermissions(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<UserPermissions> {
        const apiCalls = [];
        if (isDataExplorationSupport) {
            apiCalls.push(this.getMyUserPermissions(progressMonitor, 'data_exploration'));
        }
        if (isDataPreparationSupport) {
            apiCalls.push(this.getMyUserPermissions(progressMonitor, 'data_preparation'));
        }

        const response = await Promise.all(apiCalls);

        const ret = mapUserPermission(union(...response));

        return ret;
    }

    async getAppSettings(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Configuration> {
        const appId = Environment.appId;

        const result = await this.request(`/application/settings/me/${encodeURIComponent(appId)}`, {}, progressMonitor);

        debug('getAppSettings', 'result=', result);

        return result;
    }

    async updateAppSettings(
        settings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const appId = Environment.appId;

        await this.request(
            `/application/settings/me/${encodeURIComponent(appId)}`,
            {
                json: settings,
                method: 'PUT',
            },
            progressMonitor
        );
    }

    async getPublicUsers(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<PublicUser[]> {
        const url = '/users/public';

        const options: ConnectorRequestInit = {
            params: {
                q: search,
                deleted,
            },
            verifyJSONResponse: true,
        };

        const userResults = await this.request(url, options, progressMonitor);

        const ret = userResults.users?.map(mapUser) || [];

        return ret;
    }

    async getCollaborators(
        search?: string,
        deleted?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<Collaborator[]> {
        const users = await this.getPublicUsers(search, deleted, progressMonitor); //Temporary while groups are not handled by API

        return users.map(user => {
            const collaborator: Collaborator = {
                identityId: user.id,
                name: user.displayName || '',
                type: CollaboratorType.User,
                user: user,
                role: CollaboratorRole.Reader,
            };

            return collaborator;
        });
    }

    async getPublicUser(
        userId: ArgUserId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<PublicUser> {
        const url = `/users/public/${encodeURIComponent(userId)}`;

        const options: ConnectorRequestInit = {
            verifyJSONResponse: true,
        };

        const userResult: any = await this.request(url, options, progressMonitor);

        const ret = mapUser(userResult);

        return ret;
    }

    async getUserProfileFields(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UserProfileField[]> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        const ret = (result?.fields || []).map((raw: any) => {
            const ret = mapUserProfileField(raw);

            return ret;
        });

        return ret;
    }
}

export default UsersConnector.getInstance();

export function mapUser(userInfo: any): User {
    const user: User = {
        ...userInfo,
        displayName: userInfo.displayName || userInfo.fullName || userInfo.userName,
        createdDate: mapDate(userInfo.createdDate),
        lastUpdatedDate: mapDate(userInfo.lastUpdatedDate),
    };

    return user;
}

export function mapMeToUser(basicUser: User): User {
    const user = mapUser(basicUser);

    return { ...user };
}

export function mapUserPermission(permissions: UserPermissionId[] | null): UserPermissions {
    const ret: Record<string, true> = {};

    permissions?.forEach((permission) => {
        ret[permission] = true;
    });

    return ret;
}
