import React from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgRenderedText, ArgMessageValues } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-alert-card.less';

export type ArgAlertType = 'info' | 'success' | 'warning' | 'error'

interface ArgAlertCardProps {
    className?: ClassValue;
    type: ArgAlertType;
    title: ArgRenderedText;
    description?: ArgRenderedText;
    messageValues?: ArgMessageValues;
}

const ICONS_NAME: Record<ArgAlertType, string> = {
    info: 'icon-notification',
    success: 'icon-checkmark',
    warning: 'icon-exclamation-point',
    error: 'icon-close',
};

export function ArgAlertCard(props: ArgAlertCardProps) {
    const {
        type,
        title,
        description,
        messageValues,
        className,
    } = props;

    const classNames = useClassNames('arg-alert-card');

    const iconName = ICONS_NAME[type];

    const cls = {
        info: type === 'info',
        success: type === 'success',
        warning: type === 'warning',
        error: type === 'error',
    };

    return (
        <div className={classNames('&', cls, className)}>
            <ArgIcon name={iconName} className={classNames('&-icon')}/>

            <div className={classNames('&-body')}>
                <span className={classNames('&-body-title')}>
                    {renderText(title, messageValues)}
                </span>

                <span className={classNames('&-body-description')}>
                    {renderText(description, messageValues)}
                </span>
            </div>
        </div>
    );
}

