import React, { ReactNode } from 'react';
import { isFunction, isNil, isNumber, isString } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { getDataTestIdFromProps } from '../utils';
import { ArgBadges } from '../arg-badge/arg-badge';
import { ArgRenderedIcon, ArgRenderFunction } from '../types';
import { isPromise, PromiseComponent } from '../utils/promise';

import './arg-icon.less';

export interface ArgIconProps {
    badge?: number | boolean;
    badges?: ArgBadges;
    name: string;
    color?: string;
    size?: string | number;
    style?: React.CSSProperties;
    className?: ClassValue;
}

export function ArgIcon(props: ArgIconProps) {
    const {
        className,
        name,
        size,
        badge,
        color,
        style,
        badges,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-icon');

    const cls = {
        'has-badge': isNumber(badge) ? badge > 0 : badge,
    };

    const iconStyle: Record<string, string> = {};
    if (color !== undefined) {
        iconStyle['--arg-icon-color'] = color || 'inherit';
    }
    if (size !== undefined) {
        iconStyle['--arg-icon-font-size'] = !isNil(size) ? isNumber(size) ? `${size}px` : size : 'inherit';
    }

    const renderProps: Record<string, any> = {
        style: {
            ...style,
            ...iconStyle,
        },
        'data-color': color,
        'data-testid': dataTestId,
        className: classNames(name, '&', className, cls),
    };

    if (isNumber(badge)) {
        renderProps['data-count'] = badge;
    }

    return (
        <i {...renderProps}>
            {badges}
        </i>
    );
}


export function renderIcon(icon: ArgRenderedIcon, iconClassName?: ClassValue, iconColor?: string): ReactNode {
    if (isFunction(icon)) {
        if (isPromise(icon)) {
            const ret = <PromiseComponent promise={icon} renderType='icon' iconClass={iconClassName} />;

            return ret;
        }

        const ret = (icon as ArgRenderFunction)();

        return ret;
    }


    if (isString(icon)) {
        const ret = <ArgIcon name={icon} color={iconColor} className={iconClassName} />;

        return ret;
    }

    return icon;
}
