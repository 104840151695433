import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { isFunction } from 'lodash';
import { Menu, Popover } from 'antd';
import { defineMessages, FormattedMessage } from 'react-intl';
import { MenuInfo } from 'rc-menu/lib/interface';

import { ARG_TABLE_4_DEFAULT_SORTABLE, ArgTable4Column } from './arg-table4';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { createBuiltinPlacement } from '../defaults';
import { ArgMenu } from '../arg-menu/arg-menu';
import { DataSorter, PropertySorter } from '../arg-providers/data-provider';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { findOrCreatePopupArea } from '../utils';
import { ArgTable4SelectionManager } from './arg-table4-selection-manager';
import { ArgTable4Cursor } from './types';
import { isArgTable4CellIndex } from './utils';
import { useStateId } from '../utils/use-stateId';

import './virtual-column-header.less';

const builtinPlacements = createBuiltinPlacement(0, 0, [0, 4]);

const messages = defineMessages({
    sortAscending: {
        id: 'basic.arg-table4-virtual-columns-container.sort-ascending.Label',
        defaultMessage: 'Sort ascending',
    },
    sortDescending: {
        id: 'basic.arg-table4-virtual-columns-container.sort-descending.Label',
        defaultMessage: 'Sort descending',
    },
    noSort: {
        id: 'basic.arg-table4-virtual-columns-container.no-sort.Label',
        defaultMessage: 'Remove sort',
    },
    hideColumn: {
        id: 'basic.arg-table4-virtual-columns-container.hide-column.Label',
        defaultMessage: 'Hide this column',
    },
    lockColumn: {
        id: 'basic.arg-table4-virtual-columns-container.lock-column.Label',
        defaultMessage: 'Lock this column',
    },
    unlockColumn: {
        id: 'basic.arg-table4-virtual-columns-container.unlock-column.Label',
        defaultMessage: 'Unlock this column',
    },
});

interface VirtualColumnHeaderProps<T> {
    column: ArgTable4Column<T>;
    locked?: boolean,
    sort?: DataSorter;
    left: number;
    columnWidth: number;
    className?: ClassValue;
    onColumnSort: (column: ArgTable4Column<T>, order: 'ascending' | 'descending' | undefined, replace: boolean) => void;
    onColumnLock: (column: ArgTable4Column<T>, locked: boolean) => void;
    onColumnVisible: (column: ArgTable4Column<T>, lock: boolean) => void;
    canLockColumn?: boolean;
    dragTransform?: string;
    dragged?: boolean;
    disabled?: boolean;
    columnIndex: number;
    selectionManager?: ArgTable4SelectionManager;
    cursor?: ArgTable4Cursor;
    onCursorChange?: (cursor: ArgTable4Cursor) => void;
    hovered?: boolean;
}

export function VirtualColumnHeader<T>({
    column,
    locked,
    sort,
    left,
    className,
    onColumnSort,
    onColumnLock,
    onColumnVisible,
    canLockColumn,
    dragTransform,
    dragged,
    columnWidth,
    disabled,
    columnIndex,
    selectionManager,
    cursor,
    onCursorChange,
    hovered,
}: VirtualColumnHeaderProps<T>) {
    const classNames = useClassNames('arg-table4-virtual-column-header');
    useStateId(selectionManager);

    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [actionsMenuVisible, setActionsMenuVisible] = useState<boolean>(false);
    const [ctrlKeyPressed, setCtrlKeyPressed] = useState<boolean>(false);

    const handlePopupVisibleChange = useCallback((visible: boolean) => {
        setPopupVisible(visible);
    }, []);

    const handleHideContextMenu = useCallback(() => {
        setPopupVisible(false);
        setActionsMenuVisible(false);
    }, []);

    const handleActionMenuVisibleChange = useCallback((visible: boolean) => {
        setActionsMenuVisible(visible);
    }, []);

    const propertySorter: PropertySorter | undefined = sort?.propertySorters.find((p) => p.propertyName === (column.columnSortName || column.key));

    const handleColumnSort = useCallback((order: 'ascending' | 'descending' | undefined, e: MenuInfo) => {
        onColumnSort(column, order, true);
        setPopupVisible(false);
    }, [column, onColumnSort]);

    const handleSort = useCallback((event: ButtonClickEvent, order: 'ascending' | 'descending') => {
        event.preventDefault();
        event.stopPropagation();
        const replace = !event.ctrlKey && !event.metaKey;
        onColumnSort(column, order, replace);
    }, [column, onColumnSort]);

    const handleAscendingSort = useCallback((event: ButtonClickEvent) => {
        handleSort(event, 'ascending');
    }, [handleSort]);

    const handleDescendingSort = useCallback((event: ButtonClickEvent) => {
        handleSort(event, 'descending');
    }, [handleSort]);

    const handleColumnLock = useCallback(() => {
        onColumnLock(column, !locked);
        setPopupVisible(false);
    }, [column, onColumnLock, locked]);

    const handleColumnVisible = useCallback(() => {
        onColumnVisible(column, false);
        setPopupVisible(false);
    }, [column, onColumnVisible]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey || event.metaKey) {
                setCtrlKeyPressed(true);
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            if (!event.ctrlKey && !event.metaKey) {
                setCtrlKeyPressed(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleHeaderContextMenu = useCallback(
        (column: ArgTable4Column<T>, locked: boolean): ReactNode => {
            if (!popupVisible) {
                return null;
            }

            if (column.renderHeaderContextMenu) {
                const menu = column.renderHeaderContextMenu(column, locked, handleHideContextMenu);

                return menu;
            }

            return (
                <ArgMenu className={classNames('&-menu')}>
                    {(column.sortable ?? ARG_TABLE_4_DEFAULT_SORTABLE) && (
                        <Menu.Item
                            key='_ascending-sort_'
                            className={classNames({ selected: propertySorter?.order === 'ascending' })}
                            icon={<ArgIcon name='icon-arrow-up' className={classNames('&-menu-item-icon')}/>}
                            onClick={(e) => handleColumnSort('ascending', e)}
                        >
                            <span>
                                <FormattedMessage {...messages.sortAscending} />
                            </span>
                        </Menu.Item>
                    )}
                    {(column.sortable ?? ARG_TABLE_4_DEFAULT_SORTABLE) && (
                        <Menu.Item
                            key='_descending-sort_'
                            className={classNames({ selected: propertySorter?.order === 'descending' })}
                            icon={<ArgIcon name='icon-arrow-down' className={classNames('&-menu-item-icon')}/>}
                            onClick={(e) => handleColumnSort('descending', e)}
                        >
                            <span>
                                <FormattedMessage {...messages.sortDescending} />
                            </span>
                        </Menu.Item>
                    )}
                    {(column.sortable ?? ARG_TABLE_4_DEFAULT_SORTABLE) && (
                        <Menu.Item
                            key='_remove-sort_'
                            className={classNames({ disabled: !propertySorter?.order })}
                            icon={<ArgIcon name='icon-updown' className={classNames('&-menu-item-icon')}/>}
                            onClick={(e) => handleColumnSort(undefined, e)}
                        >
                            <span>
                                <FormattedMessage {...messages.noSort} />
                            </span>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key='_hide-column_'
                        icon={<ArgIcon name='icon-eye-crossed' className={classNames('&-menu-item-icon')}/>}
                        onClick={handleColumnVisible}
                        hidden={true}
                    >
                        <span>
                            <FormattedMessage {...messages.hideColumn} />
                        </span>
                    </Menu.Item>
                    {canLockColumn && (
                        <Menu.Item
                            key='_lock-column_'
                            icon={<ArgIcon name='icon-padlock' className={classNames('&-menu-item-icon')}/>}
                            onClick={handleColumnLock}
                        >
                            <span>
                                <FormattedMessage {...(locked ? messages.unlockColumn : messages.lockColumn)} />
                            </span>
                        </Menu.Item>
                    )}
                </ArgMenu>
            );
        },
        [handleColumnSort, handleColumnLock, handleColumnVisible, propertySorter, canLockColumn, classNames, popupVisible]);

    const containerRef = useRef<HTMLDivElement>(null);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (containerRef.current) {
            return findOrCreatePopupArea(containerRef.current) || containerRef.current.ownerDocument.body;
        }

        return document.body;
    }, []);

    const onHeaderClick = useCallback((e: React.MouseEvent) => {
        // Prevent click in the actions menu to interfer with column selection
        if ((e.target as Element).closest?.('.arg-button-popover-overlay') ||
            (e.target as Element).closest?.('.ant-tooltip')
        ) {
            e.preventDefault();

            return;
        }

        const target = e.target as HTMLElement;
        const headerResizer = target.getAttribute('data-columnresizer');
        if (dragged || !selectionManager || headerResizer || column.selectable === false) {
            return;
        }
        if (e.shiftKey && !isArgTable4CellIndex(cursor) && cursor?.columnIndex !== undefined) {
            if (!selectionManager.isColumnSelected(cursor.columnIndex)) {
                selectionManager.removeColumnRange(cursor.columnIndex, columnIndex);
            } else {
                selectionManager.addColumnRange(cursor.columnIndex, columnIndex);
            }

            return;
        }
        onCursorChange?.({ columnIndex, columnKey: column.key });
        if (!e.ctrlKey && !e.metaKey) {
            if (selectionManager.isColumnSelected(columnIndex)) {
                selectionManager.removeColumn(columnIndex);
            } else {
                selectionManager.addColumn(columnIndex, true);
            }

            return;
        }
        if (selectionManager.isColumnSelected(columnIndex)) {
            selectionManager.removeColumn(columnIndex);
        } else {
            selectionManager.addColumn(columnIndex);
        }
    }, [dragged, selectionManager, cursor, onCursorChange, columnIndex, column.key]);


    let lockIcon;
    if (locked && !column.rowHeader) {
        lockIcon = <ArgIcon name='icon-padlock' className={classNames('&-lock-icon')}/>;
    }

    let sortIconName;
    if ((column.sortable ?? ARG_TABLE_4_DEFAULT_SORTABLE) && !column.rowHeader) {
        sortIconName = (!disabled) ? 'icon-updown' : undefined;

        switch (propertySorter?.order) {
            case 'ascending':
                sortIconName = 'icon-arrow-up';
                break;
            case 'descending':
                sortIconName = 'icon-arrow-down';
                break;
        }
    }

    const titleDisabled = !(column.sortable ?? ARG_TABLE_4_DEFAULT_SORTABLE);
    const titleCls = { disabled: titleDisabled || disabled };
    const sortIcon = sortIconName ? (
        <div className={classNames('&-sort')}>
            <ArgButton
                disabled={titleDisabled}
                onClick={handleAscendingSort}
                className={classNames('&-sort-icon', { '&-sort-icon-selected': propertySorter?.order === 'ascending' })}
                type='ghost'
                icon='icon-triangle-up'
                size='small'
            />
            <ArgButton
                disabled={titleDisabled}
                onClick={handleDescendingSort}
                className={classNames('&-sort-icon', { '&-sort-icon-selected': propertySorter?.order === 'descending' })}
                type='ghost'
                icon='icon-triangle-down'
                size='small'
            />
            <div className={classNames('&-sort-append', { '&-sort-append-visible': ctrlKeyPressed })}>
                <ArgIcon
                    className={classNames('&-sort-append-icon')}
                    name='icon-triangle-up'
                    size='small'
                />
                <ArgIcon
                    className={classNames('&-sort-append-icon')}
                    name='icon-triangle-down'
                    size='small'
                />
            </div>
        </div>
    ) : undefined;

    const actionsMenu = (
        actionsMenuVisible && column.renderHeaderContextMenu && column.renderHeaderContextMenu(column, !!locked, handleHideContextMenu)
    );

    const handleActionMenuButtonClick = useCallback((e: ButtonClickEvent) => {
        e.stopPropagation();
        setActionsMenuVisible((visible) => !visible);
    }, []);

    const actionsButton = column.renderHeaderContextMenu ? <ArgButton
        size='medium'
        type='ghost'
        icon='icon-options'
        popover={actionsMenu}
        popoverTrigger='click'
        popoverVisible={actionsMenuVisible}
        data-testid='actions-button'
        popoverPlacement='bottomLeft'
        popoverArrowPointAtCenter={true}
        onPopoverVisibleChange={handleActionMenuVisibleChange}
        className={classNames('&-action-button', className)}
        popoverClassName={classNames('&-popover')}
        onClick={handleActionMenuButtonClick}
    /> : null;

    let titleComponent = column.title || column.columnName;

    // Render with title as function
    if (isFunction(titleComponent)) {
        titleComponent =
            <div
                key='title'
                className={classNames('&-title', titleCls)}>
                {titleComponent(column, propertySorter, lockIcon, sortIcon)}
            </div>;
    } else {
        let columnTitle = titleComponent;
        if (isMessageDescriptor(columnTitle)) {
            columnTitle = <FormattedMessage {...columnTitle} />;
        }

        titleComponent = <div key='title'
                              className={classNames('&-title', titleCls)}
        >
            <span className={classNames('&-title-text')} style={column.headerStyle}>{columnTitle}</span>
            {lockIcon}
            {sortIcon}
            <div className={classNames('&-hspacer')}/>
            {actionsButton}
        </div>;
    }

    let title;
    if (column.disableContextMenu) {
        title = <div className={classNames('&-column-header')}>
            {titleComponent}
            {column.resizable && <div key='resize'
                                      data-columnresizer={column.key}
                                      className={classNames('&-resizer')}/>
            }
        </div>;
    } else {
        title = <Popover content={() => handleHeaderContextMenu(column, !!locked)}
                         placement='bottomLeft'
                         overlayClassName={classNames('&-popover-overlay', 'arg-popover-overlay')}
                         className={classNames('&-popup-container')}
                         builtinPlacements={builtinPlacements}
                         visible={popupVisible}
                         onVisibleChange={handlePopupVisibleChange}
                         trigger='contextMenu'
                         getPopupContainer={computePopoverContainer}
        >
            {titleComponent}
            {column.resizable && <div key='resize'
                                      data-columnresizer={column.key}
                                      className={classNames('&-resizer')}/>
            }
        </Popover>;
    }

    const cls = {
        'popup-opened': popupVisible,
        sorted: !!propertySorter,
        dragged,
        'drag-transform': dragTransform,
        movable: column.movable !== false,
        disabled,
        '&-hovered': hovered,
    };

    const additionalHeader: ReactNode = isFunction(column.additionalHeader) ? column.additionalHeader(column) : column.additionalHeader;

    const ret = (
        <div
            className={classNames('&', className, cls)}
            data-header={column.key}
            ref={containerRef}
            key={column.key}
            onClick={onHeaderClick}
            style={{
                left: `${left}px`,
                width: `${columnWidth}px`,
                transform: dragTransform,
            }}>
            {title}
            <div className={classNames('&-column-additional-header')}>
                {additionalHeader}
            </div>
        </div>);

    return ret;
}
