import React, { DragEvent, useCallback, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Debug from 'debug';

import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgTooltip } from '../arg-tooltip/arg-tooltip';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { Snippet, SNIPPET_DND_TYPE } from './types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgMessageValues } from '../types';
import { Draggable, DraggableAction } from '../arg-dnd/draggable';
import { setDragCursor } from '../arg-dnd/utils';

import './arg-input-expression-snippet.less';

const debug = Debug('basic:arg-input:ArgInputExpressionSnippet');

const messages = defineMessages({
    viewSnippetTooltip: {
        id: 'basic.arg-input-expression-snippets-list.viewSnippetTooltip',
        defaultMessage: 'View code snippet',
    },
});

interface ArgInputExpressionSnippetProps {
    className?: ClassValue;
    snippet: Snippet;
    search?: string;
    onViewSnippet?: () => void;
    onDoubleCLick?: () => void;
    messageValues?: ArgMessageValues;
}

export function ArgInputExpressionSnippet(props: ArgInputExpressionSnippetProps) {
    const {
        className,
        snippet,
        search,
        onViewSnippet,
        messageValues,
        onDoubleCLick,
    } = props;

    const classNames = useClassNames('arg-input-expression-snippet');
    const intl = useIntl();

    const dragCleanUpRef = useRef<(() => void) | undefined>(undefined);

    const handleViewSnippet = useCallback((event: ButtonClickEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        onViewSnippet!();
    }, [onViewSnippet]);

    const action = useMemo<DraggableAction>(() => {
        const action: DraggableAction = {
            onDragStart(event: DragEvent): void {
                event.dataTransfer.effectAllowed = 'copy';

                const params = JSON.stringify(snippet);
                const dndType = SNIPPET_DND_TYPE;
                event.dataTransfer.setData(dndType, params);
                localStorage.setItem(dndType, params);

                event.dataTransfer.setData('text/plain', snippet.code);
                debug('onDragStart', 'Code=', snippet.code);

                dragCleanUpRef.current?.();

                dragCleanUpRef.current = setDragCursor(event.dataTransfer, intl, renderText(snippet.title, messageValues));
            },
            onDragEnd(): void {
                localStorage.removeItem(SNIPPET_DND_TYPE);

                dragCleanUpRef.current?.();
            },
        };

        return action;
    }, [classNames, intl, messageValues, snippet]);

    return (
        <Draggable actions={action}>
            {(provided) => {
                const cls = {
                    dragging: provided.dragging,
                    draggable: provided.draggable,
                };

                return <div
                    className={classNames('&', className, cls)}
                    {...provided.dragHandleProps}
                    onDoubleClick={onDoubleCLick}
                >
                    <div className={classNames('&-left-container')}>
                        <ArgIcon name='icon-6dots' size='large' className={classNames('&-drag-drop-icon')}/>
                        <ArgTooltip
                            className={classNames('&-container', className)}
                            placement='right'
                            title={snippet.title}
                        >
                            <div className={classNames('&-title', 'clamp-2')}>
                                {
                                    renderText(snippet.title, messageValues, search)
                                }
                            </div>
                        </ArgTooltip>
                    </div>
                    {onViewSnippet && <ArgButton
                        icon='icon-view-show'
                        type='ghost'
                        size='large'
                        className={classNames('&-view-icon')}
                        tooltip={messages.viewSnippetTooltip}
                        onClick={handleViewSnippet}
                    />}
                </div>;
            }}
        </Draggable>
    );
}
