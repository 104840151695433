import React, { Fragment, ReactNode } from 'react';
import { isFunction, map, omit } from 'lodash';

import { useToolNodes } from './use-tool-nodes';
import { ArgToolbarItem } from './arg-toolbar-item';
import { ArgToolbarCombo } from './arg-toolbar-combo';
import { ArgToolbarDivider } from './arg-toolbar-divider';
import { ToolContext, ToolTreeNode } from './tool-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { countVisibleChildren } from './utils';

import './arg-toolbar.less';

export interface ArgToolbarProps {
    prefix?: string;
    className?: ClassValue;
    toolbarContext: ToolContext;
    disabled?: boolean;
}

export function ArgToolbar(props: ArgToolbarProps) {
    const {
        prefix,
        className,
        toolbarContext,
        disabled,
    } = props;

    const [toolbarNodes] = useToolNodes(toolbarContext, prefix);
    const classNames = useClassNames('arg-toolbar');

    let shouldRenderDivider = false;
    const renderNodes = (nodes: ToolTreeNode[]): ReactNode => {
        return map(nodes, (node: ToolTreeNode) => {
            if (node.type === 'group') {
                const visibleChildren = countVisibleChildren(node);
                if (!visibleChildren?.length) {
                    return;
                }

                const addDivider = visibleChildren?.length > 0 && shouldRenderDivider;
                shouldRenderDivider = true;

                return (
                    <Fragment key={node.path}>
                        {addDivider && (
                            <ArgToolbarDivider className={classNames('&-divider')} key='divider'/>
                        )}

                        {renderNodes(visibleChildren)}
                    </Fragment>
                );
            } else if (node.type === 'panel' && !node.onClick) {
                node.onClick = () => toolbarContext.togglePanel(node, prefix);
            } else if (node.type === 'combo') {
                const _tooltip = isFunction(node.tooltip) ? node.tooltip(node) : node.tooltip;

                return <ArgToolbarCombo
                    {...node}
                    disabled={disabled || node.disabled}
                    tooltip={_tooltip}
                    key={node.path}
                    className={classNames('&-item', node.className)}
                />;
            } else if (node.type === 'separator') {
                shouldRenderDivider = false;

                return <ArgToolbarDivider className={classNames('&-divider')} key={`divider-${node.path}`}/>;
            }

            shouldRenderDivider = true;
            const _restProps = omit(node, 'children');
            const _tooltip = isFunction(node.tooltip) ? node.tooltip(node) : node.tooltip;

            return (
                <ArgToolbarItem
                    {..._restProps}
                    tooltip={_tooltip}
                    disabled={disabled || _restProps.disabled}
                    key={node.path}
                    icon={node.icon}
                    label={node.label}
                    className={classNames('&-item', node.className)}
                />
            );
        });
    };

    const renderedNodes = renderNodes(toolbarNodes);

    const cls = {
        disabled,
    };

    return (
        <div className={classNames('&', (prefix) ? `&-${prefix}` : undefined, className, cls)}>
            {renderedNodes}
        </div>
    );
}
