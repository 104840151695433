import React, { RefObject, useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { Props } from 'react-intl/src/components/relative';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { BuildInPlacements } from 'rc-trigger';
import { Tooltip } from 'antd';

import { computeRelativeTime } from '../../../utils/compute-relative-time';
import { findOrCreatePopupArea } from '../utils';
import { renderText } from '../utils/message-descriptor-formatters';
import { DEFAULT_TOOLTIP_BUTTON_DELAY, DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText } from '../types';

// import './arg-relative-time.less';

const messages = defineMessages({
    tooltip: {
        id: 'basic.arg-relative-time.tooltip',
        defaultMessage: '{date, date, full} at {date, time, medium}',
    },
});

interface ArgRelativeTimeProps extends Props {
    date?: Date;
    className?: ClassValue;
    skipDateTime?: boolean;

    messageValues?: ArgMessageValues;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;
    tooltipBuiltinPlacements?: BuildInPlacements;
    onTooltipVisibleChange?: (visible: boolean) => void;

    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
}

export function ArgRelativeTime(props: ArgRelativeTimeProps) {
    const {
        numeric,
        style,
        className,
        date,
        skipDateTime,

        tooltip,
        tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT,
        tooltipClassName,
        tooltipBuiltinPlacements,
        onTooltipVisibleChange,

        getPopoverContainer,
        messageValues,
    } = props;

    const classNames = useClassNames('arg-relative-time');

    const spanRef = useRef<HTMLSpanElement>(null);

    const relativeTime = computeRelativeTime(date, undefined, skipDateTime);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (typeof (getPopoverContainer) === 'function') {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (spanRef.current) {
            return findOrCreatePopupArea(spanRef.current) || spanRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);

    const renderDateTooltip = useCallback(() => {
        if (!date) {
            return null;
        }

        return <FormattedMessage {...messages.tooltip} values={{ date }}/>;
    }, [date]);

    if (!relativeTime) {
        return null;
    }

    let comp = <span data-testid='relative-time' className={classNames('&', className)} ref={spanRef}>
        <FormattedRelativeTime
            value={relativeTime[0]}
            style={style}
            numeric={numeric}
            unit={relativeTime[1]}
            updateIntervalInSeconds={(relativeTime[1] === 'minute' || relativeTime[1] === 'hour') ? 60 : undefined} //updateIntervalInSeconds cannot be enabled for unit longer than hour
        />
    </span>;

    let tooltipContent: ArgRenderedText = undefined;
    if (tooltip === true) {
        tooltipContent = renderDateTooltip;
    } else {
        tooltipContent = renderText(tooltip);
    }
    if (tooltipContent) {
        comp =
            <Tooltip
                key='tooltip'
                className={classNames('&-tooltip', 'arg-tooltip')}
                title={renderText(tooltipContent, messageValues)}
                overlayClassName={classNames('&-tooltip-overlay', tooltipClassName)}
                placement={tooltipPlacement}
                onVisibleChange={onTooltipVisibleChange}
                builtinPlacements={tooltipBuiltinPlacements}
                mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
                getPopupContainer={computePopoverContainer}
            >
                {comp}
            </Tooltip>;
    }

    return comp;
}
