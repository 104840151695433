import React, { useCallback, useRef } from 'react';
import { Resizable } from 're-resizable';
import { isEmpty, isNil, values } from 'lodash';

// Hooks and components
import { ArgChangeReason } from '../../../types';
import { parseRadius } from '../../helpers/parse-radius';
import { formatRadius } from '../../helpers/format-radius';
import { ArgCombo } from '../../../arg-combo/arg-combo';
import { DEFAULT_RADIUS_UNIT, GeoValue, MapMode, RadiusUnit } from '../../model/geolocation-value';
import { ArgInputNumber } from '../../../arg-input/arg-input-number';
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { ProgressMonitor } from '../../../progress-monitors/progress-monitor';
import { LatitudeAndLongitudeResponse } from '../../model/geolocation-json';
import { ArgButton } from '../../../arg-button/arg-button';
import { ArgInputText } from '../../../arg-input/arg-input-text';
import { useCallbackAsync } from 'src/components/basic/arg-hooks/use-callback-async';
import { ArgMap2 } from '../map/arg-map-2';
import { argGeolocationFormMessages } from './arg-geolocation-form';

// Styles
import './arg-geolocation-form.less';
import { useNotifications } from '../../../arg-notifications/arg-notifications';

const POPOVER_MIN_WIDTH = '420px';
const POPOVER_MAX_WIDTH = '630px';
const POPOVER_DEFAULT_WIDTH = '416px';

const POPOVER_MIN_HEIGHT = '277px';
const POPOVER_MAX_HEIGHT = '416px';
const POPOVER_DEFAULT_HEIGHT = '630px';

interface ArgGeolocationForm2Props {
    className?: ClassValue;
    value?: GeoValue;
    onChange?: (value: GeoValue | ((value: GeoValue | undefined) => GeoValue | undefined) | undefined) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
    onRadiusUnitChange: (radiusUnit?: RadiusUnit) => void;
    radiusUnit: RadiusUnit;
}

export const ArgGeolocationForm2: React.FunctionComponent<ArgGeolocationForm2Props> = (props) => {
    const {
        value,
        className,
        onChange,
        getAddressCoordinates,
        radiusUnit = RadiusUnit.Kilometer,
        onRadiusUnitChange,
    } = props;

    const classNames = useClassNames('arg-geolocation-form');
    const map = useRef(undefined as L.Map | undefined);

    const notifications = useNotifications();

    const [handleAddressChange] = useCallbackAsync(async (progressMonitor: ProgressMonitor, address: string | null, reason?: ArgChangeReason): Promise<void> => {
        if (reason === 'clear' || !address) {
            onChange?.(undefined);

            return;
        }

        if (reason === 'blur' || value?.centeredArea?.address === address) {
            return;
        }

        try {
            const addressCoordinates = await getAddressCoordinates(address, progressMonitor);
            if (!addressCoordinates) {
                return;
            }

            onChange?.(prev => {
                const newValue: GeoValue = {
                    centeredArea: {
                        latLng: {
                            lat: addressCoordinates.latitude,
                            lng: addressCoordinates.longitude,
                        },
                        address,
                        radius: prev?.centeredArea?.radius ?? 0,
                    },
                };

                return newValue;
            });
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw Error;
            }
            notifications.error({ message: argGeolocationFormMessages.loadAddressError }, error as Error);
            throw Error;
        }
    }, [getAddressCoordinates, notifications, onChange, value?.centeredArea?.address]);

    const handleResize = useCallback(() => {
        map.current?.invalidateSize();
    }, [map]);


    const handleRadiusChange = useCallback((radius: number | null) => {
        onChange?.(prev => {
            const newValue: GeoValue = {
                centeredArea: {
                    ...prev?.centeredArea!,
                    radius: parseRadius(radius, radiusUnit),
                },
            };

            return newValue;
        });
    }, [onChange, radiusUnit]);

    return (
        <Resizable
            lockAspectRatio={true}
            onResize={handleResize}
            onResizeStop={handleResize}
            onResizeStart={handleResize}
            minWidth={POPOVER_MIN_WIDTH}
            maxWidth={POPOVER_MAX_WIDTH}
            minHeight={POPOVER_MIN_HEIGHT}
            maxHeight={POPOVER_MAX_HEIGHT}
            defaultSize={{
                height: POPOVER_DEFAULT_WIDTH,
                width: POPOVER_DEFAULT_HEIGHT,
            }}
            className={classNames('&', className)}
            enable={{
                bottom: true,
                left: true,
                bottomLeft: true,
            }}
        >
            <div className={classNames('&-header')}>
                {/* Address input */}
                <ArgInputText
                    autoFocus={true}
                    value={getInputValue(value)}
                    placeholder={argGeolocationFormMessages.addressPlaceholder}
                    right={
                        <ArgButton
                            type='ghost'
                            icon='icon-map-marker'
                            // loading={getAddressProgressMonitor?.isRunning}
                            className={classNames('&-header-input-right')}
                        />
                    }
                    onChange={handleAddressChange}
                    data-testid='arg-geolocation-form-address-input'
                    className={classNames('&-header-input', '&-header-input-address')}
                />

                {/* Radius input */}
                <ArgInputNumber
                    min={0}
                    step={1}
                    placeholder={argGeolocationFormMessages.radiusPlaceholder}
                    onChange={handleRadiusChange}
                    value={formatRadius(value?.centeredArea?.radius, radiusUnit)}
                    className={classNames('&-header-input', '&-header-input-radius')}
                    left={
                        <span className={classNames('arg-input-left', '&-header-input-left')}>
                            ±
                        </span>
                    }
                    data-testid='arg-geolocation-form-radius-input'
                    disabled={isRadiusAndUnitInputsDisabled(value)}
                />

                <ArgCombo<RadiusUnit>
                    cardinality='one'
                    items={values(RadiusUnit)}
                    onChange={onRadiusUnitChange}
                    initialValue={DEFAULT_RADIUS_UNIT}
                    getItemLabel={(item) => argGeolocationFormMessages[item]}
                    value={radiusUnit || DEFAULT_RADIUS_UNIT}
                    placeholder={argGeolocationFormMessages.radiusUnitPlaceholder}
                    data-testid='arg-geolocation-form-radius-unit-input'
                    className={classNames('&-header-input', '&-header-input-unit')}
                    disabled={isRadiusAndUnitInputsDisabled(value)}
                />
            </div>

            <div className={classNames('&-body')} data-testid='arg-geolocation-form-map-container'>
                <ArgMap2
                    value={value}
                    initialMapMode={MapMode.CenterArea}
                    forwardRef={map}
                    onChange={onChange}
                    className={classNames('&-body-map')}
                />
            </div>
        </Resizable>
    );
};

function getInputValue(value: GeoValue | undefined): string | undefined {
    const centeredArea = value?.centeredArea;

    return centeredArea?.address || (centeredArea?.latLng ? `${centeredArea?.latLng?.lat?.toString()} ${centeredArea?.latLng?.lat?.toString()}` : undefined);
}

function isRadiusAndUnitInputsDisabled(value: GeoValue | undefined): boolean {
    return (isNil(value?.centeredArea?.address) || isEmpty(value?.centeredArea?.address)) && (isNil(value?.centeredArea?.latLng) || isEmpty(value?.centeredArea?.latLng));
}

const isCenteredArea = (value: GeoValue | undefined) => {
    if (value?.centeredArea) {
        return true;
    }

    return false;
};
const isPolygonArea = (value: GeoValue | undefined) => {
    if (value?.polygonArea) {
        return true;
    }

    return false;
};
