import React from 'react';

// Components and Hooks
import { ArgButtonType } from '../types';
import { getDataTestIdFromProps } from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton, ArgButtonProps } from '../arg-button/arg-button';

import './arg-button-group.less';

export interface ArgButtonGroupProps {
    className?: ClassValue,
    type?: ArgButtonType;
    buttons: ArgButtonProps[]
}

const _ArgButtonGroup: React.FunctionComponent<ArgButtonGroupProps> = (props) => {
    const { buttons, type, className } = props;

    const classNames = useClassNames('arg-button-group');

    const dataTestId = getDataTestIdFromProps(props);

    return <div className={classNames('&', className)}>
        {buttons.map((button, buttonIndex) => (
            <ArgButton {...button}
                       type={type || button.type}
                       data-testid={dataTestId}
                       key={`arg-button-group-${buttonIndex}`}
                       className={classNames('&-item', button.className)}
            />
        ))}
    </div>;
};

export const ArgButtonGroup = _ArgButtonGroup; // React.memo(_ArgButton);

