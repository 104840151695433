import React from 'react';
import Debug from 'debug';

import { AbstractConfigurations, Configuration } from '../model/configurations';
import { ProgressMonitor } from '../components/basic';
import { UsersConnector } from '../utils/connectors/users-connector';

const debug = Debug('common:contexts:UserConfiguration');

export class UserConfigurations extends AbstractConfigurations {
    #usersConnector: UsersConnector;

    constructor(usersConnector: UsersConnector) {
        super('UserConfigurations');

        this.#usersConnector = usersConnector;
    }

    protected async fetchConnector(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Configuration | undefined> {
        const ret = await this.#usersConnector.getAppSettings(progressMonitor);

        return ret;
    }

    protected async storeConnector(configuration: Configuration, progressMonitor: ProgressMonitor): Promise<Configuration | undefined> {
        debug('storeConnector', 'configuration=', configuration);

        const ret = await this.#usersConnector.updateAppSettings(configuration, progressMonitor);

        return undefined;
    }
}

export const UserConfigurationsContext = React.createContext<UserConfigurations | undefined>(undefined);
