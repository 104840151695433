import Debug from 'debug';
import EventEmitter3 from 'eventemitter3';

import { $yield } from '../../components/basic';

const debug = Debug('argonode:utils:states:BasicState');

export interface StateId {
    id: any;
    url: string;
}

let __stateId = 0;

export class BasicState extends EventEmitter3 {
    #stateId: StateId;
    #url: string;

    constructor(url: string) {
        super();

        this.#url = url;
        this.#stateId = this.constructState(__stateId++);
    }

    get url(): string {
        return this.#url;
    }

    get stateId(): StateId {
        return this.#stateId;
    }

    protected constructState(id: number, property?: string, value?: any): StateId {
        const stateId: StateId = {
            id,
            url: this.#url,
        };

        return stateId;
    }

    change(property?: string, value?: any) {
        this.#stateId = this.constructState(__stateId++, property, value);

        $yield(() => {
            debug('change', 'emit Change event for property=', property, 'stateId=', this.#stateId);
            this.emit('Change', property, value);
        });
    }

    async initialize(): Promise<void> {

    }

    async connect(): Promise<void> {

    }

    async disconnect(): Promise<void> {

    }

    async release(): Promise<void> {
    }
}
