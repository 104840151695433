import { ReactNode, useCallback, useState } from 'react';

import { ArgCameraFlash } from './arg-camera-flash';
import { useSetTimeout } from '../arg-hooks/use-set-timeout';

export function useCameraFlash(): [() => void, ReactNode] {
    const [flashComponent, setFlashComponent] = useState<ReactNode>();

    const timeoutFct = useSetTimeout(2000);

    const flash = useCallback(() => {
        setFlashComponent(<ArgCameraFlash key={Date.now()}/>);
        timeoutFct(() => {
            setFlashComponent(undefined);
        });
    }, []);


    return [flash, flashComponent];
}
