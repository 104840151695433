import { KeyboardEvent } from 'react';
import { findIndex, forEach, isNumber, isString, pick } from 'lodash';
import Debug from 'debug';
import shallowequal from 'shallowequal';

import { isToolDisabled, isToolVisible, Tool, ToolChanges } from './tool';
import { EventEmitter } from '../utils/event-emitter';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { deepDifference } from '../../../utils/deep-difference';
import { immutableSet } from '../utils/immutable-set';

const LOG_DIFFERENCE = false;

const debug = Debug('common:basic:ToolContext');

export interface ToolContextEventTypes {
    ItemAdded: (tool: Tool) => void;
    ItemsFetched: (tool: Tool, children: Tool[]) => void;
    ItemRemoved: (tool: Tool) => void;
    ItemUpdated: (tool: Tool, changes: ToolChanges) => void;

    OnShow: () => void; // For context-menu
    OnHide: () => void; // For context-menu
}

export interface ToolTreeNode extends Tool {
    children?: ToolTreeNode[];
}

interface ToolTreeState {
    nodes: Tool[];
    stateId: number;
    progressMonitor?: ProgressMonitor;
    error?: Error;
}

export interface ToolTreeContext {
    fetchedTools: Record<string, ToolTreeState>;
}

export class ToolContext extends EventEmitter<ToolContextEventTypes> {
    #tools: Tool[] = [];
    #cachedTree?: ToolTreeNode[];
    #nodeStateIds: Record<string, number> = {};

    constructor() {
        super();
    }

    addItem(tool: Tool, changes?: Record<string, any>): void {
        const existingItem = this.#tools.find((item) => item.path === tool.path);

        if (existingItem && !isNumber(tool.override)) {
            throw new Error(`A tool with the same path must have a positionned override, path=${tool.path}`);
        }

        if (changes) {
            tool = { ...tool, ...changes };
        }

        this.#cachedTree = undefined;
        this.#tools.push({
            ...tool,
        });

        this.updateStateId();
        this.emit('ItemAdded', tool);
    }

    removeItem(tool: Tool): boolean {
        const newList = this.#tools.filter((item) => item.path !== tool.path || item.override !== tool.override);

        if (newList.length === this.#tools.length) {
            return false;
        }

        this.#tools = newList;
        this.#cachedTree = undefined;

        this.updateStateId();
        this.emit('ItemRemoved', tool);

        return true;
    }

    updateItem(toolOrPath: string | Tool, changes: ToolChanges): boolean {
        const tool = isString(toolOrPath) ? { path: toolOrPath } : toolOrPath;

        const itemIndex = findIndex(this.#tools, pick(tool, 'path', 'override'));

        if (itemIndex < 0) {
            console.error('Could not find the requested item:', tool, this.#tools);

            return false;
        }

        const item = this.#tools[itemIndex];

        const newItem = { ...item, ...changes };

        if (shallowequal(item, newItem)) {
            return false;
        }

        this.#nodeStateIds[item.path] = (this.#nodeStateIds[item.path] || 0) + 1;
        this.#tools[itemIndex] = newItem;
        this.#cachedTree = undefined;

        if (LOG_DIFFERENCE) {
            console.log('Update item=', item.path, '=>', this.#nodeStateIds[item.path]);

            const d = deepDifference(item, newItem, 1);
            console.log('  diff=', d);
        }

        this.updateStateId();
        this.emit('ItemUpdated', item, changes);

        return true;
    }

    togglePanel(tool: Tool, prefix?: string): boolean {
        const toolPath = tool.path;

        // Toggle the panel
        const items = this.#tools.filter(t => t.path === toolPath);

        if (items.length === 0) {
            console.error('Could not find the requested item:', tool, this.#tools);

            return false;
        }

        for (const item of items) {
            this.updateItem(item, { selected: !item.selected });
        }

        // Close other panels
        this.#tools.forEach((item) => {
            // Do not pick the toggled item
            if (item.path === toolPath) {
                return;
            }

            // Pick toolbar items by prefix
            if (prefix && item.path.indexOf(prefix) !== 0) {
                return;
            }

            // Pick toolbar items by type
            if (item.type !== 'panel') {
                return;
            }

            this.updateItem(item, { selected: false });
        });

        return true;
    }

    clear() {
        const tools = this.#tools;

        this.#tools = [];
        this.#cachedTree = undefined;
        this.#nodeStateIds = {};

        this.updateStateId();
        tools.forEach((item) => {
            this.emit('ItemRemoved', item);
        });
    }

    computeTree(context?: ToolTreeContext, prefix?: string): ToolTreeNode[] {
        const root: ToolTreeNode = {
            path: '',
            type: 'group',
            children: [],
        };

        const items = removeDuplicate(this.#tools);
        const implicitsNode = [];

        for (; items.length;) {
            const item = items.shift()!;

            // Pick toolbar items by prefix
            if (prefix && item.path.indexOf(prefix) !== 0) {
                continue;
            }

            const toolVisible = isToolVisible(item);

            if (toolVisible && context && item.computeChildren) {
                const nodeStateId = this.#nodeStateIds[item.path] || 0;
                const computedItems = context.fetchedTools[item.path];

                if (computedItems === undefined || computedItems.stateId !== nodeStateId) {
                    // console.log('STATEID CHANGED', computedItems?.stateId, nodeStateId, item.path);
                    computedItems?.progressMonitor?.cancel();

                    const progressMonitor = new ProgressMonitor(`Fetching ${item.path} items`, 1);

                    context.fetchedTools = immutableSet(context.fetchedTools, [item.path], {
                        nodes: [],
                        stateId: nodeStateId,
                        progressMonitor,
                    });

                    item.computeChildren(item, progressMonitor).then((result) => {
                        context.fetchedTools = immutableSet(context.fetchedTools, [item.path, 'nodes'], result);

                        this.updateStateId();
                        this.emit('ItemsFetched', item, result);
                    }, (error) => {
                        if (progressMonitor.isCancelled) {
                            return;
                        }

                        context.fetchedTools = immutableSet(context.fetchedTools, [item.path, 'error'], error);

                        console.error(error);
                    }).finally(() => {
                        context.fetchedTools = immutableSet(context.fetchedTools, [item.path, 'progressMonitor'], undefined);

                        progressMonitor.done();
                    });
                } else {
                    items.push(...computedItems.nodes);
                }
            }

            function normalizePath(path: string): string {
                if (!prefix) {
                    return path;
                }

                return path.substring(prefix.length + 1);
            }


            const segments = normalizePath(item.path).split('/');

            let node = root;
            let path = '';

            segments.find((segment, index) => {
                path += (path ? '/' : '') + segment;

                let child = node.children?.find((item) => normalizePath(item.path) === path);

                if (child) {
                    node = child;

                    if (node.visible === false) {
                        return true; // Break the loop
                    }

                    if (index + 1 === segments.length) {
                        // Merge informations ?
                        if ((node as any).$$implicit) {
                            delete (node as any).$$implicit;

                            forEach(item, (value, name) => {
                                if (name === 'children') {
                                    return;
                                }
                                (node as any)[name] = value;
                            });
                        }
                    }

                    return false;
                }

                if (!node.children) {
                    node.children = [];
                }

                if (index + 1 === segments.length) {
                    node.children.push({ ...item });

                    return false;
                }

                child = {
                    path: (prefix) ? (`${prefix}/${path}`) : path,
                    type: 'marker',
                    children: [],
                    order: item.order,
                };
                (child as any).$$implicit = true;
                if (!toolVisible) {
                    child.visible = false;
                }

                node.children.push(child);
                node = child;

                return false;
            });
        }

        const sortNode = (node: ToolTreeNode) => {
            if (!node.children) {
                return;
            }

            node.children.forEach((n) => {
                if (!n.children) {
                    return;
                }

                sortNode(n);
            });

            node.children.sort((n1, n2) => {
                return (n1.order || 0) - (n2.order || 0);
            });
        };


        let toolNodes: ToolTreeNode[] = [];

        sortNode(root);

        const flatMarkers = (node: ToolTreeNode): ToolTreeNode[] => {
            if (!node.children) {
                return [];
            }

            const children = [...node.children];

            const ret = [];
            for (let i = 0; i < children.length; i++) {
                const item = children[i];
                if (item.type === 'group' || item.type === 'combo') {
                    const newChildren = flatMarkers(item);

                    const newItem = {
                        ...item,
                        children: newChildren,
                    };

                    ret.push(newItem);
                    continue;
                }

                if (item.type !== 'marker') {
                    ret.push(item);
                    continue;
                }

                const mks = flatMarkers(item);

                ret.push(...mks);
            }

            if (ret.length === 1) {
                if (ret[0].type === 'separator') {
                    return [];
                }
            }

            return ret;
        };

        toolNodes = flatMarkers(root);

        const normalizeSeparators = (node: ToolTreeNode): ToolTreeNode[] => {
            const children = node.children;
            if (!children) {
                return [];
            }

            let ret = [];
            let idx = 0;
            for (; idx < children.length && children[idx].type === 'separator'; idx++) ;

            let separator = false;
            for (; idx < children.length; idx++) {
                const node = children[idx];
                if (node.type === 'separator') {
                    if (separator) {
                        continue;
                    }
                    separator = true;
                    ret.push(node);
                    continue;
                }
                separator = false;

                if (node.type === 'group') {
                    const ch = normalizeSeparators(node);
                    ret.push({
                        ...node,
                        children: ch,
                    });
                    continue;
                }

                ret.push(node);
            }

            for (; ret.length > 0;) {
                if (ret[ret.length - 1].type !== 'separator') {
                    break;
                }

                ret = ret.slice(0, ret.length - 1);
            }

            return ret;
        };

        toolNodes = normalizeSeparators({
            ...root,
            children: toolNodes,
        });

        this.#cachedTree = toolNodes;

        return toolNodes;
    }

    handleKeyBinding(tool: Tool, event: KeyboardEvent) {
        const internalTool = this.#tools.find((t) => t.path === tool.path);
        if (!internalTool) {
            return;
        }

        if (internalTool.type === 'panel' && !internalTool.onClick) {
            this.togglePanel(internalTool);

            return;
        }

        internalTool.onClick && internalTool.onClick(internalTool, event);
    }

    onUnmount() {
        debug('onUnmount', 'Call unmount');

        this.#tools.forEach((tool) => {
            try {
                tool.onUnmount?.();
            } catch (x) {
                console.error(x);
            }
        });

        debug('onUnmount', 'End of call unmount');
    }
}

// Returns a new array of tools that only have unique path. When
// several tools have same path, it keeps only the one with the higher
// override property, couting 0 for undefined.
function removeDuplicate(tools: Tool[]): Tool[] {
    const toolsByPath = new Map<string, Tool>();
    const getOverride = (tool: Tool) => tool.override || 0;
    for (const tool of tools) {
        const otherTool = toolsByPath.get(tool.path);
        if (otherTool && isToolVisible(tool) && !isToolDisabled(tool) && (getOverride(tool) > getOverride(otherTool)) || !otherTool) {
            toolsByPath.set(tool.path, tool);
        }
    }

    return [...toolsByPath.values()];
}
