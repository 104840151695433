import React, { CSSProperties, MouseEvent, ReactNode, useCallback } from 'react';
import { isFunction, isNumber, map } from 'lodash';
import { FormattedNumber } from 'react-intl';
import { Tooltip } from 'antd';

import {
    ArgMessageValues,
    ArgTab,
    ArgTabAction,
    ArgTabKey,
    ClassValue,
    DEFAULT_TOOLTIP_DELAY,
    KeyBindingsScope,
    renderText,
    ThreeDotsLoading,
    useClassNames,
} from 'src/components/basic';
import { EmptyPane } from '../../common/panes/empty-pane';

import './arg-tabs3.less';

interface ArgTabs3Props {
    className?: ClassValue;
    bodyClassName?: ClassValue;

    tabs?: ArgTab[];
    activeTabKey?: ArgTabKey;
    defaultActiveTabKey?: ArgTabKey;

    messageValues?: ArgMessageValues;
    searchToken?: string;

    refreshTab?: boolean;

    onChange: (tabKey: ArgTabKey | undefined, action: ArgTabAction, targetIndex?: number) => void;
}

export function ArgTabs3(props: ArgTabs3Props) {
    const {
        className,
        tabs,
        activeTabKey,
        defaultActiveTabKey,
        messageValues,
        searchToken,
        refreshTab = true,
        onChange,
        bodyClassName,
    } = props;

    const classNames = useClassNames('arg-tabs3');

    let currentTab = tabs?.find((t) => t.key === activeTabKey);
    if (!currentTab) {
        currentTab = tabs?.find((t) => t.key === defaultActiveTabKey);
        if (!currentTab) {
            currentTab = tabs?.[0];
        }
    }

    const handleTabClick = useCallback((event: MouseEvent, tab: ArgTab, tabIndex: number) => {
        onChange(tab.key, ArgTabAction.Show, tabIndex);
    }, [onChange]);

    const titles = map(tabs, (tab: ArgTab, tabIndex: number): ReactNode => {
        const {
            key,
            title,
            tooltip,
            titleCount,
            titleLoading,
        } = tab;

        const cls = {
            selected: currentTab?.key === key,
        };

        const titleText = renderText(title, messageValues, searchToken);
        let _title = <span className={classNames('&-header-button-title')}>
            {titleText}
        </span>;

        if (titleLoading) {
            _title = <>
                {_title}
                <span className={classNames('&-header-button-loading')}>
                    <ThreeDotsLoading/>
                </span>
            </>;
        } else if (isNumber(titleCount)) {
            _title = <>
                {_title}
                <span className={classNames('&-header-button-count')}>
                    <FormattedNumber value={titleCount}/>
                </span>
            </>;
        }

        const style: CSSProperties = {
            maxWidth: `calc(100% / ${tabs!.length})`,
        };

        let comp = <button
            type='button'
            key={key}
            className={classNames('&-header-button', cls)}
            onClick={(event) => handleTabClick(event, tab, tabIndex)}
            style={style}
        >
            {_title}
        </button>;

        let _tooltip = tooltip;
        if (tooltip !== false && !tooltip) {
            _tooltip = titleText;
        }

        if (_tooltip) {
            if (isFunction(_tooltip)) {
                const tooltipComponent = _tooltip(tab);
                comp = <Tooltip
                    key={key}
                    mouseEnterDelay={DEFAULT_TOOLTIP_DELAY}
                    title={tooltipComponent}
                >
                    {comp}
                </Tooltip>;
            } else {
                const tooltipNode = renderText(_tooltip, messageValues);

                comp = <Tooltip
                    key={key}
                    mouseEnterDelay={DEFAULT_TOOLTIP_DELAY}
                    title={tooltipNode}
                >
                    {comp}
                </Tooltip>;
            }
        }

        return comp;
    });


    const renderTabBody = (tab: ArgTab): ReactNode => {
        const { key, children, keyBindingsScope } = tab;

        const visible = (key === currentTab?.key);

        if (refreshTab && !visible) {
            return null;
        }

        const cls = {
            hidden: !visible,
            visible,
        };

        let body: ReactNode;
        if (isFunction(children)) {
            body = children(visible);
        } else {
            body = children;
        }

        if (keyBindingsScope) {
            body = <KeyBindingsScope scope={keyBindingsScope} enabled={activeTabKey === key}>
                {body}
            </KeyBindingsScope>;
        }

        return <div
            key={key}
            className={classNames('&-tab-body', bodyClassName, cls)}
            data-tab2body={key}
        >
            {body}
        </div>;
    };

    if (!titles.length) {
        return (
            <div className={classNames('&', 'empty', className)}>
                <EmptyPane className={classNames('&-empty')}/>
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                {titles}
            </div>
            {map(tabs, renderTabBody)}
        </div>
    );
}
