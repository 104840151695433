import React, { ReactNode, RefAttributes, RefObject, useCallback, useRef, useState } from 'react';
import { ActionType } from 'rc-trigger/lib/interface';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Popover, Tooltip } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { BuildInPlacements } from 'rc-trigger';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { getDataTestIdFromProps } from '../utils';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText, ArgSize } from '../types';
import {
    DEFAULT_SIZE,
    DEFAULT_TOOLTIP_DELAY,
    DEFAULT_TOOLTIP_PLACEMENT,
    defaultBuiltinPlacements,
    EMPTY_POPOVER_TRIGGER,
} from '../defaults';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { renderText } from '../utils/message-descriptor-formatters';
import { renderIcon } from '../arg-icon/arg-icon';

import './arg-tag.less';

const DEFAULT_TAG_POPOVER_PLACEMENT = 'bottomRight';

export interface ArgTagProps {
    size: ArgSize;
    label?: ArgRenderedText;
    className?: ClassValue;
    onClick?: (event: React.MouseEvent<any>) => void;
    onClose?: (event: ButtonClickEvent) => void;
    loading?: boolean;
    icon?: ArgRenderedIcon;
    backgroundColor?: string;
    maxWidth?: number;
    closeIcon?: ArgRenderedIcon;
    closable?: boolean;
    children?: ReactNode;
    hidden?: boolean;
    messageValues?: ArgMessageValues;

    dropdown?: boolean;
    onOpenDropdown?: () => void;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    popover?: ArgRenderedText;
    popoverPlacement?: TooltipPlacement;
    popoverTrigger?: ActionType | ActionType[] | false;
    popoverVisible?: boolean;
    popoverClassName?: ClassValue;
    onPopoverVisibleChange?: (visible: boolean) => void;
    popoverBuiltinPlacements?: BuildInPlacements;
    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
    disabled?: boolean;
    iconColor?: string;
}

export function ArgTag(props: ArgTagProps & RefAttributes<HTMLDivElement>) {
    const {
        className,
        icon,
        iconColor,
        backgroundColor,
        maxWidth,
        label,
        children,
        hidden,
        messageValues,
        onClose,
        onClick,
        closable,
        closeIcon,
        dropdown,
        onOpenDropdown,
        size = DEFAULT_SIZE,
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        popover,
        popoverPlacement,
        popoverTrigger,
        popoverVisible,
        onPopoverVisibleChange,
        popoverClassName,
        popoverBuiltinPlacements = defaultBuiltinPlacements,
        getPopoverContainer,
        disabled,
    } = props;

    const useInternalPopoverVisible = !('popoverVisible' in props);
    const [internalPopoverVisible, setInternalPopoverVisible] = useState<boolean | undefined>(undefined);

    const htmlTagRef = useRef<HTMLDivElement | null>(null);

    const dataTestId = getDataTestIdFromProps(props);

    const _closable = ('closable' in props) ? closable : onClose;

    const hasIcon = icon;

    const classNames = useClassNames('arg-tag');

    const handleOnClose = useCallback((event: ButtonClickEvent) => {
        if (disabled) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        onClose?.(event);
    }, [disabled, onClose]);

    const handleOnClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (disabled) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        onClick?.(event);
    }, [disabled, onClick]);

    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        setInternalPopoverVisible(visible);

        onPopoverVisibleChange && onPopoverVisibleChange(visible);
    }, [onPopoverVisibleChange]);


    const handleOnOpenDropdown = useCallback(() => {
        //---this code is commented to let the event go to the tag to open the popover, no dropdown is used in the app! but this is to be validated
        // event.preventDefault();
        // event.stopPropagation();

        if (useInternalPopoverVisible) {
            if (internalPopoverVisible) {
                setInternalPopoverVisible(false);
                onPopoverVisibleChange?.(false);

                return;
            }
        } else if (popoverVisible) {
            onPopoverVisibleChange?.(false);

            return;
        }

        onOpenDropdown?.();

        if (useInternalPopoverVisible) {
            setInternalPopoverVisible(true);
        }

        onPopoverVisibleChange && onPopoverVisibleChange(true);
    }, [onOpenDropdown, onPopoverVisibleChange, popoverVisible, useInternalPopoverVisible, internalPopoverVisible]);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement): HTMLElement => {
        if (typeof (getPopoverContainer) === 'function') {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (htmlTagRef.current) {
            return htmlTagRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);


    if (hidden) {
        return null;
    }

    const _label = (label !== undefined) ? label : children;
    const labelNode = renderText(_label, messageValues);

    if (labelNode === undefined && icon === undefined) {
        throw new Error('Label or icon must be specified');
    }

    const cls = {
        [`size-${size}`]: true,
        'has-icon': hasIcon,
        'closable': _closable,
        'has-click': onClick,
        'disabled': disabled,
        dropdown: dropdown,
    };

    let tag = <div
        className={classNames('&', cls, className)}
        ref={htmlTagRef}
        onClick={handleOnClick}
        data-testid={dataTestId}
        style={{ backgroundColor: backgroundColor, maxWidth: maxWidth }}
    >
        {/* Icon */}
        {hasIcon && <span className={classNames('&-icon-container')}>
            {renderIcon(icon, classNames('&-icon'), iconColor)}
        </span>}

        {/* Label */}
        {labelNode && <span className={classNames('&-label')}>
            {labelNode}
        </span>}

        {dropdown &&
            <ArgButton
                key='dropdown'
                icon='icon-triangle-down'
                type='ghost'
                className={classNames('&-dropdown')}
                onClick={handleOnOpenDropdown}
                disabled={disabled}
            />
        }

        {/* Close icon */}
        {_closable && (
            <ArgButton
                key='close'
                type='ghost'
                icon={closeIcon || 'icon-cross'}
                tabIndex={-1}
                className={classNames('&-close')}
                onClick={handleOnClose}
                data-testid='arg-tag-close'
                disabled={disabled}
            />
        )}
    </div>;


    const tooltipContent = (tooltip === true) ? (labelNode) : tooltip;
    if (tooltipContent) {
        tag =
            <Tooltip
                className={classNames('&-tooltip', 'arg-tooltip', tooltipClassName)}
                title={renderText(tooltipContent, messageValues)}
                data-testid='tooltip'
                placement={tooltipPlacement || DEFAULT_TOOLTIP_PLACEMENT}
                mouseEnterDelay={DEFAULT_TOOLTIP_DELAY}
                getPopupContainer={computePopoverContainer}
            >
                {tag}
            </Tooltip>;
    }

    if (popover) {
        let _popoverTrigger: ActionType | ActionType[] = 'click';
        if (popoverTrigger === false) {
            _popoverTrigger = EMPTY_POPOVER_TRIGGER;
        } else if (popoverTrigger) {
            _popoverTrigger = popoverTrigger;
        }

        let _popoverVisible;
        if (useInternalPopoverVisible) {
            _popoverVisible = internalPopoverVisible;
        } else {
            _popoverVisible = popoverVisible;
        }

        const popoverProps: PopoverProps = {
            content: renderText(popover, messageValues),
            className: classNames('&-popover', 'arg-popover'),
            overlayClassName: classNames('&-popover-overlay', 'arg-popover-overlay', popoverClassName),
            placement: popoverPlacement || DEFAULT_TAG_POPOVER_PLACEMENT,
            onOpenChange: handlePopoverVisibleChange,
            destroyTooltipOnHide: true,
            trigger: _popoverTrigger,
            builtinPlacements: popoverBuiltinPlacements,
            getPopupContainer: computePopoverContainer,
        };
        if (_popoverVisible !== undefined) {
            popoverProps.open = _popoverVisible;
            if (_popoverVisible && !popoverProps.trigger) {
                popoverProps.trigger = 'click';
            }
        }

        if (popoverVisible !== undefined) {
            popoverProps.open = popoverVisible;
        }

        tag = <Popover {...popoverProps}>
            {tag}
        </Popover>;
    }

    return tag;
}
