import React, {
    HTMLAttributes,
    ReactNode,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Tooltip } from 'antd';
import { isFunction, isString } from 'lodash';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { isElement } from 'react-is';
import Debug from 'debug';

import { DragDropContext, DraggingEvent } from './drag-drop-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { DEFAULT_TOOLTIP_BUTTON_DELAY } from '../defaults';
import { findOrCreatePopupArea } from '../utils';
import { renderText } from '../utils/message-descriptor-formatters';
import { $yield } from '../utils/yield';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { useMemoDeepEquals } from '../arg-hooks/use-memo-deep-equals';

const debug = Debug('argonos:components:Droppable');

const DISABLE = false;

const DEFAULT_TOOLTIP_PLACEMENT = 'bottom';

const AUTO_OPEN_DELAY_MS = 1000 * 1.5;

export interface DndActionDragInfos {
    supports: boolean;
    hint?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    autoOpenSupport?: boolean;
}

export const DND_NO_SUPPORT: Readonly<DndActionDragInfos> = {
    supports: false,
};

export const DND_AUTO_OPEN_SUPPORT: Readonly<DndActionDragInfos> = {
    supports: false,
    autoOpenSupport: true,
};

export const DND_SUPPORTED: Readonly<DndActionDragInfos> = {
    supports: true,
};

export const DND_ALL_SUPPORTED: Readonly<DndActionDragInfos> = {
    supports: true,
    autoOpenSupport: true,
};


export interface DndAction {
    dropEffect: 'none' | 'copy' | 'link' | 'move';

    dragInfos: (event: DraggingEvent) => DndActionDragInfos | undefined;

    dragOver?: (event: DragEvent) => void;
    dragLeave?: (event: DragEvent) => void;
    dragAutoOpen?: () => void;

    // Called just before onDrop !
    canDrop?: (dataTransfer: DataTransfer, event: DragEvent) => boolean;
    onDrop: (dataTransfer: DataTransfer, event: DragEvent) => void;
}

export interface DroppableProvided {
    test?: string;
}

export interface DroppableStateSnapshot {
    // Is the Droppable supports current operation ? (Doesn't mean you can drop but you can maybe drop)
    supportsDragging: boolean;

    supportsAutoOpen: boolean;
    // Is the Droppable being dragged over?
    isDraggingOver: boolean;
    // What is the id of the draggable that is dragging from this list?
    // Useful for styling the home list when not being dragged over
    draggingFromThisWith?: string;
    // Hints
    hint?: ArgRenderedText;
    hintMessageValues?: ArgMessageValues;
    //
    dropEffect?: 'none' | 'copy' | 'link' | 'move';
}

//export type AsyncDroppableLabel = (progressMonitor: ProgressMonitor, provided: DroppableProvided, snapshot: DroppableStateSnapshot) => Promise<ReactNode | null>;
export type DroppableLabelFct = (provided: DroppableProvided, snapshot: DroppableStateSnapshot | undefined) => ArgRenderedText;

export interface DroppableProps extends Omit<HTMLAttributes<HTMLDivElement>, 'className' | 'children'> {
    className?: ClassValue;

    children: DroppableLabelFct | ReactNode | null;
    actions: DndAction | undefined;

    onSnapshotChange?: (snapshot: DroppableStateSnapshot) => void;

    tooltipPlacement?: TooltipPlacement;

    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
    containerRef?: React.Ref<HTMLDivElement>;
}

export function Droppable(props: DroppableProps) {
    const {
        children,
        actions,
        onSnapshotChange,
        className,
        tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT,
        getPopoverContainer,
        containerRef: externalContainerRef,
        ...otherProps
    } = props;

    const dragDropContext = useContext(DragDropContext);
    const classNames = useClassNames('arg-droppable');

    const [isDraggingOver, setDraggingOver] = useState<boolean>(false);

    const provided = useMemo<DroppableProvided>(() => {
        const ret: DroppableProvided = {};

        return ret;
    }, []);

    const snapshot = useMemoDeepEquals<DroppableStateSnapshot | undefined>(() => {
        if (!actions) {
            debug('Snapshot', 'No actions');

            return undefined;
        }

        let draggingIsPossible = false;
        let hint = undefined;
        let hintMessageValues: ArgMessageValues | undefined = undefined;
        let dropEffect = undefined;
        let supportsDragging = false;
        let supportsAutoOpen = false;

        if (dragDropContext.dragging && dragDropContext.draggingEvent) {
            draggingIsPossible = true;
            const dragInfos = actions.dragInfos(dragDropContext.draggingEvent);
            supportsDragging = dragInfos?.supports || false;
            supportsAutoOpen = dragInfos?.autoOpenSupport || false;
            hint = dragInfos?.hint;
            hintMessageValues = dragInfos?.messageValues;
            dropEffect = supportsDragging ? (actions.dropEffect ?? 'none') : 'none';
        }

        const ret: DroppableStateSnapshot = {
            isDraggingOver,
            dropEffect,
            hint,
            hintMessageValues,
            supportsDragging,
            supportsAutoOpen,
            draggingFromThisWith: draggingIsPossible ? 'drag-1' : undefined,
        };

        debug('Snapshot', 'new=', ret, 'dragDropContext=', dragDropContext);

        return ret;
    }, [actions, dragDropContext.dragging, dragDropContext.draggingEvent, isDraggingOver]);

    useEffect(() => {
        if (!snapshot || !onSnapshotChange) {
            return;
        }
        onSnapshotChange(snapshot);
    }, [onSnapshotChange, snapshot]);

    const handleFormatLabel = useCallback((hint: ArgRenderedText, messageValues?: ArgMessageValues) => {
        const label = renderText(hint, messageValues);

        if (isString(label) || isElement(label) || label === false || label === null || label === undefined) {
            return label;
        }

        debug('handleFormatLabel', 'Invalid label=', label);

        return null;
    }, []);

    useLayoutEffect(() => {
        if (!snapshot) {
            return;
        }

        const droppable = containerRef.current;
        if (!droppable) {
            return;
        }

        debug('useLayoutEffect', 'snapshot=', snapshot);

        let timerId: ReturnType<typeof setTimeout> | undefined = undefined;

        const handleDragEnter = (event: DragEvent) => {
            debug('handleDragEnter', 'supportsDragging=', snapshot.supportsDragging, 'supportsAutoOpen=', snapshot.supportsAutoOpen, 'actions=', actions?.dropEffect);

            $yield(() => {
                setDraggingOver(true);
            });

            const dragAutoOpen = actions?.dragAutoOpen;
            if (dragAutoOpen && snapshot.supportsAutoOpen) {
                timerId = setTimeout(() => {
                    // Auto Open
                    dragAutoOpen();
                }, AUTO_OPEN_DELAY_MS);
            }

            if ((!snapshot.supportsDragging && !snapshot.supportsAutoOpen) || event.defaultPrevented) {
                return;
            }

            if (event.dataTransfer) {
                let dropEffect = actions?.dropEffect;
                if (!snapshot.supportsDragging && snapshot.supportsAutoOpen) {
                    dropEffect = 'copy';
                }

                event.dataTransfer.dropEffect = dropEffect || 'none';

                debug('handleDragEnter', 'DropEffect=', event.dataTransfer.dropEffect);
            }

            //console.log('Set drop effect to', actions?.dropEffect);
            event.preventDefault();
        };

        const handleDragOver = (event: DragEvent) => {
            debug('handleDragOver', 'supportsDragging=', snapshot.supportsDragging, 'actions=', actions);

            $yield(() => {
                setDraggingOver(true);
            });

            if ((!snapshot.supportsDragging && !snapshot.supportsAutoOpen) || event.defaultPrevented) {
                return;
            }

            debug('handleDragOver', 'process');

            if (snapshot.supportsDragging) {
                actions!.dragOver?.(event);
            }

            //console.log('OVER Set drop effect to', actions?.dropEffect);

            if (event.dataTransfer) {
                let dropEffect = actions?.dropEffect;
                if (!snapshot.supportsDragging && snapshot.supportsAutoOpen) {
                    dropEffect = 'copy';
                }

                event.dataTransfer.dropEffect = dropEffect || 'none';

                //console.log('handleDragOver', 'DropEffect=', event.dataTransfer.dropEffect);
            }

            event.preventDefault();
        };

        const handleDragLeave = (event: DragEvent) => {
            debug('handleDragLeave');

            if (timerId) {
                clearTimeout(timerId);
                timerId = undefined;
            }

            $yield(() => {
                setDraggingOver(false);
            });

            if ((!snapshot.supportsDragging && !snapshot.supportsAutoOpen) || event.defaultPrevented) {
                return;
            }

            debug('handleDragLeave', 'process');

            if (snapshot.supportsDragging) {
                actions!.dragLeave?.(event);
            }

            event.preventDefault();

            return false;
        };

        const handleDrop = (event: DragEvent) => {
            $yield(() => {
                setDraggingOver(false);
            });


            if (timerId) {
                clearTimeout(timerId);
                timerId = undefined;
            }

            debug('handleDrop', 'Handle drop from', {
                target: event.target,
                relatedTarget: event.relatedTarget,
                snapshot,
                supportsDragging: snapshot.supportsDragging,
                supportsAutoOpen: snapshot.supportsAutoOpen,
                defaultPrevented: event.defaultPrevented,
                canDrop: actions!.canDrop,
            });

            if (!snapshot.supportsDragging) {
                return;
            }

            if (actions!.canDrop && !actions!.canDrop?.(event.dataTransfer!, event)) {
                return;
            }

            debug('handleDrop', 'Process drop');

            event.preventDefault();
            actions!.onDrop(event.dataTransfer!, event);

            return false;
        };

        debug('useLayoutEffect', 'Install droppable listeners');
        droppable.addEventListener('dragenter', handleDragEnter);
        droppable.addEventListener('dragover', handleDragOver);
        droppable.addEventListener('dragleave', handleDragLeave);
        droppable.addEventListener('drop', handleDrop);

        return () => {
            debug('useLayoutEffect', 'Uninstall droppable listeners');
            droppable.removeEventListener('dragenter', handleDragEnter);
            droppable.removeEventListener('dragover', handleDragOver);
            droppable.removeEventListener('dragleave', handleDragLeave);
            droppable.removeEventListener('drop', handleDrop);
        };
    }, [snapshot]);

    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(externalContainerRef, () => containerRef.current!);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (isFunction(getPopoverContainer)) {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (containerRef.current) {
            return findOrCreatePopupArea(containerRef.current) || containerRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);

    const cls = {
        'drag-over': isDraggingOver,
        'can-drop': snapshot?.supportsDragging,
    };

    let label: ArgRenderedText;
    if (isFunction(children)) {
        label = children(provided, snapshot);
    }

    label = renderText(label);


    if (DISABLE) {
        return <>
            {label}
        </>;
    }

    const comp = <div
        {...otherProps}
        className={classNames('&', cls, className)}
        ref={containerRef}
    >
        {label}
    </div>;

    return (
        <Tooltip
            key='tooltip'
            className={classNames('&-tooltip', 'arg-tooltip')}
            open={isDraggingOver && !!snapshot?.hint}
            title={handleFormatLabel(snapshot?.hint, snapshot?.hintMessageValues)}
            mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
            placement={tooltipPlacement}
            getPopupContainer={computePopoverContainer}
        >
            {comp}
        </Tooltip>
    );
}
