import React, { KeyboardEvent as ReactKeyboardEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import Debug from 'debug';

import {
    KeyBindingDescriptor,
    KeyBindingIdentifier,
    KeyBindingsConfiguration,
    KeyBindingScopeDescriptor,
    KeyBindingsScopeDefs,
    KeyBindingWithHandler,
} from './keybinding';
import { KeyBindingsContext } from './keybindings-context';
import { createKeyCommandFromEvent, isExcludedForInput } from './utils';
import { useUserConfiguration } from '../../../hooks/use-user-configuration';
import { isElement } from 'lodash';

const debug = Debug('argonode:keybindings:KeyBindingsEngine');
const debugNotFound = debug.extend('KeyBindingNotFound');

export const RESERVED_KEYBINDING_ATTRIBUTE = 'data-input-keybinding';

const DEFAULT_USER_CONFIGURATION_KEY = 'core.KeyBindings';

interface KeyBindingsEngineProps {
    children: ReactNode;
    defs: Record<KeyBindingIdentifier, KeyBindingDescriptor>;
    globalScope: KeyBindingScopeDescriptor;
    userConfigurationKey?: string;
}

export function KeyBindingsEngine(props: KeyBindingsEngineProps) {
    const {
        children,
        defs,
        globalScope,
        userConfigurationKey = DEFAULT_USER_CONFIGURATION_KEY,
    } = props;

    const [userConfiguration, setUserConfiguration] = useUserConfiguration<KeyBindingsConfiguration | undefined>(userConfigurationKey, undefined);

    debug('render', 'Engine userConfiguration=', userConfiguration);

    const [rootKeyBindings] = useState<KeyBindingsScopeDefs>(() => {
        const def = new KeyBindingsScopeDefs(globalScope, undefined, defs, false);

        def.eventEmitter.on('UserConfigChanged', (config: KeyBindingsConfiguration) => {
            debug('eventEmitter', 'Get event', config);
            setUserConfiguration(config);
        });

        return def;
    });

    useEffect(() => {
        rootKeyBindings.setUserConfig(userConfiguration);
    }, [userConfiguration, rootKeyBindings]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        for (let node = event.target as Element | null; node; node = node.parentElement) {
            if (node.hasAttribute(RESERVED_KEYBINDING_ATTRIBUTE)) {
                return;
            }
        }

        const keyCommand = createKeyCommandFromEvent(event);

        if (keyCommand === 'Control' || keyCommand === 'Shift' || keyCommand === 'Alt' || keyCommand === 'Meta') {
            return;
        }
        const target = event.target;
        if (isElement(target)) {
            const tagName = (target as Element).tagName?.toLowerCase();
            const role = (target as Element).getAttribute('role')?.toLowerCase();
            if (tagName === 'input' || tagName === 'textarea' || role === 'textbox') {
                if (isExcludedForInput(keyCommand)) {
                    return;
                }
            }
        }

        let handlersList: KeyBindingWithHandler[];

        const modalScope = rootKeyBindings.getNotInherited();
        if (modalScope) {
            handlersList = modalScope.listHandlers();
        } else {
            handlersList = rootKeyBindings.listHandlers();
        }

        const scopes = rootKeyBindings.listScopes();
        const scopesIds = scopes.reverse().map((s) => s.scope.id);

        let found: KeyBindingWithHandler | undefined;
        handlersList.find((keyBindingWithHandler) => {
            const userConfigScope = userConfiguration?.scopes.find((scope) => {
                return scopesIds.includes(scope.id);
            });
            if (userConfigScope) {
                const b = userConfigScope.bindings.find((b) => b.id === keyBindingWithHandler.def.id);
                if (b) {
                    if (b.keys === keyCommand) {
                        found = keyBindingWithHandler;

                        return true;
                    }
                }
            }

            if (keyBindingWithHandler.def.defaultKeys === keyCommand) {
                found = keyBindingWithHandler;

                return true;
            }

            return false;
        });

        if (found) {
            if (found.options?.preventDefault !== false) {
                event.preventDefault();
            }

            console.log('Got event keyCommand=', keyCommand, 'found=', found);
            debug('event', 'Got event keyCommand=', keyCommand, 'found=', found);

            try {
                found.handler(event as any as ReactKeyboardEvent);
            } catch (x) {
                console.error('Keybinding handler throws error=', x);
            }

            return;
        }

        debugNotFound('Event=', event, 'keyCommand=', keyCommand, 'found=', found, 'scopes=', scopes.map((x) => x.scope.id));
        debugNotFound(' bindings=', handlersList, rootKeyBindings.listScopes(), userConfiguration);
    }, [userConfiguration, rootKeyBindings]);

    useEffect(() => {
        debug('window.eventListeners', 'INSTALL new HANDLEKeyDown', userConfiguration);

        window.addEventListener('keydown', handleKeyDown, { capture: true });

        return () => {
            window.removeEventListener('keydown', handleKeyDown, { capture: true });
        };
    }, [handleKeyDown]);

    return <KeyBindingsContext.Provider value={rootKeyBindings}>
        {children}
    </KeyBindingsContext.Provider>;
}
