import React, { useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IAceEditorProps } from 'react-ace';
import { IAceEditor } from 'react-ace/lib/types';
import { Ace } from 'ace-builds';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-language_tools';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgInputText, ArgInputTextProps } from './arg-input-text';
import { ArgInputCustomComponentProps } from './arg-input';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgAceEditorInput, ArgAceEditorInputError, ArgAceLanguage } from './arg-input-expression-editor';
import { ArgInputExpressionModal } from './arg-input-expression-modal';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { computeText } from '../utils/message-descriptor-formatters';
import { InformationText } from './arg-input-expression-information-panel';
import { ArgPlaceholderText } from '../types';
import { ArgInputExpressionCompleter } from './arg-input-expression-completer';
import { SnippetsRepository } from './types';

import './arg-input-expression.less';


const DEFAULT_MAX_LINES = 4;

const messages = defineMessages({
    btnExpandEditor: {
        id: 'basic.arg-input-expression.Expand',
        defaultMessage: 'Expand editor',
    },
});

export interface ArgInputExpressionProps extends ArgInputTextProps {
    expandable?: boolean;

    language: string | ArgAceLanguage;
    completers?: ArgInputExpressionCompleter | ArgInputExpressionCompleter[];

    placeholder?: ArgPlaceholderText;
    aceProps?: IAceEditorProps;

    maxLines?: number;

    snippetsRepository?: SnippetsRepository;
    snippetsLoading?: ProgressMonitor;
    snippetsError?: Error;

    information?: InformationText;
    onImport?: (blob: Blob) => Promise<string>;
    onExport?: (value: string) => void;
    onValidate?: (value: string, progressMonitor: ProgressMonitor) => Promise<ArgAceEditorInputError[] | null | undefined>;
    onClickExpand?: (event: ButtonClickEvent) => void;
    expandableDisabled?: boolean;
}

export function ArgInputExpression(props: ArgInputExpressionProps) {
    const {
        expandable,
        aceProps,
        placeholder,
        language,
        onChange,
        size,
        debounce,
        className,
        value,
        autoFocus,
        maxLines = DEFAULT_MAX_LINES,
        snippetsRepository,
        snippetsLoading,
        snippetsError,
        information,
        onImport,
        onExport,
        onValidate,
        onClickExpand,
        expandableDisabled = false,
        completers,
    } = props;
    const classNames = useClassNames('arg-input-expression');

    const intl = useIntl();

    const [expandEditor, setExpandEditor] = useState(false);

    const inputRef = useRef<IAceEditor | null>(null);
    const cursorRef = useRef<Ace.Point | undefined>(undefined);

    const _placeholder = computeText(intl, placeholder);

    const handleExpandClick = useCallback((evt: ButtonClickEvent) => {
        onClickExpand?.(evt);

        if (evt.defaultPrevented) {
            return;
        }

        setExpandEditor((expandEditor) => !expandEditor);
    }, [onClickExpand]);

    const handleCancel = useCallback(() => {
        setExpandEditor(false);

        setTimeout(() => {
            inputRef.current?.focus();
            if (cursorRef.current) {
                inputRef.current?.moveCursorTo(cursorRef.current.row, cursorRef.current.column);
            }
        }, 200);
    }, []);

    const handleSaveExpression = useCallback((editorValue: string) => {
        setExpandEditor(false);
        if (cursorRef.current) {
            inputRef.current?.moveCursorToPosition(cursorRef.current);
        }
        onChange?.(editorValue, 'selection');
    }, [onChange]);

    const handleCursorChange = useCallback((selection: any) => {
        cursorRef.current = selection.cursor.getPosition();
    }, []);

    const myAceProps = useMemo<IAceEditorProps>(() => ({
        showGutter: false,
        fontSize: size === 'small' ? 12 : 14,
        highlightActiveLine: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        maxLines: 1,
        onCursorChange: handleCursorChange,
        showPrintMargin: false,
        ...aceProps,
    }), [aceProps, handleCursorChange, size]);

    const acePropsForModal = useMemo<IAceEditorProps>(() => {
        return {
            ...myAceProps,
            showGutter: true,
            highlightActiveLine: true,
            maxLines: undefined,
        };
    }, [myAceProps]);

    const renderInputComponent = useCallback((inputProps: ArgInputCustomComponentProps<string>) => {
        let aceProps = myAceProps;
        let _maxLines = 1;
        if (inputProps.internalInputValue && inputProps.internalInputValue.indexOf('\n') >= 0) {
            _maxLines = maxLines;
            aceProps = { ...acePropsForModal, showGutter: false };
        }

        return <ArgAceEditorInput
            aceProps={aceProps}
            cursorStart={cursorRef.current}
            language={language}
            inputRef={inputRef}
            maxLines={_maxLines}
            focus={autoFocus}
            placeholder={inputProps.placeholder}
            onChange={inputProps.onChange}
            onBlur={inputProps.onBlur}
            onFocus={inputProps.onFocus}
            value={inputProps.internalInputValue}
            onUnmount={inputProps.onUnmount}
            className={classNames('&-ace-inline', 'arg-input-editor')}
            completers={completers}
        />;
    }, [myAceProps, language, autoFocus, completers, classNames, maxLines, acePropsForModal]);

    return <>
        <ArgInputText
            renderInputComponent={renderInputComponent}
            right={expandable && <ArgButton
                key='expand'
                size={size}
                type='ghost'
                className={classNames('&-expand', 'arg-input-right')}
                icon='icon-expand'
                tooltip={messages.btnExpandEditor}
                tooltipPlacement='top'
                onClick={handleExpandClick}
                disabled={expandableDisabled}
            />}
            debounce={debounce}
            {...props}
            autoFocus={autoFocus}
            placeholder={_placeholder}
            className={classNames('&', className)} //keep classname after props
        />
        {expandable && <ArgInputExpressionModal
            visible={expandEditor}
            valueEditor={value || ''}
            language={language}
            cursorStart={cursorRef.current}
            aceProps={acePropsForModal}
            snippetsRepository={snippetsRepository}
            snippetsLoading={snippetsLoading}
            snippetsError={snippetsError}
            information={information}
            onSaveExpression={handleSaveExpression}
            onCancel={handleCancel}
            onImport={onImport}
            onExport={onExport}
            onValidate={onValidate}
            completers={completers}
        />}
    </>;
}

