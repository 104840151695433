import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import Debug from 'debug';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { includes, isNil, map, range, split, toString } from 'lodash';

import { ArgChangeReason } from '../types';
import { ArgInput, ArgInputKeyPressEvent, ArgInputProps } from './arg-input';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgFilteredMenu } from '../arg-menu/arg-filtered-menu';
import { ArgIcon } from '../arg-icon/arg-icon';
import { dayjs } from '../utils/dayjs';

import './arg-input-time.less';

const messages = defineMessages({
    defaultPlaceholder: {
        id: 'basic.arg-input-time.DefaultPlaceholder',
        defaultMessage: 'Select time',
    },
    hour: {
        id: 'basic.arg-input-time.Hour',
        defaultMessage: 'Hours',
    },
    minute: {
        id: 'basic.arg-input-time.Minute',
        defaultMessage: 'Minutes',
    },
    second: {
        id: 'basic.arg-input-time.Second',
        defaultMessage: 'Seconds',
    },
});

const MOMENT_FILTERED_KEY = /[0-9\:]/i;
const DEFAULT_MOMENT_DATE = dayjs(new Date(2000, 2, 1, 0, 0, 0, 0));
const DEFAULT_PLACEHOLDER = messages.defaultPlaceholder;

function momentKeypress(event: ArgInputKeyPressEvent): void {
    const keyboardEvent = event.keyboardEvent;
    const key = keyboardEvent.key;


    if (key.length === 1
        && !keyboardEvent.ctrlKey
        && !keyboardEvent.altKey
        && !keyboardEvent.metaKey
        && !MOMENT_FILTERED_KEY.test(key)) {
        keyboardEvent.preventDefault();
        keyboardEvent.stopPropagation();


        return;
    }
}

const debug = Debug('argonode:components:ArgInputDate');

export interface ArgInputTimeProps extends Omit<ArgInputProps<dayjs.Dayjs, any>, 'formatValue' | 'parseValue'> {
    format?: string;
    disabledHour?: (HH: number) => boolean;
    disabledMinute?: (mm: number) => boolean;
    disabledSecond?: (ss: number) => boolean;
}

export function ArgInputTime(props: ArgInputTimeProps) {
    const {
        format,
        readOnly,
        onChange,
        className,
        initialValue,
        disabledHour,
        disabledMinute,
        disabledSecond,
        value: externalValue,
        placeholder = DEFAULT_PLACEHOLDER,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('arg-input-time');

    const previousValue = useRef<dayjs.Dayjs>();

    const useInternalValue = !('value' in props);

    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [internalValue, setInternalValue] = useState<dayjs.Dayjs | undefined>(initialValue);


    const value: dayjs.Dayjs | undefined = useInternalValue ? internalValue : externalValue;

    const myFormat = useMemo(() => {
        if (format) {
            return format;
        }

        const f = intl.formatTime(new Date(2000, 2, 1, 10, 20, 30), {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        });
        const timeFormat = f.replace('10', 'HH').replace('20', 'mm').replace('30', 'ss');

        debug('computeFormat', 'timeFormat=', timeFormat);

        return timeFormat;
    }, [format, intl]);

    const momentFormatValue = useCallback((value: dayjs.Dayjs | null): string => {
        if (value === null) {
            return '';
        }

        return value.format(myFormat);
    }, [myFormat]);


    const momentParseValue = useCallback((value: string): dayjs.Dayjs | null => {
        if (value === '') {
            return null;
        }

        const v = dayjs(value, myFormat);

        if (!v.isValid()) {
            return null;
        }

        if (previousValue.current && v.isSame(previousValue.current)) {
            return previousValue.current;
        }

        previousValue.current = v;

        return v;
    }, [myFormat]);

    const handleTimeChanged = useCallback((time: dayjs.Dayjs | null, reason: ArgChangeReason | 'minute' | 'hour' | 'second') => {
        if (includes(['clear'], reason)) {
            setPopoverVisible(false);
        }

        if (useInternalValue) {
            setInternalValue(time || undefined);
        }

        debug('handleChange', 'value=', time);

        onChange?.(time, 'selection');
    }, [onChange, useInternalValue, setInternalValue, setPopoverVisible]);

    const popover = useMemo<ReactNode>(() => {
        const m = !isNil(value) && dayjs(value) || undefined;

        const handleSelect = (value: number, mode: 'minute' | 'hour' | 'second') => {
            handleTimeChanged(
                (m ? m : DEFAULT_MOMENT_DATE).set(mode, value),
                mode
            );
        };

        return <div className={classNames('&-popover')}>
            <div className={classNames('&-selectors')}>
                {map(split(myFormat, ':'), (part: string) => {
                    const mode = (
                        part === 'HH' ? 'hour' :
                            part === 'mm' ? 'minute' :
                                part === 'ss' ? 'second' :
                                    undefined
                    );

                    if (!mode) {
                        return;
                    }

                    const modeLabel = (
                        mode === 'hour' ? messages.hour :
                            mode === 'minute' ? messages.minute :
                                mode === 'second' ? messages.second :
                                    undefined
                    );

                    const disabledItem = (
                        mode === 'hour' ? disabledHour :
                            mode === 'minute' ? disabledMinute :
                                mode === 'second' ? disabledSecond :
                                    undefined
                    );

                    const items = range(part === 'HH' ? 24 : 60);

                    return (
                        <ArgFilteredMenu<number>
                            key={mode}
                            items={items}
                            autoScroll={true}
                            getItemLabel={toString}
                            getItemDisabled={disabledItem}
                            topRender={() => modeLabel && (
                                <span
                                    className={classNames('&-selectors-item-header', `&-selectors-item-${mode}-header`)}
                                >
                                    <FormattedMessage {...modeLabel}/>
                                </span>
                            )}
                            onSelect={(value) => handleSelect(value, mode)}
                            selected={m?.isValid() ? m?.[mode]() : undefined}
                            className={classNames('&-selectors-item', `&-selectors-item-${mode}`)}
                            menuClassName={classNames('&-selectors-item-menu', `&-selectors-item-${mode}-menu`)}
                        />
                    );
                })}
            </div>
        </div>;
    }, [handleTimeChanged, value, myFormat, intl, disabledHour, disabledSecond, disabledMinute]);


    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        setPopoverVisible(visible);
    }, [setPopoverVisible]);

    const onClockIconClick = useCallback(() => {
        setPopoverVisible((popoverVisible) => !popoverVisible);
    }, [setPopoverVisible]);


    return (
        <>
            <ArgInput<dayjs.Dayjs>
                {...props}
                value={value}
                popover={popover}
                popoverFitWidth={false}
                placeholder={placeholder}
                popoverTrigger={undefined}
                maxLength={myFormat.length}
                onKeyPress={momentKeypress}
                onChange={handleTimeChanged}
                parseValue={momentParseValue}
                formatValue={momentFormatValue}
                popoverVisible={popoverVisible}
                right={
                    !readOnly && <button
                        onClick={onClockIconClick}
                        className={classNames('&-open', 'arg-input-button', 'arg-input-right')}
                    >
                        <ArgIcon name='icon-clock'/>
                    </button>
                }
                className={classNames('&', className)}
                popoverOverlayClassName={classNames('&-popover')}
                onPopoverVisibleChange={handlePopoverVisibleChange}
            />
        </>
    );
}
