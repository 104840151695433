import { LatLng } from 'leaflet';

// Components and hooks
import { LatitudeAndLongitude } from '../model/geolocation-json';

export const LatitudeAndLongitudeToLatLng = (value?: LatitudeAndLongitude): LatLng | undefined => {
    if (!value || isNaN(value.latitude) || isNaN(value.longitude)) {
        return;
    }

    return new LatLng(value.latitude, value.longitude);
};
