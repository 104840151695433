import { useEffect, useState } from 'react';
import Debug from 'debug';

import { ImageCacheRepositoryContext } from './image-cache-repository';
import { DataCacheRepositoryResponse, useDataCacheRepository } from './use-data-cache-repository';

const debug = Debug('basic:cache-repositories:UseImageCache');

export type ImageCacheRepositoryResponse = DataCacheRepositoryResponse<Blob>;

export function useImageCache(src: string | undefined): ImageCacheRepositoryResponse | undefined {
    const ret = useDataCacheRepository<Blob>(src, undefined, ImageCacheRepositoryContext);

    return ret;
}

interface ObjectURLLinks {
    url: string;
    links: number;
}

const objectURLBySrc: Record<string, ObjectURLLinks> = {};

export type ImageBlobCacheRepositoryResponse = DataCacheRepositoryResponse<string>;

const NO_DATA: ImageBlobCacheRepositoryResponse = [undefined, undefined, undefined];

export function useImageBlobURLCache(src?: string): ImageBlobCacheRepositoryResponse | undefined {
    const [blobURLOrError, setBlobURLorError] = useState<ImageBlobCacheRepositoryResponse | undefined>();

    const ret = useImageCache(src);

    useEffect(() => {
        debug('useImageBlobURLCache.useEffect', 'ret=', ret, 'src=', src);
        if (!ret || !src) {
            setBlobURLorError(undefined);

            return;
        }

        const [blob, error, progressMonitor] = ret;

        if (error || progressMonitor) {
            setBlobURLorError([undefined, error, progressMonitor]);

            return;
        }

        if (!blob) {
            setBlobURLorError(undefined);

            return;
        }

        let objectURLLinks = objectURLBySrc[src];
        if (!objectURLLinks) {
            debug('useImageBlobURLCache.useEffect', 'create objectURL for', ret[0]);

            const url = URL.createObjectURL(ret[0]!);
            objectURLLinks = {
                url,
                links: 0,
            };
            objectURLBySrc[src] = objectURLLinks;
        }

        debug('useImageBlobURLCache.useEffect', 'link src=', src, 'links=', objectURLLinks.links);

        objectURLLinks.links++;
        setBlobURLorError([objectURLLinks.url, undefined, undefined]);

        return () => {
            debug('useImageBlobURLCache.useEffect', 'unlink src=', src, 'links=', objectURLLinks.links);

            if (--objectURLLinks.links) {
                return;
            }

            debug('useImageBlobURLCache.useEffect', 'destroy src=', src);
            URL.revokeObjectURL(objectURLLinks.url);
            delete objectURLBySrc[src];
        };
    }, ret || NO_DATA);

    if (!ret) {
        return undefined;
    }

    return blobURLOrError;
}
