import React, { useCallback, useState } from 'react';
import { isString } from 'lodash';

import {
    ArgGetItemDescription,
    ArgGetItemKey,
    ArgGetItemLabel,
    computeItemDescription,
    computeItemKey,
    computeItemLabel,
} from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgIconRadio } from '../arg-radio/arg-icon-radio';
import { ArgSize } from '../types';

import './arg-choice-list.less';

export interface ArgChoiceListProps<T> {
    className?: ClassValue;
    value?: T;
    items: T[];
    onChange: (item: T) => void;
    getItemKey?: ArgGetItemKey<T>;
    getItemLabel?: ArgGetItemLabel<T>;
    getItemDescription?: ArgGetItemDescription<T>;
    bordered?: boolean;
    size?: ArgSize;
}

export function ArgChoiceList<T>(props: ArgChoiceListProps<T>) {
    const {
        className,
        value: externalValue,
        items,
        getItemKey = 'key',
        getItemLabel = 'label',
        getItemDescription = 'description',
        onChange,
        bordered = true,
        size = 'small',
    } = props;

    const useInternalValue = !('value' in props);

    const [internalValue, setInternalValue] = useState<T>();

    const value = useInternalValue ? internalValue : externalValue;

    const classNames = useClassNames('arg-choice-list');

    const handleItemClick = useCallback((item: T) => {
        setInternalValue(item);
        onChange(item);
    }, [onChange]);

    const cls = {
        bordered,
    };

    return (
        <div className={classNames('&', className, cls)}>
            {items.map((item, index) => {
                const itemKey = computeItemKey(item, getItemKey, `index#${index}`);
                const itemLabel = computeItemLabel(item, getItemLabel);
                const itemDescription = computeItemDescription(item, getItemDescription);

                const isSelected = isString(value) ? (itemKey === value) : (item === value);

                const itemCls = {
                    selected: isSelected,
                    bordered,
                    'with-description': itemDescription !== undefined,
                };

                return (
                    <button
                        key={itemKey}
                        className={classNames('&-item', itemCls, cls)}
                        onClick={() => handleItemClick(item)}
                        type='button'
                    >
                        <div className={classNames('&-item-left')}>
                            <div className={classNames('&-item-left-icon')}>
                                <ArgIconRadio
                                    className={classNames('&-item-left-icon-radio')}
                                    size={size}
                                    state={isSelected}
                                />
                            </div>
                        </div>
                        <div className={classNames('&-item-right')}>
                            <div className={classNames('&-item-right-title')}>
                                <ArgMessageRenderer message={itemLabel} size='medium'/>
                            </div>
                            {
                                itemDescription && (
                                    <div className={classNames('&-item-right-description')}>
                                        <ArgMessageRenderer message={itemDescription} size='small'/>
                                    </div>
                                )
                            }
                        </div>
                    </button>
                );
            })}
        </div>
    );
}
