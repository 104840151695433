import React, { useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgButton,
    ArgIcon,
    ArgMessageValues,
    ArgRenderedText,
    ClassValue,
    renderText,
    useClassNames,
} from 'src/components/basic';
import { numberSorter } from '../../../utils/sorter';

import './list-panel.less';

export type ListPanelType = 'error' | 'warning' | 'success' | 'info'

const ICON_SIZE = 20;

const messages = defineMessages({
    line: {
        id: 'components.common.list-panel.Line',
        defaultMessage: 'Line {lineNumber}: ',
    },
});

interface ListPanelProps<T> {
    className?: ClassValue;
    type: ListPanelType;
    title: ArgRenderedText;
    items: T[];
    getItemLineNumber: (item: T) => number;
    getItemColumnNumber?: (item: T) => number;
    getItemMessage: (item: T) => ArgRenderedText;
    onClose: () => void;
    messageValues?: ArgMessageValues;
    onFocusLine?: (lineNumber: number, columnNumber?: number) => void;
}


export function ListPanel<T>(props: ListPanelProps<T>) {
    const {
        type,
        title,
        items,
        messageValues,
        className,
        getItemLineNumber,
        getItemMessage,
        onClose,
        onFocusLine,
        getItemColumnNumber,
    } = props;

    const classNames = useClassNames('list-panel');

    const iconName = useMemo<string>(() => {
        switch (type) {
            case 'error':
                return 'icon-close';
            case 'warning':
                return 'icon-warning';
            case 'success':
                return 'icon-checkmark';
            case 'info':
            default:
                return 'icon-notification';
        }
    }, [type]);

    const sortedItems = useMemo<T[]>(() => {
        const ret = items.sort((a, b) => {
            return numberSorter<T>(a, b, item => getItemLineNumber(item));
        });

        return ret;
    }, [getItemLineNumber, items]);

    const cls = {
        error: type === 'error',
        warning: type === 'warning',
        success: type === 'success',
        info: type === 'info',
    };

    return (
        <div className={classNames('&', cls, className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-header-left')}>
                    <ArgIcon name={iconName} size={ICON_SIZE} className={classNames('&-header-icon')}/>
                    <span className={classNames('&-header-title')}>
                        {renderText(title, messageValues)}
                    </span>

                </div>

                <div className={classNames('&-header-right')}>
                    <ArgButton
                        icon='icon-cross'
                        type='ghost'
                        onClick={onClose}
                        className={classNames('&-header-close-button')}
                    />
                </div>
            </div>
            <div className={classNames('&-list')}>
                {sortedItems.map((item, itemIndex) => {
                    const lineNumber = getItemLineNumber(item);
                    const columnNumber = getItemColumnNumber?.(item);
                    const message = getItemMessage(item);

                    const line = onFocusLine
                        ? <button
                            type='button'
                            className={classNames('&-list-item-line', 'can-click')}
                            onClick={() => onFocusLine(lineNumber, columnNumber)}
                        >
                            <FormattedMessage
                                {...messages.line}
                                values={{ lineNumber: lineNumber }}
                            />
                        </button>
                        : <div className={classNames('&-list-item-line')}>
                            <FormattedMessage
                                {...messages.line}
                                values={{ lineNumber: lineNumber }}
                            />
                        </div>;

                    return (
                        <div key={`list_panel_item_index${itemIndex}`} className={classNames('&-list-item')}>
                            {line}

                            <div className={classNames('&-list-item-message')}>
                                {renderText(message)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

