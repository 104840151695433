import { ArgLocale } from './arg-locale';

export const en_150: ArgLocale = {
    getAntdLocale: () => import('antd/lib/locale/en_US'),
    getNumberPolyfill: () => import('@formatjs/intl-numberformat/locale-data/en-150'),
    getRelativeTimePolyfill: () => import('@formatjs/intl-relativetimeformat/locale-data/en-150'),
    getDateTimePolyfill: () => import('@formatjs/intl-datetimeformat/locale-data/en-150'),
    getPluralRulesPolyfill: () => import('@formatjs/intl-pluralrules/locale-data/en'),

    getDayjsLocale: () => import('dayjs/locale/en'),
};
