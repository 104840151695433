import { DragEvent, MouseEvent, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { isArray, isFunction, isObject, isString } from 'lodash';
import { isElement } from 'react-is';

import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { DndAction, Droppable, DroppableProvided, DroppableStateSnapshot } from '../arg-dnd/droppable';
import { ArgTabAction } from '../arg-tabs/arg-tabs';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgTab, ArgTabKey, ArgTabMenuItems, ArgTagRenderTooltip } from '../arg-tabs/arg-tabs-types';
import { ArgTabMenu } from '../arg-tabs/arg-tab-menu';
import { ArgTabs2Context, DND_TAB_PROPERTY_NAME } from './arg-tabs2';
import { ArgIcon } from '../arg-icon/arg-icon';
import { $yield } from '../utils/yield';
import { MessageDescriptor } from 'react-intl';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { Tooltip } from 'antd';
import { DEFAULT_TOOLTIP_DELAY } from '../defaults';


import { ArgDndCleanUp } from '../arg-dnd/types';

const DISABLE_TOOLTIP = false;
const DISABLE_DROP = false;
const FORCE_UNDERLINE_COLOR = false;

export interface ArgTabTitle2Props {
    className?: ClassValue;
    tab: ArgTab;
    selected?: boolean;
    closable?: boolean;
    icon?: ReactNode;
    title: ArgRenderedText;
    additional?: ReactNode
    onChange: (tabKey: ArgTabKey | undefined, action: ArgTabAction) => void;
    dropAction?: DndAction;
    menu?: ArgTabMenuItems | ReactNode;

    tooltip?: boolean | ReactNode | ArgTagRenderTooltip | MessageDescriptor;
    description?: ReactNode;

    getBodyElement: (tabKey: ArgTabKey) => HTMLElement | undefined;

    fillDataTransfer?: (dataTransfer: DataTransfer) => ArgDndCleanUp | undefined;

    badge?: ReactNode;
    underlineColor?: string;

    messageValues?: ArgMessageValues;
}

export function ArgTabTitle2(props: ArgTabTitle2Props) {
    const {
        tab,
        className,
        selected,
        title,
        icon,
        closable,
        onChange,
        menu,
        dropAction,
        tooltip,
        description,
        getBodyElement,
        additional,
        fillDataTransfer,
        badge,
        underlineColor,
        messageValues,
    } = props;

    const { key: tabKey, hideTitleLabel, draggable = true } = tab;

    const classNames = useClassNames('arg-tabs2-tab');

    const tabsContext = useContext(ArgTabs2Context)!;
    const {
        registerTab,
    } = tabsContext;

    const _title = renderText(title, messageValues);

    registerTab(tabKey, icon, _title, description);

    let _menu: ReactNode;
    if (isElement(menu)) {
        _menu = menu;
    } else if (isObject(menu) || isArray(menu)) {
        _menu = <ArgTabMenu
            key='menu'
            items={menu as ArgTabMenuItems}
            className={classNames('&-menu', '&-actions-item', 'hide-size-menu')}
        />;
    }

    const handleRenderTitle = useCallback(() => {
        if (isFunction(tooltip)) {
            return tooltip(tab);
        }
        if (isElement(tooltip)) {
            return tooltip;
        }

        return renderText(tooltip);

        /*
        const bodyElement = getBodyElement(tab.key);

        return <ArgTab2Tooltip
            tabKey={tab.key}
            title={_title}
            description={description}
            bodyElement={bodyElement}
            selected={selected}
            icon={icon}
        />;
 */
        return null;
    }, [tab, tooltip]);

    const [dragging, setDragging] = useState<boolean>();

    const cls = {
        selected,
        dragging,
    };

    const handleTitleClick = useCallback((event: MouseEvent) => {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();

        onChange?.(tabKey, ArgTabAction.Show);
    }, [onChange, tabKey]);

    const handleTabClose = useCallback((event: ButtonClickEvent) => {
        onChange?.(tabKey, ArgTabAction.Close);
    }, [onChange, tabKey]);

    const cleanUpRef = useRef<ArgDndCleanUp | undefined>(undefined);

    const handleDragStart = useCallback((event: DragEvent) => {
        $yield(() => {
            setDragging(true);
        });

        if (cleanUpRef.current) {
            cleanUpRef.current();
            cleanUpRef.current = undefined;
        }

        event.dataTransfer.effectAllowed = 'copy';

        event.dataTransfer.setData('application/arg-tab2', tabKey);
        localStorage.setItem(DND_TAB_PROPERTY_NAME, tabKey);

        const cleanUp = fillDataTransfer?.(event.dataTransfer);

        cleanUpRef.current = cleanUp;
    }, [fillDataTransfer, tabKey]);

    const handleDragEnd = useCallback((event: DragEvent) => {
        $yield(() => {
            setDragging(false);
        });
        localStorage.removeItem(DND_TAB_PROPERTY_NAME);

        if (cleanUpRef.current) {
            cleanUpRef.current();
            cleanUpRef.current = undefined;
        }
    }, []);

    let _icon = icon;
    if (isString(icon)) {
        _icon = <ArgIcon
            className={classNames('&-title-icon')}
            name={icon}
        />;
    }

    let buttonStyle: any = undefined;
    if (underlineColor || FORCE_UNDERLINE_COLOR) {
        buttonStyle = {
            '--arg-tab-underline-color': underlineColor || FORCE_UNDERLINE_COLOR,
        };
    }

    const clsRound = {
        selected,
    };

    const buttonCls = {
        'has-no-title-label': !!hideTitleLabel,
    };

    let button = (
        <button
            key='title'
            type='button'
            className={classNames('&-title', buttonCls)}
            onClick={handleTitleClick}
            style={buttonStyle}
        >
            {_icon && <div className={classNames('&-title-iconContainer')}>
                {_icon}
            </div>}
            {_title && hideTitleLabel !== true &&
                <div className={classNames('&-title-text', icon ? 'hide-size-title' : undefined)}>
                    {_title}
                </div>}
            {additional && <div className={classNames('&-title-additional', icon ? 'hide-size-close' : undefined)}>
                {additional}
            </div>}
            {(underlineColor || FORCE_UNDERLINE_COLOR) &&
                <div className={classNames('&-title-underline', 'hide-size-title')}/>
            }
        </button>
    );

    if (!DISABLE_TOOLTIP && tooltip) {
        button = <Tooltip
            className={classNames('&-tooltip')}
            mouseEnterDelay={DEFAULT_TOOLTIP_DELAY}
            title={handleRenderTitle}
        >
            <div>{button}</div>
        </Tooltip>;
    }

    const comp = <>
        {button}
        {_menu}
        {closable !== false && <ArgButton
            key='close'
            className={classNames('&-close', 'hide-size-close')}
            type='ghost'
            size='medium'
            icon='icon-cross'
            onClick={handleTabClose}
        />}

        {badge && <div className={classNames('&-badge', 'hide-size-badge')}>
            {badge}
        </div>}

        <div className={classNames('&-round-left', clsRound)}/>
        <div className={classNames('&-round-right', clsRound)}/>

    </>;

    const droppableBody = comp;

    const droppable = <Droppable
        actions={dropAction}
        className={classNames('&', className, cls)}
        data-tabs2={tabKey}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable={draggable}
    >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot | undefined) => {
            return <>
                {droppableBody}
                <div className='drop-outline'/>
            </>;
        }}
    </Droppable>;

    return droppable;
}
