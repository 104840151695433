import { ArgNotifications } from './types';

let globalNotifications: ArgNotifications;

export function getGlobalNotifications(): ArgNotifications {
    if (!globalNotifications) {
        throw new Error('Global Notifications is not initialized');
    }

    return globalNotifications;
}

export function initializeGlobalNotifications(_globalNotifications: ArgNotifications) {
    globalNotifications = _globalNotifications;
}
