import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlShape } from 'react-intl';

import { ArgDndCleanUp } from './types';
import { ArgDragCursor } from './arg-drag-cursor';

import './utils.less';

interface DragImageContainerReturn {
    container: HTMLElement;
    cleanUp: ArgDndCleanUp;
}

function createDragImageContainer(): DragImageContainerReturn {
    const container = document.createElement('div');
    container.className = 'argonos-case-piece-drag-container';
    container.style.position = 'absolute';
    container.style.top = '-10000px';
    container.style.left = '-10000px';

    document.body.appendChild(container);

    const cleanUp = () => {
        document.body.removeChild(container);
    };

    return { container, cleanUp };
}


export function setDragCursor(dataTransfer: DataTransfer, intl: IntlShape, children: ReactNode): ArgDndCleanUp {
    const { container, cleanUp } = createDragImageContainer();
    dataTransfer.setDragImage(container, 0, 0);

    const snippetClone = <ArgDragCursor intl={intl}>
        {children}
    </ArgDragCursor>;
    const root = createRoot(container);
    root.render(snippetClone);

    return cleanUp;
}
