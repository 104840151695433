import { EventEmitter } from '../utils/event-emitter';
import { ArgTable3RowState, ArgTableColumn3 } from '../arg-table3/arg-table3';

export interface DataProviderEventTypes<T> {
    '*': (stateId: number) => void;
    Loading: (first: number, last: number) => void;
    Loaded: (first: number, last: number, rows: T[]) => void;
    Destroy: (first: number, last: number) => void;
    Cleared: () => void;
    Deleted: (count: number) => void;
    RowCountComputed: (count: number) => void;
    RowCountChanged: (count: number) => void;
    Refresh: () => void;
}

export const OnLoaded = 'Loaded';
export const OnLoading = 'Loading';
export const OnDestroy = 'Destroy';
export const OnCleared = 'Cleared';
export const OnDeleted = 'Deleted';
export const OnRowCountComputed = 'RowCountComputed';
export const OnRowCountChanged = 'RowCountChanged';
export const OnRefresh = 'Refresh';

export interface DataFilter {
    [key: string]: any;
}

export type PropertySorterOrder = 'ascending' | 'descending';

export interface PropertySorter {
    propertyName: string;
    order: PropertySorterOrder;
}

export interface DataSorter {
    propertySorters: PropertySorter[];
}

export type ArgTable3DataProviderColumn<T> = Pick<ArgTableColumn3<T>, 'key' | 'sorter'>;

export class DataProvider<T, F extends DataFilter = any> extends EventEmitter<DataProviderEventTypes<T>> {
    constructor() {
        super();
    }

    setFilter(filter?: F, sorter?: DataSorter, columns?: ArgTable3DataProviderColumn<T>[]) {

    }

    setSearchTerm(searchTerm: string | undefined) {
    }

    getRow(rowIndex: number): T | ArgTable3RowState {
        throw new Error('Not implemented');
    }

    setViewPort(first: number, rowCount: number): void {

    }

    get rowCount(): number | undefined {
        return undefined;
    }
}
