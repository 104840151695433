import React, { KeyboardEvent, MouseEvent, ReactNode, useCallback } from 'react';
import { Menu } from 'antd';
import Debug from 'debug';

import {
    ArgMessageValues,
    ArgRenderedIcon,
    ArgRenderedText,
    ArgTooltip,
    ButtonClickEvent,
    ClassValue,
    renderIcon,
    renderText,
    useClassNames,
} from 'src/components/basic';

import './arg-sub-menu.less';

const debug = Debug('basic:arg-menu:ArgSubMenu');

interface ArgSubmenuProps {
    className?: ClassValue;

    disabled?: boolean;

    children?: ReactNode;

    label?: ArgRenderedText;

    messageValues?: ArgMessageValues;

    icon?: ArgRenderedIcon;

    tooltip?: ArgRenderedText;

    popupClassName?: ClassValue;

    popupOffset?: [number, number];

    onTitleMouseEnter?: (event: ButtonClickEvent, menuKey: string) => void;
}

export function ArgSubMenu(props: ArgSubmenuProps) {
    const {
        className,
        label,
        messageValues,
        onTitleMouseEnter,
        icon,
        tooltip,
        children,
        popupClassName,
        popupOffset,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-sub-menu');

    const handleTitleMouseEnter = useCallback((info: {
        key: string;
        domEvent: MouseEvent | KeyboardEvent;
    }) => {
        onTitleMouseEnter?.(info.domEvent, info.key);
    }, [onTitleMouseEnter]);

    const handleTitleClick = useCallback((event: { domEvent: (MouseEvent | KeyboardEvent) }) => {
        debug('handleTitleClick', 'Title click', event.domEvent);
        event.domEvent.preventDefault();
    }, []);

    const _icon: ReactNode = renderIcon(icon, classNames('&-icon'));

    let comp = (
        <Menu.SubMenu
            onTitleClick={handleTitleClick}
            {...otherProps}
            className={classNames('&', 'arg-menu-item', 'arg-menu-item-submenu', className)}
            icon={_icon}
            title={renderText(label, messageValues)}
            popupClassName={classNames('&-popup', 'arg-menu', popupClassName)}
            popupOffset={popupOffset}
            onTitleMouseEnter={handleTitleMouseEnter}
        >
            {children}
        </Menu.SubMenu>
    );

    if (tooltip) {
        comp = <ArgTooltip
                   openClassName={classNames('&-tooltip')}
                   placement='bottom'
                   title={renderText(tooltip, messageValues)}>
            {comp}
        </ArgTooltip>;
    }

    return comp;
}
