import React, {
    CSSProperties,
    MouseEvent,
    MutableRefObject,
    ReactNode,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { debounce, DebouncedFunc, find, get, isEmpty, isFunction, isNil, isString } from 'lodash';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { Mapping as ClassMapping } from 'classnames';
import useResizeObserver from '@react-hook/resize-observer';
import { Tooltip } from 'antd';

import {
    ArgGetItemClassName,
    ArgGetItemLabel,
    computeItemClassName,
    filterItems,
    findOrCreatePopupArea,
    getTableCellValue,
    highlightSplit,
    normalizeText,
} from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { Draggable, DraggableAction } from '../arg-dnd/draggable';
import { SelectionCell } from '../arg-table3/selection-cell';
import { SelectionProvider, SelectionSource } from '../arg-providers/selection-provider';
import { SELECTION_COLUMN } from '../arg-table3/arg-table3';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgButton } from '../arg-button/arg-button';
import { useSelection } from '../arg-providers/use-selection';
import { ArgCheckboxMinus } from '../arg-checkbox/arg-checkbox';
import { ArgIcon } from '../arg-icon/arg-icon';

import './arg-table2.less';

const RESIZE_DEBOUNCE_MS = 128;

const messages = defineMessages({
    sortAscending: {
        id: 'basic.arg-table2.sort-ascending.Label',
        defaultMessage: 'Sort ascending',
    },
    sortDescending: {
        id: 'basic.arg-table2.sort-descending.Label',
        defaultMessage: 'Sort descending',
    },
    noSort: {
        id: 'basic.arg-table2.no-sort.Label',
        defaultMessage: 'Remove sort',
    },
    noData: {
        id: 'basic.arg-table2.No-data',
        defaultMessage: 'No data yet',
    },
    noResults: {
        id: 'basic.arg-table2.No-results',
        defaultMessage: 'No results found',
    },
});


export interface ArgTable2Column<T> {
    title?: ReactNode | MessageDescriptor | ((column: ArgTable2Column<T>) => ReactNode);
    getCellStringValue?: (row: T) => string,
    titleTooltip?: ReactNode | MessageDescriptor | true;
    sorter?: (a: T, b: T) => number;
    dataIndex: string | string[];
    key?: string;
    width?: string | number;
    ellipsis?: boolean;
    defaultSortOrder?: 'ascend' | 'descend';

    minWidth?: string | number;
    maxWidth?: string | number;
    className?: ClassValue;
    headerClassName?: ClassValue;
    render?: (data: any, item: T, index?: number, search?: string | null) => ReactNode;
    onTitleCellClick?: (item: T, event: MouseEvent) => void;
}

interface ColumnOrder {
    columnKey: string;
    columnDirection: 'ascending' | 'descending';
}

interface ComputedWidth<T> {
    column: ArgTable2Column<T>;
    initialWidth?: number;
    minWidth?: number;
    maxWidth?: number;

    computedWidth?: number;
}


export interface ArgTable2Props<T> {
    className?: ClassValue;
    columns: ArgTable2Column<T>[];
    dataSource: T[];
    rowKey?: keyof T | string | ((item: T) => string);
    rowHeight?: number;
    showNbRows?: number;
    hidden?: boolean;
    search?: string;
    getItemLabel?: ArgGetItemLabel<T>;
    scrollableBodyRef?: MutableRefObject<HTMLElement | null>;
    scrollableBodyClassName?: ClassValue;
    scrollableBodyStyle?: CSSProperties;
    headerClassName?: ClassValue;
    getRowBody?: (item: T) => ReactNode;
    selectionProvider?: SelectionProvider<T>;
    selectionSource?: SelectionSource;
    onSelectionChange?: (row: T, newState: boolean, selectionProvider: SelectionProvider<T>) => void;
    onRowDoubleClick?: (item: T, event: React.MouseEvent<any>) => void;
    onRowClick?: (item: T, event: React.MouseEvent<any>) => void;
    rowDraggableAction?: (item: T) => DraggableAction[] | DraggableAction | undefined;
    highlightedRowCondition?: (item: T) => boolean;
    getRowClassName?: ArgGetItemClassName<T>;
    preventHighlightedRowScroll?: boolean;
    bordered?: boolean;
    onSortColumnChange?: (columnKey?: string, direction?: 'ascend' | 'descend') => void;
    areRowsSelectable?: boolean;
    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;

    minimizedNbRows?: number;
    columnsOrder?: ColumnOrder;
    columnsToSort?: string[];
}

export function ArgTable2<T>(props: ArgTable2Props<T>) {
    const {
        className,
        columns: userColumns,
        dataSource,
        rowKey,
        hidden,
        search,
        rowHeight,
        showNbRows,
        selectionProvider,
        selectionSource,
        onSelectionChange,
        scrollableBodyClassName,
        scrollableBodyStyle,
        headerClassName,
        getRowClassName,
        getRowBody,
        onRowClick,
        onRowDoubleClick,
        highlightedRowCondition,
        preventHighlightedRowScroll,
        bordered = false,
        getItemLabel,
        rowDraggableAction,
        onSortColumnChange,
        areRowsSelectable = false,
        getPopoverContainer,
        minimizedNbRows,
        scrollableBodyRef,
        columnsOrder: externalColumnsOrder,
        columnsToSort,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('arg-table2');

    useSelection(selectionProvider);

    const tableRef = useRef<HTMLDivElement>(null);
    const titleCellContainerRef = useRef<HTMLDivElement>(null);
    const rowsContainerRef = useRef<HTMLTableSectionElement>(null);
    const highlightedRowRef = useRef<HTMLTableRowElement>(null);
    const bodyTableRef = useRef<HTMLDivElement | null>(null);

    const [tableWidth, setTableWidth] = useState<number>();

    const defaultColumnOrder: ColumnOrder | undefined = useMemo(() => {
        const column = find(userColumns, (column) => !isNil(column.defaultSortOrder) && !isNil(column.key));

        if (column && column.key) {
            return ({
                columnKey: column.key,
                columnDirection: column.defaultSortOrder === 'ascend' ? 'ascending' : 'descending',
            });
        }
    }, [userColumns]);

    const computedScrollableBodyStyle = useMemo<CSSProperties | undefined>(() => {
        if (rowHeight && showNbRows) {
            return {
                maxHeight: rowHeight * showNbRows,
                overflowY: 'auto',
                ...scrollableBodyStyle,
            };
        }

        return scrollableBodyStyle;
    }, [rowHeight, scrollableBodyStyle, showNbRows]);

    const shouldUseInternalColumnsOrder = !('externalColumnsOrder' in props);
    const [columnsOrder, setColumnsOrder] = useState<ColumnOrder | undefined>(defaultColumnOrder);
    const _columnsOrder = shouldUseInternalColumnsOrder ? columnsOrder : externalColumnsOrder;

    const resizerDebounce = useRef<DebouncedFunc<any>>();

    useEffect(() => {
        return () => {
            if (resizerDebounce.current) {
                resizerDebounce.current.cancel();
            }
        };
    }, []);

    useResizeObserver(tableRef, (entry) => {
        if (resizerDebounce.current) {
            resizerDebounce.current.cancel();
        }
        const domElement = entry.target as HTMLElement;
        resizerDebounce.current = debounce(() => {
            let totalWidth = computeHTMLElementWidth(domElement);

            if (bodyTableRef.current) {
                const margins = computeRightPaddingAndMargin(bodyTableRef.current);

                totalWidth -= margins;
            }

            setTableWidth(totalWidth);
        }, RESIZE_DEBOUNCE_MS, { maxWait: RESIZE_DEBOUNCE_MS });
        resizerDebounce.current();
    });

    const handleColumnSortClick = useCallback((column: ArgTable2Column<T>) => {
        const { newDefaultSortOrder, newColumnOrder } = getNewColumnOrder(column, _columnsOrder);

        if (shouldUseInternalColumnsOrder) {
            setColumnsOrder(newColumnOrder);
            onSortColumnChange?.(newColumnOrder?.columnKey, newDefaultSortOrder);

            return;
        }
        onSortColumnChange?.(newColumnOrder?.columnKey, newDefaultSortOrder);
    }, [_columnsOrder, shouldUseInternalColumnsOrder, onSortColumnChange]);

    const sortClassName = classNames('&-header-cell-button-sort');

    const getCellValue = useCallback((column: ArgTable2Column<T>, row: T, rowIndex: number, search?: string) => {
        return getTableCellValue(column, row, rowIndex, intl, search);
    }, [intl]);

    const rowsData = useMemo(() => {
        let filteredDataSource = dataSource;

        // Search filter the rows with search token
        if (search) {
            const normalizedSearch = normalizeText(search);

            let internalGetItemLabel = getItemLabel;
            if (!internalGetItemLabel) {
                // Create a getItemLabel for all columns returning a string
                internalGetItemLabel = (item: T) => {
                    const rowIndex = dataSource.indexOf(item);

                    return userColumns.map(column => {
                        const value = column.getCellStringValue?.(item) || getCellValue(column, item, rowIndex, normalizedSearch);

                        if (isString(value)) {
                            return value;
                        }

                        return '';
                    }).join(' ');
                };
            }

            filteredDataSource = filterItems<T>(filteredDataSource, normalizedSearch, internalGetItemLabel, intl);
        }

        if (!_columnsOrder) {
            if (minimizedNbRows) {
                return filteredDataSource.slice(0, minimizedNbRows);
            }

            return filteredDataSource;
        }

        const column = userColumns.find((c) => c.key === _columnsOrder.columnKey);
        if (!column) {
            return filteredDataSource;
        }

        const sorter = column.sorter;
        if (!sorter) {
            return filteredDataSource;
        }

        const rowsData = dataSource === filteredDataSource ? [...dataSource] : filteredDataSource;

        if (_columnsOrder.columnDirection === 'descending') {
            rowsData.sort((t1, t2) => (-sorter(t1, t2)));
        } else {
            rowsData.sort(sorter);
        }

        if (minimizedNbRows) {
            return rowsData.slice(0, minimizedNbRows);
        }

        return rowsData;
    }, [dataSource, search, _columnsOrder, userColumns, getItemLabel, intl, getCellValue, minimizedNbRows]);

    const handleRowClicks = useCallback((event: React.MouseEvent<any>) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();
        //event.stopPropagation();

        let dataRowKey: string | null = null;
        for (let element = event.target as HTMLElement | null; element; element = element.parentElement) {
            dataRowKey = element.getAttribute('data-rowkey');
            if (dataRowKey) {
                break;
            }
        }

        if (!dataRowKey) {
            console.log('Can not get rowKey from', event.target);

            return;
        }

        let row: T | undefined;
        if (rowKey) {
            row = rowsData.find((row) => {
                if (isFunction(rowKey)) {
                    return dataRowKey === rowKey(row);
                }

                const key = get(row, rowKey);

                return key === dataRowKey;
            });
        } else {
            const rowIndex = parseInt(dataRowKey);
            if (isNaN(rowIndex)) {
                console.error('DataRowKey is not a number', dataRowKey);

                return;
            }

            row = rowsData[rowIndex];
        }
        if (!row) {
            console.error('Can not find row', dataRowKey);

            return;
        }

        return row;
    }, [rowKey, rowsData]);

    const handleRowSelection = useCallback((event: React.MouseEvent<any>) => {
        if (!areRowsSelectable || !selectionProvider) {
            return;
        }
        const row = handleRowClicks(event);

        if (!row) {
            return;
        }

        if (selectionProvider.has(row)) {
            selectionProvider.remove(row, 'selection-cell');

            return;
        }

        selectionProvider?.add(row, 'selection-cell');
    }, [handleRowClicks, areRowsSelectable, selectionProvider]);

    const handleRowClick = useCallback((event: React.MouseEvent<any>) => {
        if (!onRowClick) {
            return;
        }
        const row = handleRowClicks(event);

        if (!row) {
            return;
        }

        onRowClick(row, event);
    }, [onRowClick, handleRowClicks]);

    const handleRowDoubleClick = useCallback((event: React.MouseEvent<any>) => {
        if (!onRowDoubleClick) {
            return;
        }
        const row = handleRowClicks(event);

        if (!row) {
            return;
        }

        onRowDoubleClick(row, event);
    }, [handleRowClicks, onRowDoubleClick]);

    const handleTitleCellClick = useCallback((item: T, column: ArgTable2Column<T>, event: MouseEvent) => {
        event.preventDefault();

        column.onTitleCellClick?.(item, event);
    }, []);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (typeof (getPopoverContainer) === 'function') {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (tableRef.current) {
            return findOrCreatePopupArea(tableRef.current) || tableRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);

    const handleMultipleSelection = useCallback((allSelection: boolean | string) => {
        if (!selectionSource) {
            return;
        }

        if (allSelection === true) {
            selectionProvider?.set(rowsData, selectionSource);

            return;
        }

        selectionProvider?.clear(selectionSource);
    }, [rowsData, selectionProvider, selectionSource]);

    const allSelectionValue = useMemo(() => {
        if (!selectionProvider) {
            return false;
        }

        const areAllRowsSelected = dataSource.length === selectionProvider.count;

        return areAllRowsSelected ? true : selectionProvider.count > 0 ? 'minus' : false;
    }, [dataSource, selectionProvider?.count]);

    const columns: ArgTable2Column<T>[] = useMemo(() => {
        if (!selectionProvider) {
            return userColumns;
        }

        const selectionColumn: ArgTable2Column<T> = {
            key: SELECTION_COLUMN,
            title: (
                <ArgCheckboxMinus
                    size='node'
                    value={allSelectionValue}
                    onChange={handleMultipleSelection}
                />
            ),
            dataIndex: 'selection-column',
            width: 30,
            className: 'selection-column',
            render: function checkRender(_: any, row: T, rowIndex?: number) {
                return <SelectionCell<T>
                    row={row}
                    key={rowIndex}
                    disabled={false}
                    selectionProvider={selectionProvider}
                    onSelectionChange={onSelectionChange}
                />;
            },
        };

        return [selectionColumn, ...userColumns];
    }, [selectionProvider, allSelectionValue, handleMultipleSelection, userColumns, onSelectionChange]);

    const columnsComponent = columns.map((column) => {
        let title;

        let titleLabel: ReactNode = null;
        const columnTitle = column.title;
        if (isFunction(columnTitle)) {
            titleLabel = columnTitle(column);
        } else {
            titleLabel = renderText(columnTitle);
        }
        const titleTooltip: ReactNode | MessageDescriptor = ((column.titleTooltip) === true) ? titleLabel : column.titleTooltip;

        if (column.sorter || (column.key && columnsToSort?.includes(column.key))) {
            let sortIcon = 'icon-updown';

            if (_columnsOrder?.columnKey === column.key) {
                switch (columnsOrder?.columnDirection) {
                    case 'ascending':
                        //titleTooltip = titleTooltip ?? messages.sortDescending;
                        sortIcon = 'icon-arrow-up';
                        break;
                    case 'descending':
                        //titleTooltip = titleTooltip ?? messages.noSort;
                        sortIcon = 'icon-arrow-down';
                        break;
                }
            }
            //titleTooltip = titleTooltip ?? messages.sortAscending;

            title = <ArgButton
                type='ghost'
                size='small'
                right={<ArgIcon name={sortIcon} className={sortClassName}/>}
                label={titleLabel}
                onClick={() => handleColumnSortClick(column)}
                className={classNames('&-header-cell-button')}
                tooltip={titleTooltip}
                tooltipPlacement='bottomLeft'
            />;
        } else {
            title = (
                <div className={classNames('&-header-cell-title')}>
                    {titleLabel}
                </div>
            );
            if (column.titleTooltip) {
                const _title = (column.titleTooltip) === true ? title : renderText(column.titleTooltip);
                title = <Tooltip title={_title}
                                 placement='bottomLeft'
                                 getPopupContainer={computePopoverContainer}
                >
                    {title}
                </Tooltip>;
            }
        }

        return <div key={column.key} className={classNames('&-header-cell', column.headerClassName)}>
            {title}
        </div>;
    });

    const rowsComponent = rowsData.map((row, rowIndex) => {
        const isHighlighted = highlightedRowCondition ? highlightedRowCondition(row) : selectionProvider?.has(row) || false;
        const rowCls = computeItemClassName(row, getRowClassName);

        const cls = {
            'highlighted': isHighlighted,
            'single-selection': className?.toString()?.includes('single-selection'),
        };

        const bodyRowClassName = classNames('&-body-row', cls, rowCls);
        const bodyCellClassName = classNames('&-body-cell');
        const bodyTitleCellClickClassName = classNames('&-body-cell-clickable');
        const bodyTitleCellEllipsisClassName = classNames('&-body-cell-ellipsis');

        let trKey = rowIndex;
        if (rowKey) {
            const key = isFunction(rowKey)
                ? rowKey(row)
                : get(row, rowKey);

            if (key !== undefined && key !== null) {
                trKey = key;
            }
        }

        const columnsComponent = columns.map((column, columnIndex) => {
            const cellValue = getCellValue(column, row, rowIndex, search);

            let cellContent = (search && isString(cellValue))
                ? highlightSplit(cellValue, search)
                : cellValue
            ;

            const cls: ClassMapping = {};
            let cellStyle: CSSProperties | undefined = undefined;

            if (column.ellipsis) {
                cellContent = <div className={bodyTitleCellEllipsisClassName}>
                    {cellContent}
                </div>;
            }

            if (column.onTitleCellClick) {
                cls.titleCellClick = true;
                cellContent = <button
                    type='button'
                    onClick={(event) => handleTitleCellClick(row, column, event)}
                    className={bodyTitleCellClickClassName}
                >
                    {cellContent}
                </button>;
            }

            if (column.maxWidth) {
                if (!cellStyle) {
                    cellStyle = {};
                }

                cellStyle.maxWidth = column.maxWidth;
            }

            return <td
                key={column.key || columnIndex}
                style={cellStyle}
                className={classNames(bodyCellClassName, column.className, cls)}
            >
                {cellContent}
            </td>;
        });

        const trClassName = bodyRowClassName;

        const trProps: any = {
            className: trClassName,
        };

        if (areRowsSelectable && !onRowClick) {
            trProps.onClick = handleRowSelection;
        }

        if (onRowClick) {
            trProps.onClick = handleRowClick;
        }

        if (onRowDoubleClick) {
            trProps.onDoubleClick = handleRowDoubleClick;
        }

        const dragActions = rowDraggableAction?.(row);

        let tr: ReactNode = <Draggable actions={dragActions} key={`d${trKey}`}>
            {(provided) => (
                <tr ref={isHighlighted ? highlightedRowRef : null}
                    key={trKey}
                    data-rowkey={trKey}
                    {...provided.dragHandleProps}
                    {...trProps}>
                    {columnsComponent}
                </tr>
            )}
        </Draggable>;

        if (getRowBody) {
            const body = getRowBody(row);
            if (body) {
                tr = [tr,
                    <tr key={`${trKey}-body`} className={`${trClassName}-body`}>
                        <td className={`${bodyCellClassName}-body`} colSpan={columns.length}>{body}</td>
                    </tr>,
                ];
            }
        }

        return tr;
    });

    useLayoutEffect(() => {
        const title = titleCellContainerRef.current;
        const rows = rowsContainerRef.current;

        const firstRow = rows?.firstElementChild;

        if (!title || !rows || !firstRow) {
            return;
        }

        const totalWidth = computeHTMLElementWidth(title);
        const widths = computeColumnSizes(columns, totalWidth);

        // console.log('UpdateColumns=', totalWidth, columns, widths);

        const titleCells = title.children;
        const rowCells = firstRow.children;

        widths.forEach((cw, index) => {
            if (titleCells?.[index] && rowCells?.[index]) {
                (titleCells[index] as unknown as ElementCSSInlineStyle).style.width = `${cw.computedWidth}px`;
                (rowCells[index] as unknown as ElementCSSInlineStyle).style.width = `${cw.computedWidth}px`;
                (rowCells[index] as unknown as ElementCSSInlineStyle).style.maxWidth = `${cw.maxWidth || cw.computedWidth}px`;
            }
        });
    });

    useLayoutEffect(() => {
        if (!highlightedRowRef.current || !!preventHighlightedRowScroll) {
            return;
        }

        highlightedRowRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [highlightedRowCondition, preventHighlightedRowScroll]);

    const cls = {
        'row-clickable': onRowDoubleClick || onRowClick || areRowsSelectable,
        'bordered': bordered,
    };

    const style: CSSProperties = useMemo<CSSProperties>(() => {
        const style: any = {};

        if (rowHeight) {
            style['--arg-table-row-height'] = `${rowHeight}px`;
        }

        return style as CSSProperties;
    }, [rowHeight]);

    const tableStyle: CSSProperties = {
        width: `${tableWidth}px`,
        maxWidth: `${tableWidth}px`,
    };

    if (hidden) {
        return null;
    }

    return <div ref={tableRef} className={classNames('&', className, cls)} style={style}>
        <div ref={titleCellContainerRef} className={classNames('&-header', headerClassName, 'noselect')}>
            {columnsComponent}
        </div>
        <div
            className={classNames('&-body', scrollableBodyClassName)}
            style={computedScrollableBodyStyle}
            ref={(ref) => {
                bodyTableRef.current = ref;
                if (scrollableBodyRef) {
                    scrollableBodyRef.current = ref;
                }
            }}
        >
            {isEmpty(rowsComponent)
                ? (
                    <div className={classNames('&-body-empty')}>
                        {/* No results */}
                        {search && <span>{intl.formatMessage(messages.noResults)}</span>}

                        {/* No data */}
                        {!search && <span>{intl.formatMessage(messages.noData)}</span>}
                    </div>
                ) : (
                    <table className={classNames('&-body-table')} style={tableStyle}>
                        <tbody ref={rowsContainerRef}>
                            {rowsComponent}
                        </tbody>
                    </table>
                )}
        </div>
    </div>;
}

function computeColumnSizes<T>(columns: ArgTable2Column<T>[], totalWidth: number) {
    function computeInPx(width: string | number | undefined): number | undefined {
        if (typeof (width) === 'number') {
            return width;
        }
        if (typeof (width) === 'string') {
            const swidth = width as string;
            const exp = /^([0-9.]+)%$/.exec(swidth);
            if (exp) {
                const w = totalWidth / 100 * parseFloat(exp[1]);

                return w;
            }
            const exp2 = /^([0-9.]+)px$/.exec(swidth);
            if (exp2) {
                const w = parseFloat(exp2[1]);

                return w;
            }
        }

        return undefined;
    }

    const computedWidths: ComputedWidth<T>[] = columns.map((column) => {
        const width = column.width;
        const minWidth = computeInPx(column.minWidth);
        const maxWidth = computeInPx(column.maxWidth);
        const initialWidth = computeInPx(width);
        let computedWidth = initialWidth;

        if (!computedWidth) {
            computedWidth = computeInPx(`${100 / columns.length}%`);
        }

        return {
            column,
            initialWidth,
            minWidth,
            maxWidth,
            computedWidth,
        };
    });
    //console.log('ComputedWidths=', computedWidths);

    const totalComputedMin = computedWidths.reduce((acc: number, column) => {
        return acc + (column.computedWidth || 0);
    }, 0);

    //console.log('TotalComputedMin=', totalComputedMin);

    let left = totalWidth - totalComputedMin;
    let columnLeft: ComputedWidth<T>[] = [];

    computedWidths.forEach((column) => {
        if (column.initialWidth) {
            column.computedWidth = column.initialWidth;

            return;
        }
        columnLeft.push(column);
    });

    if (!columnLeft.length) {
        if (left < 0) {
            console.error('*** CAUTION the width is too big, verify the width of each cell !');
        }

        return computedWidths;
    }

    let it = 0;
    for (; Math.abs(left) >= 1 && it < 10; it++) {
        const left2 = left / columnLeft.length;
        const columnLeft2: ComputedWidth<T>[] = [];

        columnLeft.forEach((column) => {
            if (column.computedWidth) {
                let w = column.computedWidth + left2;
                if (column.minWidth && w < column.minWidth) {
                    w = column.minWidth;
                } else if (column.maxWidth && w > column.maxWidth) {
                    w = column.maxWidth;
                } else {
                    columnLeft2.push(column);
                }

                left -= w - column.computedWidth;
                column.computedWidth = w;
            }
        });

        if (!columnLeft2.length) {
            break;
        }

        columnLeft = columnLeft2;
    }

    return computedWidths;
}

function computeRightPaddingAndMargin(dom: HTMLElement): number {
    let width = 0;

    const titleStyle = window.getComputedStyle(dom);
    const paddingRight = titleStyle.getPropertyValue('padding-right');
    if (paddingRight) {
        width += parseFloat(paddingRight);
    }

    return width;
}

function computeHTMLElementWidth(dom: HTMLElement): number {
    const titleClientRect = dom.getBoundingClientRect();
    let totalWidth = titleClientRect.width;

    const margins = computeRightPaddingAndMargin(dom);
    totalWidth -= margins;

    return totalWidth;
}

function getNewColumnOrder<T>(column: ArgTable2Column<T>, columnsOrder?: ColumnOrder) {
    let newDefaultSortOrder: ArgTable2Column<T>['defaultSortOrder'] = undefined;
    let newColumnOrder: ColumnOrder | undefined;
    if (column.key && columnsOrder?.columnKey !== column.key) {
        newDefaultSortOrder = 'ascend';
        newColumnOrder = {
            columnKey: column.key,
            columnDirection: 'ascending',
        };
    } else if (columnsOrder?.columnDirection === 'descending') {
        newDefaultSortOrder = undefined;
        newColumnOrder = undefined;
    } else if (columnsOrder?.columnKey) {
        newDefaultSortOrder = 'descend';
        newColumnOrder = {
            ...columnsOrder,
            columnDirection: 'descending',
        };
    }

    return {
        newDefaultSortOrder,
        newColumnOrder,
    };
}
