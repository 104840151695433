import React, { JSXElementConstructor, MouseEvent, RefObject, useCallback, useRef } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement, TooltipPropsWithOverlay, TooltipPropsWithTitle } from 'antd/lib/tooltip';

import { DEFAULT_TOOLTIP_DELAY, DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { findOrCreatePopupArea } from '../utils';
import { ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { useIsDragging } from '../utils/use-is-dragging';

import './arg-tooltip.less';

export interface ArgTooltipProps extends Omit<TooltipPropsWithOverlay, 'className' | 'overlay' | 'title'>, Omit<TooltipPropsWithTitle, 'className' | 'title'> {
    className?: ClassValue;
    title: ArgRenderedText;
    placement?: TooltipPlacement;
    tagName?: string | JSXElementConstructor<any>;
    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
    showEvenInDraggingMode?: boolean;
    onDoubleClick?: (event: MouseEvent) => void;
}

export function ArgTooltip(props: ArgTooltipProps) {
    const {
        tagName = 'div',
        children,
        className,
        title,
        placement = DEFAULT_TOOLTIP_PLACEMENT,
        getPopoverContainer,
        mouseEnterDelay,
        showEvenInDraggingMode,
        onDoubleClick,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-tooltip');
    const isDragging = useIsDragging();

    const htmlRef = useRef<HTMLElement>(null);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (typeof (getPopoverContainer) === 'function') {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (htmlRef.current) {
            return findOrCreatePopupArea(htmlRef.current) || htmlRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);

    const TagName = tagName;

    return (
        <Tooltip
            className={classNames('&', className)}
            overlayClassName={classNames('&-overlay')}
            open={isDragging && !showEvenInDraggingMode ? false : undefined}
            mouseEnterDelay={mouseEnterDelay ?? DEFAULT_TOOLTIP_DELAY}
            title={renderText(title)}
            placement={placement}
            getPopupContainer={computePopoverContainer}
            {...otherProps}
        >
            <TagName
                ref={htmlRef}
                onDoubleClick={onDoubleClick}
            >
                {children}
            </TagName>
        </Tooltip>
    );
}
