import { DependencyList, useCallback, useMemo, useRef } from 'react';

function useDebugCallback__Production<T extends (...args: any[]) => any>(callback: T, deps: DependencyList, name: string): T {
    return useCallback(callback, deps);
}

function useDebugCallback__Debug<T extends (...args: any[]) => any>(callback: T, deps: DependencyList, name: string): T {
    const prev = useRef<DependencyList>();

    const ret: T = useMemo(() => {
        const p = prev.current;
        if (p) {
            console.log('UseDebugCallback', name);
            for (let i = 0; i < deps.length; i++) {
                if (p[i] !== deps[i]) {
                    console.log('UseDebugCallback', name, '  #', i, ' different', 'newValue=', deps[i], 'oldValue=', p[i]);
                }
            }
        }

        prev.current = deps;

        return callback;
    }, deps);

    return ret;
}

export const useDebugCallback = (process.env.NODE_ENV !== 'production') ? useDebugCallback__Debug : useDebugCallback__Production;
