import computeArea from '@turf/area';
import { useMapEvent } from 'react-leaflet';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import L, { DrawEvents, LatLng, LeafletEvent, LeafletMouseEvent } from 'leaflet';
import { defineMessages } from 'react-intl';

// Components and hooks
import { ClassValue, useClassNames } from '../../../../../arg-hooks/use-classNames';
import { MapMode } from '../../../../model/geolocation-value';
import { ArgButton } from '../../../../../arg-button/arg-button';

// Styles
import './arg-map-toolbar.less';

const messages = defineMessages({
    polygonTooltip: {
        id: 'basic.arg-map-toolbar.PolygonTooltip',
        defaultMessage: 'Polygon',
    },
});

interface ArgMapToolbarProps {
    className?: ClassValue;
    mapMode?: MapMode;
    onChange: (mapMode?: MapMode) => void;
    startDrawingPolygonHandler: () => void;
    onPolygonDrawFinishHandler: (
        area: number,
        polygon: LatLng[]
    ) => void;
}

export const ArgMapToolbar: React.FunctionComponent<ArgMapToolbarProps> = (props) => {
    const classNames = useClassNames('arg-map-toolbar');

    const {
        className,
        onChange,
        onPolygonDrawFinishHandler,
        startDrawingPolygonHandler,
        mapMode = MapMode.CenterArea,
    } = props;

    const [mapModeInternal, setMapMode] = useState<MapMode>(MapMode.CenterArea);
    const useInternalMapMode = !('mapMode' in props);

    const handleChange = useCallback((mapMode: MapMode) => {
        if (useInternalMapMode) {
            setMapMode(mapMode);
        }
        onChange(mapMode);
    },
    [onChange, useInternalMapMode]);

    const polygonLeafletGuideRef = useRef<L.Draw.Polygon>();

    const mapControl = useRef(undefined as L.Map | undefined);
    const mapControlContainer = useRef<HTMLDivElement>(null);

    // Handle the lasso mode drawing start event
    const isStartedDrawing = useRef(false);
    const map = useMapEvent('mousedown', (event: LeafletMouseEvent) => { //detects map mousedown (clicks on the map controls are excluded)
        if (mapMode === MapMode.Polygon && isStartedDrawing.current === false) {
            isStartedDrawing.current = true;
            startDrawingPolygonHandler();
        }
    });

    const handlePolygonClick = () => {
        handleChange(MapMode.Polygon);
    };

    const handleHandClick = () => {
        handleChange(MapMode.CenterArea);
    };

    useEffect(() => {
        mapControl.current = map;

        if (!polygonLeafletGuideRef.current) {
            const polygonIcon = L.divIcon({
                className: 'arg-map__polygon-marker',
                iconSize: [10, 10],
                html: '<i></i>',
            });
            polygonLeafletGuideRef.current = new L.Draw.Polygon(map as L.DrawMap, {
                shapeOptions: {
                    color: '#2873d6',
                    fillColor: '#2873d6',
                    weight: 2,
                    opacity: 1,
                    interactive: false,
                },
                icon: polygonIcon,
                allowIntersection: false,
                showArea: true,
                repeatMode: false,
            });
        }

        if (mapMode === MapMode.Polygon) {
            polygonLeafletGuideRef.current?.enable();
        } else {
            polygonLeafletGuideRef.current?.disable();
        }
    }, [mapMode, map]);

    useEffect(() => {
        const polygonFinishedHandler = (_event: LeafletEvent) => {
            const event = _event as DrawEvents.Created;
            const polygon = (event.layer as L.Polygon);
            const area = computeArea(polygon.toGeoJSON());

            let latLngs = polygon.getLatLngs();
            // in case latLngs returns an array of polygons, we just pick the first one
            if (Array.isArray(latLngs) && latLngs.length && Array.isArray(latLngs[0]) && latLngs[0].length && typeof latLngs[0][0] === 'object' && latLngs[0][0].hasOwnProperty('lat')) {
                latLngs = latLngs[0];
            }

            onPolygonDrawFinishHandler(area, latLngs as LatLng[]);

            setMapMode(MapMode.CenterArea);
        };

        map.on(L.Draw.Event.CREATED, polygonFinishedHandler);

        return () => {
            map.off(L.Draw.Event.CREATED, polygonFinishedHandler);
        };
    }, [map, onChange, onPolygonDrawFinishHandler]);

    useEffect(() => {
        onChange(mapModeInternal);
    }, [mapModeInternal]);

    return (
        <div
            ref={mapControlContainer}
            className={classNames('&', className)}
        >
            <ArgButton
                icon='icon-polygon'
                type='ghost'
                className={classNames('&-item', '&-item-polygon', {
                    '&-item-active': mapMode === MapMode.Polygon,
                })}
                tooltip={messages.polygonTooltip}
                tooltipPlacement='left'
                data-testid='cursor-mode'
                onClick={handlePolygonClick}
            />

            <ArgButton
                type='ghost'
                icon='icon-hand'
                className={classNames('&-item', '&-item-hand', {
                    '&-item-active': mapMode === MapMode.CenterArea,
                })}
                data-testid='hand-mode'
                onClick={handleHandClick}
            />
        </div>

    );
};
