import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { isElement } from 'react-is';
import { MenuInfo } from 'rc-menu/lib/interface';
import { find, includes, isFunction, isObject, isString, keys, omitBy } from 'lodash';
import { defineMessages, FormattedMessage, FormattedNumber, MessageDescriptor, useIntl } from 'react-intl';
import Debug from 'debug';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import {
    ArgGetItemCheckedState,
    ArgGetItemClassName,
    ArgGetItemCount,
    ArgGetItemDisabled,
    ArgGetItemExpandState,
    ArgGetItemIcon,
    ArgGetItemKey,
    ArgGetItemLabel,
    ArgGetItemTooltip,
    computeItemCheckedState,
    computeItemClassName,
    computeItemCount,
    computeItemDisabled,
    computeItemExpandState,
    computeItemIcon,
    computeItemKey,
    computeItemLabel,
    filterItems,
    isNullItemKey,
} from '../utils';
import { ArgIconCheckbox, ArgIconCheckboxStates } from '../arg-checkbox/arg-icon-checkbox';
import { ArgChangeReason, ArgMessageValues, ArgPlaceholderText, ArgRenderFunction } from '../types';
import { ArgMenu } from './arg-menu';
import { ArgInputSearch } from '../arg-input/arg-input-search';
import { renderIcon } from '../arg-icon/arg-icon';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { computeText, getMessageValuesWithFormatters, renderText } from '../utils/message-descriptor-formatters';
import { ArgMenuItem } from './arg-menu-item';
import { ArgTooltip } from '../arg-tooltip/arg-tooltip';

import './arg-filtered-menu.less';

const debug = Debug('argonode:basic:arg-menu:ArgFilteredMenu');

const DEFAULT_CHILD_ITEM_PADDING_LEFT = 36;
const CHILD_ITEM_PADDING_LEFT = `var(--arg-expand-item-padding, ${DEFAULT_CHILD_ITEM_PADDING_LEFT}px)`;

let filteredMenuInstance = 1;

const CREATE_ITEM_KEY = 'create';

const messages = defineMessages({
    loading: {
        id: 'basic.arg-filtered-menu.Loading',
        defaultMessage: 'Loading {threeDotsLoading}',
    },
    empty: {
        id: 'basic.arg-filtered-menu.NoResults',
        defaultMessage: 'No Results',
    },
    placeholder: {
        id: 'basic.arg-filtered-menu.SearchPlaceholder',
        defaultMessage: 'Search',
    },
});

interface ArgFilteredMenuProps<T> {
    className?: ClassValue;
    menuClassName?: ClassValue;
    itemClassName?: ClassValue;
    items: T[];
    canCreate?: boolean;
    createLabel?: string | MessageDescriptor;
    onCreate?: () => void;
    autoScroll?: boolean;
    selected?: T | T[];
    onSelect?: (item: T, reason: ArgChangeReason, event?: ButtonClickEvent) => void;
    enableFilter?: boolean;
    getItemLabel?: ArgGetItemLabel<T>;
    getItemIcon?: ArgGetItemIcon<T>;
    getItemKey?: ArgGetItemKey<T>;
    getItemTooltip?: ArgGetItemTooltip<T>;
    getItemCount?: ArgGetItemCount<T>;
    getItemClassName?: ArgGetItemClassName<T>;
    getItemDisabled?: ArgGetItemDisabled<T>;
    getItemCheckedState?: ArgGetItemCheckedState<T>;
    getItemExpandState?: ArgGetItemExpandState<T>;
    inputPlaceHolder?: ArgPlaceholderText;
    noResultMessage?: MessageDescriptor | ReactNode;
    messageValues?: ArgMessageValues;
    renderItem?: (item: T, searchedToken?: string) => ReactNode;
    searchedToken?: string;
    filterSearchedToken?: boolean;
    topRender?: ArgRenderFunction;
    bottomRender?: ArgRenderFunction;
    autoFocus?: boolean;
    showCheckbox?: boolean;
    progressMonitor?: ProgressMonitor;
    empty?: boolean;
    onSearchInputChange?: (value?: string) => void;
    onItemExpand?: (item: T, isExpanded: boolean, event: ButtonClickEvent) => void;

    // handler for when a checkbox is checked. If undefined, checking a checkbox will trigger onSelect.
    onItemCheck?: (item: T, isCheck: boolean, event: ButtonClickEvent) => void;
}

export function ArgFilteredMenu<T>(props: ArgFilteredMenuProps<T>) {
    const {
        className,
        menuClassName,
        itemClassName,
        onSelect,
        autoScroll,
        enableFilter,
        getItemLabel,
        getItemIcon,
        items,
        selected,
        getItemCount,
        getItemDisabled,
        renderItem,
        noResultMessage,
        getItemKey,
        inputPlaceHolder,
        messageValues,
        searchedToken,
        filterSearchedToken = true,
        topRender,
        bottomRender,
        autoFocus = true,
        progressMonitor,
        getItemClassName,
        getItemTooltip,
        canCreate,
        createLabel,
        onCreate,
        onSearchInputChange,
        onItemExpand,
        onItemCheck,
        getItemCheckedState,
        getItemExpandState,
    } = props;

    const classNames = useClassNames('arg-filtered-menu');
    const intl = useIntl();
    const showCheckbox = props.showCheckbox || !!getItemCheckedState;
    const [internalSearchToken, setInternalSearchToken] = useState<string>('');

    const [menuId] = useState<string>(() => `filtered-menu-${filteredMenuInstance++}`);

    let _searchedToken = searchedToken;
    if (_searchedToken === undefined) {
        _searchedToken = internalSearchToken || '';
    }

    const filteredItems = useMemo(() => {
        if (!_searchedToken || filterSearchedToken === false) {
            return items;
        }

        const _messageValues = getMessageValuesWithFormatters(messageValues);

        const filteredItems = filterItems<T>(items, _searchedToken, getItemLabel, intl, _messageValues);

        return filteredItems;
    }, [_searchedToken, filterSearchedToken, getItemLabel, intl, items, messageValues]);

    const handleMenuClick = useCallback((menuInfo: MenuInfo) => {
        debug('handleMenuClick', 'eventType=', menuInfo.domEvent.type, 'prevented=', menuInfo.domEvent.defaultPrevented);

        if (menuInfo.key === CREATE_ITEM_KEY) {
            onCreate && onCreate();

            return;
        }

        if (onSelect) {
            const item = filteredItems.find((item) => computeItemKey(item, getItemKey) === menuInfo.key);
            if (item === undefined) {
                console.error('Can not find the item ???', menuInfo);

                return;
            }

            onSelect(item, 'selection', menuInfo.domEvent);
        }
    }, [onSelect, onCreate, filteredItems, getItemKey]);

    const handleInputChange = useCallback((newValue: string | null) => {
        setInternalSearchToken(newValue || '');
        onSearchInputChange?.(newValue || undefined);
    }, [onSearchInputChange]);

    const handleChange = useCallback((newValue: string | null, reason: ArgChangeReason) => {
        if (reason === 'unmount' || reason === 'blur') {
            // We are into a popover, so if this component is unmounted, ignore process
            return;
        }

        if (onSelect) {
            if (!newValue) {
                return;
            }

            const filteredItems = filterItems<T>(items, newValue, getItemLabel, intl, messageValues);

            if (filteredItems.length !== 1) {
                return;
            }
            onSelect(filteredItems[0], reason);
        }
    }, [onSelect, getItemLabel, intl, items, messageValues]);

    const _messageValues = getMessageValuesWithFormatters(messageValues);

    let searchComponent;
    if (enableFilter) {
        const _placeholder: string | undefined = computeText(intl, inputPlaceHolder || messages.placeholder, messageValues);

        searchComponent = (<div className={classNames('&-search')}>
            <ArgInputSearch
                className={classNames('&-search-input')}
                data-testid='arg-filtered-menu-search-input'
                autoFocus={autoFocus}
                clearable={true}
                size='medium'
                initialValue={searchedToken}
                onInputChange={handleInputChange}
                onChange={handleChange}
                debounce={false}
                placeholder={_placeholder}
            />
        </div>);
    }

    let _noResultMessage;
    if (!filteredItems.length) {
        _noResultMessage = renderText(noResultMessage || messages.empty, _messageValues);
    }

    const handleExpand = useCallback((item: T, event: ButtonClickEvent) => {
        debug('handleExpand', 'eventType=', event.type, 'prevented=', event.defaultPrevented);
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        const expandState = computeItemExpandState(item, getItemExpandState);

        onItemExpand?.(item, !expandState?.isExpanded, event);
    }, [getItemExpandState, onItemExpand]);

    const handleCheck = useCallback((item: T, event: ButtonClickEvent) => {
        debug('handleCheck', 'eventType=', event.type, 'prevented=', event.defaultPrevented);
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        const checkedState = computeItemCheckedState(item, getItemCheckedState);
        if (onItemCheck) {
            onItemCheck?.(item, checkedState !== true, event);
        } else {
            onSelect?.(item, 'selection', event);
        }
    }, [getItemCheckedState, onItemCheck, onSelect]);

    const selectedItemKeys = useMemo((): { [key: string]: ArgIconCheckboxStates } => {
        const selectedItemKeys: { [key: string]: ArgIconCheckboxStates } = {};

        if (getItemCheckedState) {
            items.forEach((item) => {
                const key = computeItemKey(item, getItemKey);
                if (key === undefined) {
                    return;
                }
                selectedItemKeys[key] = computeItemCheckedState(item, getItemCheckedState);
            });

            return selectedItemKeys;
        }

        if (Array.isArray(selected)) {
            selected.forEach((item) => {
                const key = computeItemKey(item, getItemKey);
                if (key === undefined) {
                    return;
                }
                selectedItemKeys[key] = true;
            });

            return selectedItemKeys;
        }

        if (selected !== undefined) {
            const key = computeItemKey(selected, getItemKey);
            if (key !== undefined) {
                selectedItemKeys[key] = true;
            }
        }

        return selectedItemKeys;
    }, [getItemCheckedState, selected, items, getItemKey]);

    useEffect(() => {
        if (!autoScroll) {
            return;
        }

        const menu = document.getElementById(menuId);

        // Find the first active row number
        const selectedItem = find(filteredItems, (item) => (
            includes(keys(omitBy(selectedItemKeys, (selected) => !selected)), computeItemKey(item, getItemKey))
        ));

        if (selectedItem === undefined || !menu) {
            return;
        }

        const selectedItemElement = document.getElementById(`${menuId}-${computeItemKey(selectedItem)}`);

        if (selectedItemElement) {
            // Item offset from top of the menu
            const scrollTop = (selectedItemElement.offsetTop - menu.offsetTop);

            menu.scroll({
                top: scrollTop,
                behavior: 'smooth',
            });
        }
    }, [autoScroll, menuId, selectedItemKeys]);

    if (progressMonitor?.isRunning) {
        return <div className={classNames('&', 'arg-menu-container', className)} data-testid='arg-filtered-menu'>
            <div className={classNames('&-loading-results', 'arg-menu-item', 'disabled')}>
                <FormattedMessage {...messages.loading} values={_messageValues}/>
            </div>
        </div>;
    }

    return <div className={classNames('&', 'arg-menu-container', className)} data-testid='arg-filtered-menu'>
        {topRender && topRender()}
        {searchComponent}
        {_noResultMessage &&
            <div
                className={classNames('&-no-results', 'arg-menu-item', 'disabled')}
                data-testid='arg-filtered-menu-no-result'
            >
                {_noResultMessage}
            </div>}
        <ArgMenu
            id={menuId}
            onClick={handleMenuClick}
            className={classNames('&-menu', 'arg-menu-menu', menuClassName)}
        >
            {filteredItems.map((item) => {
                const itemKey = computeItemKey(item, getItemKey);
                const itemDisabled = computeItemDisabled(item, getItemDisabled);
                const itemCls = computeItemClassName(item, getItemClassName);
                const expandState = computeItemExpandState(item, getItemExpandState);

                const cls: Record<string, any> = {
                    selected: selectedItemKeys[itemKey],
                    'has-checkbox': showCheckbox,
                    'is-null-key': isNullItemKey(itemKey),
                    'expandable': !!expandState,
                };

                if (expandState) {
                    cls[`expand-level-${expandState.level}`] = true;
                    cls['has-child'] = expandState?.hasAnyChild;
                    cls['is-expanded'] = expandState?.isExpanded;
                }

                if (renderItem) {
                    return (
                        <ArgMenuItem
                            key={itemKey}
                            id={`${menuId}-${itemKey}`}
                            data-testid='arg-filtered-menu-item'
                            disabled={itemDisabled}
                            className={classNames('&-item', 'arg-menu-item', cls, itemClassName, itemCls)}
                        >
                            {renderItem(item, _searchedToken)}
                        </ArgMenuItem>
                    );
                }

                const _count = computeItemCount(item, getItemCount);
                const count = renderText(_count, messageValues);

                const _label = computeItemLabel(item, getItemLabel);
                let label = renderText(_label, messageValues, searchedToken);

                const tooltipLabel = isFunction(getItemTooltip) ? getItemTooltip(item) :
                    isString(getItemTooltip) ? getItemTooltip : undefined;

                if (tooltipLabel) {
                    label = <ArgTooltip title={tooltipLabel} placement='left'>
                        {label}
                    </ArgTooltip>;
                }

                const _icon = computeItemIcon(item, getItemIcon);
                const icon = renderIcon(_icon, classNames('arg-menu-item-icon', '&-item-icon'));

                if (isObject(itemKey)) {
                    throw new Error('Invalid item key, please use onItemKey to return a valid key');
                }

                let paddingLeftValue: string | undefined = undefined;
                if (expandState && !searchedToken) {
                    if (!expandState.level && !expandState.hasAnyChild) {
                        paddingLeftValue = CHILD_ITEM_PADDING_LEFT;
                    } else {
                        paddingLeftValue = `calc(calc(${expandState.level} * ${CHILD_ITEM_PADDING_LEFT}) + ${
                            expandState.hasAnyChild ? '0px' : CHILD_ITEM_PADDING_LEFT})`;
                    }
                }

                return (
                    <ArgMenuItem
                        key={itemKey}
                        id={`${menuId}-${itemKey}`}
                        data-testid='arg-filtered-menu-item'
                        className={classNames('&-item', cls, itemCls, itemClassName)}
                        disabled={itemDisabled}
                        paddingLeft={paddingLeftValue}
                    >
                        {
                            (expandState?.hasAnyChild) && (
                                <ArgButton
                                    icon={expandState.isExpanded ? 'icon-cheveron-up' : 'icon-cheveron-down'}
                                    type='ghost'
                                    onClick={(event: ButtonClickEvent) => handleExpand(item, event)}
                                    style={{ marginLeft: paddingLeftValue ? undefined : `${paddingLeftValue}px` }}
                                />
                            )
                        }

                        {showCheckbox && (
                            <button
                                className={classNames('&-item-checkbox')}
                                onClick={(event) => handleCheck(item, event)}
                                type='button'
                            >
                                <ArgIconCheckbox
                                    state={selectedItemKeys[itemKey]}
                                    className={classNames('&-item-checkbox-icon')}
                                />
                            </button>
                        )}

                        {icon}

                        <span
                            key='label'
                            className={classNames('&-item-name')}
                            data-testid='arg-item-label'
                        >
                            {label}
                        </span>

                        {typeof (count) === 'number' && (
                            <span key='count' className={classNames('&-item-count')}
                                  data-testid='arg-filtered-menu-item-count'>
                                <FormattedNumber value={count} notation='compact'/>
                            </span>
                        )}

                        {isElement(count) && (
                            <span key='element-count' className={classNames('&-item-count')}
                                  data-testid='arg-item-count'>
                                {count}
                            </span>
                        )}
                    </ArgMenuItem>
                );
            })}
        </ArgMenu>
        {canCreate && (
            <div className={classNames('&-item-create')}>
                <ArgButton type='link' icon='icon-plus' onClick={onCreate}>
                    <span className={classNames('&-item-create-label')}>
                        {renderText(createLabel, messageValues)}
                    </span>
                </ArgButton>
            </div>
        )}
        {bottomRender?.()}
    </div>;
}
