import { lazy, Suspense, useEffect } from 'react';
import { defineMessages } from 'react-intl';

import { UseGetMeReturnType, UserContext } from '../../contexts/user-context';
import { ArgRoute, ProgressMonitor, useClassNames, useMemoAsync, useSetTimeout } from '../basic';
import { LoadingSuspense } from './loading-suspense';
import { LoadingPane } from '../common/panes/loading-pane';
import { ErrorPane } from '../common/panes/error-pane';
import { ChangePassword } from '../common/change-password/views/change-password';
import usersConnector from '../../utils/connectors/users-connector';
import { Environment } from '../../utils/environment';
import {
    ADMINISTRATION_ROOT_URL,
    COLLECT_ROOT_URL,
    EXPLORATION_ROOT_URL,
    PREPARATION_ROOT_URL,
    SETTINGS_ROOT_URL,
} from './routes-urls';

import './main.less';

const FORCE_ERROR = false;
const FORCE_LOADING = false;
const FORCE_SUSPENSE_LOADING = false;

const LazySettingsRouter = lazy(() => import('../../settings/settings-router'));
const LazyAdminRouter = lazy(() => import('../../administration/utils/routing/admin-router'));
const LazyExplorationRouter = lazy(() => import('../../exploration/exploration-router'));
const LazyPreparationRouter = lazy(() => import('../../preparation/preparation-router'));
const LazyCollectRouter = lazy(() => import('../../collect/utils/routing/collect-router'));

const RETRY_TIMER = 1000 * 30 + Math.floor(Math.random() * 10000);

const messages = defineMessages({
    loadingUserProfile: {
        id: 'main.LoadingUserProfile',
        defaultMessage: 'Loading user profile',
    },
    errorLoadingUserProfile: {
        id: 'main.ErrorLoadingUserProfile',
        defaultMessage: 'Error while loading user profile',
    },
});

interface MainProps {
    onLogout: () => void;
    onChangePassword: () => void;
}

export function Main(props: MainProps) {
    const {
        onLogout,
        onChangePassword,
    } = props;

    const classNames = useClassNames('common-main');

    const [currentUser, progressMonitor, error] = useMemoAsync<UseGetMeReturnType>(async (progressMonitor: ProgressMonitor) => {
        try {
            const user = await usersConnector.myUserDetails(progressMonitor);

            const ret: UseGetMeReturnType = {
                me: user,
            };

            return ret;
        } catch (error: any) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            throw error;
        } finally {
            progressMonitor.done();
        }
    }, []);

    const startTimer = useSetTimeout(RETRY_TIMER);

    useEffect(() => {
        if (!error) {
            return;
        }

        console.log('Waiting for', RETRY_TIMER, 'ms');

        async function test() {
            try {
                await usersConnector.myUserDetails(progressMonitor);
            } catch (x) {
                console.log('Retry for', RETRY_TIMER, 'ms');
                startTimer(test);

                return;
            }

            console.log('Ready !!!');

            document.location.reload();
        }

        startTimer(test);
    }, [error]);

    if (FORCE_ERROR || error) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane
                    message={messages.errorLoadingUserProfile}
                    error={error}
                    className={classNames('&-error')}
                    size='large'
                />
            </div>
        );
    }

    if (FORCE_LOADING || currentUser === undefined || progressMonitor?.isRunning) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane
                    progressMonitor={progressMonitor}
                    className={classNames('&-loading')}
                    size='large'
                />
            </div>
        );
    }

    return (
        <UserContext.Provider value={currentUser}>

            {/* Cases router */}
            {process.env.REACT_APP_EXPLORATION_SUPPORT !== 'false' &&
                <ArgRoute path={EXPLORATION_ROOT_URL} render={() => {
                    if (FORCE_SUSPENSE_LOADING) {
                        return <LoadingSuspense/>;
                    }

                    return (
                        <Suspense fallback={<LoadingSuspense/>}>
                            <LazyExplorationRouter
                                onLogout={onLogout}
                                onChangePassword={onChangePassword}
                                className={classNames('&', 'route')}
                            />
                        </Suspense>
                    );
                }}/>
            }

            {/* Folders router */}
            {process.env.REACT_APP_PREPARATION_SUPPORT !== 'false' &&
                <ArgRoute path={PREPARATION_ROOT_URL} render={() => {
                    if (FORCE_SUSPENSE_LOADING) {
                        return <LoadingSuspense/>;
                    }

                    return (
                        <Suspense fallback={<LoadingSuspense/>}>
                            <LazyPreparationRouter
                                onLogout={onLogout}
                                onChangePassword={onChangePassword}
                                className={classNames('&', 'route')}
                            />
                        </Suspense>
                    );
                }}/>
            }

            {/* Admin router */}
            {process.env.REACT_APP_ADMIN_SUPPORT !== 'false' &&
                <ArgRoute path={ADMINISTRATION_ROOT_URL} render={() => {
                    if (FORCE_SUSPENSE_LOADING) {
                        return <LoadingSuspense/>;
                    }

                    return (
                        <Suspense fallback={<LoadingSuspense/>}>
                            <LazyAdminRouter
                                onLogout={onLogout}
                                onChangePassword={onChangePassword}
                                className={classNames('&', 'route')}
                            />
                        </Suspense>
                    );
                }}/>
            }

            {/* Collect router */}
            {process.env.REACT_APP_COLLECT_SUPPORT !== 'false' &&
                <ArgRoute path={COLLECT_ROOT_URL} render={() => {
                    if (FORCE_SUSPENSE_LOADING) {
                        return <LoadingSuspense/>;
                    }

                    return (
                        <Suspense fallback={<LoadingSuspense/>}>
                            <LazyCollectRouter
                                onLogout={onLogout}
                                onChangePassword={onChangePassword}
                                className={classNames('&', 'route')}
                            />
                        </Suspense>
                    );
                }}/>
            }

            {/* Config router */}
            {process.env.REACT_APP_SETTINGS_SUPPORT !== 'false' &&
                <ArgRoute path={SETTINGS_ROOT_URL} render={() => {
                    if (FORCE_SUSPENSE_LOADING) {
                        return <LoadingSuspense/>;
                    }

                    return (
                        <Suspense fallback={<LoadingSuspense/>}>
                            <LazySettingsRouter
                                onLogout={onLogout}
                                onChangePassword={onChangePassword}
                                className={classNames('&', 'route')}
                            />
                        </Suspense>
                    );
                }}/>
            }

            {/* Change password */}
            {Environment.isKeyCloackEnabled &&
                <ArgRoute
                    path='/me/change-password'
                    render={() => <ChangePassword user={currentUser.me}/>}
                />
            }
        </UserContext.Provider>
    );
}
