import Debug from 'debug';

import { EventEmitter } from '../utils/event-emitter';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { IDisposable } from './disposable';
import { StateId } from '../../../utils/states/basic-state';
import { isString } from 'lodash';

const debug = Debug('basic:cache-repositories:DataCacheRepository');

const DEFAULT_TTL_MS = 0;

const NULL_DATA_INFO: [any, Error | undefined, ProgressMonitor | undefined] = [null, undefined, undefined];

type CacheLifecycleHandler = (key: string, phase: 'init' | 'destroyed') => void;

interface DataWithTTL<T extends Object> {
    data?: T | null;
    dataWeakRef?: WeakRef<T>;
    error?: Error | null;
    timestamp: number;
    links: number;
    progressMonitor?: ProgressMonitor;
    lifecycleHandler?: CacheLifecycleHandler;
    stateId?: StateId;

    debugStack?: Error;
}

export type DataLoader<T, K> = (key: string, infos: K, previousValue: T | undefined, progressMonitor: ProgressMonitor) => Promise<T | null>;

let uniqueId = 1;

export class DataCacheRepository<T extends Object, K = any> extends EventEmitter implements IDisposable {
    readonly #id: string;
    readonly #name: string;
    #cache: Record<string, DataWithTTL<T>> = {};
    readonly #intervalId?: any;
    readonly #ttlMs: number;
    readonly #dataLoader: DataLoader<T, K>;
    #disposed = false;
    readonly #debugger?: Debug.Debugger;
    #get = 0;
    #hit = 0;
    #missed = 0;
    #fetching = 0;
    #fetched = 0;
    #errors = 0;
    #canceled = 0;
    #links = 0;
    #unlinks = 0;

    constructor(name: string, dataLoader: DataLoader<T, K>, ttlMs: number = DEFAULT_TTL_MS) {
        super();
        this.#id = `${uniqueId++}`;
        this.#name = name;
        this.#ttlMs = ttlMs;
        this.#dataLoader = dataLoader;

        if (localStorage.DEBUG_CACHE_REPOSITORY) {
            this.#debugger = localStorage.DEBUG_CACHE_REPOSITORY === '*'
                || localStorage.DEBUG_CACHE_REPOSITORY.split(',').includes(name);
            if (this.#debugger) {
                (window as any).arg_caches = (window as any).arg_caches || {};
                (window as any).arg_caches[`${name}_${this.#id}`] = this;
            }
        }

        if (ttlMs > 0) {
            this.#intervalId = setInterval(this._handleInterval, ttlMs);
        }
    }

    _handleInterval = () => {
        if (this.#disposed) {
            return;
        }

        const keys = Object.keys(this.#cache);

        debug('handleInterval', 'keys=', keys);

        const now = Date.now();
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const data = this.#cache[key];

            debug('handleInterval', 'key=', key, 'data=', data);

            if (!data) {
                continue;
            }

            if (data.links > 0) {
                continue;
            }

            if (data.data !== undefined || data.dataWeakRef?.deref() !== undefined) {
                if (now - data.timestamp < this.#ttlMs) {
                    continue;
                }
            }

            if (data.progressMonitor) {
                data.progressMonitor.cancel();
            }

            delete this.#cache[key];

            data.lifecycleHandler?.(key, 'destroyed');
        }

        debug('handleInterval', 'processed=', this.#cache);
    };

    dispose() {
        if (this.#disposed) {
            return;
        }

        this.#disposed = true;

        if (this.#intervalId) {
            clearInterval(this.#intervalId);
        }

        debug('dispose', 'intervalId=,', this.#intervalId);

        const cache = this.#cache;
        this.#cache = {};

        let destroyedObjectCount = 0;
        if (cache) {
            Object.entries(cache).forEach(([key, info]) => {
                info.lifecycleHandler?.(key, 'destroyed');
                destroyedObjectCount++;
            });
        }

        debug('dispose', 'destroyedObjectCount=,', destroyedObjectCount);
    }

    loadPromise(key: string | undefined, infos: K, stateId: StateId | undefined, progressMonitor?: ProgressMonitor): Promise<T | null> {
        if (key === undefined) {
            if (isString(infos)) {
                key = infos;
            } else {
                key = JSON.stringify(infos);
            }
        }

        return new Promise((resolve, reject) => {
            if (this.#disposed) {
                return reject(new Error('Already disposed'));
            }

            const ret = this.load(key, infos, stateId);
            if (!ret) {
                return reject(new Error('No data'));
            }

            try {
                progressMonitor?.verifyCancelled();
            } catch (x) {
                return reject(x);
            }

            if (ret[0]) {
                resolve(ret[0]);

                return;
            }

            if (ret[1]) {
                reject(ret[1]);

                return;
            }

            this.once(`loaded:${key}`, (value, error) => {
                if (this.#disposed) {
                    return reject(new Error('Already disposed'));
                }

                try {
                    progressMonitor?.verifyCancelled();
                } catch (x) {
                    return reject(x);
                }

                if (error) {
                    return reject(error);
                }

                if (value !== undefined) {
                    return resolve(value);
                }

                reject(new Error('No data'));
            });
        });
    }

    load(key: string | undefined, infos: K, stateId: StateId | undefined): [T | null | undefined, Error | null | undefined, ProgressMonitor | undefined] {
        if (this.#disposed) {
            throw new Error('Already disposed');
        }
        this.#get++;

        if (key === undefined) {
            if (isString(infos)) {
                key = infos;
            } else {
                key = JSON.stringify(infos);
            }
        }

        let info: DataWithTTL<T> | undefined = this.#cache[key];

        if (info && info.stateId !== stateId) {
            //info?.lifecycleHandler?.(key, 'destroyed');
            info = undefined;
        }

        let weakData: T | undefined = undefined;

        if (info) {
            if (info.error) {
                return [undefined, info.error, undefined];
            }
            if (info.progressMonitor?.isRunning) {
                return [undefined, undefined, info.progressMonitor];
            }
            if (info.data === null) {
                return NULL_DATA_INFO;
            }

            if (info?.dataWeakRef) {
                weakData = info.dataWeakRef.deref();
            }

            if (info.data === undefined && weakData === undefined) {
                //info?.lifecycleHandler?.(key, 'destroyed');

                info = undefined;
            }
        }

        if (info) {
            info.timestamp = Date.now();

            debug('load', 'load key=', key, 'info=', info);

            let data = info.data;
            if (data === undefined) {
                data = weakData;
            }

            if (data) {
                this.#hit++;
            } else {
                this.#missed++;
            }

            return [data, info.error, info.progressMonitor];
        }

        this.#fetching++;

        const progressMonitor = new ProgressMonitor(`Loading data ${key}`, 1);
        this.emit(`loading:${key}`, progressMonitor);

        info = {
            progressMonitor,
            links: 1,
            timestamp: Date.now(),
            stateId,
            data: undefined,
        };
        this.#cache[key] = info;

        info?.lifecycleHandler?.(key, 'init');

        if (this.#debugger) {
            info.debugStack = new Error();
        }

        debug('load', 'create key=', key, 'info=', info);

        this.#dataLoader(key, infos, undefined, progressMonitor).then((data) => {
            this.#fetched++;
            if (data === null || data === undefined) {
                info!.data = null;
            } else {
                info!.dataWeakRef = new WeakRef<T>(data);
                info!.data = data;
            }
            info!.error = null;
            info!.progressMonitor = undefined;

            this.emit(`loaded:${key}`, data, null);
        }).catch((error) => {
            if (progressMonitor.isCancelled) {
                this.#canceled++;

                return;
            }

            this.#errors++;
            info!.data = null;
            info!.error = error;
            info!.progressMonitor = undefined;

            this.emit(`loaded:${key}`, null, error);
        }).finally(() => {
            progressMonitor.done();
            delete info!.progressMonitor;
            info!.links--;
            this.#fetching--;

            debug('load', 'loaded key=', key, 'info=', info);
        });

        return [undefined, undefined, progressMonitor];
    }

    unlink(key: string): boolean {
        if (this.#disposed) {
            return false;
        }

        this.#unlinks++;

        const info = this.#cache[key];

        debug('unlink', 'key=', key, 'beforeUnlink=', info.links, 'info=', info);

        if (!info) {
            console.error('Unknown data key=', key);

            return false;
        }
        if (info.links < 1) {
            console.error('No linked data key=', key, 'linkCount=', info.links);

            return false;
        }
        info.timestamp = Date.now();
        info.links--;

        if (info.links > 0) {
            return true;
        }

        if (info.dataWeakRef && info.data !== null) {
            // Clear strong reference, keep weak reference
            info.data = undefined;
        }

        return true;
    }

    link(key: string): boolean {
        if (this.#disposed) {
            throw new Error('Already disposed');
        }

        this.#links++;

        const info = this.#cache[key];

        debug('link', 'key=', key, 'beforeLink=', info.links, 'info=', info);

        if (!info) {
            console.error('Unknown data key=', key);

            return false;
        }

        info.links++;
        info.timestamp = Date.now();
        if (info.data === undefined && info.dataWeakRef) {
            // Fill strong reference with weak reference
            info.data = info.dataWeakRef.deref();
            if (info.data === undefined) {
                console.error('**** DATA is LOST !');

                return false;
            }
        }

        return true;
    }
}
