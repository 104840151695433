import { useCallback } from 'react';

import { ArgCheckbox } from '../arg-checkbox/arg-checkbox';
import { SelectionProvider } from '../arg-providers/selection-provider';
import { useIsSelected } from '../arg-providers/use-selection';

interface SelectionCellProps<T> {
    selectionProvider: SelectionProvider<T>;
    onSelectionChange?: (row: T, newState: boolean, selectionProvider: SelectionProvider<T>) => void;
    row: T;
    disabled?: boolean;
}

export function SelectionCell<T>(props: SelectionCellProps<T>) {
    const {
        selectionProvider,
        row,
        onSelectionChange,
        disabled,
    } = props;

    const checked = useIsSelected(selectionProvider, row);

    const handleOnChange = useCallback((checked: boolean) => {
        if (onSelectionChange) {
            onSelectionChange(row, checked, selectionProvider);

            return;
        }
        if (checked) {
            selectionProvider.add(row, 'selection-cell');

            return;
        }

        selectionProvider.remove(row, 'selection-cell');
    }, [onSelectionChange, selectionProvider, row]);

    return <ArgCheckbox disabled={disabled} size='node' value={checked} onChange={handleOnChange}/>;
}
