import {
    ArgMessageValues,
    ArgRenderedText,
    ArgSize,
    ClassValue,
    renderText,
    useClassNames,
} from 'src/components/basic';

import './arg-message-renderer.less';

const CLASSNAME = 'arg-message-renderer';

export interface ArgMessageRendererProps {
    className?: ClassValue;
    message: ArgRenderedText;
    messageValues?: ArgMessageValues;
    size?: ArgSize;
    searchToken?: string;
}

export const ArgMessageRenderer = (props: ArgMessageRendererProps) => {
    const {
        className,
        message,
        messageValues,
        size = 'medium',
        searchToken,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const messageAsReactNode = renderText(message, messageValues, searchToken);

    const cls = {
        [`size-${size}`]: true,
    };

    return (
        <div className={classNames('&', className, cls)} data-testid='arg-message-renderer'>
            {messageAsReactNode}
        </div>
    );
};
