import { ArgTable3RowState } from '../arg-table3/arg-table3';
import { DataFilter, DataProvider } from './data-provider';

export class ArrayDataProvider<T, F extends DataFilter = any> extends DataProvider<T, F> {
    #data: T[];

    constructor(data: T[]) {
        super();
        this.#data = data;
    }

    getRow(rowIndex: number): T | ArgTable3RowState {
        if (rowIndex < 0 || rowIndex >= this.#data.length) {
            return ArgTable3RowState.Error;
        }

        return this.#data[rowIndex];
    }

    getData(): T[] {
        return this.#data;
    }

    get rowCount() {
        return this.#data.length;
    }
}
