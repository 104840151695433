import { defaultTo, isNumber } from 'lodash';
import { MapContainer, TileLayer } from 'react-leaflet';
import L, { LatLng, LatLngExpression, LatLngLiteral, Map } from 'leaflet';
import React, { MutableRefObject, SetStateAction, useCallback, useMemo, useState } from 'react';

import '../../../../../utils/leaflet-draw/leaflet-draw';

// Hooks and components
import { getDataTestIdFromProps } from '../../../utils';
import { ArgMapCircle } from './components/circle/map-circle';
import { ArgMapMarker } from './components/marker/map-marker';
import { GeolocationValue, MapMode } from '../../model/geolocation-value';
import { ArgMapPolygon } from './components/polygon/map-polygon';
import { ArgMapToolbar } from './components/toolbar/arg-map-toolbar';
import { ArgMapZoomToolbox } from './components/zoom/arg-map-zoom-toolbox';
import { LatLngExpressionToLatLng } from '../../helpers/LatLngExpressionToLatLng';
import { ArgMapCenterButton } from './components/center-button/arg-map-center-button';
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { ArgChangeReason } from '../../../types';
import { KeyBindingDescriptor } from '../../../keybindings/keybinding';
import { useComponentArgMapTiles } from '../../context/arg-map-tiles-provider';

// Assets
import MakerImage from './assets/marker.svg';

// Styles
import './arg-map.less';

export interface ArgMapProps {
    initialZoom?: number;
    initialRadius?: number;
    initialCenter?: LatLngLiteral;
    initialPolygon?: LatLngLiteral[];
    initialMapMode?: MapMode;
    forwardRef?: MutableRefObject<Map | undefined>;
    className?: ClassValue;
    value?: GeolocationValue | null;
    onChange?: (value: SetStateAction<GeolocationValue | null>, reason?: ArgChangeReason) => void;
    mapToolBar?: boolean;
    zoomInKeyBinding?: KeyBindingDescriptor;
    zoomOutKeyBinding?: KeyBindingDescriptor;
}

export const DEFAULT_MAP_CENTER = [45, 2.230230] as unknown as L.LatLng;
export const DEFAULT_MAP_ZOOM = 1;
export const MAP_MAX_ZOOM = 18;
export const MAP_MIN_ZOOM = 0;

export const MarkerIcon: L.Icon<L.IconOptions> = L.icon(
    {
        iconUrl: MakerImage,
        iconAnchor: [10, 20],
        iconSize: [20, 20],
    }
);

export function ArgMap(props: ArgMapProps) {
    const {
        onChange,
        className,
        forwardRef,
        mapToolBar,
        value: externalValue,

        // Initial values
        initialZoom = DEFAULT_MAP_ZOOM,
        initialRadius,
        initialCenter,
        initialPolygon,
        initialMapMode,
        zoomInKeyBinding,
        zoomOutKeyBinding,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<GeolocationValue | undefined>();

    const value = useInternalValue ? internalValue : externalValue;

    const classNames = useClassNames('arg-map');

    const cls = {};

    const zoom = useMemo(() => {
        const ret = defaultTo(value?.zoom, initialZoom);

        return ret;
    }, [initialZoom, value]);

    const radius = useMemo(() => defaultTo(value?.radius, initialRadius), [initialRadius, value]);
    const center = useMemo(() => defaultTo(value?.center, initialCenter), [initialCenter, value]);
    const polygon = useMemo(() => defaultTo(value?.polygon, initialPolygon), [initialPolygon, value]);
    const mapMode = useMemo(() => defaultTo(value?.mapMode, initialMapMode), [initialMapMode, value]);

    const handleMapModeChange = useCallback((newMapMode?: MapMode) => {
        const updateMethod = (prev?: GeolocationValue | null) => ({
            ...prev,
            mapMode: newMapMode,
        });

        setInternalValue(updateMethod);

        if (newMapMode && mapMode !== newMapMode && onChange) {
            onChange(updateMethod);
        }
    }, [mapMode, onChange]);

    const handleZoomChange = useCallback((zoom?: number) => {
        const updateMethod = (prev?: GeolocationValue | null) => ({
            ...(prev || {}),
            zoom,
        });

        setInternalValue(updateMethod);
        onChange?.(updateMethod);
    }, [onChange]);

    const handleCenterChange = useCallback((center?: LatLngExpression) => {
        const updateMethod = (prev?: GeolocationValue | null) => ({
            ...(prev || {}),
            center: LatLngExpressionToLatLng(center),
            polygon: undefined,
        });

        setInternalValue(updateMethod);
        onChange?.(updateMethod);
    }, [onChange]);

    const startDrawingPolygonHandler = useCallback(() => {
        const updateMethod = (prev?: GeolocationValue | null) => ({
            ...(prev || {}),
            mapMode: MapMode.Polygon,
        });

        setInternalValue(updateMethod);
        onChange?.(updateMethod);
    }, [onChange]);

    const onPolygonDrawFinishHandler = useCallback((
        area: number,
        polygon: LatLng[]
    ) => {
        if (area) {
            const updateMethod = () => {
                return {
                    area,
                    polygon,
                    mapMode: MapMode.Polygon,
                };
            };

            setInternalValue(updateMethod);
            onChange?.(updateMethod, 'enter');
        }
    }, [onChange]);

    const [mapTiles, loadingMapTiles, errorMapTiles] = useComponentArgMapTiles();

    const computedZoom = useMemo(() => {
        if (!mapTiles) {
            return zoom;
        }

        let ret: number = zoom;
        if (isNumber(mapTiles?.minZoom)) {
            ret = Math.max(ret, mapTiles.minZoom);
        }
        if (isNumber(mapTiles?.maxZoom)) {
            ret = Math.min(ret, mapTiles.maxZoom);
        }

        return ret;
    }, [zoom, mapTiles]);

    return (
        <MapContainer
            whenCreated={(map: Map) => {
                if (forwardRef) {
                    forwardRef.current = map;
                }
            }}
            data-testid={dataTestId}
            scrollWheelZoom={true}
            zoomControl={false}
            preferCanvas={true}
            minZoom={isNumber(mapTiles?.minZoom) ? mapTiles!.minZoom : MAP_MIN_ZOOM}
            maxZoom={isNumber(mapTiles?.maxZoom) ? mapTiles!.maxZoom : MAP_MAX_ZOOM}
            zoom={computedZoom}
            center={defaultTo(center, DEFAULT_MAP_CENTER)}
            className={classNames(className, cls, '&')}
        >
            {mapTiles && <TileLayer
                attribution={mapTiles.attribution}
                url={mapTiles.url}
                maxZoom={mapTiles.maxZoom}
                minZoom={mapTiles.minZoom}

                // @ts-ignore url param => https://leafletjs.com/reference-1.6.0.html#tilelayer
                //mode={Environment.defaultMapViewMode}
            />}
            {/* Marker */}
            {!!center && (
                <ArgMapMarker
                    icon={MarkerIcon}
                    position={center}
                    onChange={handleCenterChange}
                />
            )}

            {/* Map circle with radius */}
            {!!(center && radius) && (
                <ArgMapCircle
                    center={center}
                    radius={radius}
                    pathOptions={{
                        color: '#2873D6',
                        fillColor: '#2873D6',
                        opacity: 1,
                    }}
                />
            )}

            {/* Map polygon */}
            {!!polygon &&
                <ArgMapPolygon
                    positions={polygon}
                    pathOptions={{
                        color: '#2873D6',
                        fillColor: '#2873D6',
                        opacity: 1,
                    }}
                />
            }

            <div className={classNames('&-right-controls')}>
                {/* Map toolbar */}
                {mapToolBar !== false && (
                    <ArgMapToolbar
                        className={classNames('&-right-controls-toolbar')}
                        mapMode={mapMode}
                        onChange={handleMapModeChange}
                        onPolygonDrawFinishHandler={onPolygonDrawFinishHandler}
                        startDrawingPolygonHandler={startDrawingPolygonHandler}
                    />
                )}

                {/* Zoom toolbox */}
                <ArgMapZoomToolbox
                    className={classNames('&-right-controls-zoom-toolbox')}
                    zoom={zoom}
                    onChange={handleZoomChange}
                    zoomInKeyBinding={zoomInKeyBinding}
                    zoomOutKeyBinding={zoomOutKeyBinding}
                />
            </div>

            {/* Center button */}
            <ArgMapCenterButton
                center={center}
            />
        </MapContainer>
    );
}
