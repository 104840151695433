import React from 'react';
import { Divider } from 'antd';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './arg-toolbar-divider.less';

export interface ArgToolbarDivider {
    className?: ClassValue;
}

export function ArgToolbarDivider(props: ArgToolbarDivider) {
    const {
        className,
    } = props;

    const classNames = useClassNames('arg-toolbar-divider');

    return <Divider type='vertical' className={classNames('&', className)}/>;
}
