import React, { CSSProperties, ReactNode } from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgSize } from '../types';
import { ArgBadges } from '../arg-badge/arg-badge';

import './basic-tag-icon.less';
import { isString } from 'lodash';

interface TagIconProps {
    className?: ClassValue;
    backgroundColor?: string | false;
    borderColor?: string;

    icon?: string | ReactNode | 'error' | 'unknown';
    badges?: ArgBadges;

    digram?: ReactNode;
    digramColor?: string;

    size?: ArgSize;
}

export function BasicTagIcon(props: TagIconProps) {
    const {
        className,
        borderColor,
        backgroundColor,
        icon,
        badges,
        digram,
        digramColor,
        size,
    } = props;

    const classNames = useClassNames('arg-tag-icon');

    const cls: ClassValue = {
        'has-border': ((!!borderColor) && borderColor !== 'none'),
    };
    if (size) {
        cls[`size-${size}`] = true;
    }

    let _icon = icon;
    if (icon === 'error') {
        _icon = <ArgIcon
            name='icon-notification'
            className={classNames('&-icon', 'error')}
        />;
        cls.error = true;
    } else if (icon === 'unknown') {
        _icon = <ArgIcon
            name='icon-question'
            className={classNames('&-icon', 'unknown')}
        />;
        cls.unknown = true;
    } else if (isString(icon)) {
        _icon = <ArgIcon
            name={icon}
            className={classNames('&-icon')}
        />;
    }

    cls['has-digram'] = digram && !_icon;

    const style: CSSProperties = {};
    if (_icon) {
        if (borderColor) {
            style.borderColor = borderColor;
        }
    } else if (digram) {
        style.color = digramColor;
    }
    if (backgroundColor && backgroundColor !== 'primary') {
        style.backgroundColor = backgroundColor;
    }

    cls['has-icon'] = !!_icon;

    return <div className={classNames('&', className, cls)}>
        <div
            className={classNames('&-avatar')}
            data-color={(backgroundColor === 'primary') ? backgroundColor : undefined}
            style={style}
        >
            {_icon && <span className={classNames('&-avatar-icon')}>
                {_icon}
            </span>}
            {digram && <span className={classNames('&-avatar-digram')}>
                {digram}
            </span>}
        </div>
        {badges}
    </div>;
}
