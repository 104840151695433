import React, { ComponentType, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { ArgToolItemRenderFunction, ArgToolItemRenderWithContextFunction } from './arg-toolbar-item';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue } from '../arg-hooks/use-classNames';
import { ArgButtonType, ArgRenderedIcon, ArgRenderedText } from '../types';

export type ToolType = 'button' | 'combo' | 'group' | 'activable' | 'custom' | 'panel' | 'marker' | 'separator' | 'menu';

export interface Tool {
    key?: React.Key;
    path: string; // Unique
    type?: ToolType;
    selected?: boolean;
    visible?: boolean | (() => boolean);
    disabled?: boolean | (() => boolean);
    loading?: boolean;
    order?: number;
    testid?: string;
    override?: number; // Tools with higher override number takes precedence

    computeChildren?: (parentTool: Tool, progressMonitor: ProgressMonitor) => Promise<Tool[]>;

    tooltip?: ReactNode | MessageDescriptor | ((props: Record<string, any>) => ReactNode | MessageDescriptor);
    tooltipPlacement?: TooltipPlacement;

    icon?: ArgRenderedIcon;
    label?: ArgRenderedText;
    description?: ArgRenderedText;
    componentType?: ComponentType;
    buttonType?: ArgButtonType;

    customRender?: ArgToolItemRenderWithContextFunction;
    menuItemCustomRender?: boolean;

    preventCloseMenuOnClick?: boolean;

    panelRender?: ArgToolItemRenderFunction;
    panelWidthConfigName?: string;
    panelDefaultWidth?: number | (() => number);

    onClick?: (props: Tool, event?: ButtonClickEvent) => void;
    onShiftClick?: (props: Tool, event: ButtonClickEvent) => void;
    onCtrlClick?: (props: Tool, event: ButtonClickEvent) => void;
    onAltClick?: (props: Tool, event: ButtonClickEvent) => void;
    onMouseOver?: (event: ButtonClickEvent) => void;

    keyBinding?: KeyBindingDescriptor;
    shiftKeyBinding?: KeyBindingDescriptor;
    altKeyBinding?: KeyBindingDescriptor;
    ctrlKeyBinding?: KeyBindingDescriptor;

    className?: ClassValue;
    menuClassName?: ClassValue;

    onUnmount?: () => void;
}

export type ToolChanges = Omit<Tool, 'path'>;

export function isToolDisabled(tool: Tool): boolean {
    const { disabled } = tool;
    if (typeof (disabled) === 'boolean') {
        return disabled;
    }
    if (typeof (disabled) === 'function') {
        return disabled();
    }

    return false;
}

export function isToolVisible(tool: Tool): boolean {
    const { visible } = tool;
    if (typeof (visible) === 'boolean') {
        return visible;
    }
    if (typeof (visible) === 'function') {
        return visible();
    }

    return true;
}
