import { LatLngLiteral } from 'leaflet';

export enum MapMode {
    Polygon = 'polygon',
    CenterArea = 'centeredArea'
}

export enum RadiusUnit {
    Kilometer = 'kilometer',
    Meter = 'meter',
}

export const DEFAULT_RADIUS_UNIT = RadiusUnit.Kilometer;

export interface GeolocationValue {
    center?: LatLngLiteral;
    address?: string;
    zoom?: number;
    radius?: number;
    radiusUnit?: RadiusUnit;
    area?: number;
    polygon?: LatLngLiteral[];
    mapMode?: MapMode;
}

export interface GeoValue {
    centeredArea?: {
        latLng?: LatLngLiteral;
        radius?: number;
        address?: string;
    },
    polygonArea?: {
        latLng: LatLngLiteral[];
        area?: number;
    }
}
