import { isNumber } from 'lodash';

import { ArgTable4CellIndex } from './types';
import { ArgTable4RowState } from './arg-table4';

export const isArgTable4CellIndex = (cellIndex: any): cellIndex is ArgTable4CellIndex => {
    return isNumber(cellIndex?.rowIndex) && isNumber(cellIndex?.columnIndex);
};

export const isArgTable4RowState = (rowState: any): rowState is ArgTable4RowState => {
    return rowState === ArgTable4RowState.Loading || rowState === ArgTable4RowState.Error;
};
