import L from 'leaflet';
import React, { useCallback, useEffect, useRef } from 'react';
import { Circle, CircleMarkerProps, useMap } from 'react-leaflet';

// Components and hooks
import { useClassNames } from '../../../../../arg-hooks/use-classNames';

export const ArgMapCircle: React.FunctionComponent<CircleMarkerProps> = ({ center, pathOptions, radius }) => {
    const classNames = useClassNames('arg-map-circle');

    const circleRef = useRef<L.Circle>(null);
    const map = useMap();

    const fitBounds = useCallback(() => {
        if (circleRef?.current) {
            map.fitBounds(circleRef.current.getBounds());
        }
    }, [map, circleRef]);

    useEffect(() => {
        fitBounds();
    }, [radius, center]);

    useEffect(() => {
        map.on('resize', fitBounds);

        return () => {
            map.off('resize', fitBounds);
        };
    }, []);

    return (
        <Circle
            ref={circleRef}
            center={center}
            radius={radius}
            pathOptions={pathOptions}
            className={classNames('&')}
        />
    );
};
