import { RtBasicState, RtStateMessage } from './rt-basic-state';
import { WebSocketChannel, WebSocketConnectionChannel } from '../../components/ws/websocket-connector';
import { ArgUserId } from '../../components/basic';
import { RtMessageEventList, USER_EVENTS, USER_PROFILE_EVENT_NAMES } from './events';

export type UserStateMessage = RtStateMessage;

const ALL_USER_EVENT_NAMES = Object.keys(USER_EVENTS);

export class UserState extends RtBasicState<UserStateMessage> {
    #userId: ArgUserId;
    #acceptedEvents: RtMessageEventList;
    #api: string;

    constructor(api: string, userId: ArgUserId, acceptedEvents: RtMessageEventList = USER_PROFILE_EVENT_NAMES) {
        super('/users', 'user', ALL_USER_EVENT_NAMES);

        this.#api = api;
        this.#userId = userId;
        this.#acceptedEvents = acceptedEvents;
    }


    protected getApi() {
        return this.#api;
    }

    protected get keepChannelOpened() {
        return true;
    }

    get userId(): ArgUserId {
        return this.#userId;
    }

    async connected(channel: WebSocketConnectionChannel<UserStateMessage>): Promise<void> {
        await super.connected(channel);

        if (!channel.link(`watch:${this.#userId}`)) {
            return;
        }

        await channel.connection.invoke('Watch', this.#userId);
    }

    async disconnecting(channel: WebSocketConnectionChannel<UserStateMessage>): Promise<void> {
        if (channel.unlink(`watch:${this.#userId}`)) {
            await channel.connection.invoke('Unwatch', this.#userId);
        }

        await super.disconnecting(channel);
    }

    processMessage = async (channel: WebSocketChannel<UserStateMessage>, type: string, message: UserStateMessage): Promise<boolean | undefined> => {
        const { userId } = message.messageContent;

        if (userId !== this.#userId) {
            // Ignore message
            return true;
        }

        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        this.processUserMessage(message);

        // Process Message
        return false;
    };

    protected processUserMessage(message: UserStateMessage): void {

    }
}
