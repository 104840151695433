import { useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ClassValue, useClassNames } from 'src/components/basic';
import { filterItems } from '../utils';
import { ArgInputSearch } from './arg-input-search';
import { Snippet, SnippetsRepository } from './types';
import { ArgInputExpressionSnippet } from './arg-input-expression-snippet';

import './arg-input-expression-snippets-list.less';

const CLASSNAME = 'arg-input-expression-snippets-list';

const messages = defineMessages({
    snippetsPanelTitle: {
        id: 'basic.arg-input-expression-snippets-list.Title',
        defaultMessage: 'Code snippets',
    },
});

export interface ArgInputExpressionSnippetsListProps {
    className?: ClassValue;
    snippetsRepository: SnippetsRepository;
    onViewSnippet?: (snippet: Snippet) => void;
    onDoubleClickSnippet?: (snippet: Snippet) => void;
}

export function ArgInputExpressionSnippetsList(props: ArgInputExpressionSnippetsListProps) {
    const {
        className,
        snippetsRepository,
        onViewSnippet,
        onDoubleClickSnippet,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const intl = useIntl();

    const [search, setSearch] = useState<string>('');

    const filteredSnippets = useMemo(() => {
        if (!snippetsRepository?.snippets) {
            return [];
        }

        const snippets = snippetsRepository.snippets;
        if (!search) {
            return snippets;
        }

        const _snippets = snippets.filter((snippet) => !!snippet.code);

        const filteredSnippets = filterItems<Snippet>(_snippets, search, (snippet: Snippet) => {
            return snippet.title;
        }, intl);

        return filteredSnippets;
    }, [intl, search, snippetsRepository?.snippets]);

    const body = <div className={classNames('&-body-list')}>
        {
            filteredSnippets.map((snippet) => {
                return (
                    <ArgInputExpressionSnippet
                        key={snippet.order}
                        className={classNames('&-body-list-item')}
                        snippet={snippet}
                        search={search}
                        onViewSnippet={onViewSnippet ? () => onViewSnippet(snippet) : undefined}
                        onDoubleCLick={onDoubleClickSnippet ? () => onDoubleClickSnippet(snippet) : undefined}
                    />
                );
            })
        }
    </div>;

    return (
        <div className={classNames('&', className)}
             data-testid='arg-input-expression-snippets-list'
        >
            <div className={classNames('&-title')}>
                <FormattedMessage {...messages.snippetsPanelTitle} />
            </div>
            <ArgInputSearch
                onInputChange={setSearch}
                value={search}
                className={classNames('&-search')}
            />
            <div className={classNames('&-body')}>
                {body}
            </div>
        </div>
    );
}
