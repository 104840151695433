import React, { ReactNode } from 'react';
import { IntlContext, IntlShape } from 'react-intl';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';

import './arg-drag-cursor.less';

const CLASSNAME = 'basic-drag-cursor';

export interface ArgDragImageProps {
    intl: IntlShape;
    children?: ReactNode;
}

export function ArgDragCursor(props: ArgDragImageProps) {
    const {
        children,
        intl,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    return <div className={classNames('&')}>
        <ArgIcon name='icon-6dots' className={classNames('&-icon')}/>
        <IntlContext.Provider value={intl}>
            {children}
        </IntlContext.Provider>
    </div>;
}

