import React, { DragEvent, ErrorInfo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NumberSize, Resizable, ResizeDirection, Size } from 're-resizable';
import { isNumber } from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';
import useResizeObserver from '@react-hook/resize-observer';

import { walkNode } from './utils';
import { isToolDisabled, isToolVisible, Tool } from './tool';
import { ArgToolbar } from './arg-toolbar';
import { ToolContext } from './tool-context';
import { useToolNodes } from './use-tool-nodes';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgErrorCatcher } from '../arg-error/arg-error-catcher';
import { ArgonosError, INVALID_SESSION } from '../utils/argonos-error';
import { ErrorPane } from '../../common/panes/error-pane';
import { useUserConfiguration } from '../../../hooks/use-user-configuration';
import { toPx } from '../utils';
import { LEFT_ENABLE, RIGHT_ENABLE, TOP_ENABLE } from '../utils/resizable';

import './arg-toolbar-layout.less';

const messages = defineMessages({
    errorInPane: {
        id: 'basic.arg-toolbar-layout.ErrorInPane',
        defaultMessage: 'An error occurred, please close the panel.',
    },
});

const DEFAULT_PANEL_WIDTH = 436;
const DEFAULT_FOOTER_HEIGHT = 250;

export interface ArgToolbarLayoutProps {
    footer?: ReactNode;
    className?: ClassValue;
    toolbarClassName?: ClassValue;
    enableFooterResize?: boolean;
    disabled?: boolean;
    toolbarContext: ToolContext;
    bodyClassName?: ClassValue;
    onDragStart?: (event: DragEvent<HTMLDivElement>) => void;
    onDragEnd?: (event: DragEvent<HTMLDivElement>) => void;
    children?: ReactNode;
    rightMinWidth?: number | string;
    rightMaxWidth?: number | string;
    rightDefaultWidth?: number | string;
    rightPanelWidthConfigurationPath?: string;
    leftMinWidth?: number | string;
    leftMaxWidth?: number | string;
    leftDefaultWidth?: number | string;
    leftPanelWidthConfigurationPath?: string;
    footerMinHeight?: number | string;
    footerMaxHeight?: number | string;
    footerDefaultHeight?: number | string;
    footerPanelHeightConfigurationPath?: string;
}

export function ArgToolbarLayout(props: ArgToolbarLayoutProps) {
    const {
        className,
        toolbarClassName,
        toolbarContext,
        enableFooterResize,
        disabled,
        children,
        footer,
        bodyClassName,
        onDragStart,
        onDragEnd,
        rightMinWidth = DEFAULT_PANEL_WIDTH,
        rightMaxWidth = '50%',
        rightDefaultWidth,
        rightPanelWidthConfigurationPath = 'ui.toolbar-layout.right-panel.width',
        leftMinWidth = DEFAULT_PANEL_WIDTH,
        leftMaxWidth = '50%',
        leftDefaultWidth,
        leftPanelWidthConfigurationPath = 'ui.toolbar-layout.left-panel.width',
        footerMinHeight,
        footerMaxHeight,
        footerDefaultHeight,
        footerPanelHeightConfigurationPath = 'ui.toolbar-layout.footer.height',
    } = props;

    const classNames = useClassNames('arg-toolbar-layout');

    const contentRef = useRef<HTMLDivElement>(null);
    const [, setContentWidth] = useState<number>();

    const [toolbarNodesLeft] = useToolNodes(toolbarContext, 'left');
    const [toolbarNodesRight] = useToolNodes(toolbarContext, 'right');

    const [leftPanel, setLeftPanel] = useState<Tool>();
    const [rightPanel, setRightPanel] = useState<Tool>();

    const [footerHeight, setFooterHeight] = useUserConfiguration<string | number>(
        footerPanelHeightConfigurationPath,
        footerDefaultHeight || DEFAULT_FOOTER_HEIGHT
    );

    const _leftPanelWidthConfigurationPath = leftPanel?.panelWidthConfigName || leftPanelWidthConfigurationPath;
    const [leftPanelWidth, setLeftPanelWidth] = useUserConfiguration<string | number>(
        _leftPanelWidthConfigurationPath,
        leftPanel?.panelDefaultWidth || leftDefaultWidth || DEFAULT_PANEL_WIDTH
    );

    const _rightPanelWidthConfigurationPath = rightPanel?.panelWidthConfigName || rightPanelWidthConfigurationPath;
    const [rightPanelWidth, setRightPanelWidth] = useUserConfiguration<string | number>(
        _rightPanelWidthConfigurationPath,
        rightPanel?.panelDefaultWidth || rightDefaultWidth || DEFAULT_PANEL_WIDTH
    );

    const leftDefaultSize = useMemo<Size>(() => {
        const ret = {
            width: leftPanelWidth,
            height: 'auto',
        };

        return ret;
    }, [leftPanelWidth]);

    const rightDefaultSize = useMemo<Size>(() => {
        const ret = {
            width: rightPanelWidth,
            height: 'auto',
        };

        return ret;
    }, [rightPanelWidth]);

    const [leftPanelSize, setLeftPanelSize] = useState<Size>(leftDefaultSize);
    useEffect(() => {
        setLeftPanelSize(leftDefaultSize);
    }, [_leftPanelWidthConfigurationPath]);

    const [rightPanelSize, setRightPanelSize] = useState<Size>(rightDefaultSize);
    useEffect(() => {
        setRightPanelSize(rightDefaultSize);
    }, [_rightPanelWidthConfigurationPath]);

    useResizeObserver(contentRef, (entry) => {
        const newClientRect = entry.target.getBoundingClientRect();

        setContentWidth(newClientRect.width);
    });

    useEffect(() => {
        const panel: Tool | undefined = walkNode(toolbarNodesLeft, (node) => {
            if (!node.selected) {
                return;
            }
            if (isToolDisabled(node) || !isToolVisible(node)) {
                return;
            }

            if (!node.panelRender) {
                return;
            }

            return node;
        });

        setLeftPanel(panel);
    }, [toolbarNodesLeft]);

    useEffect(() => {
        const panel: Tool | undefined = walkNode(toolbarNodesRight, (node) => {
            if (!node.selected) {
                return;
            }
            if (isToolDisabled(node) || !isToolVisible(node)) {
                return;
            }

            if (!node.panelRender) {
                return;
            }

            return node;
        });

        setRightPanel(panel);
    }, [toolbarNodesRight]);

    const handleRightPanelResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();
        setRightPanelWidth(bounds.width);
        setRightPanelSize((prev) => {
            const ret: Size = {
                width: (isNumber(prev.width) ? prev.width : 0) + delta.width,
                height: prev.height,
            };

            return ret;
        });
    }, [setRightPanelWidth]);

    const handleLeftPanelResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();
        setLeftPanelWidth(bounds.width);
        setLeftPanelSize((prev) => {
            const ret: Size = {
                width: (isNumber(prev.width) ? prev.width : 0) + delta.width,
                height: prev.height,
            };

            return ret;
        });
    }, [setLeftPanelWidth]);

    const handleFooterResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();
        setFooterHeight(bounds.height);
    }, [setFooterHeight]);

    const handleRenderError = useCallback((error: Error, errorInfo?: ErrorInfo) => {
        console.error('----------------------------------');
        console.error('error=', error);
        console.error('errorInfo=', errorInfo);

        if ((error as ArgonosError)?.code === INVALID_SESSION) {
            return <div className={classNames('&-content-error')}>
                <ErrorPane error={error}/>
            </div>;
        }

        return <div className={classNames('&-content-error')}>
            <ErrorPane error={error}>
                <FormattedMessage {...messages.errorInPane}/>
            </ErrorPane>
        </div>;
    }, [classNames]);

    const style: any = {
        '--left-width': `${Math.max(isNumber(leftMinWidth) ? leftMinWidth : DEFAULT_PANEL_WIDTH, toPx(leftPanel?.panelRender ? leftPanelWidth : 0))}px`,
        '--right-width': `${Math.max(isNumber(rightMinWidth) ? rightMinWidth : DEFAULT_PANEL_WIDTH, toPx(rightPanel?.panelRender ? rightPanelWidth : 0))}px`,
        '--footer-height': `${Math.max(isNumber(footerMinHeight) ? footerMinHeight : DEFAULT_FOOTER_HEIGHT, toPx(footer ? footerHeight : 0))}px`,
    };

    let footerContainer: ReactNode = null;
    if (footer) {
        if (enableFooterResize) {
            footerContainer = <Resizable
                key='footer-resizable'
                className={classNames('&-footer')}
                defaultSize={{
                    height: footerHeight,
                    width: 'auto',
                }}
                onResizeStop={handleFooterResized}
                enable={TOP_ENABLE}
                minHeight={footerMinHeight}
                maxHeight={footerMaxHeight}
            >
                <ArgErrorCatcher
                    renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
                >
                    {footer}
                </ArgErrorCatcher>
            </Resizable>;
        } else {
            footerContainer = (
                <div
                    key='footer'
                    className={classNames('&-footer')}
                >
                    <ArgErrorCatcher
                        renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
                    >
                        {footer}
                    </ArgErrorCatcher>
                </div>
            );
        }
    }

    return (
        <div className={classNames('&', className)}
             style={style}
             onDragStart={onDragStart}
             onDragEnd={onDragEnd}
        >
            {/* Toolbar */}
            <div key='toolbar' className={classNames('&-toolbar', toolbarClassName)}>
                <ArgToolbar
                    key='left'
                    className={classNames('&-toolbar-left')}
                    prefix='left'
                    disabled={disabled}
                    toolbarContext={toolbarContext}
                />
                <div className={classNames('&-toolbar-space')}/>
                <ArgToolbar
                    key='center'
                    className={classNames('&-toolbar-center')}
                    prefix='center'
                    disabled={disabled}
                    toolbarContext={toolbarContext}
                />
                <div className={classNames('&-toolbar-space')}/>
                <ArgToolbar
                    key='right'
                    className={classNames('&-toolbar-right')}
                    prefix='right'
                    disabled={disabled}
                    toolbarContext={toolbarContext}
                />
            </div>

            {/* Body */}
            <div key='body' className={classNames('&-body')}>
                {/* Left Panel */}
                {leftPanel?.panelRender && (
                    <Resizable
                        key='left-panel'
                        className={classNames('&-body-left-panel')}
                        defaultSize={leftDefaultSize}
                        size={leftPanelSize}
                        onResizeStop={handleLeftPanelResized}
                        minWidth={leftMinWidth}
                        maxWidth={leftMaxWidth}
                        enable={LEFT_ENABLE}
                    >
                        <div className={classNames('&-body-left-panel-content')}>
                            <ArgErrorCatcher
                                renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
                            >
                                {leftPanel.panelRender(leftPanel)}
                            </ArgErrorCatcher>
                        </div>
                    </Resizable>
                )}

                {/* Content */}
                <div key='content' ref={contentRef} className={classNames('&-body-content', bodyClassName)}>
                    <ArgErrorCatcher renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}>
                        {children}
                    </ArgErrorCatcher>
                </div>

                {/* Right panel */}
                {rightPanel?.panelRender && (
                    <Resizable
                        key='right-panel'
                        className={classNames('&-body-right-panel')}
                        defaultSize={rightDefaultSize}
                        size={rightPanelSize}
                        onResizeStop={handleRightPanelResized}
                        minWidth={rightMinWidth}
                        maxWidth={rightMaxWidth}
                        enable={RIGHT_ENABLE}
                    >
                        <div className={classNames('&-body-right-panel-content')}>
                            <ArgErrorCatcher
                                renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
                            >
                                {rightPanel.panelRender(rightPanel)}
                            </ArgErrorCatcher>
                        </div>
                    </Resizable>
                )}
            </div>

            {/* Footer */}
            {footerContainer}
        </div>
    );
}
