import { isEmpty, isNil } from 'lodash';
import { defineMessages, MessageDescriptor } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';

// Components and hooks
import {
    ArgButton,
    ArgFormLabel,
    ArgInputText,
    ArgModal,
    ArgPlaceholderText,
    ArgRenderedText,
    ButtonClickEvent,
    ProgressMonitor,
    ProgressMonitorName,
    ProgressMonitorOptions,
    useCallbackAsync,
    useClassNames,
} from '../../basic';

// Styles
import './naming-modal.less';


export const messages = defineMessages({
    validate: {
        id: 'common.naming-modal.ValidateButton',
        defaultMessage: 'Validate',
    },
    cancel: {
        id: 'common.naming-modal.CancelButton',
        defaultMessage: 'Cancel',
    },
    ok: {
        id: 'common.naming-modal.OkButton',
        defaultMessage: 'OK',
    },
    create: {
        id: 'common.naming-modal.CreateButton',
        defaultMessage: 'Create',
    },
    rename: {
        id: 'common.naming-modal.RenameButton',
        defaultMessage: 'Rename',
    },
});

export interface NamingModalProps {
    onSubmit: (value: string, progressMonitor: ProgressMonitor) => Promise<void>;
    onClose: () => void;
    initialValue?: string;
    value?: string;
    showErrors?: boolean;
    isNameInvalid?: (value?: string) => boolean;
    invalidNameDescription?: ArgRenderedText;
    title?: ArgRenderedText;
    label?: ArgPlaceholderText;
    description?: MessageDescriptor;
    placeholder?: ArgPlaceholderText;
    confirmText?: ArgRenderedText;
    cancelText?: ArgRenderedText;
    allowEmptyValue?: boolean;
    formId?: string;
    inputId?: string;
    maxLength?: number;

    progressMonitorName?: ProgressMonitorName;
    progressMonitorOptions?: ProgressMonitorOptions;
}

export function NamingModal(props: NamingModalProps) {
    const {
        label,
        title,
        onClose,
        onSubmit,
        placeholder,
        maxLength,
        showErrors,
        invalidNameDescription,
        isNameInvalid,
        description,
        initialValue,
        value: externalValue,
        confirmText = messages.validate,
        cancelText,
        allowEmptyValue,
        formId,
        inputId,
        progressMonitorName,
        progressMonitorOptions,
    } = props;

    // Value
    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<string | undefined>(initialValue);

    const value = useInternalValue ? internalValue : externalValue;

    const isValueInvalid = isNameInvalid?.(value);

    const classNames = useClassNames('arg-naming-modal');

    const [callSubmit, progressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        if (!allowEmptyValue && (isNil(value) || !value.trim().length)) {
            return;
        }

        await onSubmit(value || '', progressMonitor);

        onClose();
    }, [allowEmptyValue, onClose, onSubmit, value], progressMonitorName, 1, progressMonitorOptions);

    const handleSubmit = useCallback((event: ButtonClickEvent | React.FormEvent | undefined) => {
        event?.preventDefault();
        event?.stopPropagation();

        callSubmit().catch((error) => {
            console.error(error);
        });
    }, [callSubmit]);

    const handleCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (isNil(value)) {
            setInternalValue(initialValue);
        }
    }, [initialValue, value]);

    return (
        <ArgModal
            size='medium'
            title={title}
            visible={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            onClose={handleCancel}
            className={classNames('&')}
            footer={
                <div className={classNames('&-footer')} data-testid='naming-modal-footer'>
                    <ArgButton
                        className={classNames('&-footer-btn')}
                        type='secondary'
                        onClick={handleCancel}
                        data-testid='naming-modal-cancel-button'
                        label={cancelText || messages.cancel}
                    />
                    <ArgButton
                        className={classNames('&-footer-btn')}
                        type='primary'
                        htmlType='submit'
                        onClick={handleSubmit}
                        label={confirmText}
                        loading={progressMonitor?.isRunning}
                        disabled={progressMonitor?.isRunning || isValueInvalid || (!allowEmptyValue &&
                            (
                                isNil(value) ||
                                isEmpty(value) ||
                                value.trim().length === 0
                            )
                        )}
                        data-testid='naming-modal-ok-button'
                    />
                </div>
            }
        >
            <form onSubmit={handleSubmit} id={formId}>
                <ArgFormLabel
                    propertyName={label}
                    description={isValueInvalid ? invalidNameDescription : description}
                    className={classNames('&-form-label', { 'has-error': isValueInvalid })}
                >
                    <ArgInputText
                        id={inputId}
                        value={value}
                        maxLength={maxLength || 128}
                        autoFocus={true}
                        state={showErrors && isValueInvalid ? 'invalid' : undefined}
                        onInputChange={setInternalValue}
                        className={classNames('&-input')}
                        placeholder={placeholder || label}
                        data-testid='naming-modal-input'
                        htmlAutoComplete={inputId ? 'on' : undefined}
                        performChangeOnInput={!!inputId}
                    />
                </ArgFormLabel>
            </form>
        </ArgModal>
    );
}
