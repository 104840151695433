import { useCallback, useContext } from 'react';

import { UseConfigurationReturnType, useConfiguration, useConfigurations } from './use-configuration';
import { UserConfigurations, UserConfigurationsContext } from '../contexts/user-configuration';
import { ConfigurationPath } from '../model/configurations';
import { UsersConnector } from '../utils/connectors/users-connector';
import { ProgressMonitor } from '../components/basic';

export function useUserConfiguration<T>(
    path: ConfigurationPath,
    initialConfiguration: T | (() => T)
): UseConfigurationReturnType<T> {
    // Whole configurations object
    const configurations = useContext(UserConfigurationsContext);
    if (!configurations) {
        throw new Error('UserConfigurationContext is not defined');
    }

    return useConfiguration(configurations, path, initialConfiguration);
}

export function useUserConfigurations(usersConnector: UsersConnector):
    readonly [UserConfigurations | undefined, ProgressMonitor | undefined, Error | undefined] {
    const newUserConfigurations = useCallback(() => {
        const configurations = new UserConfigurations(usersConnector);

        return configurations;
    }, [usersConnector]);

    const ret = useConfigurations(newUserConfigurations);

    return ret;
}
