import { max } from 'lodash';
import { ReactNode, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Debug from 'debug';

import { useLatestCallback } from '../arg-hooks/use-latest-callback';
import { useClassNames } from '../arg-hooks/use-classNames';

import './use-horizontal-scroll.less';

const CLASS_NAME = 'arg-table4-horizontal-scroll';

const debug = Debug('arg-table4-horizontal-scrollbar');

export function useHorizontalScroll(scrollables: Array<Element|null>): ReactNode {
    const classNames = useClassNames(CLASS_NAME);
    const scrollRef = useRef<HTMLDivElement>(null);

    const update = useLatestCallback(() => {
        if (scrollRef.current) {
            const scrollLeft = scrollRef.current?.scrollLeft;
            // TODO: there is perf issue leading to unpleasant trembling while scrolling caused by the below line. Carefully using requestAnimationFrame and throttling clearing is not enough.
            scrollables.forEach(el => {
                if (el) {
                    el.scrollLeft = scrollLeft;
                }
            });
        }
    });

    useEffect(() => {
        update();
    }, [...scrollables]);

    useEffect(() => {
        let ticking = false;
        const onScroll = () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    ticking = false;

                    update();
                });
                ticking = true;
            }
        };

        scrollRef.current?.addEventListener('scroll', onScroll);

        return () => {
            scrollRef.current?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const maxScrollWidth = useMemo(() => max(scrollables.map(el => el?.scrollWidth)), [...scrollables]);

    const style = {
        width: `${maxScrollWidth}px`,
    };

    const hasScrollbar = useMemo(() => scrollables.some(el => {
        if (!el || !el.scrollWidth) {
            return false;
        }

        return el.scrollWidth > el.clientWidth;
    }), [...scrollables]);

    const cls = {
        hide: !hasScrollbar,
    };

    const component = (
        <div
            className={classNames('&', cls)}
            ref={scrollRef}
        >
            <div style={style}/>
        </div>
    );

    return component;
}
