import React from 'react';
import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgButton } from '../arg-button/arg-button';

import './arg-snackbar.less';

const messages = defineMessages({
    cancel: {
        id: 'basic.arg-snackbar.cancel.Title',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'basic.arg-snackbar.confirm.Title',
        defaultMessage: 'Ok',
    },
});

type ArgSnackbarPlacement = 'top' | 'bottom'

interface ArgSnackbarProps {
    visible: boolean;
    placement?: ArgSnackbarPlacement;
    className?: ClassValue;

    title: ArgRenderedText;
    cancelText?: ArgRenderedText;
    confirmText?: ArgRenderedText;

    messageValues?: ArgMessageValues;
    onCancel?: () => void;
    onConfirm?: () => void;
}
export const ArgSnackbar = (props: ArgSnackbarProps) => {
    const {
        visible,
        className,
        placement = 'top',
        title,
        messageValues,
        cancelText = messages.cancel,
        onCancel,
        confirmText = messages.confirm,
        onConfirm,
    } = props;

    const classNames = useClassNames('arg-snackbar');

    const cls = {
        top: placement === 'top',
        bottom: placement === 'bottom',
    };

    if (!visible) {
        return null;
    }

    return (
        <div className={classNames('&', cls, className)}>
            <span className={classNames('&-title')}>
                {renderText(title, messageValues)}
            </span>
            <div className={classNames('&-buttons')}>
                <ArgButton
                    type='ghost'
                    className={classNames('&-buttons-cancel')}
                    label={renderText(cancelText, messageValues)}
                    onClick={onCancel}
                />
                <ArgButton
                    type='ghost'
                    className={classNames('&-buttons-confirm')}
                    label={renderText(confirmText, messageValues)}
                    onClick={onConfirm}
                />
            </div>
        </div>
    );
};
