import React, { useContext, useEffect, useMemo, useState } from 'react';
import Debug from 'debug';

import { DataCacheRepository } from './data-cache-repository';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { StateId } from 'src/utils/states/basic-state';
import { isString } from 'lodash';

const debug = Debug('basic:cache-repositories:UseDataCacheRepository');

export type DataCacheRepositoryResponse<T> = [T | undefined | null, Error | undefined | null, ProgressMonitor | undefined];

export function useDataCacheRepository<T extends Object, K = string>(
    infos: K | string | undefined,
    stateId: StateId | undefined,
    context: React.Context<DataCacheRepository<T>>
): DataCacheRepositoryResponse<T> | undefined {
    const [dataOrError, setDataOrError] = useState<DataCacheRepositoryResponse<T | null>>();

    const dataRepository = useContext(context);

    const key: string | undefined = useMemo(() => {
        if (!infos) {
            return undefined;
        }
        if (isString(infos)) {
            return infos;
        }

        return JSON.stringify(infos);
    }, [infos]);

    useEffect(() => {
        debug('useDataCacheRepository', 'dataRepository=', dataRepository, 'key=', key);
        if (!dataRepository || !key) {
            // Loading manually
            return;
        }

        const _key = key;

        let linked = false;

        function handleDataLoaded(data?: T | null, error?: Error | null) {
            debug('useDataCacheRepository', 'dataLoaded key=', _key, 'data=', data, 'error=', error);

            setDataOrError([data, error, undefined]);

            if (linked) {
                console.error('*** PANIC, ALREADY LINKED');
            }
            linked = true;
            dataRepository.link(_key);
        }

        const [data, error, progressMonitor] = dataRepository.load(key, infos, stateId);
        debug('useDataCacheRepository', 'load() key=', _key, 'data=', data, 'error=', error, 'progressMonitor=', progressMonitor);

        if (progressMonitor) {
            dataRepository.once(`loaded:${_key}`, handleDataLoaded);

            setDataOrError([undefined, undefined, progressMonitor]);
        } else if (data !== undefined || error) {
            handleDataLoaded(data, error);
        } else {
            dataRepository.once(`loaded:${_key}`, handleDataLoaded);

            setDataOrError(undefined);
        }

        return () => {
            debug('useDataCacheRepository', 'release key=', _key, 'linked=', linked);

            dataRepository.removeListener(`loaded:${_key}`, handleDataLoaded);

            if (linked) {
                dataRepository.unlink(_key);
            }
        };
    }, [key, dataRepository, stateId]);

    if (!dataRepository) {
        return undefined;
    }

    return dataOrError;
}
