import React, { useCallback, useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { useMap } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import { defineMessages } from 'react-intl';

// Components and hooks
import { LatLngExpressionToLatLng } from '../../../../helpers/LatLngExpressionToLatLng';
import { useClassNames } from '../../../../../arg-hooks/use-classNames';
import { ArgButton } from 'src/components/basic/arg-button/arg-button';

// Styles
import './arg-map-center-button.less';

interface ArgMapCenterButtonProps {
    center?: LatLngExpression;
}

const messages = defineMessages({
    center: {
        id: 'basic.arg-map-center-button.center',
        defaultMessage: 'Center on the current marker',
    },
});

export const ArgMapCenterButton: React.FunctionComponent<ArgMapCenterButtonProps> = ({
    center,
}) => {
    const map = useMap();

    const [isCentered, setCentered] = useState<boolean>(false);

    const classNames = useClassNames('arg-map-center-button');

    const handleClick = useCallback(() => {
        const center_ = LatLngExpressionToLatLng(center);

        if (center_) {
            map.flyTo(center_,
                map.getZoom() < 10 ? 10 : map.getZoom(), //Zoom
                {
                    animate: true,
                    duration: 0.5,
                }
            );
        }
    }, [center, map]);

    const handleChanged = useCallback(() => {
        const mapCenter = LatLngExpressionToLatLng(center);

        if (!isNil(mapCenter)) {
            // Is the map center out of the view bounds
            const isOverflow = !map.getBounds().contains(mapCenter);

            // Is the marker is too far from the view center (distance in meters)
            const isFar = map.getCenter().distanceTo(mapCenter) > 1000;

            setCentered(!isOverflow && !isFar);
        }
    }, [center, map]);

    useEffect(() => {
        map.on('moveend', handleChanged);
        map.on('zoomend', handleChanged);
        map.on('dragend', handleChanged);

        return () => {
            map.off('moveend', handleChanged);
            map.off('zoomend', handleChanged);
            map.off('dragend', handleChanged);
        };
    }, [handleChanged, map]);

    useEffect(() => {
        handleChanged();
    }, [handleChanged]);

    return (
        <ArgButton
            type='ghost'
            onClick={handleClick}
            tooltip={messages.center}
            className={classNames('&')}
            icon='icon-center-direction'
            hidden={isNil(center) || isCentered}
        />
    );
};
