import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { OnErrorFn } from '@formatjs/intl';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';

import { UserLocaleConfig, UserLocaleContext } from './contexts/user-locale-context';

const ANTD_THEME = {
    token: {
        colorPrimary: '#39939E',
    },
};
const onIntlProviderError: OnErrorFn = (error) => {
    // Do nothing (prevent console.error logs when translations are missing)
    if (localStorage?.SHOW_MISSING_TRANSLATION_ERROR) {
        console.error(error);
    }
};

interface IntlContextProviderProps {
    userLocale: string;
    messages: Record<string, string> | null;

    children: ReactNode;
}

export function IntlContextProvider(props: IntlContextProviderProps) {
    const {
        userLocale,
        messages,
        children,
    } = props;

    return (
        <IntlProvider
            key={userLocale}
            locale={userLocale}
            messages={messages || undefined}
            onError={onIntlProviderError}
        >
            {children}
        </IntlProvider>
    );
}

interface AntdConfigProviderProps {
    antdLocale: Locale;

    children: ReactNode;
}

export function AntdConfigProvider(props: AntdConfigProviderProps) {
    const {
        antdLocale,
        children,
    } = props;

    return (
        <ConfigProvider
            locale={antdLocale}
            theme={ANTD_THEME}
        >
            {children}
        </ConfigProvider>
    );
}


interface UserLocaleProviderProps {
    userLocaleConfig: UserLocaleConfig;

    children: ReactNode;
}

export function UserLocaleProvider(props: UserLocaleProviderProps) {
    const {
        userLocaleConfig,
        children,
    } = props;

    return (
        <UserLocaleContext.Provider value={userLocaleConfig}>
            {children}
        </UserLocaleContext.Provider>
    );
}
