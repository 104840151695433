import React from 'react';
import { defineMessages } from 'react-intl';
import { ArgonosError, INVALID_SESSION, useClassNames } from '../../basic';
import { BasicPaneI18n, BasicPaneI18nProps } from './basic-pane-i18n';

import './error-pane.less';

const DEFAULT_ICON = 'icon-invalid';

const messages = defineMessages({
    invalidSession: {
        id: 'common.errorPage.InvalidMessage',
        defaultMessage: 'You have been disconnected, please login.',
    },
    defaultMessage: {
        id: 'common.errorPage.Message',
        defaultMessage: 'Oops, something went wrong,{newline} please retry in few minutes.',
    },
});

interface ErrorPaneProps extends BasicPaneI18nProps {
    error?: Error;
}

export function ErrorPane(props: ErrorPaneProps) {
    const {
        className,
        error,
        children,
        message,
        icon = DEFAULT_ICON,
        ...otherProps
    } = props;

    const classNames = useClassNames('error-pane');

    let _message;
    if (children) {
        _message = children;
    } else if ((error as ArgonosError)?.code === INVALID_SESSION) {
        _message = messages.invalidSession;
    } else if (message) {
        _message = message;
    } else {
        _message = messages.defaultMessage;
    }

    return (
        <BasicPaneI18n className={classNames('&', className)}
                       message={_message}
                       icon={icon}
                       {...otherProps}
        />
    );
}
