import React, {
    CSSProperties,
    KeyboardEvent,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { Popover, Tooltip } from 'antd';
import { isNil } from 'lodash';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { PopoverProps } from 'antd/lib/popover';
import { isElement } from 'react-is';
import { ActionType } from 'rc-trigger/lib/interface';

import { ArgIcon, renderIcon } from '../arg-icon/arg-icon';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import {
    DEFAULT_BUTTON_TYPE,
    DEFAULT_POPOVER_PLACEMENT,
    DEFAULT_SIZE,
    DEFAULT_TOOLTIP_BUTTON_DELAY,
    DEFAULT_TOOLTIP_PLACEMENT,
    defaultBuiltinPlacements,
    EMPTY_POPOVER_TRIGGER,
} from '../defaults';
import {
    ArgButtonHTMLType,
    ArgButtonType,
    ArgRenderedIcon,
    ArgRenderedText,
    ArgRenderFunction,
    FocusableElement,
} from '../types';
import { findOrCreatePopupArea, getDataTestIdFromProps } from '../utils';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { useClassNames } from '../arg-hooks/use-classNames';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { useKeyBinding } from '../keybindings/use-keybinding';
import { KeyBindingKeys } from '../keybindings/keybinding-keys';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgComponentProps } from '../arg-component/arg-component';
import { ArgBadges } from '../arg-badge/arg-badge';
import { getMessageValuesWithFormatters, renderText } from '../utils/message-descriptor-formatters';
import { useIsDragging } from '../utils/use-is-dragging';

import './arg-button.less';

export const LONG_CLICK_ACTION_TYPE: ActionType = 'longClick';

export type ButtonClickEvent = KeyboardEvent | MouseEvent;

const DEFAULT_HTML_TYPE = 'button';

export interface ArgButtonProps extends ArgComponentProps {
    id?: string;
    htmlTitle?: string;
    label?: ArgRenderedText;
    htmlType?: ArgButtonHTMLType;
    type?: ArgButtonType;
    icon?: ArgRenderedIcon;
    loading?: boolean;
    progressMonitor?: ProgressMonitor;

    onClick?: (event: ButtonClickEvent) => void;
    onLongClick?: MouseEventHandler<HTMLElement>;
    onMouseEnter?: MouseEventHandler<HTMLElement>;
    onMouseLeave?: MouseEventHandler<HTMLElement>;

    tabIndex?: number;

    buttonRef?: Ref<FocusableElement | null>;
    autoFocus?: boolean;
    right?: 'dropdown' | ReactNode | ArgRenderFunction;
    left?: ReactNode | ArgRenderFunction;

    /**
     * @deprecated
     */
    style?: CSSProperties;
    underline?: boolean;

    keyBinding?: KeyBindingDescriptor;

    // Sometime we need to have a Click on mousedown event (before the blur event of the other input)
    // clickOnMouseDown is incompatible with longClick
    clickOnMouseDown?: boolean;

    onShiftClick?: (event: ButtonClickEvent) => void;
    onAltClick?: (event: ButtonClickEvent) => void;
    onCtrlClick?: (event: ButtonClickEvent) => void;
    shiftKeyBinding?: KeyBindingDescriptor;
    altKeyBinding?: KeyBindingDescriptor;
    ctrlKeyBinding?: KeyBindingDescriptor;

    iconBadges?: ArgBadges;
}

function _ArgButton(props: ArgButtonProps) {
    const {
        id,
        htmlTitle,
        size = DEFAULT_SIZE,
        className,
        type = DEFAULT_BUTTON_TYPE,
        htmlType = DEFAULT_HTML_TYPE,
        icon,
        children,
        progressMonitor,
        hidden,
        loading,
        disabled,
        label,
        messageValues,
        onClick,
        onLongClick,
        onMouseEnter,
        onMouseLeave,
        tooltip,
        tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT,
        tooltipClassName,
        tooltipBuiltinPlacements,
        onTooltipVisibleChange,
        popover,
        popoverPlacement = DEFAULT_POPOVER_PLACEMENT,
        popoverTrigger,
        popoverVisible,
        popoverTitle,
        onPopoverVisibleChange,
        popoverClassName,
        popoverArrowPointAtCenter,
        popoverBuiltinPlacements = defaultBuiltinPlacements,
        getPopoverContainer,
        buttonRef,
        autoFocus,
        right,
        left,
        tabIndex,
        style,
        underline,
        keyBinding,
        clickOnMouseDown,
        onShiftClick,
        onAltClick,
        onCtrlClick,
        shiftKeyBinding,
        ctrlKeyBinding,
        altKeyBinding,
        iconBadges,
    } = props;

    const intl = useIntl();

    const useInternalPopoverVisible = !('popoverVisible' in props);
    const [internalPopoverVisible, setInternalPopoverVisible] = useState<boolean | undefined>(undefined);

    const unmountedRef = useRef<boolean>();

    const hasPopover = 'popover' in props;
    const hasLabel = (label !== undefined) || children;
    const hasLoading = progressMonitor?.isRunning || loading;
    const hasIcon = !isNil(icon);

    const classNames = useClassNames('arg-button');
    const dataTestId = getDataTestIdFromProps(props);

    const myButtonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(buttonRef, () => (myButtonRef?.current as FocusableElement | null));

    const handleKeyBinding = useCallback((event: KeyboardEvent) => {
        if (myButtonRef.current) {
            // TODO longclick + clickOnMouseDown
            myButtonRef.current.click();

            return;
        }
        onClick?.(event);
    }, [onClick]);

    useKeyBinding(keyBinding, handleKeyBinding, !disabled);

    useKeyBinding(shiftKeyBinding, onShiftClick, !disabled);

    useKeyBinding(altKeyBinding, onShiftClick, !disabled);

    useKeyBinding(ctrlKeyBinding, onShiftClick, !disabled);

    const isDragging = useIsDragging();

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    useLayoutEffect(() => {
        if (!autoFocus) {
            return;
        }
        const timerId = setTimeout(() => {
            myButtonRef.current?.focus();
        }, 200);

        return () => {
            clearTimeout(timerId);
        };
    }, [autoFocus]);

    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        setInternalPopoverVisible(visible);

        onPopoverVisibleChange?.(visible);
    }, [onPopoverVisibleChange, setInternalPopoverVisible]);


    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>, forceEvent?: boolean) => {
        if (event.defaultPrevented && !forceEvent) {
            return;
        }

        setInternalPopoverVisible(false);

        try {
            if (onAltClick && event.altKey) {
                onAltClick(event);

                return;
            }

            if (onCtrlClick && event.ctrlKey) {
                onCtrlClick(event);

                return;
            }

            if (onShiftClick && event.shiftKey) {
                onShiftClick(event);

                return;
            }

            onClick?.(event);
        } finally {
            if (htmlType !== 'submit') {
                event.preventDefault();
            }
        }
    }, [onClick, onShiftClick, onAltClick, onCtrlClick, htmlType, setInternalPopoverVisible]);


    const handleMouseDownLongClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        const button = event.target;

        function removeHandlers() {
            clearTimeout(timeoutId);
            button.removeEventListener('mouseup', mouseUp, true);
            button.removeEventListener('mouseleave', mouseLeave, true);
        }

        const timeoutId = setTimeout(() => {
            if (unmountedRef.current) {
                return;
            }
            removeHandlers();

            onLongClick && onLongClick(event);

            if (popoverTrigger === LONG_CLICK_ACTION_TYPE && popover) {
                setInternalPopoverVisible(true);

                onPopoverVisibleChange && onPopoverVisibleChange(true);
            }
        }, 500);

        const mouseUp = () => {
            removeHandlers();

            handleClick(event, true);
        };

        const mouseLeave = () => {
            removeHandlers();
        };

        button.addEventListener('mouseup', mouseUp, true);
        button.addEventListener('mouseleave', mouseLeave, true);
    }, [handleClick, onLongClick, onPopoverVisibleChange, popover, popoverTrigger]);

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (typeof (getPopoverContainer) === 'function') {
            const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopoverContainer?.current) {
            return getPopoverContainer?.current!;
        }

        if (myButtonRef.current) {
            return findOrCreatePopupArea(myButtonRef.current) || myButtonRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopoverContainer]);

    if (label === undefined && icon === undefined && !children && !keyBinding && !loading) {
        throw new Error('Label or icon must be specified');
    }

    if (hidden) {
        return null;
    }

    const cls = {
        'has-label': hasLabel,
        'has-icon': hasIcon,
        'has-loading': hasLoading,
        'has-popover': hasPopover,
        'disabled': disabled,
        'has-dropdown': right === 'dropdown',
        'no-click-handler': !popover && !onClick && !onAltClick && !onShiftClick && !onCtrlClick && !onLongClick, // Can be a button, but no associated handler ???
        [`type-${type}`]: true,
        [`size-${size}`]: true,
    };

    let _labelNode = label || children;

    if (_labelNode === undefined && !hasIcon && keyBinding) {
        _labelNode = keyBinding.name;
    }

    const _messageValues = getMessageValuesWithFormatters(messageValues);
    const labelNode: ReactNode = renderText(_labelNode, _messageValues);

    const buttonHandlers: Record<string, any> = {};
    if (onLongClick || popoverTrigger === LONG_CLICK_ACTION_TYPE) {
        if (clickOnMouseDown) {
            throw new Error('onLongClick and clickOnMouseDown can not be enabled in same time');
        }
        buttonHandlers.onMouseDown = handleMouseDownLongClick;
    } else if (onClick || onShiftClick || onAltClick || onCtrlClick) {
        if (clickOnMouseDown) {
            buttonHandlers.onMouseDown = handleClick;
        } else {
            buttonHandlers.onClick = handleClick;
        }
    }

    if (onMouseEnter) {
        buttonHandlers.onMouseEnter = onMouseEnter;
    }

    if (onMouseLeave) {
        buttonHandlers.onMouseLeave = onMouseLeave;
    }

    let _left = left;
    if (typeof (left) === 'function') {
        _left = left();
    }

    let _right = right;
    if (typeof (right) === 'function') {
        _right = right();
    }

    let labelClass = classNames('&-label');
    if (underline) {
        labelClass += ' underline';
    }

    let button;
    const content =
        <>
            {_left}

            {/* Icon */}
            {hasIcon && !hasLoading && <span key='icon' className={classNames('&-icon-container')}>
                {renderIcon(icon, classNames('&-icon'))}
                {iconBadges}
            </span>}

            {/* Loading depending on progress monitor */}
            {hasLoading &&
                <span
                    key='loading'
                    className={classNames('&-loading-container')}
                    data-testid='arg-button-loading-state'
                >
                    <ThreeDotsLoading className={classNames('&-loading')}/>
                </span>}

            {/* Label */}
            {hasLabel && <span key='label' className={labelClass}>
                {labelNode}
            </span>}

            {_right === 'dropdown' && <span key='dropdown' className={classNames('&-dropdown')}>
                <ArgIcon className={classNames('&-dropdown-icon')} name='icon-triangle-down'/>
            </span>}
            {isElement(_right) && _right}
        </>;

    button =
        <button
            ref={myButtonRef}
            key='button'
            style={style}
            id={id}
            title={htmlTitle}
            disabled={disabled}
            className={classNames('&', cls, className)}
            {...buttonHandlers}
            tabIndex={tabIndex}
            data-testid={dataTestId}
            type={htmlType}
        >
            {content}
        </button>;

    let _popoverTrigger: ActionType | ActionType[] = 'click';
    let _popoverVisible;
    if (hasPopover && disabled !== true) {
        if (popoverTrigger === false) {
            _popoverTrigger = EMPTY_POPOVER_TRIGGER;
        } else if (popoverTrigger === LONG_CLICK_ACTION_TYPE) {
            _popoverTrigger = EMPTY_POPOVER_TRIGGER;
        } else if (popoverTrigger) {
            _popoverTrigger = popoverTrigger;
        }

        if (useInternalPopoverVisible) {
            _popoverVisible = internalPopoverVisible;
        } else {
            _popoverVisible = popoverVisible;
        }

        if (_popoverVisible && popoverTrigger === LONG_CLICK_ACTION_TYPE) {
            _popoverTrigger = 'click';
        }
    }

    let tooltipContent = (tooltip === true) ? (labelNode) : tooltip;
    if (tooltipContent && !isDragging && (!_popoverVisible || !hasSameSide(tooltipPlacement, popoverPlacement))) {
        if (isMessageDescriptor(tooltipContent)) {
            if (keyBinding && !disabled) {
                tooltipContent = function renderTooltip() {
                    return <div className={classNames('&-tooltip-keys')}>
                        <div className={classNames('&-tooltip-keys-message')}>
                            <FormattedMessage {...tooltip as MessageDescriptor} values={_messageValues}/>
                        </div>
                        <KeyBindingKeys keyBindingDescriptor={keyBinding}
                                        className={classNames('&-tooltip-keybinding')}/>
                    </div>;
                };
            } else {
                tooltipContent = (
                    <FormattedMessage {...tooltipContent as MessageDescriptor} values={_messageValues}/>
                );
            }
        }
        button =
            <Tooltip
                key='tooltip'
                className={classNames('&-tooltip', 'arg-tooltip')}
                title={renderText(tooltipContent, messageValues)}
                overlayClassName={classNames('&-tooltip-overlay', tooltipClassName)}
                placement={tooltipPlacement}
                onOpenChange={onTooltipVisibleChange}
                builtinPlacements={tooltipBuiltinPlacements}
                mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
                getPopupContainer={computePopoverContainer}
            >
                {button}
            </Tooltip>;
    }

    if (hasPopover && disabled !== true && (_popoverTrigger || _popoverVisible)) {
        const title = isMessageDescriptor(popoverTitle)
            ? intl.formatMessage(popoverTitle, _messageValues)
            : popoverTitle;

        const popoverProps: PopoverProps = {
            content: renderText(popover, messageValues),
            className: classNames('&-popover', 'arg-popover'),
            overlayClassName: classNames('&-popover-overlay', 'arg-popover-overlay', popoverClassName),
            placement: popoverPlacement,
            title: title,
            onOpenChange: handlePopoverVisibleChange,
            destroyTooltipOnHide: true,
            arrowPointAtCenter: popoverArrowPointAtCenter,
            trigger: _popoverTrigger,
            builtinPlacements: popoverBuiltinPlacements,
            getPopupContainer: computePopoverContainer,
        };
        if (_popoverVisible !== undefined) {
            popoverProps.open = _popoverVisible;
            if (_popoverVisible && !popoverProps.trigger) {
                popoverProps.trigger = 'click';
            }
        }

        if (popoverVisible !== undefined) {
            popoverProps.open = popoverVisible;
        }

        button = <Popover {...popoverProps}>
            {button}
        </Popover>;
    }

    return button;
}

function getVerticalPosition(placement: TooltipPlacement): string | undefined {
    switch (placement) {
        case 'top':
        case 'topLeft':
        case 'topRight':
        case 'leftTop':
        case 'rightTop':
            return 'top';
        case 'bottom':
        case 'bottomLeft':
        case 'bottomRight':
        case 'leftBottom':
        case 'rightBottom':
            return 'top';
        case 'left':
        case 'right':
            return 'center';
    }

    return undefined;
}

function getHorizontalPosition(placement: TooltipPlacement): string | undefined {
    switch (placement) {
        case 'left':
        case 'leftTop':
        case 'leftBottom':
        case 'topLeft':
        case 'bottomLeft':
            return 'left';

        case 'right':
        case 'rightTop':
        case 'rightBottom':
        case 'topRight':
        case 'bottomRight':
            return 'top';

        case 'bottom':
        case 'top':
            return 'center';
    }

    return undefined;
}

function hasSameSide(placement1: TooltipPlacement, placement2: TooltipPlacement) {
    if (getVerticalPosition(placement1) === getVerticalPosition(placement2)) {
        return true;
    }

    if (getHorizontalPosition(placement1) === getHorizontalPosition(placement2)) {
        return true;
    }

    return false;
}

export const ArgButton = _ArgButton; // React.memo(_ArgButton);

