import Debug from 'debug';
import { isEmpty, isEqual, isNil } from 'lodash';
import { IntlShape, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import computeArea from '@turf/area';
import L from 'leaflet';

// Components and hooks
import { ArgInputType, ArgPlaceholderText, ArgRenderedText } from '../types';
import { getDataTestIdFromProps } from '../utils';
import { ArgInput } from '../arg-input/arg-input';
import { ArgButton } from '../arg-button/arg-button';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { GeoValue, RadiusUnit } from './model/geolocation-value';
import { LatitudeAndLongitudeResponse } from './model/geolocation-json';
import { formatRadius } from './helpers/format-radius';
import { ArgGeolocationForm2 } from './components/form/arg-geolocation-form-2';
import { argGeolocationPickerMessages as messages } from './arg-geolocation-picker';

// Styles
import './arg-geolocation-picker.less';

export interface ArgGeolocationPicker2Props {
    className?: ClassValue;
    readOnly?: boolean;
    tooltip?: ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    disabled?: boolean;
    type?: ArgInputType;
    value?: GeoValue;
    autoFocus?: boolean;
    onChange?: (value: (GeoValue | undefined) | ((prev: GeoValue | undefined) => GeoValue | undefined)) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
    placeholder?: ArgPlaceholderText;
    clearable?: boolean;
}

const debug = Debug('argonode:components:Geolocation-picker');

export const ArgGeolocationPicker2: React.FunctionComponent<ArgGeolocationPicker2Props> = (props) => {
    const {
        onChange,
        className,
        value: externalValue,
        readOnly,
        disabled,
        type,
        autoFocus,
        getAddressCoordinates,
        placeholder,
        tooltip,
        tooltipPlacement,
        clearable,
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('arg-geolocation-picker');

    const useInternalValue = !('value' in props);

    const [internalValue, setInternalValue] = useState<GeoValue>();

    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const dataTestId = getDataTestIdFromProps(props);

    const value = useInternalValue ? internalValue : externalValue;

    const handleClear = useCallback(() => {
        // Clear the internal value
        setInternalValue(undefined);

        // Close the popover
        setPopoverVisible(false);

        // Clear the form values
        onChange?.(undefined);
    }, [onChange]);

    // Input with formatted changed
    const handleChange = useCallback((value: GeoValue | undefined | ((value: GeoValue | undefined) => GeoValue | undefined)) => {
        const setAction = (prevValue: GeoValue | undefined) => {
            let newValue;
            if (typeof (value) === 'function') {
                newValue = value(prevValue || {});
            } else {
                newValue = value;
            }

            if (isEqual(prevValue, newValue)) {
                debug('handleChange', 'Ignore prevValue=', prevValue);

                return prevValue;
            }

            debug('handleChange', 'CHANGE prevValue=', prevValue, 'newValue=', newValue);

            return newValue;
        };

        setInternalValue(setAction);
        onChange?.(setAction);
    }, [onChange]);


    const [radiusUnit, setRadiusUnit] = useState(RadiusUnit.Kilometer);
    const handleRadiusUnitChange = useCallback((radiusUnit: RadiusUnit = RadiusUnit.Kilometer) => {
        setRadiusUnit(radiusUnit);
    }, []);


    const formatValue = useCallback((value: GeoValue | null) => {
        // Generate custom area with the proper unit
        const centeredArea = value?.centeredArea;
        if (centeredArea && !isNil(centeredArea?.address) && !isEmpty(centeredArea?.address)) {
            if (value && !isNil(centeredArea?.address) && !isEmpty(centeredArea?.address) && centeredArea.radius) {
                return intl.formatMessage(messages.address, {
                    address: centeredArea?.address,
                    radius: formatRadiusUnit(intl, formatRadius(centeredArea?.radius, radiusUnit), radiusUnit),
                });
            }

            return centeredArea?.address!;
        }

        const polygonArea = value?.polygonArea;

        if (polygonArea?.area) {
            return formatArea(intl, polygonArea?.area);
        }

        if (polygonArea?.latLng && polygonArea?.latLng.length > 0) {
            const GeoJSON = L.polygon(polygonArea?.latLng).toGeoJSON();
            const area = computeArea(GeoJSON);

            if (area > 0) {
                return formatArea(intl, area);
            }
        }

        const centeredAreaLatLng = centeredArea?.latLng;
        if (centeredAreaLatLng) {
            const label = `${centeredAreaLatLng.lat} ${centeredAreaLatLng.lng}`;
            const radius = centeredArea?.radius ?? 0;

            return intl.formatMessage(messages.address, {
                address: label,
                radius: formatRadiusUnit(intl, formatRadius(radius, radiusUnit), radiusUnit),
            });
        }

        return '';
    }, [intl, radiusUnit]);

    const cls = {
        editable: !readOnly && !disabled,
        disabled: readOnly || disabled,
    };

    const onPopoverVisibleChange = useCallback((value: React.SetStateAction<boolean | undefined>) => {
        if (disabled || readOnly) {
            return;
        }

        setPopoverVisible(value);
    }, [disabled, readOnly]);

    return (
        <ArgInput<GeoValue>
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            value={value || undefined}
            readOnly={true}
            disabled={disabled}
            type={type}
            clearable={!readOnly && clearable}
            popoverTrigger='click'
            popoverFitWidth={false}
            onClear={handleClear}
            data-testid={dataTestId}
            parseValue={() => (value || null)}
            formatValue={formatValue}
            popoverVisible={popoverVisible}
            onPopoverVisibleChange={onPopoverVisibleChange}
            className={classNames('&', className, cls)}
            popoverOverlayClassName={classNames('&-popover')}
            popover={(!readOnly && !disabled) && (() => (
                <ArgGeolocationForm2
                    onChange={handleChange}
                    onRadiusUnitChange={handleRadiusUnitChange}
                    radiusUnit={radiusUnit}
                    value={value}
                    getAddressCoordinates={getAddressCoordinates}
                />
            ))}
            placeholder={placeholder ? undefined : messages.placeholder}
            right={
                !readOnly && <ArgButton
                    key='marker'
                    type='ghost'
                    icon='icon-map-marker'
                    className={classNames('&-right')}
                    autoFocus={autoFocus}
                    disabled={disabled}
                />
            }
        />
    );
};
const formatArea = (intl: IntlShape, area: number) => {
    let unit = 'm²';

    if (area > 1000000 || area === 1000000) {
        area /= 1000000;
        unit = 'km²';
    }

    const formattedNumber = intl.formatNumber(area, {
        maximumFractionDigits: area < 1 ? 2 : 0,
        notation: 'standard',
    });

    return intl.formatMessage(messages.customArea, {
        value: formattedNumber,
        unit: unit,
    });
};

// Format radius value with the proper unit
const formatRadiusUnit = (intl: IntlShape, number: number | undefined, unit: RadiusUnit) => {
    return intl.formatNumber(number || 0, {
        unit,
        style: 'unit',
        notation: 'standard',
        unitDisplay: 'narrow',
    });
};
