import React from 'react';
import { BrowserRouter, BrowserRouterProps } from 'react-router-dom';

// Components
import { Routes } from './routes';
import { Environment } from './utils/environment';
import { JobQuitControlContextProvider } from './hooks/use-job-quit-control';
import {
    ArgNotificationsContextProvider,
    ArgPageContext,
    ArgUploaderContextProvider,
    DisableDndContainer,
    GlobalProgressContextProvider,
    useClassNames,
} from './components/basic';
import { CheckVersion } from './components/common/check-version';
import { BasicPane } from './components/common/panes/basic-pane';
import { ApplicationSession } from './utils/application-session';
import { useArgI18n } from './i18n/arg-locales/use-arg-i18n';
import { AntdConfigProvider, IntlContextProvider, UserLocaleProvider } from './providers';
import { useGetApplicationDescriptor } from './components/application/use-get-application-descriptor';

// Styles
import './styles/application.less';

const DEFAULT_FAVICON = '/favicon.ico';

const FORCE_LOADING = false;

export function Application() {
    const classNames = useClassNames('arg-application');
    const {
        userLocale,
        messages,
        userLocaleConfig,
        antdLocale,
    } = useArgI18n();

    const [applicationDescriptor] = useGetApplicationDescriptor('Application');

    if (FORCE_LOADING || !userLocale || messages === undefined) {
        return (
            <div className={classNames('&', 'loading')}>
                <BasicPane className='fill' backgroundAnimation='wave' icon='icon-loading'/>
            </div>
        );
    }

    return (
        <>
            <IntlContextProvider messages={messages} userLocale={userLocale}>
                <ApplicationSession>
                    <AntdConfigProvider antdLocale={antdLocale}>
                        <UserLocaleProvider userLocaleConfig={userLocaleConfig}>
                            <JobQuitControlContextProvider>
                                <ArgPageContext
                                    pageTitle={applicationDescriptor?.applicationName || Environment.appName}
                                    iconURL={applicationDescriptor?.iconURL || DEFAULT_FAVICON}
                                    subTitlePosition='start'
                                >
                                    <DisableDndContainer>
                                        <ArgNotificationsContextProvider>
                                            {Environment.checkVersionMs > 0 &&
                                                <CheckVersion intervalMs={Environment.checkVersionMs}/>
                                            }
                                            <ArgUploaderContextProvider>
                                                <GlobalProgressContextProvider>
                                                    <_BrowserRouter>
                                                        <Routes/>
                                                    </_BrowserRouter>
                                                </GlobalProgressContextProvider>
                                            </ArgUploaderContextProvider>
                                        </ArgNotificationsContextProvider>
                                    </DisableDndContainer>
                                </ArgPageContext>
                            </JobQuitControlContextProvider>
                        </UserLocaleProvider>
                    </AntdConfigProvider>
                </ApplicationSession>
            </IntlContextProvider>
            {localStorage?.SHOW_VERSION && window.argonodeConfig?.version?.build &&
                <div className={classNames('&-version')}>
                    {window.argonodeConfig.version.build}
                </div>
            }
        </>
    );
}

//TODO: Remove after React Router Migration
const _BrowserRouter = BrowserRouter as unknown as (props: BrowserRouterProps & {
    children?: React.ReactNode
}) => JSX.Element;
