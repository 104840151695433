import { ReactNode } from 'react';

import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { useMemoAsync } from '../arg-hooks/use-memo-async';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { renderText } from './message-descriptor-formatters';
import { ArgMessageValues } from '../types';
import { ClassValue } from '../arg-hooks/use-classNames';
import { renderIcon } from '../arg-icon/arg-icon';

interface PromiseComponentProps {
    promise: Promise<ReactNode>;
    renderType?: 'text' | 'icon';

    messageValues?: ArgMessageValues;
    iconClass?: ClassValue;
}

export function PromiseComponent(props: PromiseComponentProps) {
    const { promise, messageValues, renderType = 'text', iconClass } = props;

    const [component, loading, error] = useMemoAsync<ReactNode>(async (progressMonitor: ProgressMonitor) => {
        let ret = await promise;

        if (renderType) {
            ret = renderIcon(ret, iconClass);
        } else {
            ret = renderText(ret, messageValues);
        }

        return ret;
    }, [promise]);

    if (loading) {
        return <ThreeDotsLoading/>;
    }
    if (error) {
        return <span>Error !</span>;
    }

    return <>{component}</>;
}

interface PromiseFactoryComponentProps {
    promiseFactory: (progressMonitor: ProgressMonitor) => Promise<ReactNode | null>;
    messageValues?: ArgMessageValues;
}

export function PromiseFactoryComponent(props: PromiseFactoryComponentProps) {
    const { promiseFactory, messageValues } = props;

    const [component, loading, error] = useMemoAsync<ReactNode>(async (progressMonitor: ProgressMonitor) => {
        let ret = await promiseFactory(progressMonitor);

        ret = renderText(ret, messageValues);

        return ret;
    }, []);

    if (loading) {
        return <ThreeDotsLoading/>;
    }
    if (error) {
        return <span>Error !</span>;
    }

    return <>{component}</>;
}

export function isPromise<T = any>(promise: any): promise is Promise<T> {
    if (promise?.then) {
        return true;
    }

    return false;
}

