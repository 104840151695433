import React, { KeyboardEvent, MouseEvent, ReactNode, RefAttributes, useCallback, useRef, useState } from 'react';
import { Tabs } from 'antd';
import { filter, find, includes, isString, map, reject, toString } from 'lodash';

import { ArgTabMenu } from './arg-tab-menu';
import { getDataTestIdFromProps } from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { KeyBindingIdentifier } from '../keybindings/keybinding';
import { KeyBindingsScope } from '../keybindings/keybindings-context';
import { KeyBindingHandler } from '../keybindings/keybinding-handler';
import { ArgTab, ArgTabKey, ArgTabsType } from './arg-tabs-types';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgMessageValues } from '../types';
import { ArgButton } from '../arg-button/arg-button';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-tabs.less';

export enum ArgTabAction {
    Add = 'add',
    Show = 'show',
    Close = 'close',
    Move = 'move',

    // Internal
    Destroy = 'destroy',
    ResourceClosed = 'resourceClose',
}

//
export interface ArgTabsProps {
    tabs?: ArgTab[];
    type?: ArgTabsType;
    activeTab?: ArgTabKey;
    className?: ClassValue;
    internalClose?: boolean;
    defaultActiveTab?: ArgTabKey;
    hideAddTab?: boolean;
    addIcon?: ReactNode;
    onChange?: (tabKey: ArgTabKey | undefined, action: ArgTabAction, targetIndex?: number) => void;
    destroyInactiveTabPane?: boolean;
    closeCurrentTabKeyBinding?: KeyBindingIdentifier;
    closeOtherTabsKeyBinding?: KeyBindingIdentifier;
    addTabKeyBinding?: KeyBindingIdentifier;
    nextTabKeyBinding?: KeyBindingIdentifier;
    previousTabKeyBinding?: KeyBindingIdentifier;
    hideUnderline?: boolean,
    messageValues?: ArgMessageValues;
}

export function ArgTabs(props: ArgTabsProps & RefAttributes<HTMLDivElement>) {
    const {
        tabs,
        onChange,
        className,
        internalClose,
        defaultActiveTab,
        type = 'primary',
        activeTab: externalActiveTab,
        addIcon,
        hideAddTab = false,
        destroyInactiveTabPane,
        hideUnderline,
        messageValues,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-tabs');

    // Active tab
    const [internalActiveTab, setInternalActiveTab] = useState<ArgTabKey>();

    const useInternalActiveTab = !('activeTab' in props);

    const activeTab: ArgTabKey | undefined = (useInternalActiveTab ? internalActiveTab : externalActiveTab) || defaultActiveTab;

    const activeTabsRef = useRef<Record<string, true>>({});
    if (activeTab) {
        activeTabsRef.current[activeTab] = true;
    }

    // Closed tabs
    const [closed, setClosed] = useState<string[]>();

    // Handle active tab change
    const handleChange = useCallback((tabKey: ArgTabKey): void => {
        setInternalActiveTab(tabKey);

        onChange && onChange(tabKey, ArgTabAction.Show);
    }, [onChange]);

    const handleTabClick = useCallback((tabKey: ArgTabKey, event: MouseEvent | KeyboardEvent): void => {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();

        handleChange(tabKey);
    }, [handleChange]);

    // Handle close tab
    const handleClose = useCallback((tabKey: ArgTabKey) => {
        const tab = find(tabs, { key: tabKey });

        if (!tab) {
            return;
        }

        internalClose && setClosed((closed) => [...closed || [], tabKey]);

        onChange && onChange(tabKey, ArgTabAction.Close);
        tab.onClose && tab.onClose();
    }, [tabs, internalClose, onChange]);

    // Handle add / close tab
    const handleEdit = useCallback((tabKey: ArgTabKey | MouseEvent | KeyboardEvent, action: 'add' | 'remove') => {
        if ((tabKey as any).preventDefault) {
            if ((tabKey as MouseEvent)?.defaultPrevented) {
                return;
            }

            (tabKey as MouseEvent).preventDefault();
        }

        if (action === 'add') {
            onChange && onChange(undefined, ArgTabAction.Add);
        }

        if (action === 'remove' && isString(tabKey)) {
            handleClose(tabKey);
        }
    }, [onChange, handleClose]);

    // Get the tab title
    const getTitle = (tab: ArgTab): ReactNode => {
        const _key = toString(tab.key);

        const tabTitle = renderText(tab.title, messageValues);

        // Title
        const title = (
            <span className={classNames('&-tab-title')}>
                {tabTitle}
            </span>
        );

        // Icon
        const icon = isString(tab.icon) ? (
            <ArgIcon
                name={tab.icon}
                color={tab.iconColor}
                className={classNames('&-tab-icon')}
            />
        ) : tab.icon;

        // Actions
        const actions = (tab.menu || tab.closable) && (
            <div className={classNames('&-tab-actions')}>
                {/* Menu button */}
                {tab.menu && (
                    <ArgTabMenu
                        items={tab.menu}
                        className={classNames('&-tab-menu', '&-tab-actions-item')}
                    />
                )}

                {/* Close button */}
                {tab.closable && (
                    <ArgButton
                        size='node'
                        type='ghost'
                        icon='icon-cross'
                        onClick={() => handleClose(_key)}
                        className={classNames('&-tab-close', '&-tab-actions-item')}
                    />
                )}
            </div>
        );

        return (
            <>
                {icon}
                {title}
                {actions}
            </>
        );
    };

    const renderPinnedTabTitle = (tab: ArgTab) => {
        const _key = toString(tab.key);

        // Reject closed items
        if (includes(closed, _key)) {
            return;
        }

        // Title
        let _title = getTitle(tab);

        if (tab.activeKeyBinding) {
            _title =
                <KeyBindingHandler keyBinding={tab.activeKeyBinding} onKeyHandler={() => handleChange(_key)}>
                    {_title}
                </KeyBindingHandler>;
        }

        // Render each pinned tabs
        return (
            <div key={_key}
                 onClick={() => handleChange(_key)}
                 className={classNames('&-tab', { '&-tab-active': activeTab === _key })}
            >
                <span className={classNames('&-tab-btn')}>
                    {_title}
                </span>
            </div>
        );
    };

    const renderPinnedEndTabTitle = (tab: ArgTab) => {
        const _key = toString(tab.key);

        // Reject closed items
        if (includes(closed, _key)) {
            return;
        }

        // Title
        let _title = getTitle(tab);

        if (tab.activeKeyBinding) {
            _title =
                <KeyBindingHandler keyBinding={tab.activeKeyBinding} onKeyHandler={() => handleChange(_key)}>
                    {_title}
                </KeyBindingHandler>;
        }

        // Render each pinned-end tabs
        return (
            <div key={_key}
                 onClick={() => handleChange(_key)}
                 className={classNames('&-tab', '&-tab-end', { '&-tab-active': activeTab === _key })}
            >
                {_title}
            </div>
        );
    };

    // Render the tap pane
    const renderTab = (tab: ArgTab) => {
        const _key = toString(tab.key);

        // Reject closed items
        if (includes(closed, _key)) {
            return;
        }

        // Title
        let _title = getTitle(tab);
        let _children = tab.children;
        if (typeof (_children) === 'function') {
            _children = _children(activeTabsRef.current[_key]);
        }

        if (tab.activeKeyBinding) {
            _title =
                <KeyBindingHandler keyBinding={tab.activeKeyBinding} onKeyHandler={() => handleChange(_key)}>
                    {_title}
                </KeyBindingHandler>;
        }

        if (tab.keyBindingsScope) {
            _children = <KeyBindingsScope scope={tab.keyBindingsScope} enabled={activeTab === _key}>
                {_children}
            </KeyBindingsScope>;
        }

        // Render each tabs items
        return (
            <Tabs.TabPane closable={false} prefixCls={classNames('&')} key={_key} tab={_title}>
                {_children}
            </Tabs.TabPane>
        );
    };

    const renderPinnedTab = (tab?: ArgTab) => {
        let _children;
        if (tab) {
            _children = tab?.children;
            if (typeof (_children) === 'function') {
                _children = _children(activeTabsRef.current[tab.key]);
            }

            if (tab.activeKeyBinding) {
                _children =
                    <KeyBindingHandler keyBinding={tab.activeKeyBinding} onKeyHandler={() => handleChange(tab.key)}>
                        {_children}
                    </KeyBindingHandler>;
            }
        }

        // Render each tabs items
        return (
            <Tabs.TabPane closable={false} disabled={true}
                          prefixCls={classNames('&')}
                          key={tab ? tab.key : 'virtual-pinned-tab'}
                          tab='DO NOT DISPLAY THAT TAB'>
                {_children}
            </Tabs.TabPane>
        );
    };

    const antTabsType = (type === 'secondary') ? 'line' : 'editable-card';

    let prefixCls;
    let virtualTab;
    let tabBarExtra;
    switch (type) {
        case 'primary':
        case 'primary-bottom':
            prefixCls = classNames('&');
            virtualTab = renderPinnedTab(find(tabs, {
                key: activeTab,
                pinned: true,
            }) as ArgTab);
            tabBarExtra = {
                left: (
                    <div className={classNames('&-nav-list')}>
                        {/* Dumb tab */}
                        {/* Should nether be displayed */}
                        <div className={classNames('&-tab')}/>

                        {/* Pinned tabs */}
                        {map(filter(tabs, 'pinned'), renderPinnedTabTitle)}

                        {/* Dumb ink bar */}
                        <div className={classNames('&-ink-bar')}/>
                    </div>
                ),
                right: (
                    <div className={classNames('&-nav-list')}>
                        {/* Dumb tab */}
                        {/* Should nether be displayed */}
                        <div className={classNames('&-tab')}/>

                        {/* Pinned-end tabs */}
                        {map(filter(tabs, 'pinnedEnd'), renderPinnedEndTabTitle)}

                        {/* Dumb ink bar */}
                        <div className={classNames('&-ink-bar')}/>
                    </div>
                ),
            };
            break;
    }

    const cls = {
        [`type-${type}`]: true,
        [`arg-tabs-${type}`]: true,
        'no-underline': !!hideUnderline,
    };

    return (
        <Tabs
            type={antTabsType}
            onEdit={handleEdit}
            hideAdd={hideAddTab}
            activeKey={activeTab}
            prefixCls={prefixCls}
            data-test-id={dataTestId}
            onTabClick={handleTabClick}
            tabBarExtraContent={tabBarExtra}
            addIcon={addIcon}
            destroyInactiveTabPane={destroyInactiveTabPane}
            className={classNames(className, cls)}
        >
            {/* Virtual pinned tab */}
            {/* Should only be displayed when a pinned tab is active */}
            {virtualTab}

            {/* Tabs */}
            {map(reject(tabs, (tab) => tab.pinned || tab.pinnedEnd), renderTab)}
        </Tabs>
    );
}
