import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import { map } from 'lodash';

// Components and hooks
import { createBuiltinPlacement } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgButton } from '../arg-button/arg-button';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgTabMenuItem, ArgTabMenuItems } from './arg-tabs-types';

// Styles
import './arg-tab-menu.less';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgMenuItemDivider } from '../arg-menu/arg-menu-item-divider';

type ArgTabMenuProps = {
    items: ArgTabMenuItems;
    className?: ClassValue;
};

const actionsButtonBuiltinPlacements = createBuiltinPlacement(0, 0, [13, -4]);

export function ArgTabMenu(props: ArgTabMenuProps) {
    const { items, className } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const classNames = useClassNames('arg-tab-menu');

    const actionsMenu = (
        <ArgMenu data-testid='actions-button-menu' className={classNames('&')}>
            {map(items, ({ onClick, key, label, icon, title, disabled, hasDivider }: ArgTabMenuItem, idx: number) => {
                const _key = key || idx;
                const _label = renderText(label);

                function _onClick(event: MouseEvent | KeyboardEvent) {
                    onClick?.(event);

                    setVisible(false);
                }

                const _icon = icon && <ArgIcon name={icon} className={classNames('&-menu-item-icon')} />;

                const testId = `arg-tab-menu-item-${_key}`;

                return (
                    <React.Fragment key={_key}>
                        {hasDivider && <ArgMenuItemDivider />}
                        <ArgMenuItem
                            icon={_icon}
                            onClick={_onClick}
                            data-testid={testId}
                            className={classNames('&-item')}
                            disabled={disabled}
                            tooltip={title}
                            label={_label} />
                    </React.Fragment>
                );
            })}
        </ArgMenu>
    );

    return (
        <ArgButton
            size='medium'
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            data-testid='actions-button'
            popoverPlacement='bottomLeft'
            popoverArrowPointAtCenter={true}
            onPopoverVisibleChange={setVisible}
            className={classNames('&-btn', className)}
            popoverClassName={classNames('&-popover')}
            onClick={() => setVisible((visible) => !visible)}
            popoverBuiltinPlacements={actionsButtonBuiltinPlacements}
        />
    );
}
