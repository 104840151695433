import React, { ReactNode } from 'react';

import { ArgRenderedIcon, ArgSize, ClassValue, renderIcon, useClassNames } from '../../basic';
import { Background } from '../chapsvision-background';

import './basic-pane.less';

export interface BasicPaneProps {
    className?: ClassValue;
    message?: ReactNode;
    children?: ReactNode;
    icon?: ArgRenderedIcon;
    backgroundAnimation?: string;
    size?: ArgSize;
}

export function BasicPane(props: BasicPaneProps) {
    const {
        className,
        message,
        icon,
        backgroundAnimation,
        size = 'large',
    } = props;

    const classNames = useClassNames('basic-pane');

    const cls = {
        [`size-${size}`]: true,
        'with-message': !!message,
        'with-icon': !!icon,
    };

    return (
        <div className={classNames('&', className, cls)}>
            <div className={classNames('&-body')}>
                <Background className={classNames('&-body-background')} animation={backgroundAnimation}/>
                {renderIcon(icon, classNames('&-body-icon'))}
            </div>

            {message && <span className={classNames('&-message')}>
                {message}
            </span>
            }
        </div>
    );
}
