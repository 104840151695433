import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Debug from 'debug';

import { UsersCache, useUserById } from '../../caches/users-repository';
import { BasicTag } from '../arg-tag/basic-tag';
import { ArgUser, ArgUserProps } from './arg-user';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgUserId, ArgUserInfo } from './arg-user-id';
import { useGetMe } from '../../../contexts/user-context';

//import './arg-user-id.less';

const FORCE_LOADING = false;

const debug = Debug('basic:arg-users:ArgUserId');

const messages = defineMessages({
    unknown: {
        id: 'basic.arg-user-id.Unknown',
        defaultMessage: 'Unknown',
    },
});

export interface ArgUserByIdProps extends Omit<ArgUserProps, 'user'> {
    userId?: ArgUserId | ArgUserInfo;

    userIdsCache?: UsersCache;
}

export function ArgUserById(props: ArgUserByIdProps) {
    const {
        className,
        userId,
        userIdsCache,
        size,
        label,
        ...otherProps
    } = props;

    const intl = useIntl();

    const classNames = useClassNames('arg-user-id');

    const { me } = useGetMe();

    const userInfo = useMemo<ArgUserInfo>(() => {
        let _userId: ArgUserId;
        let _displayName: string | undefined;
        if (typeof (userId) === 'object') {
            _userId = userId.id;
            _displayName = userId.displayName;
        } else {
            _userId = userId as ArgUserId;
        }

        if (_userId === me.id) {
            return me;
        }

        if (!_displayName) {
            _displayName = intl.formatMessage(messages.unknown);
        }

        return {
            id: _userId,
            displayName: _displayName,
        };
    }, [userId, intl, me]);

    const [user, error, loading] = useUserById(userInfo.id, userIdsCache);

    if (loading || FORCE_LOADING) {
        if (size === 'tiny') {
            return <div className={classNames('&', 'tiny', 'loading', className)}/>;
        }

        return <BasicTag
            className={classNames('&', className)}
            icon={false}
            label={label}
            loading={true}
            size={size}
        />;
    }

    if (!user || error) {
        return <ArgUser
            {...otherProps}
            user={userInfo}
            size={size}
            label={label}
            className={classNames('&', className)}
        />;
    }

    return <ArgUser
        {...otherProps}
        user={user}
        size={size}
        label={label}
        className={classNames('&', className)}
    />;
}
