import EventEmitter3, { ValidEventTypes } from 'eventemitter3';


export class EventEmitter<EventTypes extends ValidEventTypes = string | symbol> extends EventEmitter3<EventTypes | '*'> {
    #stateId = 1;

    protected updateStateId() {
        this.#stateId++;
    }

    get stateId(): number {
        return this.#stateId;
    }

    emit<T extends EventEmitter3.EventNames<EventTypes | '*'>>(
        event: T,
        ...args: EventEmitter3.EventArgs<EventTypes | '*', T>
    ): boolean {
        const ret = super.emit(event, ...args);
        super.emit('*', this.#stateId, event, ...args);

        return ret;
    }
}
