import React from 'react';
import classNames from 'classnames';

import { ClassValue } from '../arg-hooks/use-classNames';

import './three-dots-loading.less';

interface ThreeDotLoadingProps {
    char?: string;
    className?: ClassValue;
}

const DEFAULT_CHAR = '.';

export const ThreeDotsLoading: React.FunctionComponent<ThreeDotLoadingProps> = (
    {
        className,
        char = DEFAULT_CHAR,
    }
) => {
    return (
        <span className={classNames('arg-threeDotsLoading', className)}>
            <span className='dot1'>{char}</span>
            <span className='dot2'>{char}</span>
            <span className='dot3'>{char}</span>
        </span>
    );
};
