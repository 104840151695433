import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import antdUseNotification from 'antd/es/notification/useNotification';
import classNames from 'classnames';
import { ArgsProps } from 'antd/es/notification/interface';
import { defineMessages, IntlContext, useIntl } from 'react-intl';

import { initializeGlobalNotifications } from './notifications';
import { ArgNotifications, NotificationDescription, NotificationKey } from './types';
import { ArgMessageValues } from '../types';
import { ArgIcon, renderIcon } from '../arg-icon/arg-icon';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgErrorsMap } from './arg-errors-map';
import { getProblemDetailsType, isResponseError } from '../utils/response-error';

import './arg-notifications.less';

const NO_DURATION = localStorage.NO_DURATION === 'true';

const messages = defineMessages({
    undefinedMessage: {
        id: 'basic.notifications.UndefinedMessage',
        defaultMessage: 'Unknown error',
    },
});

const DEFAULT_NOTIFICATIONS: ArgNotifications = {
    close(key: NotificationKey): void {
    },
    error(description?: NotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Error', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    info(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Info', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    open(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Open', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    success(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Success', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    warning(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Warning', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
};

const ArgNotificationsContext = createContext<ArgNotifications>(DEFAULT_NOTIFICATIONS);

let notificationId = 0;

interface ArgNotificationsContextProviderProps {
    errorMap?: ArgErrorsMap;
    children: ReactNode;
}

export function ArgNotificationsContextProvider(props: ArgNotificationsContextProviderProps) {
    const { children, errorMap } = props;

    const intl = useIntl();

    const parent = useContext(ArgNotificationsContext);

    const [notifications, contextHolder] = antdUseNotification();

    const argNotifications = useMemo<ArgNotifications>(() => {
        if (parent && parent !== DEFAULT_NOTIFICATIONS) {
            if (!errorMap) {
                return parent;
            }

            const error = (description?: NotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey => {
                const problemDetailType = getProblemDetailsType(error);
                if (problemDetailType) {
                    const errorByType = errorMap[problemDetailType];
                    if (errorByType) {
                        description = {
                            ...description,
                            message: errorByType.title,
                            description: errorByType.description,
                        };
                    }

                    if (isResponseError(error)) {
                        messageValues = {
                            ...error.json,
                            ...messageValues,
                        };
                    }
                }

                const ret = parent.error(description, error, messageValues);

                return ret;
            };

            const ret: ArgNotifications = {
                ...parent,
                error,
            };

            return ret;
        }


        function createParams(notificationDescription: NotificationDescription, messageValues?: ArgMessageValues): ArgsProps {
            const {
                message,
                description,
                icon,
                //className,
                onClose,
                key,
                duration,
                footer,
            } = notificationDescription;

            const _key = key || `useNotifications-${notificationId++}`;

            const ret: ArgsProps = {
                key: _key,
                message: (message) ? <IntlContext.Provider value={intl}>
                    {renderText(message, messageValues)}
                </IntlContext.Provider> : undefined,
                description: (description) ? <IntlContext.Provider value={intl}>
                    {renderText(description, messageValues)}
                </IntlContext.Provider> : undefined,
                icon: (icon) ? renderIcon(icon) : undefined,
                //className: classNames(className),
                onClose,
                duration: NO_DURATION ? 0 : duration,
                btn: footer,
            };

            return ret;
        }

        return {
            info(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);

                notifications.info({
                    closeIcon: <ArgIcon className='arg-notification-close-icon' name='icon-cross'/>,
                    ...args,
                    className: classNames(
                        'arg-notifications-info',
                        args.description ? 'arg-notifications-info-with-description' : 'arg-notifications-info-without-description',
                        args.className
                    ),
                });

                const ret: NotificationKey = args.key!.toString();

                return ret;
            },
            error(description?: NotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey {
                if (!description) {
                    description = {
                        message: error?.name || messages.undefinedMessage,
                    };
                }
                const params = createParams(description, messageValues);

                if (params.icon) {
                    throw new Error('Icon can not specified for error notification any more');
                }

                notifications.error({
                    ...params,
                    icon: <ArgIcon className='arg-notification-error-icon' name='icon-cross'/>,
                    closeIcon: <ArgIcon className='arg-notification-close-icon' name='icon-cross'/>,
                    className: classNames(
                        'arg-notifications-error',
                        params.description ? 'arg-notifications-error-with-description' : 'arg-notifications-error-without-description',
                        params.className
                    ),
                });

                const ret: NotificationKey = params.key!.toString();

                return ret;
            },
            warning(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const params = createParams(description, messageValues);

                if (params.icon) {
                    throw new Error('Icon can not specified for warning notification any more');
                }

                notifications.warning({
                    ...params,
                    icon: <ArgIcon className='arg-notification-warning-icon' name='icon-exclamation-point'/>,
                    closeIcon: <ArgIcon className='arg-notification-close-icon' name='icon-cross'/>,
                    className: classNames(
                        'arg-notifications-warning',
                        params.description ? 'arg-notifications-warning-with-description' : 'arg-notifications-warning-without-description',
                        params.className
                    ),
                });

                const ret: NotificationKey = params.key!.toString();

                return ret;
            },
            success(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const params = createParams(description, messageValues);

                if (params.icon) {
                    throw new Error('Icon can not specified for success notification any more');
                }

                notifications.success({
                    ...params,
                    icon: <ArgIcon className='arg-notification-success-icon' name='icon-validate'/>,
                    closeIcon: <ArgIcon className='arg-notification-close-icon' name='icon-cross'/>,
                    className: classNames(
                        'arg-notifications-success',
                        params.description ? 'arg-notifications-success-with-description' : 'arg-notifications-success-without-description',
                        params.className
                    ),
                });

                const ret: NotificationKey = params.key!.toString();

                return ret;
            },
            open(description: NotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);

                notifications.open(args);

                const ret: NotificationKey = args.key!.toString();

                return ret;
            },

            close(notificationKey: NotificationKey) {
                if (NO_DURATION) {
                    return;
                }
                notifications.destroy(notificationKey);
            },
        };
    }, [errorMap, intl, notifications, parent]);

    initializeGlobalNotifications(argNotifications);

    return <ArgNotificationsContext.Provider value={argNotifications}>
        {children}
        {contextHolder}
    </ArgNotificationsContext.Provider>;
}

export function useNotifications(): ArgNotifications {
    const ret = useContext(ArgNotificationsContext);

    return ret;
}

