import React, { MouseEvent, ReactNode, RefAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Divider } from 'antd';
import { isFunction, isString } from 'lodash';
import Debug from 'debug';

import { ArgInput, ArgInputCustomComponentProps, ArgInputDndConfig } from '../arg-input/arg-input';
import { ArgFilteredMenu } from '../arg-menu/arg-filtered-menu';
import {
    ArgGetItemCheckedState,
    ArgGetItemClassName,
    ArgGetItemCount,
    ArgGetItemDisabled,
    ArgGetItemExpandState,
    ArgGetItemIcon,
    ArgGetItemKey,
    ArgGetItemLabel,
    ArgGetItemTooltip,
    computeItemClassName,
    computeItemKey,
    computeItemLabel,
    getDataTestIdFromProps,
} from '../utils';
import { ArgIconCheckbox, ArgIconCheckboxStates } from '../arg-checkbox/arg-icon-checkbox';
import { ArgChangeReason, ArgInputState, ArgInputType, ArgMessageValues, ArgRenderedText, ArgSize } from '../types';
import { DEFAULT_CARDINALITY, DEFAULT_SIZE } from '../defaults';
import { ArgInputSearch } from '../arg-input/arg-input-search';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ButtonClickEvent } from '../arg-button/arg-button';

import './arg-combo.less';

const debug = Debug('argonode:basic:ArgCombo');

const messages = defineMessages({
    all: {
        id: 'basic.arg-combo.SelectAll',
        defaultMessage: 'All',
    },
    searchPlaceHolder: {
        id: 'basic.arg-combo.SearchPlaceholder',
        defaultMessage: 'Search',
    },
});

const multipleTagKey = '###MultipleTagKey####OO';

interface MultipleTag {
    key: string;
    label: string;
}

export type Cardinality = 'zeroMany' | 'optional' | 'one' | 'many'

export interface ArgComboProps<T> {
    id?: string;
    cardinality?: Cardinality;
    className?: ClassValue;
    hidden?: boolean;
    size?: ArgSize;
    type?: ArgInputType;
    state?: ArgInputState;
    left?: ReactNode | 'magnifier';
    right?: ReactNode | 'dropdown';

    progressMonitor?: ProgressMonitor;

    // T | T[] | undefined
    onChange?: (value: any, reason: ArgChangeReason, item?: T, forceSelection?: boolean) => void;
    onSearchInputChange?: (value?: string) => void;

    placeholder?: string | MessageDescriptor;
    disabled?: boolean;
    readOnly?: boolean;

    initialValue?: T | T[];
    value?: T | T[];

    clearable?: boolean;

    canCreate?: boolean;
    createLabel?: string | MessageDescriptor;
    onCreate?: () => void;

    messageValues?: ArgMessageValues;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    items: T[] | (() => T[]);
    getItemLabel?: ArgGetItemLabel<T>;
    getItemKey?: ArgGetItemKey<T>;
    getItemCount?: ArgGetItemCount<T>;
    getItemClassName?: ArgGetItemClassName<T>;
    getItemDisabled?: ArgGetItemDisabled<T>;
    getItemIcon?: ArgGetItemIcon<T>;
    getItemTooltip?: ArgGetItemTooltip<T>;
    getItemCheckedState?: ArgGetItemCheckedState<T>;
    getItemExpandState?: ArgGetItemExpandState<T>;

    enableFilter?: boolean;

    topRender?: () => ReactNode;
    bottomRender?: () => ReactNode;
    renderItem?: (item: T, searchedToken?: string) => ReactNode;

    autoFocus?: boolean;

    popoverFitWidth?: boolean;
    popoverClassName?: ClassValue;

    renderInput?: (props: ArgInputCustomComponentProps<T>) => ReactNode;
    hideTags?: boolean;
    onPopoverClose?: (value?: T | T[]) => void;

    dndConfig?: ArgInputDndConfig;

    onItemExpand?: (item: T, isExpanded: boolean, event: ButtonClickEvent) => void;
    onItemCheck?: (item: T, checked: boolean, event: ButtonClickEvent) => void;

    allCheckButtonStates?: ArgIconCheckboxStates;
    onSelectAll?: (check: boolean, event: ButtonClickEvent) => void;
}

export function ArgCombo<T>(props: (ArgComboProps<T> & RefAttributes<HTMLInputElement>)) {
    const {
        id,
        canCreate,
        createLabel,
        onCreate,
        cardinality = DEFAULT_CARDINALITY,
        hidden,
        size = DEFAULT_SIZE,
        className,
        state,
        type,
        left,
        right = 'dropdown',
        placeholder,
        disabled,
        readOnly,
        value: externalValue,
        items,
        onChange,
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        getItemKey,
        getItemLabel,
        getItemDisabled,
        getItemCount,
        getItemTooltip,
        getItemIcon,
        getItemCheckedState,
        getItemExpandState,
        enableFilter,
        topRender,
        bottomRender,
        renderItem,
        messageValues,
        clearable = true,
        autoFocus,
        progressMonitor,
        popoverFitWidth,
        popoverClassName,
        renderInput,
        getItemClassName,
        hideTags,
        onPopoverClose,
        dndConfig,
        onSearchInputChange,
        onItemExpand,
        onItemCheck,
        allCheckButtonStates: allCheckButtonStatesExternal,
        onSelectAll,
    } = props;

    const intl = useIntl();
    const dataTestId = getDataTestIdFromProps(props);

    const useInternalValue = !('value' in props);

    const classNames = useClassNames('arg-combo');

    const zeroOrOneSelection = (cardinality === 'one' || cardinality === 'optional');

    const [internalValue, setInternalValue] = useState<T | T[] | undefined>();
    const [popoverVisible, setPopoverVisible] = useState<boolean>();
    const [searchedToken, setSearchedToken] = useState<string>();

    useEffect(() => {
        if (!popoverVisible) {
            setSearchedToken(undefined);
        }
    }, [popoverVisible]);

    const valueArray = useMemo<{ single: T, array: T[] } | undefined>(() => {
        const _value = (useInternalValue) ? internalValue : externalValue;

        if (Array.isArray(_value)) {
            if (!_value.length) {
                return undefined;
            }

            return {
                array: _value,
                single: _value[0],
            };
        }

        if (_value !== undefined) {
            return {
                array: [_value],
                single: _value,
            };
        }

        return undefined;
    }, [externalValue, useInternalValue, internalValue]);

    const handleSelect = useCallback((item: T) => {
        if (zeroOrOneSelection) {
            setPopoverVisible(false);
            setInternalValue([item]);
            onChange?.(item, 'selection', item);

            return;
        }

        if (valueArray) {
            const idx = valueArray.array.indexOf(item);
            if (idx < 0) {
                const newSelection = [...valueArray.array, item];
                setInternalValue(newSelection);
                onChange?.(newSelection, 'selection', item);

                return;
            }

            const itemKey = computeItemKey(item, getItemKey);
            const newSelection = valueArray.array.filter((i) => (itemKey !== computeItemKey(i, getItemKey)));

            setInternalValue(newSelection);
            onChange?.(newSelection, 'selection', item);

            return;
        }

        const newSelection = [item];
        setInternalValue(newSelection);
        onChange?.(newSelection, 'selection', item);
    }, [valueArray, onChange, getItemKey, zeroOrOneSelection]);

    const allCheckButtonStates = useMemo(() => {
        let allStates = allCheckButtonStatesExternal;

        if (allStates === undefined) {
            let allLeafItems: T[];
            if (isFunction(items)) {
                allLeafItems = items();
            } else {
                allLeafItems = items;
            }

            const allSelected = valueArray?.array.length === allLeafItems.length;

            allStates = allSelected ? true : (valueArray?.array.length ? 'minus' : false);

            debug('render', 'ValueArrayCount=', valueArray?.array.length, 'allItems=', allLeafItems, 'allSelected=', allSelected);
        }

        return allStates;
    }, [allCheckButtonStatesExternal, items, valueArray]);

    const handleSelectAll = useCallback((event: MouseEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        if (onSelectAll) {
            const check = (allCheckButtonStates !== true);
            onSelectAll(check, event);

            return;
        }

        const forceSelection = !(valueArray?.array.length ?? 0 > 0);

        let itemsList: T | T[] | undefined;
        if (isFunction(items)) {
            itemsList = items();
        } else {
            itemsList = items;
        }

        if ([true, 'minus'].includes(allCheckButtonStates)) {
            itemsList = [];
        }

        setInternalValue(itemsList);
        onChange?.(itemsList, 'selection', undefined, forceSelection);
    }, [onSelectAll, valueArray?.array.length, items, onChange, allCheckButtonStates]);

    const handleSearchTokenChange = useCallback((searchedToken: string) => {
        setSearchedToken(searchedToken);
        onSearchInputChange?.(searchedToken);
    }, [setSearchedToken]);

    const handleOpenPopover = useCallback((event: MouseEvent) => {
        event.preventDefault();

        if (readOnly || disabled) {
            return;
        }
        setPopoverVisible(true);
    }, [readOnly, disabled]);

    const handleTagClick = useCallback(() => {
        setPopoverVisible(true);
    }, [setPopoverVisible]);

    const handleTagClose = useCallback((tag: T | MultipleTag) => {
        if (!valueArray?.array.length) {
            return;
        }

        if ((tag as MultipleTag).key === multipleTagKey) {
            return;
        }

        const tagKey = computeItemKey(tag as T, getItemKey);
        const newSelection = valueArray.array.filter((t) => (computeItemKey(t, getItemKey) !== tagKey));

        setInternalValue(newSelection);
        onChange?.(newSelection, 'clear');
    }, [valueArray?.array, getItemKey, onChange]);

    const handleClear = useCallback(() => {
        setInternalValue([]);
        onChange?.([], 'clear');
    }, [onChange]);

    const cls = {
        [`cardinality-${cardinality}`]: true,
    };

    const renderAllButtons = useCallback((): ReactNode => {
        if (zeroOrOneSelection) {
            return topRender?.();
        }

        const allStates = allCheckButtonStates;

        return <>
            <div className={classNames('&-dropDown-header')}>
                {/* Select all button */}
                <div className={classNames('&-dropDown-header-item', '&-dropDown-select-all')}>
                    <button
                        data-testid='select-all'
                        className={classNames('&-dropDown-select-all-button', 'arg-menu-item')}
                        onClick={handleSelectAll}
                        type='button'
                    >
                        <ArgIconCheckbox
                            state={allStates}
                            className={classNames('&-dropDown-select-all-button-checkbox', '&-dropDown-checkbox')}
                        />
                        <span className={classNames('&-dropDown-select-all-button-title')}>
                            <FormattedMessage {...messages.all}/>
                        </span>
                    </button>
                </div>

                {/* Search filter */}
                {enableFilter && (
                    <div className={classNames('&-dropDown-header-item', '&-dropDown-filter')}>
                        <ArgInputSearch
                            className={classNames('&-dropDown-filter-input')}
                            data-testid='arg-combo-dropdown-filter-input'
                            autoFocus={autoFocus}
                            clearable={true}
                            size='medium'
                            value={searchedToken}
                            onInputChange={handleSearchTokenChange}
                            placeholder={messages.searchPlaceHolder}
                        />
                    </div>
                )}
            </div>
            <div className={classNames('&-dropDown-divider')}>
                <Divider className={classNames('&-dropDown-divider-line', 'arg-divider')}/>
            </div>
        </>;
    }, [zeroOrOneSelection, allCheckButtonStates, classNames, handleSelectAll, enableFilter, autoFocus, searchedToken, handleSearchTokenChange, topRender, items, valueArray?.array.length]);

    const computeMenu = useCallback(() => {
        let itemsList = items;
        if (isFunction(items)) {
            itemsList = items();
        }

        return <ArgFilteredMenu<T>
            items={itemsList as T[]}
            canCreate={canCreate}
            createLabel={createLabel}
            onCreate={onCreate}
            className={classNames('&-dropDown')}
            messageValues={messageValues}
            selected={valueArray?.array}
            topRender={renderAllButtons}
            bottomRender={bottomRender}
            renderItem={renderItem}
            onSelect={handleSelect}
            getItemKey={getItemKey}
            enableFilter={enableFilter && zeroOrOneSelection}
            searchedToken={searchedToken}
            getItemIcon={getItemIcon}
            getItemTooltip={getItemTooltip}
            getItemLabel={getItemLabel}
            getItemDisabled={getItemDisabled}
            showCheckbox={!zeroOrOneSelection}
            getItemCount={getItemCount}
            progressMonitor={progressMonitor}
            getItemClassName={getItemClassName}
            onSearchInputChange={onSearchInputChange}
            onItemExpand={onItemExpand}
            onItemCheck={onItemCheck}
            getItemCheckedState={getItemCheckedState}
            getItemExpandState={getItemExpandState}
        />;
    }, [
        items, canCreate, createLabel, onCreate, classNames, messageValues, valueArray?.array, renderAllButtons, bottomRender, renderItem,
        handleSelect, getItemKey, enableFilter, zeroOrOneSelection, searchedToken, getItemIcon, getItemTooltip, getItemLabel, getItemDisabled,
        getItemCount, progressMonitor, getItemClassName, onSearchInputChange, onItemExpand, onItemCheck, getItemCheckedState, getItemExpandState,
    ]);

    const handleParseValue = useCallback(() => {
        return null;
    }, []);

    const handleFormatLabel = useCallback((item: T | null) => {
        let label = computeItemLabel(item, getItemLabel);

        if (isMessageDescriptor(label)) {
            label = intl.formatMessage(label, messageValues);
        }

        if (isString(label)) {
            return label;
        }

        return '';
    }, [getItemLabel, intl, messageValues]);

    const value = (zeroOrOneSelection) ? valueArray?.single : undefined;

    const itemClassName = getItemClassName && (value !== undefined) && computeItemClassName(value, getItemClassName);

    const values = !zeroOrOneSelection ? valueArray?.array : undefined;

    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        if (onPopoverClose && !visible) {
            onPopoverClose(value || values);
        }
        setPopoverVisible(visible);
    }, [onPopoverClose, value, values]);

    if (hidden) {
        return null;
    }

    return <ArgInput<T, T>
        id={id}
        size={size}
        state={state}
        type={type}
        data-testid={dataTestId}
        messageValues={messageValues}
        progressMonitor={progressMonitor}
        left={left}
        autoFocus={autoFocus}
        right={right}
        clearable={!readOnly && (!zeroOrOneSelection || cardinality === 'optional' && clearable)}
        readOnly={true}
        placeholder={(zeroOrOneSelection || !valueArray?.array.length) ? placeholder : undefined}
        disabled={disabled}
        value={value}
        parseValue={handleParseValue}
        formatValue={handleFormatLabel}
        tooltip={tooltip}
        tooltipClassName={tooltipClassName}
        tooltipPlacement={tooltipPlacement}
        popover={computeMenu}
        popoverPlacement='bottomRight'
        popoverTrigger={undefined}
        popoverVisible={popoverVisible}
        popoverOverlayClassName={popoverClassName}
        popoverClassName={classNames('&-popover')}
        popoverFitWidth={popoverFitWidth}
        onPopoverVisibleChange={handlePopoverVisibleChange}
        onInputClick={handleOpenPopover}
        tags={hideTags ? undefined : values}
        getTagLabel={getItemLabel}
        getTagKey={getItemKey}
        tagToolip={false}
        tagReadOnly={readOnly}
        onClear={handleClear}
        onTagClick={handleTagClick}
        onTagClose={handleTagClose}
        className={classNames('&', className, cls, itemClassName)}
        renderInputComponent={renderInput}
        dndConfig={dndConfig}
    />;
}
