import React, { ReactNode, RefAttributes, useCallback } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { isFunction } from 'lodash';

import { ArgChangeReason, ArgRenderedText, ArgSize } from '../types';
import { DEFAULT_SIZE } from '../defaults';
import { ArgCombo } from './arg-combo';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { Gradient } from '../../../utils/color-utils';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-combo-color.less';

export interface GradientInputItem extends Gradient {
    id: string;
    colors: string[];
}

export interface ArgComboColorProps {
    className?: ClassValue;

    items: GradientInputItem[] | (() => GradientInputItem[]);

    id?: string;
    size?: ArgSize;
    disabled?: boolean,
    value?: GradientInputItem;

    bottomText?: {
        left?: ArgRenderedText;
        right?: ArgRenderedText;
    };

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    popoverClassName?: ClassValue;
    itemClassName?: ClassValue;

    onChange?: (value: any, reason: ArgChangeReason) => void;
}

export function ArgComboColor(props: (ArgComboColorProps & RefAttributes<HTMLInputElement>)) {
    const {
        items,
        id,
        value,
        size = DEFAULT_SIZE,
        disabled,
        bottomText,
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        onChange,
        className,
        popoverClassName,
        itemClassName,
    } = props;
    const classNames = useClassNames('arg-combo-color');
    const gradientItems = isFunction(items) ? items() : items;

    const getItemKey = useCallback((gradient: GradientInputItem) => {
        return gradient.id;
    }, []);

    const renderItem = useCallback((item: GradientInputItem | undefined): ReactNode => {
        if (!item) {
            return null;
        }
        const style = {
            background: `linear-gradient(90deg, ${item.colors.join(',')})`,
        };

        return (
            <div key={item.id} className={classNames('&-item', itemClassName)} style={style}>
                <div className={classNames('selection-border')}/>
            </div>
        );
    }, [classNames, itemClassName]);

    return <div className={classNames('&-container')}>
        <ArgCombo<GradientInputItem>
            items={gradientItems}
            value={value}
            id={id}
            className={classNames('&', className)}
            size={size}
            disabled={disabled}
            tooltip={tooltip}
            tooltipClassName={tooltipClassName}
            tooltipPlacement={tooltipPlacement}
            popoverClassName={classNames('&-popover', popoverClassName)}
            getItemKey={getItemKey}
            onChange={onChange}
            getItemClassName={classNames('&-item')}
            renderItem={renderItem}
            renderInput={(inputProps) => renderItem(inputProps.value || undefined)}
        />
        {bottomText && (
            <div className={classNames('&-bottom-text-container')}>
                <div className={classNames('text-left')}>
                    {renderText(bottomText.left)}
                </div>
                <div className={classNames('text-right')}>
                    {renderText(bottomText.right)}
                </div>
            </div>)}
    </div>;
}

