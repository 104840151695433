import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isFunction } from 'lodash';
import Debug from 'debug';

import { AbstractConfigurations, ConfigurationPath } from '../model/configurations';
import {
    $yield,
    ArgNotifications,
    ProgressMonitor,
    useDebounce,
    useEffectAsync,
    useNotifications,
} from '../components/basic';
import { BasicState, StateId } from '../utils/states/basic-state';
import { useInitialValue } from './use-initial-value';

type ChangeActionValue<T> = ((currentValue: T) => T) | T;
export type ChangeAction<T> = (value: SetStateAction<T>) => void;

const debug = Debug('common:hooks:use-configuration');

const USE_CONFIGURATION_UPDATE_MS = 500;

export type UseConfigurationReturnType<T> = readonly [T, ChangeAction<T>, boolean, number];

export function useConfiguration<T>(
    configurations: AbstractConfigurations | undefined,
    path: ConfigurationPath,
    initialConfiguration: T | (() => T)
): UseConfigurationReturnType<T> {
    const [stateId, setStateId] = useState<number>(0);
    const unmountedRef = useRef<boolean>(false);
    const initialConfigurationValue = useInitialValue(initialConfiguration);
    useEffect(() => {
        if (!configurations) {
            return;
        }

        function handleChanged(changedPath: string, value: any) {
            if (!changedPath.startsWith(path)) {
                return;
            }

            if (unmountedRef.current) {
                return;
            }

            debug('handleChanged', 'Get changed event', changedPath, value);

            $yield(() => {
                setStateId((prev) => (++prev));
            });
        }

        function handleLoaded() {
            if (unmountedRef.current) {
                return;
            }

            $yield(() => {
                setStateId((prev) => (++prev));
            });
        }

        configurations.on('Changed', handleChanged);
        configurations.on('Loaded', handleLoaded);

        return () => {
            configurations.off('Changed', handleChanged);
            configurations.off('Loaded', handleLoaded);
        };
    }, [configurations, path]);

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    // Set the configuration value in the whole configurations object
    const setConfiguration = useCallback(async (value: ChangeActionValue<T>) => {
        if (!configurations) {
            throw new Error('Configurations is not setted !');
        }

        try {
            await configurations.set(path, (prevConfiguration: T | undefined) => {
                if (prevConfiguration === undefined) {
                    prevConfiguration = initialConfigurationValue;
                }
                const newConfiguration = isFunction(value) ? value(prevConfiguration!) : value;

                return newConfiguration;
            });
        } catch (error) {
            console.error(error);
        }
    }, [configurations, initialConfigurationValue, path]);

    // Current configuration value
    const configuration: T = configurations?.get(path);

    // Return the initial value if gathered configuration was undefined
    let _configuration = configuration;
    if (_configuration === undefined) {
        _configuration = initialConfigurationValue;
    }

    debug('useConfiguration', 'Return configuration path=', path, 'value=', _configuration, 'stateId=', stateId);

    return [_configuration, setConfiguration, configurations?.isLoaded || false, stateId];
}

export function useConfigurations<T extends AbstractConfigurations>(
    newConfiguration: () => T,
    stateObject?: BasicState
): [(T | undefined), (ProgressMonitor | undefined), (Error | undefined)] {
    const initialStateIdRef = useRef<StateId | undefined>();
    const needFetchRef = useRef<boolean>(false);

    const notifications = useNotifications();

    // Whole configurations object
    const configurations = useMemo<T>(() => {
        const configurations = newConfiguration();

        debug('configurations', 'Create configuration=', configurations);

        initialStateIdRef.current = stateObject?.stateId;
        needFetchRef.current = true;

        debug('configurations', 'Create configuration=', configurations);

        return configurations;
    }, [newConfiguration]);

    const [debounceEngine] = useDebounce(USE_CONFIGURATION_UPDATE_MS);

    useEffect(() => {
        function handleToBeStored() {
            debug('handleToBeStored', 'configuration=', configurations.name);
            debounceEngine(() => {
                saveConfiguration(configurations, notifications, stateObject).catch((error) => {
                    console.error(error);
                });
            });
        }

        configurations.on('ToBeStored', handleToBeStored);

        return () => {
            saveConfiguration(configurations, notifications, stateObject).catch((error) => {
                console.error(error);
            });
            configurations.off('ToBeStored', handleToBeStored);
        };
    }, [configurations]);

    const [progressMonitor, error] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        debug('configurationsEffect', 'Update configuration=', configurations, 'needFetch=', needFetchRef.current);

        if (!configurations) {
            return;
        }

        if (needFetchRef.current) {
            needFetchRef.current = false;
            await configurations.fetch(notifications, progressMonitor);

            return;
        }

        await configurations.sync(notifications, progressMonitor);
    }, [stateObject?.stateId, configurations]);

    return [configurations, progressMonitor, error];
}

async function saveConfiguration(configurations: AbstractConfigurations, notifications: ArgNotifications, stateObject?: BasicState) {
    const myProgressMonitor = ProgressMonitor.empty();
    try {
        debug('storeConfiguration', 'configuration=', configurations.name);
        await configurations.store(notifications, myProgressMonitor);

        stateObject?.change();
    } catch (error) {
        console.error('Can not store configuration', error);
    }
}
