import { useEffect, useMemo, useRef } from 'react';
import { forEach } from 'lodash';

import { ToolContext, ToolTreeContext, ToolTreeNode } from './tool-context';
import { useContextUpdate } from './use-context-update';

export function useToolNodes(toolContext: ToolContext, prefix?: string): [ToolTreeNode[], ToolTreeContext] {
    useContextUpdate(toolContext);

    const treeContext = useRef<ToolTreeContext>();
    if (!treeContext.current) {
        treeContext.current = {
            fetchedTools: {},
        };
    }

    const tree = useMemo<ToolTreeNode[]>(() => {
        const tree = toolContext.computeTree(treeContext.current, prefix);

        return tree;
    }, [toolContext, toolContext.stateId]);

    useEffect(() => {
        return () => {
            toolContext.onUnmount();

            const pm = treeContext.current?.fetchedTools;

            forEach(pm, (p) => {
                p.progressMonitor?.cancel();
            });
        };
    }, []);

    return [tree, treeContext.current];
}
