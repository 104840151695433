import Debug from 'debug';
import { getArgLocale } from './get-locale';

const debug = Debug('common:i18n:CKEditorLocale');

export async function installCKEditorLocale(localeName: string): Promise<void> {
    const locale = getArgLocale(localeName);

    if (!locale) {
        return;
    }

    const ckEditorLocale = locale.getCKEditorLocale;
    const promise = ckEditorLocale?.();
    if (!promise) {
        return;
    }

    try {
        await promise;
        debug('loadLocale', 'Locale', localeName, 'loaded');
    } catch (error) {
        console.error('Can not load moment locale=', localeName, 'error=', error);
        throw error;
    }
}
