import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

import { Application } from './application';

disableBrowserContextMenu();
const container = document.getElementById('root');
const root = createRoot(container!);

// There is an issue with Ant Design when activating the Strict Mode
root.render(<Application/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

function disableBrowserContextMenu() {
    if (!(process.env.NODE_ENV === 'production' && localStorage?.KEEP_CONTEXT_MENU !== 'true')) {
        return;
    }
    document.body.oncontextmenu = (event) => {
        event.preventDefault();

        return false;
    };
}

if (localStorage?.ARG_DARK_SUPPORT) {
    document.body.classList.add('arg-dark-support');
}
