import { ArgRenderedText } from '../types';

export const SNIPPET_DND_TYPE = 'application/arg-snippet';

export interface Snippet {
    order: number;
    title: ArgRenderedText;
    code: string;
}

export interface SnippetsRepository {
    snippets: Snippet[];
}

