import { defaultTo, isNumber } from 'lodash';
import { MapContainer, TileLayer } from 'react-leaflet';
import L, { LatLng, LatLngExpression, LatLngLiteral, Map } from 'leaflet';
import React, { MutableRefObject, SetStateAction, useCallback, useMemo, useState } from 'react';

import '../../../../../utils/leaflet-draw/leaflet-draw';

// Hooks and components
import { getDataTestIdFromProps } from '../../../utils';
import { ArgMapCircle } from './components/circle/map-circle';
import { ArgMapMarker } from './components/marker/map-marker';
import { GeoValue, MapMode } from '../../model/geolocation-value';
import { ArgMapPolygon } from './components/polygon/map-polygon';
import { ArgMapToolbar } from './components/toolbar/arg-map-toolbar';
import { ArgMapZoomToolbox } from './components/zoom/arg-map-zoom-toolbox';
import { LatLngExpressionToLatLng } from '../../helpers/LatLngExpressionToLatLng';
import { ArgMapCenterButton } from './components/center-button/arg-map-center-button';
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { ArgChangeReason } from '../../../types';
import { KeyBindingDescriptor } from '../../../keybindings/keybinding';
import { useComponentArgMapTiles } from '../../context/arg-map-tiles-provider';

// Assets
import MakerImage from './assets/marker.svg';

// Styles
import './arg-map.less';

export interface ArgMapProps {
    initialZoom?: number;
    initialRadius?: number;
    initialCenter?: LatLngLiteral;
    initialPolygon?: LatLngLiteral[];
    initialMapMode?: MapMode;
    forwardRef?: MutableRefObject<Map | undefined>;
    className?: ClassValue;
    value?: GeoValue | undefined;
    onChange?: (value: SetStateAction<GeoValue | undefined>, reason?: ArgChangeReason) => void;
    mapToolBar?: boolean;
    zoomInKeyBinding?: KeyBindingDescriptor;
    zoomOutKeyBinding?: KeyBindingDescriptor;
}

export const DEFAULT_MAP_CENTER = [45, 2.230230] as unknown as L.LatLng;
export const DEFAULT_MAP_ZOOM = 1;
export const MAP_MAX_ZOOM = 18;
export const MAP_MIN_ZOOM = 0;

export const MarkerIcon: L.Icon<L.IconOptions> = L.icon(
    {
        iconUrl: MakerImage,
        iconAnchor: [10, 20],
        iconSize: [20, 20],
    }
);

export const ArgMap2: React.FunctionComponent<ArgMapProps> = (props) => {
    const {
        onChange,
        className,
        forwardRef,
        mapToolBar,
        value: externalValue,

        // Initial values
        initialZoom,
        initialRadius,
        initialCenter,
        initialPolygon,
        initialMapMode,
        zoomInKeyBinding,
        zoomOutKeyBinding,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<GeoValue | undefined>();
    const handleChange = useCallback((geoValue: React.SetStateAction<GeoValue | undefined>) => {
        if (useInternalValue) {
            setInternalValue(geoValue);
        }
        onChange?.(geoValue);
    }, [onChange, useInternalValue]);

    const classNames = useClassNames('arg-map');

    const value = useInternalValue ? internalValue : externalValue;

    const [zoom, setZoom] = useState(initialZoom);
    const radius = useMemo(() => defaultTo(value?.centeredArea?.radius, initialRadius), [initialRadius, value]);
    const center = useMemo(() => defaultTo(value?.centeredArea?.latLng, initialCenter), [initialCenter, value]);
    const polygon = useMemo(() => defaultTo(value?.polygonArea?.latLng, initialPolygon), [initialPolygon, value]);

    const handleZoomChange = useCallback((zoom?: number) => {
        setZoom(zoom);
    }, []);

    const handleCenterChange = useCallback((center?: LatLngExpression) => {
        const updateMethod = (prev?: GeoValue | undefined) => {
            const newValue: GeoValue = {
                centeredArea: {
                    ...(prev?.centeredArea || {}),
                    latLng: LatLngExpressionToLatLng(center),
                },
            };

            return newValue;
        };

        handleChange(updateMethod);
    }, [handleChange]);

    const [mapMode, setMapMode] = useState<MapMode | undefined>(() => initialMapMode);
    const startDrawingPolygonHandler = useCallback(() => {
        setMapMode(MapMode.Polygon);
    }, []);

    const onPolygonDrawFinishHandler = useCallback((area: number, polygon: LatLng[]) => {
        setMapMode(MapMode.CenterArea);

        if (!area) {
            return;
        }
        const updateMethod = (): GeoValue => {
            const newValue: GeoValue = {
                polygonArea: {
                    area,
                    latLng: polygon,
                },
            };

            return newValue;
        };

        handleChange(updateMethod);
    }, [handleChange]);

    const [mapTiles, loadingMapTiles, errorMapTiles] = useComponentArgMapTiles();

    return (
        <MapContainer
            whenCreated={(map: Map) => {
                if (forwardRef) {
                    forwardRef.current = map;
                }
            }}
            data-testid={dataTestId}
            scrollWheelZoom={true}
            zoomControl={false}
            preferCanvas={true}
            minZoom={isNumber(mapTiles?.minZoom) ? mapTiles!.minZoom : MAP_MIN_ZOOM}
            maxZoom={isNumber(mapTiles?.maxZoom) ? mapTiles!.maxZoom : MAP_MAX_ZOOM}
            zoom={defaultTo(zoom, DEFAULT_MAP_ZOOM)}
            center={defaultTo(center, DEFAULT_MAP_CENTER)}
            className={classNames(className, '&')}
        >
            {mapTiles && <TileLayer
                attribution={mapTiles.attribution}
                url={mapTiles.url}
                maxZoom={mapTiles.maxZoom}
                minZoom={mapTiles.minZoom}

                // @ts-ignore url param => https://leafletjs.com/reference-1.6.0.html#tilelayer
                //mode={Environment.defaultMapViewMode}
            />}
            {/* Marker */}
            {!!center && (
                <ArgMapMarker
                    icon={MarkerIcon}
                    position={center}
                    onChange={handleCenterChange}
                />
            )}

            {!!(center && radius) && (
                <ArgMapCircle
                    center={center}
                    radius={radius}
                    pathOptions={{
                        color: '#2873D6',
                        fillColor: '#2873D6',
                        opacity: 1,
                    }}
                />
            )}

            {!!polygon &&
                <ArgMapPolygon
                    positions={polygon}
                    pathOptions={{
                        color: '#2873D6',
                        fillColor: '#2873D6',
                        opacity: 1,
                    }}
                />
            }

            <div className={classNames('&-right-controls')}>
                {mapToolBar !== false && (
                    <ArgMapToolbar
                        className={classNames('&-right-controls-toolbar')}
                        mapMode={mapMode}
                        onChange={(mapMode) => {
                            setMapMode(mapMode);
                        }}
                        onPolygonDrawFinishHandler={onPolygonDrawFinishHandler}
                        startDrawingPolygonHandler={startDrawingPolygonHandler}
                    />
                )}

                <ArgMapZoomToolbox
                    className={classNames('&-right-controls-zoom-toolbox')}
                    zoom={zoom}
                    onChange={handleZoomChange}
                    zoomInKeyBinding={zoomInKeyBinding}
                    zoomOutKeyBinding={zoomOutKeyBinding}
                />
            </div>

            <ArgMapCenterButton
                center={center}
            />
        </MapContainer>
    );
};
