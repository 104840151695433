import React, { useCallback, useRef, useState } from 'react';
import { Popover } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgButton, ArgButtonProps } from './arg-button';
import { DEFAULT_SIZE } from '../defaults';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgRenderedText } from '../types';

import './arg-action-dropdown-button.less';

export interface ArgActionDropdownButtonAction {
    key: string;
    label: ArgRenderedText;
    isDisabled?: boolean;
    tooltip?: ArgRenderedText;
    onClick?: (event: MenuInfo) => void;
}

export interface ArgActionDropdownButtonProps extends ArgButtonProps {
    className?: ClassValue;
    actions: ArgActionDropdownButtonAction[];
}

export function ArgActionDropdownButton(props: ArgActionDropdownButtonProps) {
    const {
        className,
        popoverClassName,
        actions,
        size = DEFAULT_SIZE,
        disabled,
    } = props;

    const classNames = useClassNames('arg-action-dropdown-button');
    const buttonsContainerRef = useRef<HTMLDivElement>(null);
    const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickActions = useCallback((evt: MenuInfo) => {
        const { key } = evt;

        const action = actions.find((action: ArgActionDropdownButtonAction) => action.key === key);
        action?.onClick?.(evt);
        setIsOpen(false);
    }, [actions]);

    const handleVisibleChange = useCallback((isPopoverOpen: boolean) => {
        if (isPopoverOpen && !menuWidth) {
            setMenuWidth(buttonsContainerRef?.current?.offsetWidth);
        }

        if (isPopoverOpen == false) {
            setIsOpen(isPopoverOpen);
        }
    }, [menuWidth]);

    const menuCls = {
        [`size-${size}`]: true,
    };

    const popoverMenu = (
        <ArgMenu
            className={classNames('&-popover-menu', menuCls)}
            data-testid='arg-action-dropdown-button-popover-menu"'
            onClick={handleClickActions}
        >
            {
                actions.map((action: ArgActionDropdownButtonAction) => {
                    return (
                        <ArgMenuItem
                            key={action.key}
                            data-testid={`extra-actions-${action.key}`}
                            className={classNames('&-popover-menu-item')}
                            disabled={action.isDisabled}
                            tooltip={action.tooltip}
                        >
                            {renderText(action.label)}
                        </ArgMenuItem>
                    );
                })
            }
        </ArgMenu>
    );

    const iconName = getIconName(isOpen);
    const toggleOpenState = useCallback(() => {
        setIsOpen(prev => !prev);
    }, []);

    return (
        <Popover
            content={popoverMenu}
            overlayClassName={classNames('&-popover', popoverClassName)}
            placement='bottomLeft'
            trigger='click'
            className={classNames('&', className)}
            overlayStyle={{ minWidth: menuWidth }}
            onOpenChange={handleVisibleChange}
            open={isOpen}
        >
            <div ref={buttonsContainerRef} >
                <ArgButton {...props} className={classNames('&-action')} />
                <ArgButton
                    className={classNames('&-dropdown')}
                    icon={iconName}
                    size={size}
                    disabled={disabled}
                    onClick={toggleOpenState}
                />
            </div>
        </Popover>
    );
}

function getIconName(isOpen: boolean) {
    if (isOpen) {
        return 'icon-cheveron-up';
    }

    return 'icon-cheveron-down';
}
