import { Tooltip } from 'antd';
import React, { MouseEvent, ReactNode, RefObject, useCallback, useImperativeHandle, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ArgIcon } from '../arg-icon/arg-icon';
import { DEFAULT_TOOLTIP_DELAY } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText, ArgSize } from '../types';
import { findOrCreatePopupArea, getDataTestIdFromProps } from '../utils';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-form-label.less';

const messages = defineMessages({
    required: {
        id: 'common.basic.form-label.Required',
        defaultMessage: 'Required',
    },
});

interface FormLabelProps {
    id?: string;
    className?: ClassValue;
    propertyName?: ArgRenderedText;
    currentCount?: ArgRenderedText;
    totalCount?: ArgRenderedText;
    children?: ReactNode;
    containerRef?: React.Ref<HTMLDivElement>;
    onTrashClick?: (force: boolean) => void;
    type?: 'property';
    onInfoMouseEnter?: (event: MouseEvent) => void;
    onInfoMouseLeave?: (event: MouseEvent) => void;
    description?: ArgRenderedText;
    infoTooltipMessage?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    addedRow?: boolean;
    size?: ArgSize;
    required?: true | ArgRenderedText;
    disabled?: boolean;
    errorMessage?: ArgRenderedText;

    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
}

export function ArgFormLabel(props: FormLabelProps) {
    const {
        id,
        className,
        propertyName,
        currentCount,
        totalCount,
        children,
        onTrashClick,
        type,
        containerRef: externalContainerRef,
        onInfoMouseEnter,
        onInfoMouseLeave,
        description,
        errorMessage,
        infoTooltipMessage,
        messageValues,
        size = 'large',
        required,
        getPopoverContainer,
        disabled,
    } = props;
    const classNames = useClassNames('arg-form-label');
    const dataTestId = getDataTestIdFromProps(props);

    const handleTrashClick = useCallback((event: MouseEvent) => {
        onTrashClick && onTrashClick(event.metaKey || event.ctrlKey);
    }, [onTrashClick]);

    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(externalContainerRef, () => containerRef.current!);

    const computePopoverContainer = useCallback(
        (triggerNode: HTMLElement) => {
            if (typeof getPopoverContainer === 'function') {
                const ret = (getPopoverContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

                return ret;
            }

            if (getPopoverContainer?.current) {
                return getPopoverContainer?.current!;
            }

            if (containerRef.current) {
                return findOrCreatePopupArea(containerRef.current) || containerRef.current.ownerDocument.body;
            }

            return document.body;
        },
        [getPopoverContainer]
    );

    const renderPropertyInfo = () => {
        if (!infoTooltipMessage) {
            return undefined;
        }

        const title = renderText(infoTooltipMessage, messageValues);

        return (
            <Tooltip
                title={title}
                placement='right'
                mouseEnterDelay={DEFAULT_TOOLTIP_DELAY}
                getPopupContainer={computePopoverContainer}
            >
                <div className={classNames('&-info-icon')}>
                    <ArgIcon name='icon-information-outline'/>
                </div>
            </Tooltip>
        );
    };

    const _propertyName: ReactNode = renderText(propertyName, messageValues);

    const _totalCount: ReactNode = renderText(totalCount, messageValues);

    const _currentCount: ReactNode = renderText(currentCount, messageValues);

    let _required: ReactNode;
    if (required === true) {
        _required = <FormattedMessage {...messages.required} values={messageValues}/>;
    } else {
        _required = renderText(required, messageValues);
    }

    const cls: ClassValue = {
        [`size-${size}`]: true,
        required,
    };
    if (type) {
        cls[`type-${type}`] = true;
    }

    return (
        <div id={id} className={classNames('&', className, cls)} data-testid={dataTestId} ref={containerRef}>
            <div
                className={classNames(props.addedRow ? '&-added-row' : '&-property-info')}
                data-testid='form-label-info'
                onMouseEnter={onInfoMouseEnter}
                onMouseLeave={onInfoMouseLeave}
            >

                <label className={classNames('&-property-name')}>
                    {_propertyName}
                    {renderPropertyInfo()}
                </label>
                <span className={classNames('&-property-count')} data-testid='form-label-info-counts'>
                    {_currentCount !== undefined && (
                        <>
                            {_currentCount}
                            <span className={classNames('&-property-count-separator')}>/</span>
                        </>
                    )}
                    {_totalCount}
                    {required && <span className={classNames('&-property-required')}>{_required}</span>}
                </span>

                {onTrashClick && !disabled && (
                    <button
                        type='button'
                        className={classNames('&-trash-button')}
                        data-testid='form-label-trash-button'
                        onClick={handleTrashClick}
                    >
                        <i className={classNames('icon-trash', 'icon', '&-trash-button-icon')}/>
                    </button>
                )}
            </div>
            {children}
            {description && (
                <div className={classNames('&-description')}>
                    {renderText(description, messageValues)}
                </div>
            )}
            {errorMessage && (
                <div className={classNames('&-error-message')}>
                    {renderText(errorMessage, messageValues)}
                </div>
            )}
        </div>
    );
}
