import React, { ReactNode, useRef } from 'react';
import EventEmitter from 'eventemitter3';

import { ProgressMonitor } from './progress-monitor';
import { $yield } from '../utils/yield';

interface GlobalProgressEventType {
    Change: (show: boolean) => void;
}

export class GlobalProgress extends EventEmitter<GlobalProgressEventType> {
    #progressMonitors: ProgressMonitor[] = [];
    #visible = false;

    constructor() {
        super();
    }

    register(progressMonitor: ProgressMonitor) {
        this.#progressMonitors.push(progressMonitor);

        progressMonitor.addListener('Begin', this.#update);
        progressMonitor.addListener('Cancel', this.#update);
        progressMonitor.addListener('Done', this.#update);

        this.#update();
    }

    #update = (progressMonitor?: ProgressMonitor) => {
        if (progressMonitor?.isDone || progressMonitor?.isCancelled) {
            progressMonitor.removeListener('Begin', this.#update);
            progressMonitor.removeListener('Cancel', this.#update);
            progressMonitor.removeListener('Done', this.#update);
        }

        const oldList = this.#progressMonitors;
        const newList = oldList.filter((p) => (!p.isDone && !p.isCancelled));

        this.#progressMonitors = newList;

        const newVisible = newList.length > 0;

        if (newVisible === this.#visible) {
            return;
        }

        this.#visible = newVisible;

        $yield(() => {
            this.emit('Change', newVisible);
        });
    };
}


export const GlobalProgressContext = React.createContext<GlobalProgress>(new GlobalProgress());

export interface GlobalProgressContextProviderProps {
    children?: ReactNode;
}

export const GlobalProgressContextProvider = (props: GlobalProgressContextProviderProps) => {
    const { children } = props;

    const globalProgress = useRef<GlobalProgress>();
    if (!globalProgress.current) {
        globalProgress.current = new GlobalProgress();
    }

    return (
        <GlobalProgressContext.Provider value={globalProgress.current}>
            {children}
        </GlobalProgressContext.Provider>
    );
};

