import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { isEqual, isFunction } from 'lodash';


export function useStateEquals<S>(initialState: S | (() => S), debugName?: string): [S, Dispatch<SetStateAction<S>>] {
    const [value, setter] = useState<S>(initialState);

    const mySetter = useCallback((newValue: SetStateAction<S>) => {
        const setterStack: Error | undefined = (process.env.NODE_ENV !== 'production') ? (new Error) : undefined;

        setter((prevValue: S) => {
            if (!isFunction(newValue)) {
                if (isEqual(prevValue, newValue)) {
                    return prevValue;
                }

                if (process.env.NODE_ENV !== 'production') {
                    if (debugName) {
                        console.log('useStateEquals[', debugName, '] change prev=', prevValue, 'to=', newValue, 'stack=', setterStack);
                    }
                }

                return newValue;
            }

            const computedNewValue = newValue(prevValue);
            if (isEqual(prevValue, computedNewValue)) {
                return prevValue;
            }

            if (process.env.NODE_ENV !== 'production') {
                if (debugName) {
                    console.log('useStateEquals[', debugName, '] change prev=', prevValue, 'to=', computedNewValue, 'stack=', setterStack);
                }
            }

            return computedNewValue;
        });
    }, [setter]);


    return [value, mySetter];
}
